import { format } from 'date-fns'
import { sr } from 'date-fns/locale'

export function getDate(isoString: string): string {
    const date = new Date(isoString)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()

    return `${day}/${month}/${year}`
}

export const dateFormat: string = 'dd.MMM.yyyy'
export const dateAndTimeFormat: string = 'dd.MMM.yyyy HH:mm'

export function formatCyrillicDateWithCapitalizedMonth(date: any, selectedFormat: string = dateFormat) {
    const formatted = format(date, selectedFormat, { locale: sr })
    return formatted.replace(/(?<=\d{2}\.)\s*([а-я])/i, (c) => c.toUpperCase())
}
