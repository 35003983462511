import React, { useState } from 'react'
import { Control, useWatch } from 'react-hook-form'
import ComplexTable from '../../../../../components/Tables/ComplexTable'
import ActionButtons from '../../../../../components/ActionButtons'
import { Form7_2Entity } from '../../../../../models/Form7/Entities/Form7_2.entity'
import { sumValues } from '../../../../../utils/mathUtils'

export interface StepInterface {
    control?: Control<Form7_2Entity>
    nextStep: (skip?: boolean) => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
}

interface RowInterface {
    label: string
    fields: (keyof Form7_2Entity)[]
}

const rowsTable: RowInterface[] = [
    { label: 'Плаћени посао', fields: ['o7_2_k1_z_placeni', 'o7_2_k1_m_placeni'] },
    { label: 'Неплаћени посао', fields: ['o7_2_k1_z_neplaceni', 'o7_2_k1_m_neplaceni'] },
    { label: 'Учење', fields: ['o7_2_k1_z_ucenje', 'o7_2_k1_m_ucenje'] },
    { label: 'Личне потребе', fields: ['o7_2_k1_z_licne', 'o7_2_k1_m_licne'] },
    { label: 'Слободно време', fields: ['o7_2_k1_z_slobodno', 'o7_2_k1_m_slobodno'] },
    { label: 'Остало', fields: ['o7_2_k1_z_ostalo', 'o7_2_k1_m_ostalo'] },
]

const allFieldsTable = [
    'o7_2_k1_z_placeni',
    'o7_2_k1_z_neplaceni',
    'o7_2_k1_z_ucenje',
    'o7_2_k1_z_licne',
    'o7_2_k1_z_slobodno',
    'o7_2_k1_z_ostalo',

    'o7_2_k1_m_placeni',
    'o7_2_k1_m_neplaceni',
    'o7_2_k1_m_ucenje',
    'o7_2_k1_m_licne',
    'o7_2_k1_m_slobodno',
    'o7_2_k1_m_ostalo',
] as const

const allMaleFieldsTable = [...allFieldsTable.filter((e) => e.includes('_m_'))] as const
const allFemaleFieldsTable = [...allFieldsTable.filter((e) => e.includes('_z_'))] as const

type AllFieldsType = (typeof allFieldsTable)[number]

function Step1({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_2Entity>({ control })
    const fieldsTable: AllFieldsType[] = [...allFieldsTable]
    const sumMale = sumValues(watchedValues, [...allMaleFieldsTable])
    const sumFemale = sumValues(watchedValues, [...allFemaleFieldsTable])

    const disabledNext = !fieldsTable.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })
    const errorMessage = (sumMale != 24 || sumFemale != 24) && !disabledNext

    const goToNextStep = () => {
        if (!errorMessage && !disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">1. Радни дани - Број проведених сати:</h1>
            <ComplexTable
                rows={rowsTable}
                control={control}
                allFemaleFields={[...allFemaleFieldsTable]}
                allMaleFields={[...allMaleFieldsTable]}
                allFields={[...allFieldsTable]}
                header={['', 'Број сати жене', 'Жене %', 'Број сати мушкарци', 'Мушкарци %', 'Укупно', 'Укупно %']}
                errors={errors}
                disabled={adminView}
                decimal
            />

            <p className={`mt-5 h-5 text leading-5 ml-[50px] ${errorMessage ? 'text-[#d32f2f]' : 'text-[#000000]'} text-[12px]`}>
                {!errorMessage && !disabledNext ? '' : 'Збир броја сати у колони за жене и у колони за мушкарце мора бити 24 појединачно.'}
            </p>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step1
