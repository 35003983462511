import React from 'react'
import { StepInterfaceObl2 } from './Step1'
import Textbox from '../../../components/Common/Textbox'
import ActionButtons from '../../../components/ActionButtons'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import { useWatch } from 'react-hook-form'

const Step15 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterfaceObl2) => {
    const watchedValues = useWatch<TblOblast2>({ control })

    const fields: ('o2_k15_naziv' | 'o2_k15_opis')[] = ['o2_k15_naziv', 'o2_k15_opis']

    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e] !== '')

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name">
                Усвојени развојни планови и други акти у областима из надлежности јединице територијалне аутономије или јединица локалне самоуправе, који садрже родну перспективу
            </h1>
            <div className="mt-[50px]">
                <h4 className="mb-[10px]">Назив развојних планова и других аката:*</h4>
                <Textbox
                    name="o2_k15_naziv"
                    control={control}
                    placeholder="Текст (2048 карактера)*"
                    errors={errors}
                    maxNumberOfCharacters={2048}
                    isRequired={adminView ? false : 'Попуните обавезно поље.'}
                    disabled={adminView}
                />
            </div>
            <div className="mt-[50px]">
                <h4 className="mb-[10px] text-disabled">Сажети опис развојних планова и других аката:*</h4>
                <Textbox
                    name="o2_k15_opis"
                    control={control}
                    placeholder="Текст (2048 карактера)*"
                    maxNumberOfCharacters={2048}
                    errors={errors}
                    isRequired={adminView ? false : 'Попуните обавезно поље.'}
                    disabled={adminView}
                />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step15
