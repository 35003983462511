import React, { useEffect } from 'react'
import { StepInterface } from './Step1'
import { getAnalyticsDataForForm2 } from '../../../../server/server'
import PositiveNumberInputCell from '../../../Form2/Components/PositiveNumberInputCell'
import ActionButtons from '../../../../components/ActionButtons'

const Step8 = ({
    control,
    nextStep,
    previousStep,
    previousButtonDisabled = false,
    errors,
    adminView,
    countType,
    setValue,
    selectedCategories,
    selectedSubCategories,
}: StepInterface) => {
    const header = [
        'Укупна вредност неплаћеног кућног рада особа оба пола, нa годишњем нивоу исказана у динаримa (РСД):',
        'Укупан број сати особа оба пола које проводе у неплаћеном кућном раду, исказаних на годишњем нивоу',
        'Укупна вредност у РСД које жене у јединици територијалне аутономије или јединици локалне самоуправе проводе у неплаћеном кућном раду, исказаних на годишњем нивоу:',
        'Укупан број сати које жене у јединици територијалне аутономије или јединици локалне самоуправе проводе у неплаћеном кућном раду, исказаних на годишњем нивоу:',
        'Укупна вредност у РСД које мушкарци у јединици територијалне аутономије или јединици локалне самоуправе проводе у неплаћеном кућном раду, исказаних на годишњем нивоу:',
        'Укупан број сати које мушкарци у јединици територијалне аутономије или јединици локалне самоуправе проводе у неплаћеном кућном раду, исказаних на годишњем нивоу:',
    ]

    const rows = [
        { field: 'o4_k8_br1', decimal: true },
        { field: 'o4_k8_br2', decimal: false },
        { field: 'o4_k8_br3', decimal: true },
        { field: 'o4_k8_br4', decimal: false },
        { field: 'o4_k8_br5', decimal: true },
        { field: 'o4_k8_br6', decimal: false },
    ]
    const fields: ('o4_k8_br1' | 'o4_k8_br2' | 'o4_k8_br3' | 'o4_k8_br4' | 'o4_k8_br5' | 'o4_k8_br6')[] = [
        'o4_k8_br1',
        'o4_k8_br2',
        'o4_k8_br3',
        'o4_k8_br4',
        'o4_k8_br5',
        'o4_k8_br6',
    ]

    useEffect(() => {
        loadData()
    }, [countType, selectedCategories, selectedSubCategories])

    const loadData = async () => {
        const data = {
            type: countType,
            fields: fields,
            category: selectedCategories?.map((e) => e.name),
            subCategory: selectedSubCategories?.map((e) => e.name),
        }
        const resp = await getAnalyticsDataForForm2(data)
        if (resp.status === 200) {
            const data = resp.data
            console.log('🚀 ~ loadData ~ data:', data)
            fields.forEach((field) => {
                setValue(field, parseFloat(data[field]))
            })
        }
    }
    return (
        <div>
            <h1 className="step-name mb-[20px]">
                Подаци о неплаћеном кућном раду на нивоу јединице територијалне аутономије или јединице локалне самоуправе исказани за годину извештавања, разврстани по полу:
            </h1>
            <div className=" flex flex-row gap-2">
                <table className="simple-table">
                    <thead>
                        <tr className="simple-table-header">
                            {header?.map((head, index) => (
                                <th className="simple-table-cell-tall" key={index}>
                                    {head}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="simple-table-row pt-[6px]">
                            {rows?.map((row, id) => (
                                <td key={id} className="simple-table-cell editable !border-none !px-[0px] pt-">
                                    <PositiveNumberInputCell
                                        name={row.field}
                                        control={control}
                                        defaultValue=""
                                        decimal={row.decimal}
                                        errors={errors}
                                        isRequired={false}
                                        disabled={true}
                                    />
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step8
