import React from 'react'
import { StepInterface } from './Step1'
import UploadFile from '../../../../../components/UploadFile'
import ActionButtons from '../../../../../components/ActionButtons'
import { Form7_5Entity } from '../../../../../models/Form7/Entities/Form7_5.entity'
import { useWatch } from 'react-hook-form'
import { isNotEmpty } from '../../../../../utils/Validation'

const Step8 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const field = 'o7_5_k8_fajl'

    const disabledNext = isNotEmpty(field)
    const goToNextStep = () => {
        // if (!disabledNext) nextStep()
        // else if (adminView) nextStep()
        nextStep()
    }
    return (
        <div className="mt-10 gap-10">
            <h1>
                Молимо вас да приложите "Преглед броја случајева породичног насиља разврстаних по полу, сродству и врсти насиља" за годину извештавања, сачињеног од стране
                Републичког завода за социјалну заштиту:
            </h1>
            <UploadFile control={control} name={'o7_5_k8_fajl'} disabled={adminView} errors={errors} isRequired={false} form7 />

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step8
