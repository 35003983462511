import { TblOblast4 } from '../../../models/Form4/Form4.entity'
import '../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import Textbox from '../../../components/Common/Textbox'
import { StepInterface } from './Step1'

function Step5({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast4>({ control })

    const fields: 'o4_k5_nacin'[] = ['o4_k5_nacin']
    const disabledNext = !fields?.every((e) => (e in watchedValues ? !!watchedValues[e] : false))

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name mb-[20px]">Начин спровођења и контроле примене посебних мера</h1>
            <Textbox
                name="o4_k5_nacin"
                control={control}
                maxNumberOfCharacters={2048}
                errors={errors}
                isRequired={adminView ? false : 'Попуните обавезно поље.'}
                disabled={adminView}
            />
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step5
