import '../../../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../../../components/ActionButtons'
import { Control, useWatch } from 'react-hook-form'
import Textbox from '../../../../Contact/TextBoxAutoSize'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { Form7_8Entity } from '../../../../../models/Form7/Entities/Form7_8.entity'
import { StepInterface } from './Step1'
import SimpleTable from '../../../../../components/Tables/SimpleTable'
import ComplexTable from '../../../../../components/Tables/ComplexTable'

interface RowInterface {
    label: string
    fields: (keyof Form7_8Entity)[]
}

const rowsTable1: RowInterface[] = [
    { label: 'Роми', fields: ['o7_8_k3_t1_z_romi', 'o7_8_k3_t1_m_romi'] },
    { label: 'Особе са инвалидитетом', fields: ['o7_8_k3_t1_z_invalid', 'o7_8_k3_t1_m_invalid'] },
]

const allFieldsTable1 = [
    'o7_8_k3_t1_z_romi',
    'o7_8_k3_t1_z_invalid',
    'o7_8_k3_t1_m_romi',
    'o7_8_k3_t1_m_invalid',
] as const

const allMaleFieldsTable1 = [...allFieldsTable1.filter((e) => e.includes('_m_'))] as const
const allFemaleFieldsTable1 = [...allFieldsTable1.filter((e) => e.includes('_z_'))] as const

const rowsTable2: RowInterface[] = [
    { label: 'Роми', fields: ['o7_8_k3_t2_z_romi', 'o7_8_k3_t2_m_romi'] },
    { label: 'Особе са инвалидитетом', fields: ['o7_8_k3_t2_z_invalid', 'o7_8_k3_t2_m_invalid'] },
]

const allFieldsTable2 = [
    'o7_8_k3_t2_z_romi',
    'o7_8_k3_t2_z_invalid',
    'o7_8_k3_t2_m_romi',
    'o7_8_k3_t2_m_invalid',
] as const

const allMaleFieldsTable2 = [...allFieldsTable2.filter((e) => e.includes('_m_'))] as const
const allFemaleFieldsTable2 = [...allFieldsTable2.filter((e) => e.includes('_z_'))] as const

const joinValues = [...allFieldsTable1, ...allFieldsTable2] as const
type AllFieldsType = (typeof joinValues)[number]
    

export function Step3({ control, nextStep, previousStep, previousButtonDisabled = false, errors, clearErrors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_8Entity>({ control })
    const fieldsTable: AllFieldsType[] = [...joinValues]

    const disabledNext = !fieldsTable.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name" style={{ maxWidth: '900px' }}>Број девојчица и дечака из осетљивих група (Рома/Ромкиња, девојчица/дечака са инвалидитетом) који су у школској години која се завршила у години извештавања уписали школску годину у средњој школи</h1>
            <ComplexTable
                rows={rowsTable1}
                control={control}
                allFemaleFields={[...allFemaleFieldsTable1]}
                allMaleFields={[...allMaleFieldsTable1]}
                allFields={[...allFieldsTable1]}
                header={['Осетљива група', 'Уписани број Ж', 'Уписани проценат Ж', 'Уписани број М', 'Уписани проценат Ж']}
                errors={errors}
                disabled={adminView}
                showTotalSum={false}
            />
            <h1 className="step-name mt-[40px]" style={{ maxWidth: '900px' }}>Број девојчица и дечака из осетљивих група (Рома/Ромкиња, девојчица/дечака са инвалидитетом) који су се исписали из средње школе у школској години која се завршила у години извештавања</h1>
            <ComplexTable
                rows={rowsTable2}
                control={control}
                allFemaleFields={[...allFemaleFieldsTable2]}
                allMaleFields={[...allMaleFieldsTable2]}
                allFields={[...allFieldsTable2]}
                header={['Осетљива група', 'Исписани број Ж', 'Исписани проценат Ж', 'Исписани број М', 'Исписани проценат Ж']}
                errors={errors}
                disabled={adminView}
                showTotalSum={false}
            />
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
