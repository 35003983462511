import React, { useEffect, useState } from 'react'
import { createCategory, getAllCategories, updateCategory } from '../../server/server'
import HelpButton from '../../components/Common/HelpButton'
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import SortArrow from '../User/Components/SortArrow'
import { EditCategoryNameModal } from './EditCategoryNameModal'

interface Category {
    id: string
    name: string
    parent: Category
}

const CategoriesTable = () => {
    const [modalOpen, setModalOpen] = useState(false)
    const [createOpen, setCreateOpen] = useState(false)
    const [categories, setCategories] = useState<Category[]>([])
    const [parentCategories, setParentCategories] = useState<Category[]>([])
    const [selectedCategory, setSelectedCategory] = useState<Category>()
    const [searchTerm, setSearchTerm] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        fetchCategories()
    }, [])

    const fetchCategories = async () => {
        const resp = await getAllCategories()
        if (resp.status === 200) {
            setCategories(resp.data)

            const parentCandidates = resp.data.filter((category: Category) => !category.parent)
            setParentCategories(parentCandidates)
        }
    }

    const onClose = () => {
        setModalOpen(false)
    }
    const onConfirm = async (payload: any) => {
        setModalOpen(false)

        const data = {
            id: selectedCategory?.id,
            name: payload.name,
            parentId: payload.parentId,
        }

        const resp = await updateCategory(data)
        console.log('🚀 ~ onConfirm ~ resp:', resp)

        if (resp.status === 201) {
            alert('Успешно измењена категорија')
            setSelectedCategory(undefined)
            fetchCategories()
        } else {
            alert('Грешка при измени категорије')
        }
    }

    const onCreateConfirm = async (newCategoryData: any) => {
        console.log(newCategoryData)
        setCreateOpen(false)
        const resp = await createCategory(newCategoryData)
        if (resp.status === 201) {
            alert('Успешно направљена категорија')
            fetchCategories()
        } else {
            alert('Грешка при прављењу категорије')
        }
    }

    return (
        <div className="px-[100px] mt-10">
            <div className="flex flex-row items-center justify-start mb-2">
                <div className="flex flex-row gap-8 mb-4 justify-between w-full">
                    <h2 className="text-[24px] font-light max-w-[800px]">Управљање категоријама</h2>
                    <div className="flex flex-row gap-5">
                        <Button
                            variant="contained"
                            style={{ backgroundColor: '#243964', color: 'white' }}
                            onClick={() => {
                                setCreateOpen(true)
                            }}
                        >
                            Додај подкатегорију
                        </Button>
                        <HelpButton name="categories_help" />
                    </div>
                </div>
            </div>
            <Paper>
                <TableContainer>
                    <Table className="overflow-x-auto w-full">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <div className="flex flex-row items-center justify-between gap-2 cursor-pointer min-w-[200px]">
                                        <p>Назив категорије</p>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="flex flex-row items-center justify-between gap-2 cursor-pointer min-w-[200px]">
                                        <p>Родитељ категорија</p>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="flex flex-row items-center justify-between gap-2 cursor-pointer min-w-[200px]">
                                        <p>Акције</p>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {categories?.map((c) => (
                                <TableRow key={c?.id}>
                                    <TableCell>{c.name}</TableCell>
                                    <TableCell>{c.parent ? c.parent.name : '-'}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: '#243964', color: 'white' }}
                                            onClick={() => {
                                                setSelectedCategory(c)
                                                setModalOpen(true)
                                            }}
                                        >
                                            Измена назива категорије
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <EditCategoryNameModal isOpen={modalOpen} onClose={onClose} onConfirm={onConfirm} category={selectedCategory} parentCategories={parentCategories} />
            <EditCategoryNameModal
                isOpen={createOpen}
                onClose={() => {
                    setCreateOpen(false)
                }}
                onConfirm={onCreateConfirm}
                parentCategories={parentCategories}
            />
        </div>
    )
}

export default CategoriesTable
