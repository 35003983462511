import { TblOblast1 } from '../../../models/Form1/Form1.entity'
import '../../../assets/styles/ComplexTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import { StepInterface } from './Step1'
import PositiveNumberInputCell from '../../Form2/Components/PositiveNumberInputCell'

function Step12({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast1>({ control })
    const fields: ['o1_k12_br_sp_zap'] = ['o1_k12_br_sp_zap']
    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const skip = watchedValues.o1_k12_br_sp_zap === 0

    const goToNextStep = () => {
        if (!disabledNext && !skip) nextStep()
        else if (!disabledNext && skip) nextStep(skip)
        else if (adminView) nextStep()
    }
    return (
        <div>
            <h1 className="step-name">12. Број судских спорова који су окончани у корист запосленог, у години вођења евиденције:</h1>
            <div className=" w-[140px] h-[50px] flex items-center justify-center mt-[40px]">
                <PositiveNumberInputCell name={'o1_k12_br_sp_zap'} control={control} defaultValue="" errors={errors} isRequired={!adminView} height={52} disabled={adminView} />
            </div>
            <div className="h-[2px]"> {errors?.o1_k12_br_sp_zap && <p className="text leading-5 text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step12
