import React, { useEffect, useState } from 'react'
import { getParentCategories } from '../../../server/server'
import Loading from '../../../components/Common/LoadingSpinner'
import RadioButtonsControl from '../../../components/Common/RadioButtonsControlWithError'
import { Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material'
import { useUser } from '../../../auth/UseUser'

const CategorySelectionStep1 = ({ setSelected, nextStep }: any) => {
    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const { user } = useUser()
    useEffect(() => {
        getParents()
    }, [])

    const getParents = async () => {
        setLoading(true)
        const resp = await getParentCategories()
        if (resp.status === 200) {
            if (!user.noPib) {
                setCategories(resp.data)
            } else {
                setCategories(resp.data.filter((category: any) => category.name === 'Тела за родну равноправност'))
            }
        }
        setLoading(false)
    }

    const onChange = (cat: any) => {
        setSelectedCategory(cat.id)
        setSelected(cat)
        setErrorMessage(null)
    }

    const handleNext = () => {
        if (selectedCategory) {
            nextStep()
        } else {
            setErrorMessage('Молимо вас да одаберете категорију.')
        }
    }
    return (
        <div className="w-full h-full flex items-center justify-center flex-col">
            <div className="max-w-[650px] min-w-[600px]">
                <p>Поштовани, како бисте током извештавања правилно испунили своју законску обавезу молимо Вас да пажљиво одаберете којој категорији обвезника припадате.</p>
                <p className="mt-[20px]">Овај чаробњак ће Вам помоћи да се на лак начин правилно разврстате:</p>
                {loading ? (
                    <Loading height={50} />
                ) : (
                    <FormControl className="!mt-[40px]">
                        <p className="mb-[20px]">{user.noPib ? 'Одаберите:' : 'Да ли сте:'}</p>
                        <RadioGroup>
                            {categories.map((cat: any) => (
                                <FormControlLabel value={cat.id} control={<Radio />} onChange={() => onChange(cat)} label={cat.name} />
                            ))}
                        </RadioGroup>
                        {errorMessage && <FormHelperText className="!text-textError !text-[15px] !text-center">{errorMessage}</FormHelperText>}
                    </FormControl>
                )}
                <div className="flex flex-row justify-between mt-[30px] w-full max-w-[600px]">
                    <button className=" invisible w-full h-[42px] flex items-center justify-center rounded-[4px] border border-[#243964] text-[15px] text-[#243964] font-medium leading-[26px] cursor-pointer mt-[43px] max-w-[200px]">
                        НАЗАД
                    </button>
                    <button
                        className="w-full h-[42px] flex items-center justify-center rounded-[4px] !bg-[#243964] text-[15px] text-[#ffffff] font-medium leading-[26px] cursor-pointer' mt-[43px] max-w-[200px]"
                        onClick={handleNext}
                    >
                        ДАЉЕ
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CategorySelectionStep1
