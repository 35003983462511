import { File } from '../../File.entity'
import { Organization } from '../../Organization.entity'
import { SubmittedBy } from '../../SubmittedBy.entity'
import { FileDescription } from './Common/FileDescription.entity'

export const NumberOfSteps = 5

export interface Form7_7Entity {
    report_id: string
    rep_year: number
    submitted_date?: Date
    submitted_place?: Date
    category: string
    organisation: Organization
    status: string

    o7_7_k1_revised_programs_count: number
    o7_7_k1_gender_equality_programs_count: number
    o7_7_k1_professional_development_programs_count: number
    o7_7_k1_bylaws_count: number

    o7_7_k2_z: number
    o7_7_k2_m: number
    o7_7_k2_accredited_trainings: number

    o7_7_k3_z: number
    o7_7_k3_m: number

    o7_7_k4_dokumentaIzvestavanja: FileDescription[]
    o7_7_k4_files: File[]


    submitted_by?: SubmittedBy
}
