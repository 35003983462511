import { StepInterfaceObl2 } from './Step1'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import { useWatch } from 'react-hook-form'
import Textbox from '../../../components/Common/Textbox'
import ActionButtons from '../../../components/ActionButtons'
import RadioButtonsControl from '../../../components/Common/RadioButtonsControlWithError'

const Step8 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterfaceObl2) => {
    const watchedValues = useWatch<TblOblast2>({ control })

    const fields: ('o2_k8_procena' | 'o2_k8_opis')[] = ['o2_k8_procena', 'o2_k8_opis']

    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name">Полазнa оцена стањa у области родне равноправности, у години вођења евиденције:</h1>
            <RadioButtonsControl
                control={control}
                name={fields[0]}
                options={['Одлично', 'Врло добро', 'Задовољава', 'Недовољно']}
                errors={errors}
                enumerated
                disabled={adminView}
            />
            <div className="mt-[50px] ">
                <h4 className="mb-[10px] text-disabled">Навести опис постојећег стања и идентификоване изазове за остваривање и унапређење родне равноправности:*</h4>
                <Textbox
                    name="o2_k8_opis"
                    control={control}
                    placeholder="Текст (200 карактера)*"
                    errors={errors}
                    isRequired={adminView ? false : 'Попуните обавезно поље.'}
                    maxNumberOfCharacters={200}
                    disabled={adminView}
                />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step8
