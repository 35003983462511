import React, { useEffect } from 'react'
import { StepInterface } from './Step1'
import { TblOblast2 } from '../../../../models/Form2/Form2.entity'
import SimpleTable from '../../../../components/Tables/SimpleTable'
import ActionButtons from '../../../../components/ActionButtons'
import { getAnalyticsDataForForm2 } from '../../../../server/server'

export interface RowInterface {
    label: string
    field: keyof TblOblast2
}
const rows: RowInterface[] = [
    { label: 'Мушкарци', field: 'o2_k13_m' },
    { label: 'Жене', field: 'o2_k13_z' },
]

const Step13 = ({
    control,
    nextStep,
    previousStep,
    previousButtonDisabled = false,
    errors,
    adminView,
    countType,
    setValue,
    selectedCategories,
    selectedSubCategories,
}: StepInterface) => {
    const fields: ('o2_k13_m' | 'o2_k13_z')[] = ['o2_k13_m', 'o2_k13_z']

    useEffect(() => {
        loadData()
    }, [countType, selectedCategories, selectedSubCategories])

    const loadData = async () => {
        const data = {
            type: countType,
            fields: fields,
            category: selectedCategories?.map((e) => e.name),
            subCategory: selectedSubCategories?.map((e) => e.name),
        }
        const resp = await getAnalyticsDataForForm2(data)
        if (resp.status === 200) {
            const data = resp.data
            fields.forEach((field) => {
                setValue(field, parseFloat(data[field]))
            })
        }
    }
    return (
        <div>
            <h1 className="step-name">
                Број тражилаца и корисника бесплатне правне помоћи у сврху остваривања родне равноправности у години вођења евиденције, разврстаних по полу у следећем формату:
            </h1>
            <div className="flex flex-row gap-2 ">
                <SimpleTable control={control} header={['Пол', 'Број', 'Проценат']} rows={rows} fields={fields} errors={errors} disabled={true} />
            </div>
            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={false} />
        </div>
    )
}

export default Step13
