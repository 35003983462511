import { Button, Checkbox, Stack } from '@mui/material'
import React, { useState } from 'react'

interface ActionButtonsInterface {
    nextButton: () => void
    previousButton?: () => void
    nextButtonDisabled?: boolean
    previousButtonDisabled: boolean
    lastStep?: boolean
    skipButton?: () => void
    dash?: boolean
    closeModal?: () => void
}
function Actions({
    nextButton,
    nextButtonDisabled = false,
    previousButton,
    previousButtonDisabled,
    lastStep = false,
    skipButton,
    dash = false,
    closeModal,
}: ActionButtonsInterface) {
    const [checked, setChecked] = useState(false)
    return (
        <div className={`mt-[50px] flex ${lastStep ? 'flex-col items-center justify-center' : 'justify-between'}`}>
            <div className="flex flex-col max-w-[250px]">
                <div className="flex flex-row items-center">
                    <Checkbox
                        checked={checked}
                        onChange={() => {
                            setChecked(true)
                            if (skipButton) skipButton()
                        }}
                        size="large"
                        sx={{
                            color: '#A2CEFF',
                            '&.Mui-checked': {
                                color: '#A2CEFF',
                            },
                            '&.Mui-disabled': {
                                color: '#A2CEFF',
                            },
                        }}
                    />
                    <p className="text-[14px] leading-[20px] font-light">Не приказуј ми више водич</p>
                </div>
                {!dash && (
                    <Button variant="contained" onClick={closeModal}>
                        Затвори водич
                    </Button>
                )}
            </div>
            {!lastStep && (
                <div className=" max-w-[250px] flex flex-col">
                    <div className="flex flex-row items-center invisible">
                        <Checkbox
                            checked={checked}
                            onChange={() => {
                                setChecked(true)
                                if (skipButton) skipButton()
                            }}
                            size="large"
                            sx={{
                                color: '#A2CEFF',
                                '&.Mui-checked': {
                                    color: '#A2CEFF',
                                },
                                '&.Mui-disabled': {
                                    color: '#A2CEFF',
                                },
                            }}
                        />
                        <p className="text-[14px] leading-[20px] font-light">Не приказуј ми више водич</p>
                    </div>
                    {lastStep ? (
                        <Button variant="contained" type="submit" title="Заврши и пошаљи" disabled={nextButtonDisabled} onClick={nextButton}>
                            Затвори упутство
                        </Button>
                    ) : (
                        <Button variant="contained" type="submit" title="Следећи корак" disabled={nextButtonDisabled} onClick={nextButton}>
                            Следећа страница
                        </Button>
                    )}
                </div>
            )}
        </div>
    )
}

export default Actions
