import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Checkbox } from '@mui/material'
import { getAllCities, getAPREntityByMaticniBroj, getAPREntityByPIB, getLegalInfo, registerStep2, whoAmI } from '../../../server/server'
import { useUser } from '../../../auth/UseUser'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { VirtualizedAutocomplete } from '../../Common/VirtualizedAutocomplete'
import PrivacyAndPolicyModal from '../../Common/PrivacyAndPolicyModal'
import HelpButton from '../../Common/HelpButton'

export interface RegisterStepInterface {
    handleSubmit: any
    onSubmit: any
    errors: any
    register: any
    control?: any
    setValue?: any
    error?: any
    setError?: any
}
export type CityDto = {
    id?: string
    name: string
    zip: number
    region: string
    municipality?: string
}

export type RegisterStep2Inputs = {
    pib: number
    mb: number
    subjectName: string
    firstName: string
    lastName: string
    address: string
    phoneNumber: string
    city: CityDto
    cb1: boolean
    cb2: boolean
    cb3: boolean
}

const SignUpStep2 = () => {
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        control,
        watch,
        formState: { errors },
    } = useForm<RegisterStep2Inputs>()
    const { user, setUser } = useUser()
    const navigate = useNavigate()
    const [message, setMessage] = useState('')
    const fields: { label: string; field: keyof RegisterStep2Inputs }[] = [
        { label: 'ПИБ', field: 'pib' },
        { label: 'Матични број', field: 'mb' },
        // { label: 'Назив субјекта', field: 'subjectName' },
        { label: 'Назив oбвезника', field: 'subjectName' },
        { label: 'Име овлашћеног лица', field: 'firstName' },
        { label: 'Презиме овлашћеног лица', field: 'lastName' },
        { label: 'Адреса', field: 'address' },
        { label: 'Број телефона', field: 'phoneNumber' },
        // { label: 'Електронска адреса', field: 'email2' },
        // { label: 'Категорија', field: 'category' },
        // { label: 'CB1', field: 'cb1' },
        // { label: 'CB2', field: 'cb2' },
    ]
    const isCommissionMember = watch('cb3')
    const [cities, setCities] = useState<any>()
    const [modalVisible, setModalVisible] = useState(false)
    const [legalInfo, setLegalInfo] = useState<any>()
    const [autocompletedCity, setAutocompletedCity] = useState<any>()
    const [pibValue, setPibValue] = useState('')
    const [mbValue, setMbValue] = useState('')

    useEffect(() => {
        getCities()
        getLegal()
    }, [])

    // Watch PIB and MB values
    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'pib') {
                setPibValue(value?.pib?.toString() ?? '')
            }
            if (name === 'mb') {
                setMbValue(value?.mb?.toString() ?? '')
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    // Handle PIB validation and auto-completion
    useEffect(() => {
        const fetchPibData = async () => {
            if (pibValue?.toString().length === 9) {
                try {
                    const entity = await getAPREntityByPIB(pibValue.toString())
                    if (entity?.status === 200) {
                        autoComplete(entity.data)
                    }
                } catch (error) {
                    console.error('Error fetching PIB data:', error)
                }
            }
        }

        const timeoutId = setTimeout(fetchPibData, 500)
        return () => clearTimeout(timeoutId)
    }, [pibValue])

    // Handle MB validation and auto-completion
    useEffect(() => {
        const fetchMbData = async () => {
            if (mbValue?.toString().length === 8) {
                try {
                    const entity = await getAPREntityByMaticniBroj(mbValue.toString())
                    if (entity?.status === 200) {
                        autoComplete(entity.data)
                    }
                } catch (error) {
                    console.error('Error fetching MB data:', error)
                }
            }
        }

        const timeoutId = setTimeout(fetchMbData, 500)
        return () => clearTimeout(timeoutId)
    }, [mbValue])

    const getCities = async () => {
        const resp = await getAllCities()
        if (resp.status === 200) {
            setCities(resp.data)
        }
    }

    const getLegal = async () => {
        const resp = await getLegalInfo()
        if (resp.status === 200) {
            setLegalInfo(resp.data)
        }
    }

    const onSubmit: SubmitHandler<RegisterStep2Inputs> = async () => {
        nextStep()
    }

    const nextStep = async () => {
        const data = {
            pib: isCommissionMember ? null : Number(getValues('pib')),
            mb: isCommissionMember ? null : Number(getValues('mb')),
            subjectName: getValues('subjectName'),
            firstName: getValues('firstName'),
            lastName: getValues('lastName'),
            address: getValues('address'),
            phoneNumber: getValues('phoneNumber'),
            email: user.email,
            city: getValues('city'),
            noPib: getValues('cb3'),
        }

        const resp = await registerStep2(data)
        if (resp.status == 201) {
            const data = resp.data
            const oldToken = localStorage.getItem('accessToken') ?? ''
            localStorage.setItem('oldToken', oldToken)
            localStorage.setItem('accessToken', data['accessToken'])
            const whoResp = await whoAmI()
            if (whoResp.status === 200) {
                const user = whoResp.data
                console.log('User: ', user)
                setUser(user)
                // if (!user.category) {
                //     navigate('/user/category-selection')
                // } else {
                //     navigate('/')
                // }
            }
        } else if (resp.status == 305) {
            setMessage('legalEntityExists')
        }
    }

    const getValidationPattern = (field: any, label: any) => {
        switch (field) {
            case 'pib':
                return {
                    value: /^[0-9]{9}$/,
                    message: `${label} мора садржати тачно 9 цифара.`,
                }
            case 'mb':
                return {
                    value: /^[0-9]{8}$/,
                    message: `${label} мора садржати тачно 8 цифара.`,
                }
            case 'firstName':
            case 'lastName':
                return {
                    value: /^[A-Za-zА-Ша-шЉљЊњЏџЂђЈјЋћ\s]+$/,
                    message: `${label} може садржати само слова`,
                }
            default:
                return undefined
        }
    }

    const preventNumbers = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const key = event.key
        if (!/[A-Za-zА-Ша-шЉљЊњЏџЂђЈјЋћ\s]/.test(key) && key !== 'Backspace' && key !== 'Tab' && key !== 'Enter') {
            event.preventDefault()
        }
    }

    const preventLetters = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const key = event.key
        if (!/^[0-9]$/.test(key) && key !== 'Backspace' && key !== 'Tab' && key !== 'Enter') {
            event.preventDefault()
        }
    }
    const preventInvalidPhoneInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const allowedKeys = ['/', '-', '+', 'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight']
        const isNumber = /^\d$/.test(event.key)
        const isAllowedKey = allowedKeys.includes(event.key)

        if (!isNumber && !isAllowedKey) {
            event.preventDefault()
        }
    }

    const autoComplete = (entity: any) => {
        const city = cities?.find((city: CityDto) => city.name.toLocaleLowerCase() === entity.mesto.toLocaleLowerCase())
        if (city) {
            setAutocompletedCity(city.name)
        }
        setValue('pib', entity.pib)
        setValue('mb', entity.maticniBroj)
        setValue('subjectName', entity.naziv)
        setValue('firstName', entity.ovlascenoLiceIme)
        setValue('lastName', entity.ovlascenoLicePrezime)
        setValue('address', entity.ulica + ' ' + entity.broj)
        setValue('city', city)
    }

    return (
        <div className="items-center justify-center flex mb-[50px] relative">
            <div className="absolute top-5 right-10 ">
                <HelpButton name="user_registration_step2_help" />
            </div>
            <div className="pl-[121px] pr-[45px] pb-[156px] pt-[30px] bg-[#ffffff] items-start flex flex-col ">
                <h2 className="mb-[20px] text-[24px] leading-[28px] font-light max-w-[520px] text-center">
                    {/* Наставите са регистрацијом тако што ћете попунити потребне информације */}
                    Регистрација обвезника закона о родној равноправности
                </h2>
                <form className="flex flex-col items-center w-full max-w-[520px]" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="w-full flex flex-col">
                        <div className="flex flex-row  my-[16px] max-w-[520px] min-h-[56px] items-center border border-disabled rounded-[4px]">
                            <Controller
                                name={'cb3'}
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => (
                                    <Checkbox
                                        size="large"
                                        onChange={(e) => {
                                            field.onChange(e.target.checked)
                                        }}
                                    />
                                )}
                            />
                            <span className={`text-[14px] leading-[20px] font-light max-w-[520px]`}>Члан сам Комисије/Савета за родну равноправност (немам ПИБ/МБ)</span>
                        </div>
                        {fields.map((item, index) => {
                            const isDisabled = isCommissionMember && (item.field === 'pib' || item.field === 'mb')
                            return (
                                <div key={index} className="flex flex-col mb-[16px]">
                                    <input
                                        className={`border ${
                                            isDisabled ? 'border-[#0000003B]' : 'border-disabled'
                                        } max-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px] `}
                                        placeholder={item.label}
                                        disabled={isDisabled}
                                        {...register(item.field, {
                                            required: item.field === 'pib' || item.field === 'mb' ? !isCommissionMember : true,
                                            pattern: getValidationPattern(item.field, item.label),
                                        })}
                                        maxLength={item.field === 'pib' ? 9 : item.field === 'mb' ? 8 : undefined}
                                        // onBlur={async () => {
                                        //     if (item.field === 'pib' && !!getValues('pib')) {
                                        //         const entity = await getAPREntityByPIB(getValues('pib').toString())
                                        //         if (entity?.status === 200) {
                                        //             autoComplete(entity.data)
                                        //         }
                                        //     } else if (item.field === 'mb' && !!getValues('mb')) {
                                        //         const entity = await getAPREntityByMaticniBroj(getValues('mb').toString())
                                        //         if (entity?.status === 200) {
                                        //             autoComplete(entity.data)
                                        //         }
                                        //     }
                                        // }}
                                        // onPaste={handlePaste}
                                        onKeyDown={(event) => {
                                            if (item.field === 'firstName' || item.field === 'lastName') {
                                                preventNumbers(event)
                                            } else if (item.field === 'pib' || item.field === 'mb') {
                                                preventLetters(event)
                                            } else if (item.field === 'phoneNumber') {
                                                preventInvalidPhoneInput(event)
                                            }
                                        }}
                                    />
                                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[3px]">
                                        {errors[item.field]?.type === 'required' ? `${item.label} је обавезно поље` : ''}
                                        {errors[item.field]?.type === 'pattern' ? `Молимо унесите исправан формат: ${errors[item.field]?.message} ` : ''}
                                    </span>
                                </div>
                            )
                        })}
                        <Controller
                            name="city"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <VirtualizedAutocomplete
                                    borderColor="#00000061"
                                    items={cities}
                                    label={'Седиште'}
                                    onChange={(newValue: any) => {
                                        onChange(newValue)
                                    }}
                                    disabled={false}
                                    cityValue={autocompletedCity}
                                />
                            )}
                        />
                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[3px]">{errors.city?.type === 'required' ? `Седиште је обавезно поље` : ''}</span>

                        <div className="flex flex-row items-center mt-[16px] max-w-[520px]">
                            <Controller
                                name={'cb1'}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Checkbox
                                        size="large"
                                        onChange={(e) => {
                                            field.onChange(e.target.checked)
                                        }}
                                        sx={{
                                            color: errors.cb1 ? '#d32f2f' : '#A2CEFF',
                                            '&.Mui-checked': {
                                                color: errors.cb1 ? '#d32f2f' : '#A2CEFF',
                                            },
                                        }}
                                    />
                                )}
                            />
                            <span className={`text-[14px] leading-[20px] font-light max-w-[520px] ${errors.cb1 && 'text-[#d32f2f]'}`}>
                                СЛАЖЕМ СЕ СА{' '}
                                <span className="underline cursor-pointer" onClick={() => setModalVisible(!modalVisible)}>
                                    УСЛОВИМА КОРИШЋЕЊА ПОРТАЛА И ПРИХВАТАМ ОПШТЕ УСЛОВЕ И ПОЛИТИКУ ПРИВАТНОСТИ
                                </span>{' '}
                                ПРУЖАЊА УСЛУГЕ ЕЛЕКТРОНСКОГ ИЗВЕШТАВАЊА И ПРАЋЕЊА ПРИМЕНЕ ЗАКОНА О РОДНОЈ РАВНОПРАВНОСТИ
                            </span>
                        </div>

                        <div className="flex flex-row  mt-[16px] max-w-[520px]">
                            <Controller
                                name={'cb2'}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Checkbox
                                        size="large"
                                        onChange={(e) => {
                                            field.onChange(e.target.checked)
                                        }}
                                        sx={{
                                            color: errors.cb2 ? '#d32f2f' : '#A2CEFF',
                                            '&.Mui-checked': {
                                                color: errors.cb2 ? '#d32f2f' : '#A2CEFF',
                                            },
                                        }}
                                    />
                                )}
                            />
                            <span className={`text-[14px] leading-[20px] font-light max-w-[520px] ${errors.cb2 && 'text-[#d32f2f]'}`}>
                                ПОД МАТЕРИЈАЛНОМ И КРИВИЧНОМ ОДГОВОРНОШЋУ ИЗЈАВЉУЈЕМ ДА СУ СВИ ПОДАЦИ ТАЧНИ И У СКЛАДУ СА ПОЗИТИВНИМ ПРОПИСИМА РЕПУБЛИКЕ СРБИЈЕ
                            </span>
                        </div>
                        {message === 'success' ? (
                            <></>
                        ) : (
                            <span className={`${message !== 'legalEntityExists' ? '' : 'text-[#d32f2f]'} max-w-[500px] text-center h-[30px] mt-[20px]`}>
                                {message === 'legalEntityExists' && 'ПИБ или Матични број већ постоји у бази'}
                            </span>
                        )}

                        <button
                            type="submit"
                            className="w-full h-[42px] flex items-center justify-center rounded-[4px] bg-[#243964] text-[15px] text-[#ffffff] font-medium leading-[26px] cursor-pointer mt-[43px] max-w-[520px]"
                        >
                            РЕГИСТРУЈ СЕ
                        </button>
                    </div>
                </form>
                <PrivacyAndPolicyModal modalIsOpen={modalVisible} closeModal={() => setModalVisible(false)} infoText={legalInfo && legalInfo[0]?.terms} />
            </div>
        </div>
    )
}

export default SignUpStep2
