import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { useNavigate } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { editHelpContent } from '../../server/server'
import MDEditor from '@uiw/react-md-editor'

interface HelpRow {
    id: number
    name: string
    title: string
    text: string
    created: string
}

interface ModalInterface {
    modalIsOpen: boolean
    closeModal: () => void
    helpContent?: HelpRow | null
    updateList: any
}

function EditHelpMessageModal({ modalIsOpen, closeModal, helpContent, updateList }: ModalInterface) {
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
        setValue,
    } = useForm<HelpRow>()
    const navigate = useNavigate()
    const [text, setText] = useState<string | undefined>('')

    useEffect(() => {
        if (modalIsOpen && !!helpContent) {
            setValue('name', helpContent.name)
            setValue('title', helpContent.title)
            setText(helpContent.text)
        }
    }, [modalIsOpen])

    const onSubmit: SubmitHandler<HelpRow> = async () => {
        const data = {
            name: getValues('name'),
            title: getValues('title'),
            text: text || '',
        }
        const resp = await editHelpContent(data)
        if (resp.status === 201) {
            alert('Упутство је успешно ажурирано!')
            updateList()
            closeModal()
        } else {
            alert(`Грешка при чувању!, ${resp.status}`)
        }
    }
    return (
        <ReactModal
            ariaHideApp={false}
            //closeTimeoutMS={600}
            isOpen={modalIsOpen}
            onAfterOpen={() => {
                const el = document.getElementById('scroll-target')
                if (el) el.classList.add('no-scroll-behind')
            }}
            onAfterClose={() => {
                const el = document.getElementById('scroll-target')
                if (el) el.classList.remove('no-scroll-behind')
            }}
            onRequestClose={closeModal}
            className="no-scrollbar outline-0"
            style={{
                overlay: {
                    zIndex: 5,
                    background: '#000000A6',
                },
                content: {
                    padding: 0,
                    opacity: 1,
                    width: 800,
                    margin: 'auto',
                    backgroundColor: 'transparent',
                    height: '100%',
                    maxHeight: '100%',
                    border: '0px',
                    boxShadow: 'none',
                    paddingTop: 30,
                    paddingBottom: 15,
                    overflow: 'auto',
                },
            }}
        >
            <div className="bg-[white] px-[30px] py-[30px] rounded-[4px] w-full ">
                <div className="flex flex-col items-center gap-[20px] ">
                    <h2 className="text-[24px] leading-[28px] font-light max-w-[800px]">Ажурирање упутства</h2>
                    <form className="flex flex-col items-center w-full max-w-[800px]" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <div className="flex flex-col w-full mb-[16px]">
                            <label>Наслов упутства</label>
                            <input
                                className="border border-[#0000003B] max-w-full min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                                placeholder="Назив"
                                {...register('title', {
                                    required: true,
                                })}
                            />
                            <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[3px]">{errors.title?.type === 'required' ? 'Назив is required' : ''}</span>
                        </div>
                        <div className="flex flex-col w-full mb-[16px]">
                            <label>Текст</label>
                            <div data-color-mode="light">
                                <MDEditor value={text} onChange={setText} height={450} style={{ backgroundColor: '#ffffff' }} />
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-between gap-[20px] w-full mt-[30px]">
                            <button
                                type="button"
                                onClick={() => closeModal()}
                                className="w-full h-[42px] flex items-center justify-center rounded-[4px] border border-[#243964] text-[15px] text-[#243964] font-medium leading-[26px] cursor-pointer max-w-[520px]"
                            >
                                Откажи
                            </button>
                            <button
                                type="submit"
                                className="w-full h-[42px] flex items-center justify-center rounded-[4px] bg-[#243964] text-[15px] text-[#ffffff] font-medium leading-[26px] cursor-pointer max-w-[520px]"
                            >
                                Сачувај
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </ReactModal>
    )
}

export default EditHelpMessageModal
