import React from 'react'
import { TblOblast6 } from '../../../models/Form6/Form6.entity'

export interface RowInterface {
    label: string
    subtitle: string
    field: number
    color: string
}

function RegisteredCasesTable({ rows }: { rows: RowInterface[] }) {
    return (
        <table className="simple-table mt-[42px] gap-2">
            <tr className="simple-table-header">
                <th className=" w-[296px] h-[54px] text-[14px]">Степен ризика</th>
                <th className=" w-[296px] h-[54px] text-[14px]">Број регистрованих случајева</th>
            </tr>
            {rows.map((row, index) => (
                <tr className="simple-table-row" key={index}>
                    <td className=" disabled w-[296px] h-[54px] text-[14px] leading-5 " style={{ padding: 0, borderColor: row.color }}>
                        <div className=" h-full content-center text-textPrimary" style={{ backgroundColor: row.color }}>
                            {row.label}
                        </div>
                    </td>
                    <td className=" disabled w-[296px] h-[54px]">
                        <div className=" h-full content-center text-textPrimary ">{row.field}</div>
                    </td>
                </tr>
            ))}
        </table>
    )
}

export default RegisteredCasesTable
