import React, { useEffect, useState } from 'react'
import { Button, TextField } from '@mui/material'
import { Category } from './types'
import { convertToCyrillic, convertToLatin } from '../../utils/searchTranslation'

interface CategoryListProps {
    categories: Category[]
    onSelect: (id: string) => void
    type?: 'category' | 'subcategory' | 'entity' | 'pib'
}

export const CategoryList: React.FC<CategoryListProps> = ({ categories, onSelect, type }) => {
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        setSearchTerm('')
    }, [type])

    const normalizeSearchTerm = (term: string): string[] => {
        return [term, convertToCyrillic(term), convertToLatin(term)]
    }

    const matchesSearch = (name: string, searchTerm: string): boolean => {
        const normalizedSearchTerms = normalizeSearchTerm(searchTerm)
        return normalizedSearchTerms.some((normalizedTerm) => name.toLowerCase().includes(normalizedTerm.toLowerCase()))
    }

    return (
        <div className="flex-1 h-full  max-h-[600px] min-h-[600px] flex flex-col ">
            <TextField label="Претрага" variant="outlined" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} style={{ width: '100%', marginBottom: 10 }} />
            <div className=" flex-1 border overflow-y-auto border-[gray] rounded-md gap-[5px] flex flex-col p-[10px]">
                {categories
                    .filter((category) => matchesSearch(category.name, searchTerm))
                    .map((category) => (
                        <Button variant="contained" color={category.selected ? 'primary' : 'inherit'} key={category.id} onClick={() => onSelect(category.id)}>
                            <div>{category.name}</div>
                        </Button>
                    ))}
            </div>
        </div>
    )
}
