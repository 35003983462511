import React from 'react'
import image13 from '../../../../assets/images/FormTutorialImages/13.png'
import image14 from '../../../../assets/images/FormTutorialImages/14.png'
import image15 from '../../../../assets/images/FormTutorialImages/15.png'
import image16 from '../../../../assets/images/FormTutorialImages/16.png'
import image17 from '../../../../assets/images/FormTutorialImages/17.png'

import Actions from '../../Components/Actions'
import { TutorialStepInterface } from './Step1'

const Step4 = ({ nextStep, previousStep, previousButtonDisabled = false, skipClick, onClose }: TutorialStepInterface) => {
    return (
        <div className="flex h-full w-full flex-col items-center">
            <div className="relative w-full h-full">
                <img src={image13} alt="Образац слика 13" width={1000} className="w-full" />
                <img src={image14} alt="Образац слика 14" width={30} className="absolute right-[250px] top-[220px] z-50" />
            </div>
            <div className="mt-10 gap-10 items-start w-full">
                <div className="flex flex-col ml-[100px] gap-6">
                    <div className="flex flex-row items-center gap-4">
                        <img src={image15} alt="Образац слика 15" width={200} />
                        <p className="text-[16px] max-w-[700px]">
                            Када кликнете на дугме „ДОДАЈ ЈОШ“, додаје се нова колона у табели. Ово вам омогућава да унесете додатне информације или вредности које су вам потребне.
                        </p>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                        <img src={image16} alt="Образац слика 16" width={200} />
                        <p className="text-[16px] max-w-[700px]">
                            Када кликнете на падајући мени са натписом „ИЗАБЕРИТЕ“, отвориће се листа опција. Када се листа опција појави, пронађите и кликните на опцију коју
                            желите да изаберете. Након клика на жељену опцију, падајући мени ће се затворити, а изабрана опција ће бити приказана у пољу.
                        </p>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                        <img src={image14} alt="Образац слика 17" width={200} />
                        <p className="text-[16px] max-w-[700px]">Када кликнете на икону канте поред колоне, колона ће бити обрисана.</p>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                        <img src={image17} alt="Образац слика 18" width={200} />
                        <p className="text-[16px] max-w-[700px]">
                            Када кликнете на модул „ПОМОЋ“, бићете преусмерени на страницу са Правилницима о попуњавању образаца. Правилници су у ПДФ формату који ће се отворити
                            након клика.
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-10 w-full max-w-[70%]">
                <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={false} skipButton={skipClick} closeModal={onClose} />
            </div>
        </div>
    )
}

export default Step4
