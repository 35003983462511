import { useEffect, useState } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { Button } from '@mui/material'
import ActionButtons from '../../../../../components/ActionButtons'
import { Form7_11Entity } from '../../../../../models/Form7/Entities/Form7_11.entity'
import { StepInterface } from './Step1'
import Form7_11_k3Table from '../../../Components/Form7_11_k3Table'

export interface RowInterface {
    label: string
    subtitle: string
    field: number
}
const arrayName = 'o7_11_k3_oblici'

const HEADER_LIST = ['Број жртава', 'Облик експлоатације', 'Пол извршиоца', 'Узраст жртве']

function Step3({ control, nextStep, previousStep, previousButtonDisabled = false, errors, setValue, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_11Entity>({ control })

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: arrayName,
    })

    useEffect(() => {
        if (!fields.length) append([{ brojZrtava: undefined }])
    }, [])

    const handleAddField = () => {
        append({ brojZrtava: undefined })
    }

    const validateFields = (): boolean => {
        if (adminView) {
            return true
        }
        const newMap = watchedValues.o7_11_k3_oblici?.map((field) => !!field.brojZrtava && !!field.oblikEksploatacije && !!field.uzrastZrtve && !!field.polPocinioca)
        return newMap?.includes(false) ? true : false
    }

    const disabledNext = validateFields()
    const disabledAddMore = validateFields()

    const goToNexStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name mb-1">3. Број жртава трговине људима, разврстано према полу, узрасту, и облику експлоатације:</h1>

            <Form7_11_k3Table fields={fields} remove={remove} control={control} header={HEADER_LIST} errors={errors} disabled={adminView} />
            <p className="ml-5 min-h-[30px] text-textError text-[0.75rem] text leading-[30px]">{errors['o7_11_k3_oblici'] ? 'Попуните обавезно поље.' : ''}</p>

            <Button variant="contained" onClick={handleAddField} disabled={disabledAddMore}>
                Додај још
            </Button>

            <ActionButtons nextButton={goToNexStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step3
