import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Input, Box, Button, Typography } from '@mui/material'

interface PositiveNumberInputInterface {
    name: string
    control: any
    defaultValue?: string
    maxLength?: number
    height?: number
    borderColor?: string
    errors?: any
    isRequired?: any
}

function PositiveNumberInputCell({
    name,
    control,
    defaultValue = '',
    errors = {},
    maxLength = 5,
    isRequired = false,
    height = 54,
    borderColor = '#00000010',
}: PositiveNumberInputInterface) {
    const regexString = /^\d*$/

    const hasError = !!errors[name]
    return (
        <div className="min-w-[100px] border flex items-center p-[15px]" style={{ height, borderColor: hasError ? '#d32f2f' : borderColor }}>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={{ required: isRequired, maxLength: 5 }}
                render={({ field }) => (
                    <Input
                        {...field}
                        onChange={(event) => {
                            const newValue = event.target.value
                            if (regexString.test(newValue) && newValue.length <= maxLength) {
                                field.onChange(!!newValue ? parseFloat(newValue) : null)
                            }
                        }}
                        type="tel"
                        className="!bg-[transparent] border-none"
                        onKeyDown={(event) => {
                            const allowedKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown']
                            if (!regexString.test(event.key) && !allowedKeys.includes(event.key)) {
                                event.preventDefault()
                            }
                        }}
                        placeholder="Цео број*"
                        style={{ fontSize: '14px' }}
                        sx={{
                            textAlign: 'center',
                            '& .MuiInputBase-input': {
                                textAlign: 'center',
                                color: hasError ? '#d32f2f' : 'inherit',
                            },
                            '&.MuiInputBase-root:before': {
                                borderBottom: 'none',
                            },
                            '&.MuiInputBase-root:after': {
                                borderBottom: 'none',
                            },
                            '&.MuiInputBase-root:hover:not(.Mui-disabled):before': {
                                borderBottom: 'none',
                            },
                        }}
                    />
                )}
            />
        </div>
    )
}

export default PositiveNumberInputCell
