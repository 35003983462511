import { TblOblast1 } from '../../../../models/Form1/Form1.entity'
import ActionButtons from '../../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import ComplexTable from '../../../../components/Tables/ComplexTable'
import Textbox from '../../../../components/Common/Textbox'
import { StepInterface } from './Step1'
import HelpButton from '../../../../components/Common/HelpButton'
import { isNotEmpty } from '../../../../utils/Validation'
import { useEffect } from 'react'
import { getAnalyticsDataForForm1 } from '../../../../server/server'

interface RowInterface {
    label: string
    fields: (keyof TblOblast1)[]
}

const rows: RowInterface[] = [
    { label: 'Од 15-30 година живота', fields: ['o1_k7_z_15_30', 'o1_k7_m_15_30'] },
    { label: 'Од 31-40 година живота', fields: ['o1_k7_z_31_40', 'o1_k7_m_31_40'] },
    { label: 'Од 41-50 година живота', fields: ['o1_k7_z_41_50', 'o1_k7_m_41_50'] },
    { label: 'Од 51-60 година живота', fields: ['o1_k7_z_51_60', 'o1_k7_m_51_60'] },
    { label: 'Од 61-70 година живота и више', fields: ['o1_k7_z_61_70', 'o1_k7_m_61_70'] },
]

const allFields = [
    'o1_k7_m_15_30',
    'o1_k7_m_31_40',
    'o1_k7_m_41_50',
    'o1_k7_m_51_60',
    'o1_k7_m_61_70',
    'o1_k7_z_15_30',
    'o1_k7_z_31_40',
    'o1_k7_z_41_50',
    'o1_k7_z_51_60',
    'o1_k7_z_61_70',
] as const

const allMaleFields = ['o1_k7_m_15_30', 'o1_k7_m_31_40', 'o1_k7_m_41_50', 'o1_k7_m_51_60', 'o1_k7_m_61_70'] as const
const allFemaleFields = ['o1_k7_z_15_30', 'o1_k7_z_31_40', 'o1_k7_z_41_50', 'o1_k7_z_51_60', 'o1_k7_z_61_70'] as const

type AllFieldsType = (typeof allFields)[number]

function Step7({
    control,
    nextStep,
    previousStep,
    previousButtonDisabled = false,
    errors,
    adminView,
    countType,
    setValue,
    selectedCategories,
    selectedSubCategories,
}: StepInterface) {
    const fields: AllFieldsType[] = [...allFields]

    useEffect(() => {
        loadData()
    }, [countType, selectedCategories, selectedSubCategories])

    const loadData = async () => {
        const data = {
            type: countType,
            fields: fields,
            category: selectedCategories?.map((e) => e.name),
            subCategory: selectedSubCategories?.map((e) => e.name),
        }
        const resp = await getAnalyticsDataForForm1(data)
        if (resp.status === 200) {
            const data = resp.data
            fields.forEach((field) => {
                setValue(field, parseFloat(data[field]))
            })
        }
    }

    return (
        <div>
            <h1 className="step-name">
                7. Број запослених и радно ангажованих лица која су у години вођења евиденције добила отказ, односно која су отпуштена, разврстаних по полу и старосној доби:
            </h1>
            <div className="flex flex-row gap-2 ">
                <ComplexTable
                    rows={rows}
                    control={control}
                    allFemaleFields={[...allFemaleFields]}
                    allMaleFields={[...allMaleFields]}
                    allFields={[...allFields]}
                    header={['Старост', 'Жене', 'Жене%', 'Мушкарци', 'Мушкарци%', 'Укупно', 'Укупно%']}
                    errors={errors}
                    disabled={true}
                />
                <HelpButton name="o1-k7" marginTop={30} />
            </div>

            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step7
