import React, { useState } from 'react'
import { TutorialStepInterface } from './Step1'
import selectSearch from '../../../../assets/images/LoginTutorialImages/SelectSearch.png'
import Actions from '../../Components/Actions'
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import Tour from 'reactour'

const Step6 = ({ nextStep, previousStep, previousButtonDisabled = false, skipClick, onClose }: TutorialStepInterface) => {
    const [isTourOpen, setIsTourOpen] = useState(true)
    const legalEntities = [
        {
            id: 1,
            pib: '123456789',
            subjectName: 'Фирма 1',
            firstName: 'Пера',
            lastName: 'Перић',
            selected: false,
        },
        {
            id: 2,
            pib: '987654321',
            subjectName: 'Фирма 2',
            firstName: 'Марко',
            lastName: 'Марковић',
            selected: false,
        },
        {
            id: 3,
            pib: '111223344',
            subjectName: 'Фирма 3',
            firstName: 'Јанко',
            lastName: 'Јанковић',
            selected: false,
        },
    ]
    const tourSteps = [
        {
            selector: 'div[id="main"]',
            content: 'Овај екран служи за одабир правног лица за које желите да испуните извештај.',
        },
        {
            selector: 'div[id="search"]',
            content: 'Ово поље омогућава кориснику да претражи обвезнике помоћу њиховог ПИБ-а (пореског идентификационог броја) или назива.',
        },
        { selector: 'div[id="table"]', content: 'Испод претраге приказана је табела са подацима о обвезницима за које корисник може да подноси извештаје.' },
        { selector: 'div[id="pib"]', content: 'Приказује ПИБ обвезника.' },
        { selector: 'div[id="comp"]', content: 'Приказује назив обвезника.' },
        { selector: 'div[id="own"]', content: 'Приказује име овлашћеног лица.' },
        {
            selector: 'div[id="add"]',
            content:
                'Ово дугме омогућава кориснику да дода новог обвезника закона. Када корисник кликне на ово дугме биће преусмерен на страницу Регистрација обвезника закона о родној равноправности.',
        },
        {
            selector: 'Button[id="select_0"]',
            content: 'Дугме „Изабери“ омогућава избор обвезника из листе. Кликом на ово дугме корисник се преусмерава на страницу специфичну за изабраног обвезника.',
        },
    ]
    return (
        <div className="w-full h-full flex items-center flex-col">
            <Tour steps={tourSteps} isOpen={isTourOpen} onRequestClose={() => setIsTourOpen(false)} />
            <div className="py-[45px] bg-[#ffffff] items-center flex flex-col w-full max-w-[1600px]">
                <h2 className="mb-[32px] text-[24px] leading-[28px] font-light">Изабери обвезника закона о родној равноправности</h2>

                <div className="flex flex-col items-center w-full">
                    <div id="search" className="w-full flex flex-row gap-[10px]">
                        <input
                            disabled
                            className="border border-[#0000003B] min-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px] flex-1"
                            placeholder="Унесите ПИБ број или Назив правног лица"
                        />
                    </div>
                    <div id="table" className="!w-full py-10 min-h-full">
                        {!!legalEntities.length && (
                            <Paper>
                                <TableContainer>
                                    <Table className="overflow-x-auto w-full">
                                        <TableHead>
                                            <TableRow className="flex flex-row !font-bold text-[1.5rem]">
                                                <TableCell className="min-w-[90px] " style={{ minWidth: 90 }}>
                                                    <div className=" !flex !flex-row justify-between items-center px-2"></div>
                                                </TableCell>
                                                <TableCell className="min-w-[250px]  !max-w-[250px]" style={{ minWidth: 250, maxWidth: '250px !important' }}>
                                                    <div id="pib" className=" !flex !flex-row justify-between items-center px-2">
                                                        <p>ПИБ</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell className="min-w-[300px] flex-1" style={{ minWidth: 300 }}>
                                                    <div id="comp" className=" !flex !flex-row justify-between items-center px-2">
                                                        Назив обвезника
                                                    </div>
                                                </TableCell>
                                                <TableCell className="min-w-[300px] flex-1" style={{ minWidth: 300 }}>
                                                    <div id="own" className=" !flex !flex-row justify-between items-center px-2">
                                                        Назив овлашћеног лица
                                                    </div>
                                                </TableCell>

                                                <TableCell className="min-w-[150px] text-center" style={{ minWidth: 150, textAlign: 'right' }}>
                                                    <div id="add">
                                                        <Button
                                                            disabled
                                                            variant="contained"
                                                            sx={{
                                                                '&.Mui-disabled': {
                                                                    opacity: 1,
                                                                    backgroundColor: '#1976d2',
                                                                    color: 'white',
                                                                    pointerEvents: 'none',
                                                                },
                                                            }}
                                                        >
                                                            Додај новог обвезника
                                                        </Button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {legalEntities?.map((legalEntity: any, index) => (
                                                <TableRow key={legalEntity.id}>
                                                    <TableCell height={52}>
                                                        <p className="text-center">{index + 1}.</p>
                                                    </TableCell>
                                                    <TableCell height={52}>
                                                        <div className=" px-2">{legalEntity?.pib ?? '-'}</div>
                                                    </TableCell>
                                                    <TableCell height={52}>
                                                        <p className="px-2">{legalEntity?.subjectName}</p>
                                                    </TableCell>
                                                    <TableCell height={52}>
                                                        <p className="px-2">
                                                            {legalEntity?.firstName} {legalEntity?.lastName}
                                                        </p>
                                                    </TableCell>

                                                    <TableCell height={52} className="flex justify-center items-center text-right" style={{ textAlign: 'right' }}>
                                                        <Button
                                                            sx={{
                                                                '&.Mui-disabled': {
                                                                    opacity: 1,
                                                                    backgroundColor: '#1976d2',
                                                                    color: 'white',
                                                                    pointerEvents: 'none',
                                                                },
                                                            }}
                                                            id={`select_${index}`}
                                                            variant="contained"
                                                            className="self-center"
                                                            disabled={true}
                                                        >
                                                            Изабери
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        )}
                    </div>
                </div>
            </div>
            {/* <div className="flex items-center justify-center w-full">
                <img src={selectSearch} alt="Претрага правних лица" width={700} />
            </div>
            <div className="w-full flex flex-col items-center h-full justify-center">
                <p className="font-bold text-[18px] mt-2">На слици је приказана страница апликације након успешне регистрације и пријаве корисника.</p>
                <p className="mt-4 text-[16px]">
                    Ова страница омогућава кориснику да одабере обвезника закона о родној равноправности за кога ће поднети извештај. Уколико корисник има само једног регистрованог
                    обвезника након успешне пријаве биће аутоматски преусмерен на страницу обвезника за попуњавање образаца.
                </p>
                <div className="w-full flex flex-col gap-2 mt-4">
                    {steps.map((step, index) => (
                        <div key={index} className={`flex flex-row gap-2 items-start ${(index > 1 && index < 5) || (index > 5 && index < 9) ? 'ml-10' : ''}`}>
                            <span className="font-bold text-[16px]">{step.title}</span>
                            {index !== 5 && index !== 1 ? '–' : ''}
                            <span className="text-[16px] flex-1">{step.description}</span>
                        </div>
                    ))}
                </div>
            </div> */}
            <div className="w-full max-w-[70%]">
                <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={false} skipButton={skipClick} closeModal={onClose} lastStep />
            </div>
        </div>
    )
}

export default Step6
