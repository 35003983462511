import ActionButtons from '../../../../components/ActionButtons'
import { StepInterface } from './Step1'
import NoAnalytics from '../../Common/NoAnalytics'
import { useNavigate } from 'react-router-dom'

function Step17({ control, nextStep, previousStep, previousButtonDisabled = false, adminView }: StepInterface) {
    const navigate = useNavigate()
    const finishOverview = () => {
        navigate('/admin/analytics')
    }
    return (
        <div>
            <h1 className="step-name">
                17. Број пријављених случајева насиља у органу јавне власти или код послодавца по основу пола, односно рода, разврстаних по облику насиља, полу жртве насиља и полу
                извршиоца насиља, као и међусобном односу жртве и извршиоца насиља, у години вођења евиденције:
            </h1>

            <NoAnalytics />
            <ActionButtons
                nextButton={finishOverview}
                previousButton={previousStep}
                nextButtonDisabled={false}
                isAdmin={true}
                lastStep
                previousButtonDisabled={previousButtonDisabled}
            />
        </div>
    )
}

export default Step17
