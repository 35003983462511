export const FORM7_3NAMES = [
    '1. Просечне зараде запослених према полу (у хиљадама РСД)',
    '2. Платни јаз и учешће запослених са ниским зарадама у укупном броју запослених по полу и старосним групама',
    '3. Платни јаз и учешће запослених са ниским зарадама у укупном броју запослених по степену образовања',
    '4. Платни јаз и учешће запослених са ниским зарадама у укупном броју запослених по групи занимања',
    '5. Корисници пензија',
    '6. Корисници пензија по категоријама, према врсти пензије, просечној старости и полу',
    '7. Просечне пензије у хиљадама динара',
    '8. Поштовани, кликом на заврши и пошаљи потврђујете да сте унели све потребне информације у дефинисана поља',
]
export const HELP_BUTTON_7_3 = ['o7_3-k1', 'o7_3-k2', 'o7_3-k3', 'o7_3-k4', 'o7_3-k5', 'o7_3-k6', 'o7_3-k7', 'o7_3-k8']
