import React, { useEffect } from 'react'
import { StepInterface } from './Step1'
import { getAnalyticsDataForForm2 } from '../../../../server/server'
import SimpleInputRow from '../../../../components/Common/SimpleInputRow'
import ActionButtons from '../../../../components/ActionButtons'

const Step2 = ({
    control,
    nextStep,
    previousStep,
    previousButtonDisabled = false,
    errors,
    adminView,
    countType,
    setValue,
    selectedCategories,
    selectedSubCategories,
}: StepInterface) => {
    const fields: ('o4_k2_br1' | 'o4_k2_br2' | 'o4_k2_br3' | 'o4_k2_br4')[] = ['o4_k2_br1', 'o4_k2_br2', 'o4_k2_br3', 'o4_k2_br4']

    useEffect(() => {
        loadData()
    }, [countType, selectedCategories, selectedSubCategories])

    const loadData = async () => {
        const data = {
            type: countType,
            fields: fields,
            category: selectedCategories?.map((e) => e.name),
            subCategory: selectedSubCategories?.map((e) => e.name),
        }
        const resp = await getAnalyticsDataForForm2(data)
        if (resp.status === 200) {
            const data = resp.data
            console.log('🚀 ~ loadData ~ data:', data)
            fields.forEach((field) => {
                setValue(field, parseFloat(data[field]))
            })
        }
    }
    return (
        <div>
            <h1 className="step-name mb-[10px]">
                'Број, врсте и сажети опис посебних мера иницираних од стране комисије за родну равноправност ради остваривања и унапређивања родне равноправности:' 'Број, врсте и
                сажети опис посебних мера иницираних од стране савета за родну равноправност ради остваривања и унапређивања родне равноправности:'
            </h1>
            <div className="flex flex-row gap-2 w-full">
                <div className="mt-[20px] mb-[40px] flex flex-col gap-[8px] ml-[5px]">
                    <SimpleInputRow title="Укупан број посебних мера" name="o4_k2_br1" type="tel" control={control} errors={errors} isRequired={false} disabled={true} />
                    <SimpleInputRow
                        title="Број мера које се одређују и спроводе у случајевима осетно неуравнотежене заступљености полова"
                        name="o4_k2_br2"
                        type="tel"
                        control={control}
                        errors={errors}
                        isRequired={false}
                        disabled={true}
                    />
                    <SimpleInputRow
                        title="Број подстицајних мера, којима се дају посебне погодности или уводе посебни подстицаји у циљу унапређења положаја и обезбеђивања једнаких могућности за жене и мушкарце у свим областима друштвеног живота"
                        name="o4_k2_br3"
                        type="tel"
                        control={control}
                        errors={errors}
                        isRequired={false}
                        disabled={true}
                    />
                    <SimpleInputRow
                        title="Број програмских мера, којима се операционализују програми за остваривање и унапређење родне равноправности"
                        name="o4_k2_br4"
                        type="tel"
                        control={control}
                        errors={errors}
                        isRequired={false}
                        disabled={true}
                    />
                </div>
            </div>

            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step2
