import React from 'react'
import { StepInterface } from './Step1'
import NoAnalytics from '../../Common/NoAnalytics'
import ActionButtons from '../../../../components/ActionButtons'

const Step3 = ({
    control,
    nextStep,
    previousStep,
    previousButtonDisabled = false,
    errors,
    adminView,
    countType,
    setValue,
    selectedCategories,
    selectedSubCategories,
}: StepInterface) => {
    return (
        <div>
            <h1 className="step-name">
                Списак иницираних посебних мера и њихови периоди извођења на нивоу јединице територијалне аутономије или јединице локалне самоуправе ради остваривања и унапређивања
                родне равноправности:
            </h1>
            <NoAnalytics />

            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step3
