import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Checkbox } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useUser } from '../../auth/UseUser'
import { getAllCities, registerStep2, updateUser, whoAmI } from '../../server/server'
import { VirtualizedAutocomplete } from '../../components/Common/VirtualizedAutocomplete'
import HelpButton from '../../components/Common/HelpButton'

export interface RegisterStepInterface {
    handleSubmit: any
    onSubmit: any
    errors: any
    register: any
    control?: any
    setValue?: any
    error?: any
    setError?: any
}
export type CityDto = {
    id?: string
    name: string
    zip: number
    region: string
    municipality?: string
}

export type RegisterStep2Inputs = {
    pib: number
    mb: number
    subjectName: string
    firstName: string
    lastName: string
    address: string
    phoneNumber: string
    city: CityDto
    cb1: boolean
    cb2: boolean
}

const UserProfile = () => {
    const {
        setValue,
        register,
        handleSubmit,
        getValues,
        control,
        formState: { errors },
    } = useForm<RegisterStep2Inputs>()
    const { user, setUser } = useUser()
    console.log('🚀 ~ UserProfile ~ user:', user)
    const navigate = useNavigate()
    const fields: { label: string; field: keyof RegisterStep2Inputs }[] = [
        { label: 'Име корисника', field: 'firstName' },
        { label: 'Презиме корисника', field: 'lastName' },
    ]

    useEffect(() => {
        if (user) {
            setUserData()
        }
    }, [user])

    const setUserData = () => {
        setValue('firstName', user?.firstName)
        setValue('lastName', user?.lastName)
    }

    const onSubmit: SubmitHandler<RegisterStep2Inputs> = async () => {
        nextStep()
    }

    const nextStep = async () => {
        const data = {
            id: user?.id,
            firstName: getValues('firstName'),
            lastName: getValues('lastName'),
        }
        const resp = await updateUser(data)
        if (resp.status == 201) {
            const whoResp = await whoAmI()
            const user = whoResp.data
            if (whoResp.status === 200) {
                setUser(user)
                alert('Кориснички профил успешно измењен.')
                navigate('/')
            }
        } else {
            alert('Greška pri izmeni profila')
        }
    }

    const getValidationPattern = (field: any, label: any) => {
        switch (field) {
            case 'firstName':
            case 'lastName':
                return {
                    value: /^[A-Za-zА-Ша-шЉљЊњЏџЂђЈјЋћ]+$/,
                    message: `${label} може садржати само слова`,
                }
            default:
                return undefined
        }
    }

    const preventNumbers = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const key = event.key
        if (/[^A-Za-zА-Ша-шЉљЊњЏџЂђЈјЋћ]/.test(key) && key !== 'Backspace' && key !== 'Tab' && key !== 'Enter') {
            event.preventDefault()
        }
    }

    return (
        <div className="items-center justify-center flex mb-[50px] min-w-[550px] relative">
            <div className="absolute top-5 right-10 ">
                <HelpButton name="user_profile_help" />
            </div>
            <div className="pb-[156px] pt-[20px] bg-[#ffffff] items-start flex flex-col ">
                <h2 className="mb-[32px] text-[24px] leading-[28px] font-light max-w-[520px] min-w-[500px] text-center">Профил корисника</h2>
                <form className="flex flex-col items-center w-full" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="w-full flex flex-col">
                        {fields.map((item, index) => (
                            <div key={index} className="flex flex-col mb-[16px]">
                                <div className="flex flex-row items-end ">
                                    <label>{item.label}</label>
                                </div>
                                <input
                                    className="border border-[#0000003B] max-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                                    placeholder={item.label}
                                    {...register(item.field, {
                                        required: true,
                                        pattern: getValidationPattern(item.field, item.label),
                                    })}
                                    onKeyDown={(event) => {
                                        if (item.field === 'firstName' || item.field === 'lastName') {
                                            preventNumbers(event)
                                        }
                                    }}
                                />
                                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[3px]">
                                    {errors[item.field]?.type === 'required' ? `${item.label} је обавезно поље` : ''}
                                    {errors[item.field]?.type === 'pattern' ? `Молимо унесите исправан формат: ${errors[item.field]?.message} ` : ''}
                                </span>
                            </div>
                        ))}

                        <button
                            type="submit"
                            className="w-full h-[42px] flex items-center justify-center rounded-[4px] bg-[#243964] text-[15px] text-[#ffffff] font-medium leading-[26px] cursor-pointer mt-[43px] max-w-[520px]"
                        >
                            Измени кориснички профил
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UserProfile
