import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { whoAmI } from '../../../server/server';
import { useUser } from '../../../auth/UseUser';

const AuthComplete = () => {
    const { setUser } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        const handleAuth = async () => {
            const params = new URLSearchParams(window.location.search);
            const token = params.get('token');

            if (token) {
                localStorage.setItem('accessToken', token);

                try {
                    const response = await whoAmI();
                    if (response.status === 200) {
                        const user = response.data;
                        if (user.valid === false) {
                            localStorage.removeItem('accessToken');
                            navigate(`/validation/send?email=${user.email}&login=true`);
                            return;
                        }
                        setUser(user);
                        localStorage.setItem('user', JSON.stringify(user));

                        if (!user.firstName || !user.lastName) {
                            navigate('/register/step2');
                        } else {
                            navigate('/');
                        }
                    } else {
                        console.log('Who am I failed', response.status);
                        navigate('/login');
                    }
                } catch (error) {
                    console.error('Error during authentication:', error);
                    navigate('/login');
                }
            } else {
                navigate('/login');
            }
        };

        handleAuth();
    }, [navigate, setUser]);

    return <div>Completing authentication...</div>;
};

export default AuthComplete;
