import React, { useMemo } from 'react'
import FinishButton from '../../../assets/svg/stepButtons/FinishButton'
import StepButton from '../../../assets/svg/stepButtons/StepButton'
import FirstButton from '../../../assets/svg/stepButtons/FirstButton'
import { ReactComponent as Check } from '../../../assets/svg/stepButtons/CompletedButtonCheck.svg'
import { twoDigits } from '../../../utils/mathUtils'
import { Tooltip } from 'react-tooltip'
import { FORM1_NAMES } from '../../../models/Form1/Form1Names'
export interface StepInterface {
    label: string
    number: number
    active?: boolean
    formNames?: string[]
    isFirst?: boolean
    isLast?: boolean
    completed?: boolean
    locked?: boolean
    onClick?: () => void
    selelectedItemRef: any
}
function Step({ label, number, active, isFirst, formNames, isLast, completed, locked, onClick, selelectedItemRef }: StepInterface) {
    const isNormal = !isLast && !isFirst

    const textColor = useMemo(() => {
        if (completed || active) return '#4891CB'
        return '#00000061'
    }, [completed, active])

    return (
        <div className="h-[57px]" ref={selelectedItemRef}>
            <button
                className="h-[57px] relative"
                onClick={onClick}
                disabled={locked}
                data-tooltip-hidden={!formNames}
                data-tooltip-id="step"
                data-tooltip-content={`${!!formNames ? formNames[number - 1].slice(0, 25) : ''}...`}
            >
                {isFirst && <FirstButton fill={active ? '#C5FD8D' : 'white'} />}
                {isNormal && <StepButton fill={active ? '#C5FD8D' : 'white'} />}
                {isLast && <FinishButton fill={active ? '#C5FD8D' : 'white'} />}
                <div className={`absolute z-1 top-0 h-full w-full flex  flex-row items-center pl-[31px] gap-[6px]`}>
                    {completed && <Check />}
                    {!completed && (
                        <div
                            className="w-[40px] h-[40px] flex items-center justify-center border-[2px] border-inactive rounded-full"
                            style={{ borderColor: active ? '#4891CB' : '#CFD6DC' }}
                        >
                            <p className="text-center text-inactive" style={{ color: active ? '#4891CB' : '#CFD6DC' }}>
                                {twoDigits(number)}
                            </p>
                        </div>
                    )}
                    <span style={{ color: textColor }}>
                        {label} {number}
                    </span>
                </div>
            </button>
            <Tooltip id="step" place="top" />
        </div>
    )
}

export default Step
