import { StepInterfaceObl2 } from './Step1'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import SimpleTable from '../../../components/Tables/SimpleTable'
import ActionButtons from '../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import { isNotEmpty } from '../../../utils/Validation'

export interface RowInterface {
    label: string
    field: keyof TblOblast2
}
const rows: RowInterface[] = [
    { label: 'Мушкарци', field: 'o2_k13_m' },
    { label: 'Жене', field: 'o2_k13_z' },
]

const Step13 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterfaceObl2) => {
    const watchedValues = useWatch<TblOblast2>({ control })
    const fields: ('o2_k13_m' | 'o2_k13_z')[] = ['o2_k13_m', 'o2_k13_z']
    const disabledNext = !fields.every((e) => isNotEmpty(watchedValues[e]))

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <h1 className="step-name">
                Број тражилаца и корисника бесплатне правне помоћи у сврху остваривања родне равноправности у години вођења евиденције, разврстаних по полу у следећем формату:
            </h1>
            <SimpleTable control={control} header={['Пол', 'Број', 'Проценат']} rows={rows} fields={fields} errors={errors} disabled={adminView} />{' '}
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={false} />
        </div>
    )
}

export default Step13
