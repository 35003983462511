import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { Checkbox, Input, Paper, Popper } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { latinToCyrillic } from '../../utils/searchTranslation'

interface CustomPaperProps {
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
    inputValue: string
    setInputValue: (value: string) => void
    onTextChange?: (text: string) => void
    children: React.ReactNode
}

const CustomPaper: React.FC<CustomPaperProps> = ({ isOpen, onOpen, onClose, inputValue, setInputValue, onTextChange, children }) => {
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (isOpen && inputRef.current) {
            inputRef.current.focus()
        }
    }, [isOpen])

    return (
        <Paper className="!w-full">
            <>
                <Input
                    className="p-2 !w-full"
                    placeholder="Претрага"
                    value={inputValue}
                    onFocus={onOpen}
                    onBlur={onClose}
                    onChange={(e) => {
                        setInputValue(e.target.value)
                        if (onTextChange) {
                            onTextChange(e.target.value)
                        }
                    }}
                    inputRef={inputRef}
                />
                {children}
            </>
        </Paper>
    )
}

const CustomPopper = function (props: any) {
    const anchorEl = props.anchorEl
    const width = anchorEl.clientWidth === 160 ? 'fit-content' : anchorEl.clientWidth

    return (
        <Popper
            {...props}
            onMouseLeave={props.onClose}
            style={{
                width: width,
                zIndex: 1300,
            }}
            placement="bottom-start"
        />
    )
}

export default function AutoComplete({
    options,
    setSelectedFilters,
    type,
    onTextChange,
    isOpen,
    onOpen,
    onClose,
    borderColor = '#00000010',
}: {
    options: (number | string)[]
    setSelectedFilters: any
    type: any
    onTextChange?: (text: string) => void
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
    borderColor?: string
}) {
    const [selectedOptions, setSelectedOptions] = useState<any[]>([])
    const [filteredOptions, setFilteredOptions] = useState(options)
    const previousOptions = useRef<(number | string)[]>([])
    const [inputValue, setInputValue] = useState('')
    const inputRef = useRef<HTMLInputElement>(null)

    const handleChange = (event: React.ChangeEvent<{}>, value: (number | string)[]) => {
        if (value.includes('Сви') && !previousOptions.current.includes('Сви')) {
            previousOptions.current = value
            setSelectedOptions(options)
            setSelectedFilters((prevFilter: any) => {
                switch (type) {
                    case 'reportYears':
                        return { ...prevFilter, years: [] }
                    case 'uniqueCategories':
                        return { ...prevFilter, categories: [] }
                    case 'uniqueSubCategories':
                        return { ...prevFilter, categories: [] }
                    case 'submittedByNames':
                        return { ...prevFilter, names: [] }
                    case 'statuses':
                        return { ...prevFilter, statuses: [] }
                    default:
                        return previousOptions.current
                }
            })
        } else if (previousOptions.current.includes('Сви') && !value.includes('Сви')) {
            previousOptions.current = []
            setSelectedOptions([])
            setSelectedFilters((prevFilter: any) => {
                switch (type) {
                    case 'reportYears':
                        return { ...prevFilter, years: [] }
                    case 'submittedByNames':
                        return { ...prevFilter, names: [] }
                    case 'uniqueCategories':
                        return { ...prevFilter, categories: [] }
                    case 'uniqueSubCategories':
                        return { ...prevFilter, categories: [] }
                    case 'statuses':
                        return { ...prevFilter, statuses: [] }
                    default:
                        return previousOptions.current
                }
            })
        } else {
            const newValue = value.filter((item) => item !== 'Сви')
            if (newValue.length === 0) {
                setSelectedOptions([])
            } else {
                setSelectedOptions(newValue)
            }

            previousOptions.current = newValue

            setSelectedFilters((prevFilter: any) => {
                switch (type) {
                    case 'reportYears':
                        return { ...prevFilter, years: newValue }
                    case 'submittedByNames':
                        return { ...prevFilter, names: newValue }
                    case 'uniqueCategories':
                        return { ...prevFilter, categories: newValue }
                    case 'uniqueSubCategories':
                        return { ...prevFilter, subCategories: newValue }
                    case 'statuses':
                        return { ...prevFilter, statuses: newValue }
                    default:
                        return previousOptions.current
                }
            })
        }
    }
    useEffect(() => {
        const isCyrillic = /[\u0400-\u04FF]/.test(inputValue)
        const query = isCyrillic ? inputValue : latinToCyrillic(inputValue)
        const filtered = options.filter((option) => option.toString().toLowerCase().includes(query.toLowerCase()))
        setFilteredOptions(filtered)
    }, [inputValue, options])

    const toggleAutocomplete = () => {
        if (isOpen) {
            onClose()
        } else {
            onOpen()
        }
    }

    return (
        <Autocomplete
            disableCloseOnSelect
            noOptionsText={'Није пронађена ни једна ставка'}
            multiple
            limitTags={1}
            disablePortal
            size="medium"
            disableClearable
            open={isOpen}
            value={selectedOptions}
            className="!w-full"
            freeSolo={false}
            onChange={handleChange}
            options={filteredOptions}
            PopperComponent={CustomPopper}
            PaperComponent={(paperProps) => (
                <CustomPaper {...paperProps} isOpen={isOpen} onOpen={onOpen} onClose={onClose} inputValue={inputValue} setInputValue={setInputValue} onTextChange={onTextChange}>
                    {paperProps.children}
                </CustomPaper>
            )}
            getOptionLabel={(option) => (typeof option === 'number' ? option.toString() : option)}
            renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props

                return (
                    <li key={key} {...optionProps} style={{ paddingLeft: '5px' }}>
                        <Checkbox style={{ marginRight: 8, paddingLeft: '0px' }} checked={selected} />
                        {option}
                    </li>
                )
            }}
            sx={{
                '& .MuiOutlinedInput-root': {
                    paddingRight: '0px !important',
                    borderRadius: '0px',
                    overflowY: 'auto',
                    '& fieldset': {
                        borderColor: borderColor,
                    },
                    '&:hover fieldset': {
                        borderColor: borderColor,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: borderColor,
                    },
                },
                '& .MuiAutocomplete-tag': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
                '& .MuiAutocomplete-tagSizeMedium': {
                    height: '26px',
                    maxWidth: '80px',
                },
                '& .MuiAutocomplete-input': {
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                },
                '& .Mui-focused ': {
                    background: '#ffffff',
                },
            }}
            renderInput={(params) => (
                <TextField {...params} inputProps={{ ...params.inputProps, readOnly: true }} className="w-[160px]" label={'Филтер'} onClick={toggleAutocomplete} />
            )}
        />
    )
}
