import '../../../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../../../components/ActionButtons'
import { Control, useWatch } from 'react-hook-form'
import Textbox from '../../../../Contact/TextBoxAutoSize'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { Form7_17Entity } from '../../../../../models/Form7/Entities/Form7_17.entity'
import { StepInterface } from './Step1'
import SimpleTable from '../../../../../components/Tables/SimpleTable'
import { useEffect, useState } from 'react'

export function Step6({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_17Entity>({ control })

    const fields: ('o7_17_k6_procenatNeplacenogRada' | 'o7_17_k6_ukupanNeplacenRad')[] = ['o7_17_k6_procenatNeplacenogRada', 'o7_17_k6_ukupanNeplacenRad']

    const disabledNext = false //!fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }

    return (
        <div>
            <h1 className="step-name mb-[20px] ">Процентуално учешће неплаћеног рада у БДП Р. Србије?</h1>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell
                        name="o7_17_k6_procenatNeplacenogRada"
                        control={control}
                        errors={errors}
                        isRequired={false}
                        height={52}
                        disabled={adminView}
                        decimal
                        precentage
                    />
                </div>
            </div>

            <h1 className="step-name mb-[20px] ">Укупна вредност неплаћеног рада у Р. Србији за годину извештавања уколико постоји податак? </h1>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell name="o7_17_k6_ukupanNeplacenRad" control={control} errors={errors} isRequired={false} height={52} disabled={adminView} decimal />
                </div>
            </div>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
