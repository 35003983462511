import { TblOblast1 } from '../../../models/Form1/Form1.entity'
import '../../../assets/styles/SuperComplexTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import { getPercentage, sumValues } from '../../../utils/mathUtils'
import PositiveNumberInputCell from '../../Form2/Components/PositiveNumberInputCell'
import { StepInterface } from './Step1'

// export interface StepInterface {
//     control?: Control<TblOblast1>
//     nextStep: () => void
//     previousStep: () => void
//     previousButtonDisabled?: boolean
//     errors?: any
// }
export interface RowInterface {
    label: string
    fields: (keyof TblOblast1)[]
}

const rows: RowInterface[] = [
    {
        label: '0 Лице без стеченог основног образовања и васпитања',
        fields: [
            'o1_k15_z_n0_15_30',
            'o1_k15_m_n0_15_30',
            'o1_k15_z_n0_31_40',
            'o1_k15_m_n0_31_40',
            'o1_k15_z_n0_41_50',
            'o1_k15_m_n0_41_50',
            'o1_k15_z_n0_51_60',
            'o1_k15_m_n0_51_60',
            'o1_k15_z_n0_61_70',
            'o1_k15_m_n0_61_70',
        ],
    },
    {
        label: 'Први ниво (ниво 1) НОКС',
        fields: [
            'o1_k15_z_n1_15_30',
            'o1_k15_m_n1_15_30',
            'o1_k15_z_n1_31_40',
            'o1_k15_m_n1_31_40',
            'o1_k15_z_n1_41_50',
            'o1_k15_m_n1_41_50',
            'o1_k15_z_n1_51_60',
            'o1_k15_m_n1_51_60',
            'o1_k15_z_n1_61_70',
            'o1_k15_m_n1_61_70',
        ],
    },
    {
        label: 'Други ниво (ниво 2) НОКС',
        fields: [
            'o1_k15_z_n2_15_30',
            'o1_k15_m_n2_15_30',
            'o1_k15_z_n2_31_40',
            'o1_k15_m_n2_31_40',
            'o1_k15_z_n2_41_50',
            'o1_k15_m_n2_41_50',
            'o1_k15_z_n2_51_60',
            'o1_k15_m_n2_51_60',
            'o1_k15_z_n2_61_70',
            'o1_k15_m_n2_61_70',
        ],
    },
    {
        label: 'Трећи ниво (ниво 3) НОКС',
        fields: [
            'o1_k15_z_n3_15_30',
            'o1_k15_m_n3_15_30',
            'o1_k15_z_n3_31_40',
            'o1_k15_m_n3_31_40',
            'o1_k15_z_n3_41_50',
            'o1_k15_m_n3_41_50',
            'o1_k15_z_n3_51_60',
            'o1_k15_m_n3_51_60',
            'o1_k15_z_n3_61_70',
            'o1_k15_m_n3_61_70',
        ],
    },
    {
        label: 'Четврти ниво (ниво 4) НОКС',
        fields: [
            'o1_k15_z_n4_15_30',
            'o1_k15_m_n4_15_30',
            'o1_k15_z_n4_31_40',
            'o1_k15_m_n4_31_40',
            'o1_k15_z_n4_41_50',
            'o1_k15_m_n4_41_50',
            'o1_k15_z_n4_51_60',
            'o1_k15_m_n4_51_60',
            'o1_k15_z_n4_61_70',
            'o1_k15_m_n4_61_70',
        ],
    },
    {
        label: 'Пети ниво (ниво 5) НОКС',
        fields: [
            'o1_k15_z_n5_15_30',
            'o1_k15_m_n5_15_30',
            'o1_k15_z_n5_31_40',
            'o1_k15_m_n5_31_40',
            'o1_k15_z_n5_41_50',
            'o1_k15_m_n5_41_50',
            'o1_k15_z_n5_51_60',
            'o1_k15_m_n5_51_60',
            'o1_k15_z_n5_61_70',
            'o1_k15_m_n5_61_70',
        ],
    },
    {
        label: 'Шести ниво, подниво један (ниво 6.1) и  подниво два (ниво 6.2) НОКС',
        fields: [
            'o1_k15_z_n6_15_30',
            'o1_k15_m_n6_15_30',
            'o1_k15_z_n6_31_40',
            'o1_k15_m_n6_31_40',
            'o1_k15_z_n6_41_50',
            'o1_k15_m_n6_41_50',
            'o1_k15_z_n6_51_60',
            'o1_k15_m_n6_51_60',
            'o1_k15_z_n6_61_70',
            'o1_k15_m_n6_61_70',
        ],
    },
    {
        label: 'Седми ниво, подниво један (ниво 7.1) и  подниво два (ниво 7.2) НОКС',
        fields: [
            'o1_k15_z_n7_15_30',
            'o1_k15_m_n7_15_30',
            'o1_k15_z_n7_31_40',
            'o1_k15_m_n7_31_40',
            'o1_k15_z_n7_41_50',
            'o1_k15_m_n7_41_50',
            'o1_k15_z_n7_51_60',
            'o1_k15_m_n7_51_60',
            'o1_k15_z_n7_61_70',
            'o1_k15_m_n7_61_70',
        ],
    },
    {
        label: 'Осми ниво (ниво 8) НОКС',
        fields: [
            'o1_k15_z_n8_15_30',
            'o1_k15_m_n8_15_30',
            'o1_k15_z_n8_31_40',
            'o1_k15_m_n8_31_40',
            'o1_k15_z_n8_41_50',
            'o1_k15_m_n8_41_50',
            'o1_k15_z_n8_51_60',
            'o1_k15_m_n8_51_60',
            'o1_k15_z_n8_61_70',
            'o1_k15_m_n8_61_70',
        ],
    },
]

const allFields = [
    'o1_k15_z_n0_15_30',
    'o1_k15_m_n0_15_30',
    'o1_k15_z_n0_31_40',
    'o1_k15_m_n0_31_40',
    'o1_k15_z_n0_41_50',
    'o1_k15_m_n0_41_50',
    'o1_k15_z_n0_51_60',
    'o1_k15_m_n0_51_60',
    'o1_k15_z_n0_61_70',
    'o1_k15_m_n0_61_70',
    'o1_k15_z_n1_15_30',
    'o1_k15_m_n1_15_30',
    'o1_k15_z_n1_31_40',
    'o1_k15_m_n1_31_40',
    'o1_k15_z_n1_41_50',
    'o1_k15_m_n1_41_50',
    'o1_k15_z_n1_51_60',
    'o1_k15_m_n1_51_60',
    'o1_k15_z_n1_61_70',
    'o1_k15_m_n1_61_70',
    'o1_k15_z_n2_15_30',
    'o1_k15_m_n2_15_30',
    'o1_k15_z_n2_31_40',
    'o1_k15_m_n2_31_40',
    'o1_k15_z_n2_41_50',
    'o1_k15_m_n2_41_50',
    'o1_k15_z_n2_51_60',
    'o1_k15_m_n2_51_60',
    'o1_k15_z_n2_61_70',
    'o1_k15_m_n2_61_70',
    'o1_k15_z_n3_15_30',
    'o1_k15_m_n3_15_30',
    'o1_k15_z_n3_31_40',
    'o1_k15_m_n3_31_40',
    'o1_k15_z_n3_41_50',
    'o1_k15_m_n3_41_50',
    'o1_k15_z_n3_51_60',
    'o1_k15_m_n3_51_60',
    'o1_k15_z_n3_61_70',
    'o1_k15_m_n3_61_70',
    'o1_k15_z_n4_15_30',
    'o1_k15_m_n4_15_30',
    'o1_k15_z_n4_31_40',
    'o1_k15_m_n4_31_40',
    'o1_k15_z_n4_41_50',
    'o1_k15_m_n4_41_50',
    'o1_k15_z_n4_51_60',
    'o1_k15_m_n4_51_60',
    'o1_k15_z_n4_61_70',
    'o1_k15_m_n4_61_70',
    'o1_k15_z_n5_15_30',
    'o1_k15_m_n5_15_30',
    'o1_k15_z_n5_31_40',
    'o1_k15_m_n5_31_40',
    'o1_k15_z_n5_41_50',
    'o1_k15_m_n5_41_50',
    'o1_k15_z_n5_51_60',
    'o1_k15_m_n5_51_60',
    'o1_k15_z_n5_61_70',
    'o1_k15_m_n5_61_70',
    'o1_k15_z_n6_15_30',
    'o1_k15_m_n6_15_30',
    'o1_k15_z_n6_31_40',
    'o1_k15_m_n6_31_40',
    'o1_k15_z_n6_41_50',
    'o1_k15_m_n6_41_50',
    'o1_k15_z_n6_51_60',
    'o1_k15_m_n6_51_60',
    'o1_k15_z_n6_61_70',
    'o1_k15_m_n6_61_70',
    'o1_k15_z_n7_15_30',
    'o1_k15_m_n7_15_30',
    'o1_k15_z_n7_31_40',
    'o1_k15_m_n7_31_40',
    'o1_k15_z_n7_41_50',
    'o1_k15_m_n7_41_50',
    'o1_k15_z_n7_51_60',
    'o1_k15_m_n7_51_60',
    'o1_k15_z_n7_61_70',
    'o1_k15_m_n7_61_70',
    'o1_k15_z_n8_15_30',
    'o1_k15_m_n8_15_30',
    'o1_k15_z_n8_31_40',
    'o1_k15_m_n8_31_40',
    'o1_k15_z_n8_41_50',
    'o1_k15_m_n8_41_50',
    'o1_k15_z_n8_51_60',
    'o1_k15_m_n8_51_60',
    'o1_k15_z_n8_61_70',
    'o1_k15_m_n8_61_70',
] as const

// 20
const allMaleFields20 = [...allFields.filter((e) => e.includes('_m_') && e.includes('15_30'))] as const
const allFemaleFields20 = [...allFields.filter((e) => e.includes('_z_') && e.includes('15_30'))] as const
// 30
const allMaleFields30 = [...allFields.filter((e) => e.includes('_m_') && e.includes('31_40'))] as const
const allFemaleFields30 = [...allFields.filter((e) => e.includes('_z_') && e.includes('31_40'))] as const
// 40
const allMaleFields40 = [...allFields.filter((e) => e.includes('_m_') && e.includes('41_50'))] as const
const allFemaleFields40 = [...allFields.filter((e) => e.includes('_z_') && e.includes('41_50'))] as const
// 50
const allMaleFields50 = [...allFields.filter((e) => e.includes('_m_') && e.includes('51_60'))] as const
const allFemaleFields50 = [...allFields.filter((e) => e.includes('_z_') && e.includes('51_60'))] as const
// 60
const allMaleFields60 = [...allFields.filter((e) => e.includes('_m_') && e.includes('61_70'))] as const
const allFemaleFields60 = [...allFields.filter((e) => e.includes('_z_') && e.includes('61_70'))] as const
// 70
const allMaleFields70 = [...allFields.filter((e) => e.includes('_m_') && e.includes('70'))] as const
const allFemaleFields70 = [...allFields.filter((e) => e.includes('_z_') && e.includes('70'))] as const

function Step15({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast1>({ control })

    type AllFieldsType = (typeof allFields)[number]
    const fields: AllFieldsType[] = [...allFields]

    const maleFields20: AllFieldsType[] = [...allMaleFields20]
    const femaleFields20: AllFieldsType[] = [...allFemaleFields20]
    const sumMale20 = sumValues(watchedValues, maleFields20)
    const sumFemale20 = sumValues(watchedValues, femaleFields20)

    const maleFields30: AllFieldsType[] = [...allMaleFields30]
    const femaleFields30: AllFieldsType[] = [...allFemaleFields30]
    const sumMale30 = sumValues(watchedValues, maleFields30)
    const sumFemale30 = sumValues(watchedValues, femaleFields30)

    const maleFields40: AllFieldsType[] = [...allMaleFields40]
    const femaleFields40: AllFieldsType[] = [...allFemaleFields40]

    const sumMale40 = sumValues(watchedValues, maleFields40)
    const sumFemale40 = sumValues(watchedValues, femaleFields40)

    const maleFields50: AllFieldsType[] = [...allMaleFields50]
    const femaleFields50: AllFieldsType[] = [...allFemaleFields50]
    const sumMale50 = sumValues(watchedValues, maleFields50)
    const sumFemale50 = sumValues(watchedValues, femaleFields50)

    const maleFields60: AllFieldsType[] = [...allMaleFields60]
    const femaleFields60: AllFieldsType[] = [...allFemaleFields60]
    const sumMale60 = sumValues(watchedValues, maleFields60)
    const sumFemale60 = sumValues(watchedValues, femaleFields60)

    const maleFields70: AllFieldsType[] = [...allMaleFields70]
    const femaleFields70: AllFieldsType[] = [...allFemaleFields70]
    const sumMale70 = sumValues(watchedValues, maleFields70)
    const sumFemale70 = sumValues(watchedValues, femaleFields70)

    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }

    const hasRowErrors = rows.some((row) => row.fields.some((field) => errors?.[field]))
    return (
        <div>
            <h1 className="step-name">
                15. Број лица на извршилачким радним местима и на положајима, ангажованих у органима управљања и надзора, комисијама и другим телима код органа јавне власти и
                послодавца, разврстаних по полу, квалификацијама и старосној доби:
            </h1>
            <div className="overflow-x-scroll  step15">
                <table className="super-complex-table mt-[10px] w-[1790px]">
                    <tr className="super-complex-table-header">
                        <th className="super-complex-table-cell !min-w-[270px] !ml-0"></th>
                        <th className="super-complex-table-cell !min-w-[300px] !ml-0">Од 15 - 30 година</th>
                        <th className="super-complex-table-cell !min-w-[300px] !ml-0">Од 31 - 40 година</th>
                        <th className="super-complex-table-cell !min-w-[300px] !ml-0">Од 41 - 50 година</th>
                        <th className="super-complex-table-cell !min-w-[300px] !ml-0">Од 51 - 60 година</th>
                        <th className="super-complex-table-cell !min-w-[300px] !ml-0">Од 61 - 70 година</th>
                    </tr>
                    <tr className="super-complex-table-header">
                        <th className="super-complex-table-cell !min-w-[270px] !ml-0">Ниво квалификације:</th>
                        {/* Од 15 - 20 година 
                        <th className="super-complex-table-cell !ml-0">Ж</th>
                        <th className="super-complex-table-cell">Ж%</th>
                        <th className="super-complex-table-cell">M</th>
                        <th className="super-complex-table-cell">M%</th> */}
                        {/* Од 15 - 30 година  */}
                        <th className="super-complex-table-cell !ml-0">Ж</th>
                        <th className="super-complex-table-cell">Ж%</th>
                        <th className="super-complex-table-cell">M</th>
                        <th className="super-complex-table-cell">M%</th>
                        {/* Од 31 - 40 година  */}
                        <th className="super-complex-table-cell !ml-0">Ж</th>
                        <th className="super-complex-table-cell">Ж%</th>
                        <th className="super-complex-table-cell">M</th>
                        <th className="super-complex-table-cell">M%</th>
                        {/* Од 41 - 50 година  */}
                        <th className="super-complex-table-cell !ml-0">Ж</th>
                        <th className="super-complex-table-cell">Ж%</th>
                        <th className="super-complex-table-cell">M</th>
                        <th className="super-complex-table-cell">M%</th>
                        {/* Од 51 - 60 година  */}
                        <th className="super-complex-table-cell !ml-0">Ж</th>
                        <th className="super-complex-table-cell">Ж%</th>
                        <th className="super-complex-table-cell">M</th>
                        <th className="super-complex-table-cell">M%</th>
                        {/* Од 61 - 70 година  */}
                        <th className="super-complex-table-cell !ml-0">Ж</th>
                        <th className="super-complex-table-cell">Ж%</th>
                        <th className="super-complex-table-cell">M</th>
                        <th className="super-complex-table-cell">M%</th>
                    </tr>
                    {rows.map((row) => (
                        <tr className="super-complex-table-row">
                            <td className="super-complex-table-cell disabled !min-w-[270px] !ml-0 px-[5px]">{row.label}</td>
                            {/* Од 15 - 30 година */}
                            <td className="super-complex-table-cell editable !border-none !ml-0">
                                <PositiveNumberInputCell name={row.fields[0]} control={control} defaultValue="" errors={errors} isRequired={!adminView} disabled={adminView} />
                            </td>
                            <td className="super-complex-table-cell disabled ">{getPercentage(watchedValues[row.fields[0]], sumFemale20)}%</td>
                            <td className="super-complex-table-cell editable !border-none">
                                <PositiveNumberInputCell name={row.fields[1]} control={control} defaultValue="" errors={errors} isRequired={!adminView} disabled={adminView} />
                            </td>
                            <td className="super-complex-table-cell disabled">{getPercentage(watchedValues[row.fields[1]], sumMale20)}%</td>
                            {/*  Од 31 - 40 година */}
                            <td className="super-complex-table-cell editable !ml-0 !border-none">
                                <PositiveNumberInputCell name={row.fields[2]} control={control} defaultValue="" errors={errors} isRequired={!adminView} disabled={adminView} />
                            </td>
                            <td className="super-complex-table-cell disabled">{getPercentage(watchedValues[row.fields[2]], sumFemale30)}%</td>
                            <td className="super-complex-table-cell editable !border-none">
                                <PositiveNumberInputCell name={row.fields[3]} control={control} defaultValue="" errors={errors} isRequired={!adminView} disabled={adminView} />
                            </td>
                            <td className="super-complex-table-cell disabled">{getPercentage(watchedValues[row.fields[3]], sumMale30)}%</td>
                            {/* Од 41 - 50 година  */}
                            <td className="super-complex-table-cell editable !ml-0 !border-none">
                                <PositiveNumberInputCell name={row.fields[4]} control={control} defaultValue="" errors={errors} isRequired={!adminView} disabled={adminView} />
                            </td>
                            <td className="super-complex-table-cell disabled">{getPercentage(watchedValues[row.fields[4]], sumFemale40)}%</td>
                            <td className="super-complex-table-cell editable !border-none">
                                <PositiveNumberInputCell name={row.fields[5]} control={control} defaultValue="" errors={errors} isRequired={!adminView} disabled={adminView} />
                            </td>
                            <td className="super-complex-table-cell disabled">{getPercentage(watchedValues[row.fields[5]], sumMale40)}%</td>
                            {/* Од 51 - 60 година  */}
                            <td className="super-complex-table-cell editable !ml-0 !border-none">
                                <PositiveNumberInputCell name={row.fields[6]} control={control} defaultValue="" errors={errors} isRequired={!adminView} disabled={adminView} />
                            </td>
                            <td className="super-complex-table-cell disabled">{getPercentage(watchedValues[row.fields[6]], sumFemale50)}%</td>
                            <td className="super-complex-table-cell editable !border-none">
                                <PositiveNumberInputCell name={row.fields[7]} control={control} defaultValue="" errors={errors} isRequired={!adminView} disabled={adminView} />
                            </td>
                            <td className="super-complex-table-cell disabled">{getPercentage(watchedValues[row.fields[7]], sumMale50)}%</td>
                            {/* Од 61 - 70 година  */}
                            <td className="super-complex-table-cell editable !ml-0 !border-none">
                                <PositiveNumberInputCell name={row.fields[8]} control={control} defaultValue="" errors={errors} isRequired={!adminView} disabled={adminView} />
                            </td>
                            <td className="super-complex-table-cell disabled">{getPercentage(watchedValues[row.fields[8]], sumFemale60)}%</td>
                            <td className="super-complex-table-cell editable !border-none">
                                <PositiveNumberInputCell name={row.fields[9]} control={control} defaultValue="" errors={errors} isRequired={!adminView} disabled={adminView} />
                            </td>
                            <td className="super-complex-table-cell disabled">{getPercentage(watchedValues[row.fields[9]], sumMale60)}%</td>
                        </tr>
                    ))}
                    <tr className="super-complex-table-footer">
                        <td className="super-complex-table-cell !min-w-[270px] !ml-0 px-[5px]">Укупно</td>
                        {/* 20 */}
                        <td className="super-complex-table-cell !ml-0">{sumFemale20}</td>
                        <td className="super-complex-table-cell">{getPercentage(sumFemale20, sumFemale20)}%</td>
                        <td className="super-complex-table-cell">{sumMale20}</td>
                        <td className="super-complex-table-cell">{getPercentage(sumMale20, sumMale20)}%</td>
                        {/* 30 */}
                        <td className="super-complex-table-cell !ml-0">{sumFemale30}</td>
                        <td className="super-complex-table-cell">{getPercentage(sumFemale30, sumFemale30)}%</td>
                        <td className="super-complex-table-cell">{sumMale30}</td>
                        <td className="super-complex-table-cell">{getPercentage(sumMale30, sumMale30)}%</td>
                        {/* 40 */}
                        <td className="super-complex-table-cell !ml-0">{sumFemale40}</td>
                        <td className="super-complex-table-cell">{getPercentage(sumFemale40, sumFemale40)}%</td>
                        <td className="super-complex-table-cell">{sumMale40}</td>
                        <td className="super-complex-table-cell">{getPercentage(sumMale40, sumMale40)}%</td>
                        {/* 50 */}
                        <td className="super-complex-table-cell !ml-0">{sumFemale50}</td>
                        <td className="super-complex-table-cell">{getPercentage(sumFemale50, sumFemale50)}%</td>
                        <td className="super-complex-table-cell">{sumMale50}</td>
                        <td className="super-complex-table-cell">{getPercentage(sumMale50, sumMale50)}%</td>
                        {/* 60 */}
                        <td className="super-complex-table-cell !ml-0">{sumFemale60}</td>
                        <td className="super-complex-table-cell">{getPercentage(sumFemale60, sumFemale60)}%</td>
                        <td className="super-complex-table-cell">{sumMale60}</td>
                        <td className="super-complex-table-cell">{getPercentage(sumMale60, sumMale60)}%</td>
                    </tr>
                </table>
            </div>
            <div className="h-[2px]">
                {hasRowErrors && (
                    <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">
                        Попуните обавезно поље, обратите пажњу, табела је обимна и пoља су можда скривена иза хоризонталног померања.
                    </p>
                )}
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={false} />
        </div>
    )
}

export default Step15
