import React from 'react'
import { StepInterface } from './Step1'
import { Form7_5Entity } from '../../../../../models/Form7/Entities/Form7_5.entity'
import { useWatch } from 'react-hook-form'
import SimpleTable from '../../../../../components/Tables/SimpleTable'
import ActionButtons from '../../../../../components/ActionButtons'

export interface RowInterface {
    label: string
    field: keyof Form7_5Entity
}

const rows: RowInterface[] = [
    { label: 'Мушкарци', field: 'o7_5_k4_m' },
    { label: 'Жене', field: 'o7_5_k4_z' },
]

export const Step4 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_5Entity>({ control })
    const fields: ('o7_5_k4_m' | 'o7_5_k4_z')[] = ['o7_5_k4_m', 'o7_5_k4_z']
    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">Број запослених и радно ангажованих лица у центрима за социјални рад разврстане по полу</h1>
            <div className="flex flex-row gap-2 ">
                <SimpleTable control={control} header={['Пол', 'Број', 'Проценат']} rows={rows} fields={fields} errors={errors} disabled={adminView} />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
