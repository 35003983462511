export const FORM5_PARTY_NAMES = [
    '1. Да ли су актима ове организације регулисана права из члана 48. став 1. Закона о родној равноправности („Службени гласник РС”, број 52/21)?',
    '2. Дописати одговор ако су регулисана предметна права',
    '3. Да ли је и када донет План деловања (који садржи посебне мере за подстицање и унапређивање родне равноправности и уравнотежене заступљености полова у органима ваше политичке странке и обезбеђивање активног учешћа мање заступљеног пола у саставу и раду тих органа)',
    '4. Да ли је чланство у вашој политичкој странци искључиво или превасходно намењено лицима, групама лица истог пола или рода из оправданих разлога и којих?',
    '5. Навести кључне руководеће позиције у вашој политичкој странци и да ли се на њима налазе мушкарци или жене (уписати у десну колону онолико одговора колико има таквих позиција)',
    '6. Подаци о лицу које је унело податке у дефинисана поља',
    '7. Поштовани, кликом на заврши и пошаљи потврђујете да сте унели све потребне информације у дефинисана поља',
]
export const FORM5_UNION_NAMES = [
    '1. Да ли су актима ове организације регулисана права из члана 48. став 1. Закона о родној равноправности („Службени гласник РС”, број 52/21)?',
    '2. Дописати одговор ако су регулисана предметна права',
    '3. Да ли је и када донет План деловања (који садржи посебне мере за подстицање и унапређивање родне равноправности и уравнотежене заступљености полова у органима ваше синдикалне организације и обезбеђивање активног учешћа мање заступљеног пола у саставу и раду тих органа)',
    '4. Да ли је чланство у вашој политичкој странци искључиво или превасходно намењено лицима, групама лица истог пола или рода из оправданих разлога и којих?',
    '5. Навести кључне руководеће позиције у вашој синдикалној организацији и да ли се на њима налазе мушкарци или жене (уписати у десну колону онолико одговора колико има таквих позиција) ',
    '6. Подаци о лицу које је унело податке у дефинисана поља',
    '7. Поштовани, кликом на заврши и пошаљи потврђујете да сте унели све потребне информације у дефинисана поља',
]

export const HELP_BUTTON_5 = ['o5-k1', 'o5-k2', 'o5-k3', 'o5-k4', 'o5-k5', 'o5-k6', 'o5-k7']
