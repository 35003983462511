import '../../../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../../../components/ActionButtons'
import { Control, useWatch } from 'react-hook-form'
import Textbox from '../../../../Contact/TextBoxAutoSize'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { Form7_16Entity } from '../../../../../models/Form7/Entities/Form7_16.entity'
import { StepInterface } from './Step1'
import SimpleTable from '../../../../../components/Tables/SimpleTable'

export interface RowInterface {
    label: string
    field: keyof Form7_16Entity
}

const rows: RowInterface[] = [
    { label: 'Мушкарци', field: 'o7_16_k4_t1_m' },
    { label: 'Жене', field: 'o7_16_k4_t1_z' },
]
const fields: ('o7_16_k4_t1_m' | 'o7_16_k4_t1_z')[] = ['o7_16_k4_t1_m', 'o7_16_k4_t1_z']

export function Step4({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_16Entity>({ control })

    const disabledNext =
        !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '') ||
        watchedValues?.o7_16_k4_brojObuka === undefined ||
        typeof watchedValues.o7_16_k4_brojObuka !== 'number'
    const goToNextStep = () => {
        if (!disabledNext && !errors?.o7_16_k4_brojObuka) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name mb-[20px] ">Број одржаних обука у вашој рганизацији о родно заснованом насиљу и примени антидискриминационог законодавства </h1>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell name="o7_16_k4_brojObuka" control={control} errors={errors} isRequired={!adminView} height={52} disabled={adminView} />
                </div>
            </div>

            <h1 className="step-name mb-[0px] ">Број обучених особа од поверења саветника/ца за род у вашој организацији по полу </h1>
            <div className="flex flex-row gap-2 mb-[20px]">
                <SimpleTable control={control} header={['Пол', 'Број', 'Проценат']} rows={rows} fields={fields} errors={errors} disabled={adminView} />
            </div>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
