import React from 'react'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'

interface Step7TableRowComponentInterface {
    index: number
    name: string
    name1: string
    control: any
    errors: any
    isRequired: any
    text: string
    precentageValue?: any
    precentage1Value?: any
    precentage2Value?: any
}

const Step7TableRowComponent = ({
    index,
    control,
    errors,
    isRequired,
    name,
    name1,
    text,
    precentageValue,
    precentage1Value,
    precentage2Value,
}: Step7TableRowComponentInterface) => {
    return (
        <tr key={index} className="multiple-input-table-row">
            <td className="multiple-input-table-cell disabled !min-w-[500px]">{text}</td>
            <td className={`multiple-input-table-cell !border-none`}>
                <PositiveNumberInputCell
                    name={`${name}`}
                    control={control}
                    isMultipleTable={true}
                    errors={errors}
                    isRequired={isRequired}
                    height={62}
                    isArray={true}
                    disabled={!isRequired}
                />
            </td>
            <td className="multiple-input-table-cell disabled">{precentageValue || 0}%</td>
            <td className="multiple-input-table-cell editable !border-none">
                <PositiveNumberInputCell
                    name={`${name1}`}
                    control={control}
                    isMultipleTable={true}
                    errors={errors}
                    isRequired={isRequired}
                    height={62}
                    isArray={true}
                    disabled={!isRequired}
                />
            </td>
            <td className="multiple-input-table-cell disabled">{precentage1Value || 0}%</td>
            <td className="multiple-input-table-cell disabled">{precentage2Value || 0}%</td>
        </tr>
    )
}

export default Step7TableRowComponent
