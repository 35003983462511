import React, { useState } from 'react'
import { Form7_6Entity } from '../../../../../models/Form7/Entities/Form7_6.entity'
import { Control, useFieldArray, useWatch } from 'react-hook-form'
import ActionButtons from '../../../../../components/ActionButtons'
import UploadFile from '../../../../../components/UploadFile'
import { Button } from '@mui/material'
import FileDescriptionTable from '../../../Components/FileDescriptionTable'
import Textbox from '../../../../../components/Common/Textbox'

export interface StepInterface {
    control?: Control<Form7_6Entity>
    nextStep: () => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
    clearErrors?: any
}

export const Step5 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_6Entity>({ control })
    const [emptyArrayError, setEmptyArrayError] = useState(false)
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o7_6_k5_programiPodrske',
    })
    const name = 'o7_6_k5_programiPodrske'

    let disabledNext = watchedValues[name]?.length === 0

    fields.forEach((item, index) => {
        const { programName, organizer, plan } = watchedValues[name]?.[index] || {}

        if (programName === undefined || programName === null || programName === '') {
            disabledNext = true
        } else if (organizer === undefined || organizer === null || organizer === '') {
            disabledNext = true
        } else if (plan === undefined || plan === null || plan === '') {
            disabledNext = true
        } else disabledNext = false
    })

    const header = ['Бр.', 'Назив програма', 'Организатор програма', 'Елаборат/План линк']

    const handleAddArrayField = () => {
        setEmptyArrayError(false)
        append({ programName: '', organizer: '', plan: '' })
    }

    const validateEmptyArray = () => {
        if (watchedValues[name]?.length === 0 || watchedValues[name] === undefined) {
            setEmptyArrayError(true)
            return true
        }
        return false
    }

    const goToNextStep = () => {
        if (!disabledNext && !validateEmptyArray()) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <div className="flex flex-col justify-between">
                <h1 className="step-name mb-[10px]">Програми подршке друштвеним и технолошким иновацијама који посебно циљају жене</h1>
            </div>
            <FileDescriptionTable header={header} fields={fields} control={control} errors={errors} name={name} adminView={adminView} remove={remove} />

            <div className="flex flex-col mt-[56px]">
                <Button variant="contained" className="max-w-[172px] mt-[56px]" onClick={handleAddArrayField} disabled={adminView}>
                    Додај датотеку
                </Button>
                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError ? 'Додајте барем једну активност!' : ''}</span>
            </div>

            <div className="my-10 gap-10">
                <h1>Молимo Вас приложите планове за горе наведене програме ако нема јавно доступног линка</h1>
                <UploadFile control={control} name={'o7_6_k5_files'} disabled={adminView} errors={errors} form7 />
            </div>

            <td className="multiple-input-table-cell editable flex flex-col !border-none !min-w-[650px]">
                <h1>Напомена:</h1>
                <Textbox
                    name="o7_6_k5_napomena"
                    control={control}
                    maxNumberOfCharacters={300}
                    placeholder="Текст (300 карактера)*"
                    errors={errors}
                    isRequired={adminView ? false : 'Попуните обавезно поље.'}
                    disabled={adminView}
                />
            </td>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
