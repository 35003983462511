import React from 'react'
import { Navigate, Route } from 'react-router-dom'
import { useUser } from '../../auth/UseUser'

const PublicRoute = ({ Component }: any) => {
    const { isSignedIn } = useUser()
    // const isSignedIn = true
    return isSignedIn ? <Navigate to="/" /> : <Component />
}

export default PublicRoute
