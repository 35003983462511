import { FieldValues } from 'react-hook-form'
import { TblOblast1 } from '../models/Form1/Form1.entity'
import { CATEGORY_ENUM } from '../models/Organization.entity'

export const getPercentage = (number1: any, number2: any) => {
    if (number1 === undefined || number1 === null || isNaN(number1) || number2 === undefined || number2 === null || isNaN(number2)) {
        return ''
    }
    if (number2 === 0) return 0

    const percentage = (Number(number1) / Number(number2)) * 100
    const roundedPercentage = Math.round(percentage * 100) / 100
    return roundedPercentage.toFixed(2)
}

export function sumValues<T extends FieldValues>(values: T, fields: (keyof T)[], decimal = false): number {
    const sum = fields.reduce((acc, field) => {
        const value = values[field]
        return acc + (typeof value === 'number' ? value : 0)
    }, 0)

    return decimal ? parseFloat(sum.toFixed(2)) : sum
}

export function subtractValues<T extends Record<string, number | string>>(values: T, fields: (keyof T)[], decimal = false): number {
    if (fields.length === 0) return 0

    const initialField = fields[0]
    const initialTotal = values[initialField] === '' || values[initialField] === undefined ? 0 : Number(values[initialField])

    return fields.slice(1).reduce((total, field) => {
        const fieldValue = values[field] === '' || values[field] === undefined ? 0 : Number(values[field])
        return Math.abs(total - fieldValue)
    }, initialTotal)
}

export const twoDigits = (number: number) => `0${number}`.slice(-2)

export function getEnumValues(enumObj: any): string[] {
    return Object.values(enumObj)
}

export function calculateDifference(num1: any, num2: any, decimal = false) {
    if (typeof num1 === 'number' && typeof num2 === 'number') {
        const dif = num1 - num2
        return decimal ? dif.toFixed(2) : dif
    }
    return ''
}
export function calculateSumOfTwoValues(num1: any, num2: any, decimal: boolean = false): string | number {
    if (typeof num1 === 'number' && typeof num2 === 'number') {
        const sum = num1 + num2
        return decimal ? sum.toFixed(2) : sum
    }
    return ''
}
export function percentage(num1: any, num2: any, decimal = false) {
    if (typeof num1 === 'number' && typeof num2 === 'number') {
        if (num1 == 0 || num2 == 0) {
            return ''
        }
        const dif = (num1 / num2) * 100
        return dif.toFixed(2) + '%'
    }
    return ''
}
export function percentageOfDifference(num1: any, num2: any, decimal = false) {
    if (typeof num1 === 'number' && typeof num2 === 'number') {
        if (num2 == 0) {
            return ''
        }
        const dif = ((num1 - num2) / num2) * 100
        return dif.toFixed(2) + '%'
    }
    return ''
}
export function removeLeadingZeros(input: string) {
    return input.replace(/^0+(?!\.)/, '')
}
