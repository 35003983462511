import { Button, MenuItem, Modal, Select, TextareaAutosize } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ReactComponent as CloseFilled } from '../../assets/svg/CloseFilled.svg'
import Textbox from '../Contact/TextBoxAutoSize'
import { Category } from './types'

interface modalInterface {
    isOpen: boolean
    onClose: any
    onConfirm: any
    category?: any
    parentCategories?: any
}
export const EditCategoryNameModal = ({ isOpen, onClose, onConfirm, category, parentCategories }: modalInterface) => {
    const [selectedCategory, setSelectedCategory] = useState<Category>()
    console.log('🚀 ~ EditCategoryNameModal ~ selectedCategory:', selectedCategory)
    const [newName, setNewName] = useState('')
    useEffect(() => {
        setNewName(category?.name)
    }, [category])
    if (!isOpen) return null

    const handleSave = () => {
        if (!!category) {
            const payload = {
                parentId: selectedCategory?.id || null,
                name: newName,
            }

            onConfirm(payload)
            setSelectedCategory(undefined)
            setNewName('')
        } else {
            const payload = {
                parentId: selectedCategory?.id || null,
                name: newName,
            }
            onConfirm(payload)
            setSelectedCategory(undefined)
            setNewName('')
        }
    }

    return (
        <Modal open={isOpen} onClose={onClose}>
            <div className="flex flex-col justify-center items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white min-w-[40%] max-w-[40%] h-full max-h-[40%] p-5 shadow-lg rounded-lg">
                <Select
                    value={selectedCategory}
                    className={`border-[#00000010] min-w-[186px] select-component-css`}
                    sx={{
                        '.MuiSvgIcon-root ': {
                            fill: '#0000008F !important',
                        },
                    }}
                    onChange={(e) => {
                        const category = parentCategories?.find((cat: any) => cat.id === e.target.value)
                        setSelectedCategory(category)
                    }}
                >
                    {parentCategories?.map((item: any) => (
                        <MenuItem key={item.id} value={item.id} style={{ textAlign: 'center', height: 50, justifyContent: 'center' }}>
                            {item?.name}
                        </MenuItem>
                    ))}
                </Select>
                <p>Назив категорије</p>

                <TextareaAutosize
                    value={newName}
                    placeholder={`${!!parentCategories ? 'Назив категорије' : ''}`}
                    onChange={(e) => setNewName(e.target.value)}
                    className={`border border-[#0000003B] max-w-full w-full min-h-[56px] py-1 px-3 rounded-[4px] outline-none text-[16px] leading-[24px] ${
                        parentCategories?.length !== 0 && 'mt-10'
                    } `}
                />
                <div className="mt-[40px] flex flex-row justify-between w-[80%] gap-[10px]">
                    <Button
                        onClick={onClose}
                        style={{
                            width: '100%',
                            height: '42px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px',
                            border: '1px solid #243964',
                            color: '#243964',
                            fontSize: '15px',
                            fontWeight: '500',
                            lineHeight: '26px',
                        }}
                    >
                        Назад
                    </Button>
                    <Button
                        onClick={() => {
                            handleSave()
                        }}
                        className="w-full h-[42px] flex items-center justify-center rounded-[4px] border !bg-[#243964] text-[15px] !text-white font-medium leading-[26px]"
                    >
                        Измени
                    </Button>
                </div>
                <Button
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        fontSize: '16px',
                        cursor: 'pointer',
                        color: 'black',
                    }}
                >
                    <CloseFilled />
                </Button>
            </div>
        </Modal>
    )
}
