export const FORM4_COMISION_NAMES = [
    '01. Оцена стања у години вођења евиденције',
    '02. Број врсте и сажети опис посебних мера иницираних од стране комисије за родну равноправност ради остваривања и унапређивања родне равноправности',
    '03. Списак иницираних посебних мера на нивоу јединице територијалне аутономије или јединице локалне самоуправе ради остваривања и унапређивања родне равноправности',
    '04. Степен укључености наведених мера и активности у развојне планове и програме јединице територијалне аутономије или јединице локалне самоуправе',
    '05. Начин спровођења и контроле примене посебних мера',
    '06. Оцена ефеката примене посебних мера',
    '07. Проценат реализованог буџета на годишњем нивоу са становишта родне перспективе',
    '08. Података о неплаћеном кућном раду на нивоу јединице територијалне аутономије или јединице локалне самоуправе исказани за годину извештавања и разврстани по полу',
    '09. Остварени степен и квалитет сарадње тела за родну равноправност са другим радним телима или органима на републичком, покрајинском и локалном нивоу по питању остваривања и унапређивања родне равноправности  ',
    '10. Подаци Секретар/Секретарка тела за родну равноправност',
    '11. Поштовани, кликом на заврши и пошаљи потврђујете да сте унели све потребне информације у дефинисана поља',
]

export const FORMS4_COUNCIL_NAMES = [
    '01. Оцена стања у години вођења евиденције',
    '02. Број врсте и сажети опис посебних мера иницираних од стране савета за родну равноправност ради остваривања и унапређивања родне равноправности',
    '03. Списак иницираних посебних мера на нивоу јединице територијалне аутономије или јединице локалне самоуправе ради остваривања и унапређивања родне равноправности',
    '04. Степен укључености наведених мера и активности у развојне планове и програме јединице територијалне аутономије или јединице локалне самоуправе',
    '05. Начин спровођења и контроле примене посебних мера',
    '06. Оцена ефеката примене посебних мера',
    '07. Проценат реализованог буџета на годишњем нивоу са становишта родне перспективе',
    '08. Података о неплаћеном кућном раду на нивоу јединице територијалне аутономије или јединице локалне самоуправе исказани за годину извештавања и разврстани по полу',
    '09. Остварени степен и квалитет сарадње тела за родну равноправност са другим радним телима или органима на републичком, покрајинском и локалном нивоу по питању остваривања и унапређивања родне равноправности  ',
    '10. Подаци Секретар/Секретарка тела за родну равноправност',
    '11. Поштовани, кликом на заврши и пошаљи потврђујете да сте унели све потребне информације у дефинисана поља',
]

export const HELP_BUTTON_4 = ['o4-k1', 'o4-k2', 'o4-k3', 'o4-k4', 'o4-k5', 'o4-k6', 'o4-k7', 'o4-k8', 'o4-k9', 'o4-k10', 'o4-k11']
