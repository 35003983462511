import React from 'react'
import { Control, useWatch } from 'react-hook-form'
import TextInputCell from '../../../components/Common/TextInputCell'
import { ReactComponent as Delete } from '../../../assets/svg/Delete.svg'
import { Button } from '@mui/material'
import SelectBody from '../../Form2/Components/SelectBody'
import { getEnumValues, getPercentage } from '../../../utils/mathUtils'
import { Form7_11Entity, MedjusobniOdnos, PolPocinioca, UzrastZrtve, VrstaNasilja } from '../../../models/Form7/Entities/Form7_11.entity'
import PositiveNumberInputCell from '../../Form2/Components/PositiveNumberInputCell'

interface RiskTableInterface {
    control: Control<Form7_11Entity> | undefined
    fields: any[]
    remove: any
    header: string[]
    errors?: any
    disabled?: boolean
}

const vrstaNasiljaEnumValues = getEnumValues(VrstaNasilja)
const uzrastZrtveEnumValues = getEnumValues(UzrastZrtve)
const polPociniocaEnumValues = getEnumValues(PolPocinioca)
const medjusobniOdnosEnumValues = getEnumValues(MedjusobniOdnos)

function Form7_11_k2Table({ control, fields, remove, header, errors, disabled }: RiskTableInterface) {
    const watchedValues = useWatch<Form7_11Entity>({ control })

    const ukupnoPrijavljenih = watchedValues.o7_11_k2_prijave?.reduce((sum, item) => sum + (!!item.prijavljeniSlucajevi ? item.prijavljeniSlucajevi : 0), 0)

    return (
        <table className="simple-table mt-[50px] gap-[6px]">
            <tr className="simple-table-header">
                {header.map((head, index) => (
                    <th className={`${index == 0 ? 'w-[274px]' : index + 1 == header.length ? 'w-[246px]' : 'w-[186px]'}  h-[54px]`} key={index}>
                        {head}
                    </th>
                ))}

                <td className=" pl-3 flex flex-row gap-6 items-center invisible" style={{ border: 0 }}>
                    {fields.length > 1 && (
                        <Button className="w-fit cursor-pointer" disabled={disabled}>
                            <Delete />
                        </Button>
                    )}
                </td>
            </tr>
            {fields.map((field, index) => (
                <tr className="simple-table-row" key={field.id}>
                    <td className=" editable w-[126px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <PositiveNumberInputCell
                            name={`o7_11_k2_prijave.${index}.prijavljeniSlucajevi`}
                            control={control}
                            isMultipleTable={true}
                            errors={errors}
                            isRequired={!disabled}
                            disabled={disabled}
                            maxW={126}
                            isArray={true}
                            min={1}
                        />
                    </td>
                    <td className="complex-table-cell disabled  min-w-[136px] h-[54px]">
                        {getPercentage(!!watchedValues.o7_11_k2_prijave ? watchedValues.o7_11_k2_prijave[index]?.prijavljeniSlucajevi : 0, ukupnoPrijavljenih)}%
                    </td>
                    <td className=" editable w-[186px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <SelectBody
                            control={control}
                            name={`o7_11_k2_prijave.${index}.vrstaNasilja`}
                            value={!!watchedValues.o7_11_k2_prijave ? watchedValues.o7_11_k2_prijave[index]?.vrstaNasilja : ''}
                            items={vrstaNasiljaEnumValues}
                            errors={errors}
                            isArray={true}
                            isRequired={!disabled}
                            disabled={disabled}
                            minWidth={''}
                        />
                    </td>
                    <td className=" editable w-[186px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <SelectBody
                            control={control}
                            name={`o7_11_k2_prijave.${index}.uzrastZrtve`}
                            value={!!watchedValues.o7_11_k2_prijave ? watchedValues.o7_11_k2_prijave[index]?.uzrastZrtve : ''}
                            items={uzrastZrtveEnumValues}
                            errors={errors}
                            isArray={true}
                            isRequired={!disabled}
                            disabled={disabled}
                            minWidth={''}
                        />
                    </td>
                    <td className=" editable w-[186px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <SelectBody
                            control={control}
                            name={`o7_11_k2_prijave.${index}.polPocinioca`}
                            value={!!watchedValues.o7_11_k2_prijave ? watchedValues.o7_11_k2_prijave[index]?.polPocinioca : ''}
                            items={polPociniocaEnumValues}
                            errors={errors}
                            isArray={true}
                            isRequired={!disabled}
                            disabled={disabled}
                            minWidth={''}
                        />
                    </td>
                    <td className=" editable w-[186px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <SelectBody
                            control={control}
                            name={`o7_11_k2_prijave.${index}.medjusobniOdnos`}
                            value={!!watchedValues.o7_11_k2_prijave ? watchedValues.o7_11_k2_prijave[index]?.medjusobniOdnos : ''}
                            items={medjusobniOdnosEnumValues}
                            errors={errors}
                            isArray={true}
                            isRequired={!disabled}
                            disabled={disabled}
                            minWidth={''}
                        />
                    </td>
                    <td className=" editable w-[246px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <TextInputCell
                            name={`o7_11_k2_prijave.${index}.napomena`}
                            control={control}
                            placeholder="Tекст (100 карактера)"
                            disabled={disabled}
                            maxNumberOfCharacter={100}
                            errors={errors}
                            isRequired={!disabled}
                        />
                    </td>
                    <td className=" pl-3 flex flex-row gap-6 items-center " style={{ border: 0 }}>
                        {/* <div className="w-fit cursor-pointer">
                            <Edit />
                        </div> */}
                        {fields.length > 1 && !disabled && (
                            <Button className="w-fit cursor-pointer" onClick={() => remove(index)}>
                                <Delete />
                            </Button>
                        )}
                    </td>
                </tr>
            ))}
        </table>
    )
}

export default Form7_11_k2Table
