import { GRADE } from '../../CommonTypes'
import { File } from '../../File.entity'
import { Organization } from '../../Organization.entity'
import { SubmittedBy } from '../../SubmittedBy.entity'
import { FileDescription } from './Common/FileDescription.entity'

export const NumberOfSteps1 = 4
export const NumberOfSteps2 = 3
export interface Form7_14_1Entity {
    report_id: string
    rep_year: number
    submitted_date?: Date
    submitted_place?: Date
    category: string
    organisation: Organization
    status: string
    submitted_by?: SubmittedBy

    o7_14_1_k1_fileDescription: FileDescription[]
    o7_14_1_k1_file: File[]

    o7_14_1_k2_politike: number | undefined
    o7_14_1_k2_obuke: number | undefined
    o7_14_1_k2_obuceni: number | undefined
    o7_14_1_k2_uputstva: number | undefined

    o7_14_1_k3_ocena: GRADE
    o7_14_1_k3_opis: string //max400
}

export interface Form7_14_2Entity {
    report_id: string
    rep_year: number
    submitted_date?: Date
    submitted_place?: Date
    category: string
    organisation: Organization
    status: string
    submitted_by?: SubmittedBy

    o7_14_2_k1_obuke: number | undefined
    o7_14_2_k1_z: number | undefined
    o7_14_2_k1_m: number | undefined

    o7_14_2_k3_ocena: number
    o7_14_2_k3_opis: string //max400
}
