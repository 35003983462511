import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getUser } from '../../server/server'
import { useForm } from 'react-hook-form'
import { EditCategoryModal } from './Components/EditCategoryModal'
import { TextareaAutosize } from '@mui/material'
import HelpButton from '../../components/Common/HelpButton'

interface CategoryInterface {
    id: string
    name: string
}

type UserDetails = {
    pib: number
    mb: number
    subjectName: string
    email: string
    admin: boolean
    firstName: string
    lastName: string
    address: string
    phoneNumber: string
    city: string
    category: CategoryInterface | undefined
    subCategory: CategoryInterface | undefined
    legalEntities: any[]
}

const UserDetails = () => {
    const {
        register,
        formState: { errors },
        setValue,
    } = useForm<UserDetails>()
    let [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const [userData, setUserData] = useState<UserDetails | null>(null)

    const userId = searchParams.get('userId')

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        if (userId) loadUser()
    }, [userId])

    const loadUser = async () => {
        const resp = await getUser(userId)
        const data = resp?.data
        if (resp.status === 200 && data) {
            setUserData(data)
            Object.keys(data).forEach((key) => {
                setValue(key as keyof UserDetails, data[key])
            })
        }
    }

    return (
        <div className="flex items-center mt-[20px] w-full h-full flex-col">
            <h2 className="mb-[32px] text-[24px] leading-[28px] text-center font-light max-w-[200px]">Детаљи</h2>
            <form className="flex flex-col items-center w-full max-w-[520px]" autoComplete="off">
                <div className="flex flex-col w-full">
                    <div className="flex flex-col mb-[16px]">
                        <div className="flex flex-row items-end justify-between">
                            <label>Име</label>
                            <div className="mb-[5px]">
                                <HelpButton name="user_details_help" />
                            </div>
                        </div>
                        <input
                            className="border border-[#0000003B] max-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                            placeholder="Име"
                            {...register('firstName')}
                            disabled={true}
                        />
                    </div>
                    <div className="flex flex-col mb-[16px]">
                        <label>Презиме</label>
                        <input
                            className="border border-[#0000003B] max-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                            placeholder="Презиме"
                            {...register('lastName')}
                            disabled={true}
                        />
                    </div>
                    <div className="flex flex-col mb-[16px]">
                        <label>Имејл</label>
                        <input
                            className="border border-[#0000003B] max-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                            placeholder="Имејл"
                            {...register('email')}
                            disabled={true}
                        />
                    </div>
                    {!userData?.admin && (
                        <div className="flex flex-col mb-[16px]">
                            <label>Правна лица</label>
                            {userData?.legalEntities?.map((entity: any) => (
                                <input
                                    className="border border-[#0000003B] max-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                                    value={`${entity.subjectName} - ПИБ: ${entity.pib} МБ: ${entity.mb}`}
                                    disabled={true}
                                />
                            ))}
                        </div>
                    )}
                </div>
                <div className="flex flex-row items-center justify-between w-full mb-[20px]">
                    <button
                        onClick={() => navigate('/admin/users')}
                        className="w-full h-[42px] flex items-center justify-center rounded-[4px] border border-[#243964] text-[15px] text-[#243964] font-medium leading-[26px] cursor-pointer mt-[43px] max-w-[200px]"
                    >
                        ПРЕТХОДНА СТРАНИЦА
                    </button>
                </div>
            </form>
        </div>
    )
}

export default UserDetails
