import React, { useEffect, useState } from 'react'
import { useUser } from '../../../auth/UseUser'
import { newEmailChange, whoAmI } from '../../../server/server'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from '@mui/material'

const EmailChangeStep2 = () => {
    let [searchParams, setSearchParams] = useSearchParams()
    const { setUser } = useUser()
    const [error, setError] = useState<boolean | undefined>(undefined)
    const navigate = useNavigate()

    const emailParam = searchParams.get('email')
    const email = emailParam ? emailParam.replace(/\s/g, '+') : null
    const token = searchParams.get('token') ?? null

    useEffect(() => {
        resetEmailAddress()
    }, [])

    const resetEmailAddress = async () => {
        const data = {
            email: email,
            token: token,
        }
        const resp = await newEmailChange(data)

        if (resp.status === 201) {
            const response = await whoAmI()
            if (response.status === 200) {
                const user = response.data
                if (user.valid === false) {
                    return
                }
                setUser(user)
                localStorage.setItem('user', JSON.stringify(user))
            }
        } else {
            setError(true)
        }
    }

    return (
        <div className="w-full h-full flex flex-col justify-center items-center">
            {!error ? (
                <p>Поштовани корисниче, ваша имејл адреса је успешно измењена на: {email}. Кликом на дугме "Почетна" можете да се вратите на почетну страницу.</p>
            ) : (
                <p>Грешка приликом измене имејл адресе, молимо контактирајте нас.</p>
            )}
            <button
                type="button"
                className="h-[44px] px-2 flex items-center justify-center rounded-md border-2 border-greyLight hover:border-greyMedium bg-white text-[18px] text-greyMedium font-bold cursor-pointer mt-5 "
                onClick={() => {
                    navigate('/')
                }}
            >
                Почетна
            </button>
        </div>
    )
}

export default EmailChangeStep2
