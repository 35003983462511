import React from 'react'
import { Control, useWatch } from 'react-hook-form'
import ActionButtons from '../../../../../components/ActionButtons'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { Form7_12Entity } from '../../../../../models/Form7/Entities/Form7_12.entity'
import { calculateDifference, percentage, percentageOfDifference } from '../../../../../utils/mathUtils'

export interface StepInterface {
    control?: Control<Form7_12Entity>
    nextStep: (skip?: boolean) => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
}

function Step1({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_12Entity>({ control })
    const fields: ['o7_12_k1_tekuca', 'o7_12_k1_prethodna'] = ['o7_12_k1_tekuca', 'o7_12_k1_prethodna']

    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">
                1. Број реализованих обука о родној равноправности, смањењу родних стереотипа и равноправном учешћу жена и мушкараца у јавном и политичком животу за представнике/це
                институција јавне власти у години извештавања:
            </h1>
            <h1 className="mt-5">Текућа година</h1>
            <div className=" w-[140px] h-[50px] flex items-center justify-center mt-[10px]">
                <PositiveNumberInputCell name={'o7_12_k1_tekuca'} control={control} defaultValue="" errors={errors} isRequired={!adminView} height={52} disabled={adminView} />
            </div>
            <div className="h-[2px] mt-1"> {errors?.o7_12_k1_tekuca && <p className="text leading-5 text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            <h1 className="mt-5">Претходна година</h1>
            <div className=" w-[140px] h-[50px] flex items-center justify-center mt-[10px]">
                <PositiveNumberInputCell name={'o7_12_k1_prethodna'} control={control} defaultValue="" errors={errors} isRequired={!adminView} height={52} disabled={adminView} />
            </div>
            <div className="h-[2px] mt-1"> {errors?.o7_12_k1_prethodna && <p className="text leading-5 text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            <h1 className="mt-5">Разлика %</h1>
            <div className=" border border-inactive w-[140px] h-[50px] flex items-center justify-center mt-[10px]">
                <p>{percentageOfDifference(watchedValues['o7_12_k1_tekuca'], watchedValues['o7_12_k1_prethodna'])}</p>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step1
