import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { Controller, useForm } from 'react-hook-form'
import { Button, TextField } from '@mui/material'
import { updateCity } from '../../server/server'
import Loading from '../../components/Common/LoadingSpinner'
import PositiveNumberInput from './PositiveNumberInput'

function AddCityModal({ modalIsOpen, closeModal, city = null, reloadCities }: any) {
    const [processing, setProcessing] = useState(false)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        getValues,
        setValue,
        control,
    } = useForm({
        defaultValues: {
            name: '',
            zip: '',
            region: '',
            municipality: '',
        },
    })

    useEffect(() => {
        if (modalIsOpen) {
            ReactModal.setAppElement('#root')
        }
    }, [modalIsOpen])

    useEffect(() => {
        if (!!city?.id) {
            setValue('name', city.name)
            setValue('region', city.region)
            setValue('municipality', city.municipality)
            setValue('zip', city.zip)
        }
    }, [city])

    const onSubmitModal = async () => {
        setProcessing(true)
        const data: any = getValues()
        if (!!city?.id) {
            data.id = city.id
        }
        const resp = await updateCity(data)
        if (resp.status === 201) {
            closeModal()
            reloadCities()
            setProcessing(false)

            alert(data.id ? 'Успешно сте изменили место.' : 'Успешно сте креирали место.')
        } else {
            setProcessing(false)
        }
    }
    const cleanFunction = () => {
        reset()
        const el = document.getElementById('scroll-target')
        if (el) el.classList.remove('no-scroll-behind')
    }
    const onInputChange = (e: any) => {
        const inputValue = e.target.value
        const filteredValue = inputValue.replace(/[^a-zA-ZčćžšđČĆŽŠĐа-яА-ЯёЁЊњЉљЂђЋћЏџ ]/g, '')
        e.target.value = filteredValue
    }
    return (
        <ReactModal
            ariaHideApp={false}
            //closeTimeoutMS={600}
            isOpen={modalIsOpen}
            onAfterOpen={() => {
                const el = document.getElementById('scroll-target')
                if (el) el.classList.add('no-scroll-behind')
            }}
            onAfterClose={() => cleanFunction()}
            onRequestClose={closeModal}
            className="no-scrollbar"
            style={{
                overlay: {
                    zIndex: 5,
                    backdropFilter: 'brightness(30%)',
                },
                content: {
                    padding: 0,
                    opacity: 1,
                    width: 600,
                    margin: 'auto',
                    backgroundColor: 'transparent',
                    height: '100%',
                    maxHeight: '100%',
                    border: '0px',
                    boxShadow: 'none',
                    overflow: 'auto',
                    paddingTop: 15,
                    paddingBottom: 15,
                },
            }}
        >
            <div className="   max-h-[600px] w-full  ">
                <form onSubmit={handleSubmit(onSubmitModal)} className=" flex flex-1 flex-col gap-5 rounded-[10px] bg-white  px-[40px] py-[30px] ">
                    <div className="flex w-full flex-col ">
                        <p className="text-[24px] font-medium leading-[30px]">{!!city ? 'Измени место' : 'Додај ново место'}</p>
                    </div>
                    <div className="">
                        <TextField
                            {...register('name', { required: 'Поље је обавезно' })}
                            helperText={errors.name?.message}
                            error={!!errors.name}
                            className="background-transparent w-full"
                            label="Име места"
                            variant="filled"
                            onInput={onInputChange}
                        />
                    </div>

                    <div className="flex flex-row items-center gap-[10px]">
                        <div className="w-[150px] border flex items-center justify-center h-[50px] bg-[#F5F5F5] text-[#00000090] border-[#00000010]">
                            <p className="text-center mx-auto">Поштански броj</p>
                        </div>
                        <PositiveNumberInput name={`zip`} control={control} errors={errors} isRequired={true} maxLength={5} />
                    </div>

                    <div className="">
                        <TextField
                            {...register('region', { required: 'Поље је обавезно' })}
                            helperText={errors.region?.message}
                            error={!!errors.region}
                            className="background-transparent w-full"
                            label="Регион"
                            variant="filled"
                            onInput={onInputChange}
                        />
                    </div>
                    <div className="">
                        <TextField
                            {...register('municipality', { required: 'Поље је обавезно' })}
                            helperText={errors.municipality?.message}
                            error={!!errors.municipality}
                            className="background-transparent w-full"
                            label="Општина"
                            variant="filled"
                            onInput={onInputChange}
                        />
                    </div>
                    <div className="flex w-full flex-row justify-end gap-8 mt-10  ">
                        <Button variant="contained" onClick={() => closeModal()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
                            Одустани
                        </Button>
                        <Button type="submit" variant="contained" style={{ backgroundColor: '#2ec4b6', color: 'white' }} disabled={processing}>
                            {processing ? <Loading height={20} /> : 'Потврди'}
                        </Button>
                    </div>
                </form>
            </div>
        </ReactModal>
    )
}

export default AddCityModal
