import React from 'react'
import { ReactComponent as UploadCloud } from '../../assets/svg/UploadCloud.svg'

function UploadFileMock({ fileTypes, disabled, error }: { fileTypes: string[]; disabled?: boolean; error?: any }) {
    return (
        <div
            className={`h-[202px] w-[445px] border ${
                !error ? 'border-primary  border-dashed' : 'border-textError'
            } rounded-[4px] flex items-center justify-center flex-col gap-[20px] ${!disabled ? 'cursor-pointer' : 'cursor-auto'}`}
            style={disabled ? { filter: 'grayscale(100%)' } : {}}
        >
            <UploadCloud />
            <div className="flex flex-col gap-[8px]">
                <p className="font-medium text-[18px] text-center">
                    Превуците и отпустите датотеке или{' '}
                    <button tabIndex={disabled ? -1 : 0} className={`text-primary ${!disabled ? 'cursor-pointer' : 'cursor-auto'}`}>
                        Претражите
                    </button>
                </p>
                <p className="text-disabled font-bold text-[14px] text-center">Подржани формати: {fileTypes.join(', ')}</p>
            </div>
        </div>
    )
}

export default UploadFileMock
