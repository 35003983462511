import { MenuItem, Select } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

interface SelectComponentInterface {
    value: any
    onChange: (value: string) => void
    elements: any[]
    o2s4?: boolean
    error?: boolean
    disabled?: boolean
}

function SelectComponent({ elements, onChange, value, o2s4, error, disabled }: SelectComponentInterface) {
    return (
        <Select
            labelId="select-component"
            disabled={disabled}
            id="select-component"
            value={value}
            onChange={(e) => onChange(e.target.value as string)}
            className={`border-[#00000010] min-w-[186px] max-w-[186px] ${o2s4 ? '!h-full' : 'h-[50px]'} select-component-css`}
            sx={{
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: error ? '#d32f2f' : '#00000010',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: error ? '#d32f2f' : '#00000010',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: error ? '#d32f2f' : '#00000010',
                },
                '.MuiSvgIcon-root ': {
                    fill: '#0000008F !important',
                },
            }}
            displayEmpty
            renderValue={(selected) => (!selected ? 'Изаберите' : selected)}
        >
            {elements?.map((item) => (
                <MenuItem key={item} value={item} style={{ textAlign: 'center', height: 50, justifyContent: 'center' }}>
                    {item}
                </MenuItem>
            ))}
        </Select>
    )
}

export default SelectComponent

interface SelectComponentYesNotInterface {
    control: any
    name: string
    value: any
    disabled?: boolean
}

export function SelectYesNoComponent({ control, value, name, disabled }: SelectComponentYesNotInterface) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <Select
                    {...field}
                    labelId="select-component"
                    id="select-component"
                    value={value ? 'Да' : 'Не'}
                    onChange={(e) => field.onChange(e.target.value === 'Да')}
                    className="border-[#00000010] min-w-[186px] max-w-[186px] h-[50px] select-component-css"
                    disabled={disabled}
                    sx={{
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#00000010',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#00000010',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#00000010',
                        },
                        '.MuiSvgIcon-root ': {
                            fill: '#0000008F !important',
                        },
                    }}
                    displayEmpty
                    renderValue={(selected) => (!selected ? 'Изаберите' : selected)}
                >
                    <MenuItem value={'Да'} style={{ textAlign: 'center', height: 50, justifyContent: 'center' }}>
                        Да
                    </MenuItem>
                    <MenuItem value={'Не'} style={{ textAlign: 'center', height: 50, justifyContent: 'center' }}>
                        Не
                    </MenuItem>
                </Select>
            )}
        />
    )
}
