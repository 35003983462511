import { TblOblast1 } from '../../../models/Form1/Form1.entity'
import '../../../assets/styles/ComplexTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useFieldArray, useWatch } from 'react-hook-form'
import { StepInterface } from './Step1'
import { Button, TextareaAutosize } from '@mui/material'
import { useState } from 'react'

function Step10({ control, nextStep, previousStep, previousButtonDisabled = false, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast1>({ control })
    const [error, setError] = useState<any>({
        active: false,
        text: '',
    })
    const [textbox, setTextbox] = useState('')
    const tbDisabled =
        watchedValues.o1_k9_m_15_30 === 0 &&
        watchedValues.o1_k9_z_15_30 === 0 &&
        watchedValues.o1_k9_m_31_40 === 0 &&
        watchedValues.o1_k9_z_31_40 === 0 &&
        watchedValues.o1_k9_m_41_50 === 0 &&
        watchedValues.o1_k9_z_41_50 === 0 &&
        watchedValues.o1_k9_m_51_60 === 0 &&
        watchedValues.o1_k9_z_51_60 === 0 &&
        watchedValues.o1_k9_m_61_70 === 0 &&
        watchedValues.o1_k9_z_61_70 === 0

    const disabledNext = watchedValues?.o1_k10_nacin?.length == 0 || watchedValues?.o1_k10_nacin?.length == undefined
    const disabledAddMore = textbox.trim() === ''

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o1_k10_nacin',
    })

    const handleAddField = () => {
        setTextbox('')
        append({ text_content: textbox })
        setError({ active: false, text: '' })
    }

    const goToNextStep = () => {
        if (adminView) {
            nextStep()
        } else if (tbDisabled) {
            setError({
                active: false,
                text: '',
            })
            nextStep()
        } else if (disabledNext && disabledAddMore) {
            setError({
                active: true,
                text: 'Попуните обавезно поље.',
            })
        } else if (disabledNext && !disabledAddMore) {
            setError({
                active: true,
                text: 'Додајте начин поступања',
            })
        } else {
            setError({
                active: false,
                text: '',
            })
            nextStep()
        }
    }

    const checkDisabled = () => {
        return tbDisabled || adminView
    }

    return (
        <div>
            <h1 className="step-name">
                10. Начин поступања одговорног лица у органима јавне власти и послодавца по поднетим пријавама запослених и радно ангажованих лица о њиховој изложености
                узнемиравању, сексуалном узнемиравању или уцењивању или неком другом поступању које има за последицу дискриминацију на основу пола, односно рода, разврстаних по
                полу и старосној доби.
            </h1>
            {tbDisabled && !adminView && (
                <p className="min-h-[5px] mt-[10px] text-textError">
                    У претходном кораку сте унели да је број примљених пријава за све старосне групе 0. Уколико желите да унесете вредност у ова поља измените вредности у
                    претходном кораку.
                </p>
            )}
            <div className="mt-[20px]">
                <div className="flex flex-col gap-[20px] mb-[30px]">
                    {fields?.map((field, index) => (
                        <div className="flex flex-row items-center gap-[50px] ">
                            <p className="w-[1028px] break-words">{field.text_content}</p>
                            <Button color="error" disabled={adminView} variant="contained" onClick={() => remove(index)}>
                                Обриши
                            </Button>
                        </div>
                    ))}
                    {!fields?.length && <div className="text-inactive h-[100px] flex items-center">Тренутно нема унетих начина поступања</div>}
                </div>
                <div className=" flex flex-col w-full max-w-[1028px]">
                    <h4 className="mb-[10px]">Додај начин поступања:</h4>
                    <TextareaAutosize
                        value={textbox}
                        onChange={(e) => {
                            setTextbox(e.target.value)
                            setError(false)
                        }}
                        // required={!checkDisabled()}
                        minRows={4}
                        placeholder={'Текст (2048 карактера)'}
                        className={`border ${!error.active ? 'border-[#D9D9D9]' : 'border-[#d32f2f] placeholder-[#d32f2f]'}`}
                        style={{
                            width: '100%',
                            padding: '12px 16px',
                            boxSizing: 'border-box',
                            borderRadius: '8px',
                            resize: 'none',
                        }}
                        maxLength={2048}
                        disabled={checkDisabled()}
                    />
                </div>
                <div className="h-[26px]">{error.text == 'Попуните обавезно поље.' && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">{error.text}</p>}</div>
            </div>
            <Button variant="contained" onClick={handleAddField} disabled={disabledAddMore}>
                Додај начин поступања
            </Button>
            <div className="h-[26px]">{error.text == 'Додајте начин поступања' && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">{error.text}</p>}</div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step10
