import '../../../../../assets/styles/SimpleTable.css'
import { Control, useWatch } from 'react-hook-form'
import { Form7_10Entity } from '../../../../../models/Form7/Entities/Form7_10.entity'
import ActionButtons from '../../../../../components/ActionButtons'
import SimpleTable from '../../../../../components/Tables/SimpleTable'

export interface StepInterface {
    control?: Control<Form7_10Entity>
    nextStep: (skip?: boolean) => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
}
export interface RowInterface {
    label: string
    field: keyof Form7_10Entity
}

const rowsTable1: RowInterface[] = [
    { label: 'Мушкарци', field: 'o7_10_k2_m_korisnik' },
    { label: 'Жене', field: 'o7_10_k2_z_korisnik' },
]

const rowsTable2: RowInterface[] = [
    { label: 'Мушкарци', field: 'o7_10_k2_m_pruzalac' },
    { label: 'Жене', field: 'o7_10_k2_z_pruzalac' },
]

function Step2({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_10Entity>({ control })
    const fieldsTable1: ('o7_10_k2_m_korisnik' | 'o7_10_k2_z_korisnik')[] = ['o7_10_k2_m_korisnik', 'o7_10_k2_z_korisnik']
    const fieldsTable2: ('o7_10_k2_m_pruzalac' | 'o7_10_k2_z_pruzalac')[] = ['o7_10_k2_m_pruzalac', 'o7_10_k2_z_pruzalac']

    const joinArray = [...fieldsTable1, ...fieldsTable2]
    const disabledNext = !joinArray.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">2. Број корисника бесплатне правне помоћи разврстаних по полу:</h1>
            <SimpleTable control={control} header={['Пол', 'Број', 'Проценат']} rows={rowsTable1} fields={fieldsTable1} errors={errors} disabled={adminView} />
            <h1 className="step-name mt-6">Број пружалаца бесплатне правне помоћи разврстаних по полу:</h1>
            <SimpleTable control={control} header={['Пол', 'Број', 'Проценат']} rows={rowsTable2} fields={fieldsTable2} errors={errors} disabled={adminView} />
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step2
