import { Input } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
interface TextInputInterface {
    name: string
    maxNumberOfCharacter?: number
    control: any
    disabled?: boolean
    placeholder?: string
    error?: boolean
    isName?: boolean
}
function TextInput({ control, name, disabled, maxNumberOfCharacter, placeholder, isName = false }: TextInputInterface) {
    return (
        <div className="min-w-[100px] border border-[#00000010]  h-[50px] flex items-center p-[15px]">
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <Input
                        {...field}
                        onChange={(event) => {
                            const newValue = event.target.value

                            if (isName) {
                                const regex = /^[a-zA-Z' ]*$/
                                if (regex.test(newValue)) {
                                    field.onChange(newValue)
                                }
                            } else {
                                field.onChange(newValue)
                            }
                        }}
                        onKeyDown={(event) => {
                            if (isName) {
                                const key = event.key
                                const regex = /^[a-zA-Z' ]$/

                                const controlKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown']

                                if (!regex.test(key) && !controlKeys.includes(key)) {
                                    event.preventDefault()
                                }
                            }
                        }}
                        className="!bg-[transparent] border-none w-full"
                        placeholder={placeholder}
                        disabled={disabled}
                        inputProps={{ maxLength: maxNumberOfCharacter }}
                        // error={true}
                        sx={{
                            textAlign: 'left',
                            '& .MuiInputBase-input': {
                                textAlign: 'left',
                            },
                            '&.MuiInputBase-root:before': {
                                borderBottom: 'none',
                            },
                            '&.MuiInputBase-root:after': {
                                borderBottom: 'none',
                            },
                            '&.MuiInputBase-root:hover:not(.Mui-disabled):before': {
                                borderBottom: 'none',
                            },
                        }}
                    />
                )}
            />
        </div>
    )
}

export default TextInput
