import React from 'react'

function FinishButton({ borderColor = '', fill = '' }) {
    return (
        <svg width="188" height="60" viewBox="0 0 188 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_21_775)">
                <mask id="path-1-inside-1_21_775" fill="white">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.41966 6.98456C1.89587 4.31793 3.82134 1 6.89263 1H182C184.209 1 186 2.79086 186 5V53C186 55.2091 184.209 57 182 57H6.89263C3.82134 57 1.89587 53.6821 3.41966 51.0154L14.8659 30.9846C15.5686 29.7548 15.5686 28.2452 14.8659 27.0154L3.41966 6.98456Z"
                    />
                </mask>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.41966 6.98456C1.89587 4.31793 3.82134 1 6.89263 1H182C184.209 1 186 2.79086 186 5V53C186 55.2091 184.209 57 182 57H6.89263C3.82134 57 1.89587 53.6821 3.41966 51.0154L14.8659 30.9846C15.5686 29.7548 15.5686 28.2452 14.8659 27.0154L3.41966 6.98456Z"
                    fill={fill}
                />
                <path
                    d="M14.8659 30.9846L13.9976 30.4884L13.9976 30.4884L14.8659 30.9846ZM14.8659 27.0154L13.9976 27.5116L13.9976 27.5116L14.8659 27.0154ZM3.41966 51.0154L2.55141 50.5193L3.41966 51.0154ZM3.41966 6.98456L2.55141 7.48069L3.41966 6.98456ZM182 0H6.89263V2H182V0ZM187 53V5H185V53H187ZM6.89263 58H182V56H6.89263V58ZM13.9976 30.4884L2.55141 50.5193L4.2879 51.5116L15.7341 31.4807L13.9976 30.4884ZM13.9976 27.5116C14.5247 28.4339 14.5247 29.5661 13.9976 30.4884L15.7341 31.4807C16.6125 29.9435 16.6125 28.0565 15.7341 26.5193L13.9976 27.5116ZM2.55141 7.48069L13.9976 27.5116L15.7341 26.5193L4.2879 6.48842L2.55141 7.48069ZM6.89263 56C4.58916 56 3.14506 53.5116 4.2879 51.5116L2.55141 50.5193C0.64668 53.8526 3.05352 58 6.89263 58V56ZM185 53C185 54.6569 183.657 56 182 56V58C184.761 58 187 55.7614 187 53H185ZM6.89263 0C3.05352 0 0.64668 4.14741 2.55141 7.48069L4.2879 6.48842C3.14506 4.48845 4.58916 2 6.89263 2V0ZM182 2C183.657 2 185 3.34315 185 5H187C187 2.23858 184.761 0 182 0V2Z"
                    fill="#C4C4C4"
                    mask="url(#path-1-inside-1_21_775)"
                />
            </g>
            <defs>
                <filter id="filter0_d_21_775" x="0.886719" y="0" width="187.113" height="60" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.215686 0 0 0 0 0.254902 0 0 0 0 0.317647 0 0 0 0.08 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21_775" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_21_775" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export default FinishButton
