import '../../../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../../../components/ActionButtons'
import { Control, useWatch } from 'react-hook-form'
import Textbox from '../../../../Contact/TextBoxAutoSize'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { Form7_18Entity } from '../../../../../models/Form7/Entities/Form7_18.entity'
import { StepInterface } from './Step1'
import SimpleTable from '../../../../../components/Tables/SimpleTable'
import { useEffect, useState } from 'react'
import RadioButtonsControl from '../../../../../components/Common/RadioButtonsControlWithError'
import CheckboxComponent from '../../../Components/CheckboxComponent'

export function Step4({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_18Entity>({ control })

    const disabledNext = watchedValues.o7_18_k4_prepreke && !Object.values(watchedValues.o7_18_k4_prepreke).some((value) => value) && !watchedValues.o7_18_k4_opis
    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }

    const setUndefinedToFalse = (obj: any) => {
        for (const key in obj) {
            if (obj[key] === undefined) {
                obj[key] = false
            }
        }
    }

    setUndefinedToFalse(watchedValues.o7_18_k4_prepreke)

    return (
        <div>
            <h1 className="step-name mb-[30px] ">Шта је по вама највећа препрека или изазов за успешну примену и унапрећење РОБ ? </h1>
            <CheckboxComponent
                control={control}
                name="o7_18_k4_prepreke.losSoftverZaPlaniranje"
                text="Лош софтвер за планирање"
                errors={errors}
                isRequired={adminView ? false : 'Попуните обавезно поље.'}
                disabled={adminView}
            />
            <CheckboxComponent
                control={control}
                name="o7_18_k4_prepreke.nedostatakObuke"
                text="Недостатак обуке"
                errors={errors}
                isRequired={adminView ? false : 'Попуните обавезно поље.'}
                disabled={adminView}
            />
            <CheckboxComponent
                control={control}
                name="o7_18_k4_prepreke.nedostatakSaznanja"
                text="Недостатак сазнања"
                errors={errors}
                isRequired={adminView ? false : 'Попуните обавезно поље.'}
                disabled={adminView}
            />
            <CheckboxComponent
                control={control}
                name="o7_18_k4_prepreke.nedovoljnoSredstvaUBudzetu"
                text="Недовољно средства у буџету"
                errors={errors}
                isRequired={adminView ? false : 'Попуните обавезно поље.'}
                disabled={adminView}
            />
            <CheckboxComponent
                control={control}
                name="o7_18_k4_prepreke.nedovoljnoVremena"
                text="Недовољно времена"
                errors={errors}
                isRequired={adminView ? false : 'Попуните обавезно поље.'}
                disabled={adminView}
            />
            <CheckboxComponent
                control={control}
                name="o7_18_k4_prepreke.nejasnaRegulativa"
                text="Нејасна регулатива"
                errors={errors}
                isRequired={adminView ? false : 'Попуните обавезно поље.'}
                disabled={adminView}
            />
            <CheckboxComponent
                control={control}
                name="o7_18_k4_prepreke.organizacionePrepreke"
                text="Организационе препреке"
                errors={errors}
                isRequired={adminView ? false : 'Попуните обавезно поље.'}
                disabled={adminView}
            />
            <CheckboxComponent
                control={control}
                name="o7_18_k4_prepreke.neadekvatnaSvest"
                text="Неадекватна свест"
                errors={errors}
                isRequired={adminView ? false : 'Попуните обавезно поље.'}
                disabled={adminView}
            />
            <div className="mt-[50px] ">
                <h4 className="mb-[10px]">Друго - молим Вас да упишете:</h4>
                <div className="flex flex-col">
                    <Textbox
                        name="o7_18_k4_opis"
                        control={control}
                        placeholder="Текст (200 карактера)*"
                        errors={errors}
                        isRequired={adminView ? false : 'Попуните бар једно поље.'}
                        maxNumberOfCharacters={200}
                        disabled={adminView}
                    />
                </div>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
