import { StepInterfaceObl2 } from './Step1'
import { useFieldArray, useWatch } from 'react-hook-form'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import MultipleInputTable from '../../../components/Tables/MultipleInputTable'
import { Button } from '@mui/material'
import ActionButtons from '../../../components/ActionButtons'
import { useState } from 'react'

const Step11 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterfaceObl2) => {
    const watchedValues = useWatch<TblOblast2>({ control })
    const [emptyArrayError, setEmptyArrayError] = useState(false)
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o2_k11_aktivnosti',
    })
    const name = 'o2_k11_aktivnosti'

    let disabledNext = watchedValues[name]?.length === 0

    fields.forEach((item, index) => {
        const { femaleNumber, maleNumber, level } = watchedValues[name]?.[index] || {}

        if (femaleNumber === undefined || femaleNumber === null || femaleNumber?.toString() === '') {
            disabledNext = true
        } else if (maleNumber === undefined || maleNumber === null || maleNumber?.toString() === '') {
            disabledNext = true
        } else if (level === undefined || level === null || level === '') {
            disabledNext = true
        } else disabledNext = false
    })

    const header = ['Ниво активности:', 'Жене', 'Жене %', 'Мушкарци', 'Мушкарци %', 'Укупно']

    const handleAddArrayField = () => {
        setEmptyArrayError(false)
        append({ level: '', maleNumber: undefined, femaleNumber: undefined })
    }

    const validateEmptyArray = () => {
        if (watchedValues[name]?.length === 0 || watchedValues[name] === undefined) {
            setEmptyArrayError(true)
            return true
        }
        return false
    }

    const goToNextStep = () => {
        if (!disabledNext && !validateEmptyArray()) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <h1 className="step-name">
                Број и степен укључености организација цивилног друштва (удружења) у активности тела за родну равноправност везаних за промоцију родне равноправности, спречавање и
                сузбијање родно заснованог насиља и насиља према женама, као и борбу против дискриминације на основу пола, односно рода (у областима: рада, запошљавања,
                самозапошљавања, социјалног осигурања, социјалне и здравствене заштите, образовања, одбране и безбедности, саобраћаја, енергетике и комуникација, заштите животне
                средине, културе, јавног информисања, спорта, политичког деловања и јавних послова, репродуктивних и сексуалних права и приступу роби и услугама) у години вођења
                евиденцијe
            </h1>
            <MultipleInputTable fields={fields} control={control as any} header={header} allFields={[...fields]} remove={remove} name={name} errors={errors} disabled={adminView} />

            <div className="flex flex-col mt-[56px]">
                <Button variant="contained" className="max-w-[172px] mt-[56px]" onClick={handleAddArrayField} disabled={adminView}>
                    Додај активност
                </Button>
                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError ? 'Додајте барем једну активност!' : ''}</span>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step11
