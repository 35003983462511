import { TblOblast1 } from '../../../models/Form1/Form1.entity'
import '../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import '../../../assets/styles/SimpleTable.css'
import { format } from 'date-fns'
import TextInput from '../../../components/Common/TextInput'
import { StepInterface } from './Step1'
import { useEffect } from 'react'
import { useUser } from '../../../auth/UseUser'

function Step19({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, setValue }: StepInterface) {
    const watchedValues = useWatch<TblOblast1>({ control })

    const disabledNext = false //!watchedValues['submitted_place'] &&!adminView
    const { user } = useUser()
    const city = user?.city

    useEffect(() => {
        setValue('submitted_place', city)
    }, [])

    return (
        <div>
            <h1 className="step-name">19. Поштовани, кликом на заврши и пошаљи потврђујете да сте унели све потребне информације у дефинисана поља</h1>
            <div className="flex flex-row items-center mt-[100px] gap-[5px]">
                <p>У</p>
                <TextInput control={control} name="submitted_place" placeholder="Град" isName disabled={true} />

                <p>, дана {format(new Date(), 'dd.MM.yyyy.')}</p>
            </div>
            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={disabledNext} previousButtonDisabled={false} lastStep isAdmin={adminView} />
        </div>
    )
}

export default Step19
