import React, { useEffect } from 'react'
import { StepInterface } from './Step1'
import ActionButtons from '../../../../components/ActionButtons'
import PositiveNumberInputCell from '../../../Form2/Components/PositiveNumberInputCell'
import { getAnalyticsDataForForm2 } from '../../../../server/server'

const Step17 = ({
    control,
    nextStep,
    previousStep,
    previousButtonDisabled = false,
    errors,
    adminView,
    countType,
    setValue,
    selectedCategories,
    selectedSubCategories,
}: StepInterface) => {
    const fields: 'o2_k17_procenat'[] = ['o2_k17_procenat']

    useEffect(() => {
        loadData()
    }, [countType, selectedCategories, selectedSubCategories])

    const loadData = async () => {
        const data = {
            type: countType,
            fields: fields,
            category: selectedCategories?.map((e) => e.name),
            subCategory: selectedSubCategories?.map((e) => e.name),
        }
        const resp = await getAnalyticsDataForForm2(data)
        if (resp.status === 200) {
            const data = resp.data
            fields.forEach((field) => {
                setValue(field, parseFloat(data[field]))
            })
        }
    }
    return (
        <div>
            <h1 className="step-name">Планирани степен реализације покрајинског или локалног буџета са становиштва остварења родне перспективе у години вођења евиденције</h1>
            <div className="mt-[20px] py-[15px] flex flex-row items-center min-h-[60px]">
                <p className="font-[14px] mr-[20px]">Проценат буџета</p>
                <div className="h-[52px]">
                    <div className="flex flex-row gap-5 min-w-[200px]">
                        <PositiveNumberInputCell
                            name={'o2_k17_procenat'}
                            control={control}
                            decimal={true}
                            isMultipleTable={false}
                            errors={errors}
                            isRequired={!adminView}
                            precentage={true}
                            max={100}
                            disabled={true}
                        />
                    </div>
                </div>
            </div>

            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step17
