import { Controller } from 'react-hook-form'
import { TextareaAutosize } from '@mui/material'
interface TextboxInterface {
    name: string
    control: any
    defaultValue?: string
    placeholder?: string
    maxNumberOfCharacters?: number
    width?: number
    disabled?: boolean
    errors?: any
    isRequired?: any
    rules?: any
}

const Textbox = ({
    name,
    control,
    defaultValue = '',
    placeholder,
    width = 1028,
    disabled = false,
    maxNumberOfCharacters = 100,
    errors,
    isRequired = false,
    rules,
}: TextboxInterface) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={{ required: isRequired, ...rules }}
            render={({ field }) => (
                <>
                    <TextareaAutosize
                        disabled={disabled}
                        {...field}
                        minRows={4}
                        placeholder={placeholder === undefined ? `Текст (${maxNumberOfCharacters} карактера)` : placeholder}
                        maxLength={maxNumberOfCharacters}
                        className={`border border-[#D9D9D9]`}
                        style={{
                            width: '100%',
                            padding: '12px 16px',
                            boxSizing: 'border-box',
                            borderRadius: '4px',
                            resize: 'none',
                            maxWidth: width,
                            outline: 'none',
                        }}
                    />
                    {errors && <p className="min-h-5 text-[#d32f2f] text-[0.75rem] text leading-5 ml-[14px]">{errors[name] ? errors[name]?.message : ''}</p>}
                </>
            )}
        />
    )
}

export default Textbox

interface TextboxStateInterface {
    onChange?: (value: string) => void
    value: string
    disabled?: boolean
    maxNumberOfCharacters?: number
    error?: boolean
}

export const TextboxState = ({ value, onChange = () => null, disabled = false, maxNumberOfCharacters = 100, error }: TextboxStateInterface) => {
    const getBorderClass = () => {
        if (disabled) {
            return 'border-[#00000010]'
        } else if (error) {
            return 'border-[#d32f2f] placeholder-[#d32f2f]'
        } else {
            return 'border-[#D9D9D9]'
        }
    }

    const getTextClass = () => {
        if (disabled) {
            return 'placeholder-[#00000010]'
        } else if (error) {
            return 'placeholder-[#d32f2f80]'
        } else {
            return 'placeholder-[#D9D9D9]'
        }
    }

    return (
        <>
            <TextareaAutosize
                disabled={disabled}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                minRows={4}
                onFocus={(e) => e.target.select()}
                placeholder={`Текст (${maxNumberOfCharacters} карактера)`}
                className={`border ${getBorderClass()} ${getTextClass()}`}
                maxLength={maxNumberOfCharacters}
                style={{
                    width: '100%',
                    padding: '12px 16px',
                    boxSizing: 'border-box',
                    borderRadius: '8px',
                    resize: 'none',
                    outline: 'none',
                    color: disabled ? '#00000080' : '#000000',
                }}
            />
            {error && <p className="min-h-5 text-[#d32f2f] text-[0.75rem] text leading-5 ml-[10px]"> * Попуните обавезно поље </p>}
        </>
    )
}
