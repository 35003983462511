import React, { useState } from 'react'
import { getHelpByName } from '../../server/server'
import HelpMessageModal from './HelpMessageModal'

const HelpButton = ({ name, marginTop = 0 }: { name: string; marginTop?: number }) => {
    const [open, setOpen] = useState(false)
    const [helpContent, setHelpContent] = useState<{ title: string; text: string } | null>(null)

    const handleOpen = async (e: any) => {
        e.preventDefault()
        setOpen(true)
        const resp = await getHelpByName(name)
        if (resp.status === 200 && resp.data) {
            setHelpContent(resp.data)
        }
    }

    const closeModal = () => {
        setOpen(false)
        setHelpContent(null)
    }

    return (
        <div style={{ marginTop }}>
            <button
                className="flex flex-row items-center justify-center gap-2 border border-[#909090] hover:border-[#202020] rounded-full h-8 pl-[5px] pr-[8px] py-[4px] transition"
                onClick={handleOpen}
                type="button"
            >
                <div className="flex items-center justify-center min-w-6 h-6 rounded-full bg-[#606060]">
                    <p className="font-bold text-white text-[16px] leading-4 mt-[1px]">?</p>
                </div>
                <p className="text-[#606060] text-[16px] leading-5">Упутство</p>
            </button>
            <HelpMessageModal modalIsOpen={open} closeModal={closeModal} helpContent={helpContent} />
        </div>
    )
}

export default HelpButton
