import React, { createContext, useContext, useEffect, useState } from 'react'
import { whoAmI } from '../server/server'
import Loading from '../components/Common/LoadingSpinner'
import { useNavigate } from 'react-router-dom'

const UserContext = createContext<any>(undefined)

const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<any>(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchUser()
    }, [])

    const isSignedIn = !!user?.email

    const fetchUser = async () => {
        const resp = await whoAmI()
        if (resp.status === 200) {
            const _user = resp.data
            console.log('Fetched user:', _user)

            setUser(_user)
        } else {
            setUser({})
        }
        setLoading(false)
    }

    if (loading) {
        return (
            <div>
                <Loading height={20} />
            </div>
        )
    }

    return <UserContext.Provider value={{ user, setUser, isSignedIn }}>{children}</UserContext.Provider>
}

function useUser() {
    const context = useContext(UserContext)

    if (context === undefined) {
        throw new Error(`useUser must be used within a UserProvider`)
    }

    return context
}

export { UserProvider, useUser }
