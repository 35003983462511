import { useUser } from '../../../auth/UseUser'

const DropdownItem = ({ onClick, children }: any) => (
    <li className="px-4 py-2 cursor-pointer hover:bg-[rgba(36,57,100,0.2)] text-[#243964] w-full text-center" onClick={onClick}>
        {children}
    </li>
)

export const DropdownMenu = ({ showDropdown, setShowDropdown, user, navigate, logout, tutorial = false }: any) => {
    const { setUser } = useUser()
    const handleItemClick = (path: any) => {
        navigate(path)
        setShowDropdown(false)
    }

    const openChangeLegalEntity = () => {
        setUser({ ...user, legalEntityId: null, subjectName: null })
        // const oldToken = localStorage.getItem('oldToken') ?? ''
        // localStorage.setItem('accessToken', oldToken)
        navigate('/select-legal-entity')
        setShowDropdown(false)
    }

    return (
        showDropdown && (
            <div
                className="absolute right-0 w-48 bg-white shadow-lg rounded-lg z-10 flex items-center overflow-hidden"
                onMouseLeave={() => {
                    !tutorial && setShowDropdown(false)
                }}
            >
                <ul className="w-full">
                    {!user.admin && (
                        <>
                            {!!user.legalEntityId && (
                                <>
                                    <DropdownItem onClick={openChangeLegalEntity}>Одабир законског обвезника</DropdownItem>
                                    <DropdownItem onClick={() => handleItemClick('/user/profile')}>Измена профила</DropdownItem>
                                    <DropdownItem onClick={() => handleItemClick('/user/update-legal-entity')}>Измена тренутног обвезника</DropdownItem>
                                </>
                            )}
                            <DropdownItem onClick={() => handleItemClick('/user/password')}>Измена лозинке</DropdownItem>
                            <DropdownItem onClick={() => handleItemClick('/user/email-change')}>Измена имејла</DropdownItem>
                        </>
                    )}
                    <DropdownItem
                        onClick={() => {
                            logout()
                            setShowDropdown(false)
                        }}
                    >
                        Одјава
                    </DropdownItem>
                </ul>
            </div>
        )
    )
}
