import React, { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useUser } from '../../auth/UseUser'
import { ReactComponent as ShowPasswordIcon } from '../../assets/svg/EyeFilled.svg'
import { ReactComponent as HidePasswordIcon } from '../../assets/svg/EyeHidden.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { login, resendValidationEmail, whoAmI } from '../../server/server'
import Loading from '../Common/LoadingSpinner'
import { Button } from '@mui/material'
import { baseUrl } from '../../server/connections'
import HelpButton from '../Common/HelpButton'
import TutorialModal from '../../screens/Tutorial/TutorialModal'

type Inputs = {
    email: string
    password: string
}
const Login = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const message = location.state?.message
    const { setUser, user } = useUser()
    const [showPassword, setShowPassword] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [resendMessage, setResendMessage] = useState('')
    const [openTutorial, setOpenTutorial] = useState(true)
    const [disabledTutorial, setDisableTutorial] = useState(false)
    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: {
            email: '',
            password: '',
        },
    })

    const onSubmit: SubmitHandler<Inputs> = async () => {
        loginUser()
    }
    const loginUser = async () => {
        setLoading(true)
        const resp = await login({
            email: getValues('email'),
            password: getValues('password'),
        })
        if (resp?.status === 201) {
            const data = resp.data
            setErrorMessage('')
            localStorage.setItem('accessToken', data['accessToken'])
            const response = await whoAmI()
            if (response.status === 200) {
                const user = response.data
                if (user.valid === false) {
                    localStorage.removeItem('accessToken')
                    setLoading(false)
                    navigate(`/validation/send?email=${getValues('email')}&login=true`)
                    return
                }
                setUser(user)
                setLoading(false)
                if (!user.admin) {
                    if (user.numberOfEntities === 0) navigate('/register/step2')
                    else if (user.numberOfEntities === 1) navigate('/')
                    else navigate('/select-legal-entity')
                } else {
                    navigate('/')
                }
                // if (!user.firstName || !user.lastName) {
                //     navigate('/register/step2')
                // } else if (!user.category && !user.admin) {
                //     navigate('/user/category-selection')
                // } else {
                //     navigate('/')
                // }
            } else {
                setLoading(false)
            }
        } else {
            setLoading(false)
            if (resp.data?.message === 'Погрешни креденцијали, покушајте поново.') setErrorMessage('Неисправно корисничко име или лозинка, проверите унос и покушајте поново.')
        }
    }

    return (
        <div className="flex items-center justify-center h-full relative">
            <div className="absolute top-5 right-10 ">
                <HelpButton name="user_login_help" />
            </div>
            <div className="px-[99px] py-[45px] bg-[#ffffff] items-center flex flex-col">
                <h2 className="mb-[32px] text-[24px] leading-[28px] font-light">Пријава</h2>

                <form className="flex flex-col items-center w-full " onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="w-full flex flex-col">
                        <input
                            className="border border-[#0000003B] min-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                            placeholder="Емаил"
                            {...register('email', {
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'invalid email address',
                                },
                            })}
                        />
                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px]">
                            {errors.email?.type === 'required' ? 'Емаил је обавезно поље' : ''}
                            {errors.email?.type === 'pattern' ? 'Емаил адреса није исправно попуњена' : ''}
                        </span>
                    </div>
                    <div className="flex flex-col w-full mt-[16px]">
                        <div className="border border-[#0000003B] rounded-[4px] min-w-[520px] min-h-[56px] w-full px-3 flex flex-row items-center ">
                            <input
                                className="text-[16px] leading-[24px] w-full outline-none "
                                placeholder="Лозинка"
                                type={showPassword ? 'text' : 'password'}
                                {...register('password', { required: true })}
                                autoComplete="off"
                                onClick={() => setErrorMessage('')}
                            />
                            <div className="cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                                {!showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                            </div>
                        </div>
                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] ">{errors.password?.type === 'required' ? 'Лозинка је обавезно поље' : ''}</span>
                    </div>

                    <p className="text-[#d32f2f] text-[12px] leading-5 h-[50px] max-w-[500px]">{!!errorMessage ? errorMessage : ''}</p>
                    <span className={`${message !== 'success' ? 'text-[#d32f2f]' : ''} max-w-[520px] text-center`}>
                        {message === 'success' && 'Налог је успешно валидиран, можете да се улогујете'}
                        {message === 'error' && 'Грешка при валидацији налога, контактирајте администратора'}
                    </span>
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ backgroundColor: '#243964' }} disabled={loading}>
                        {!loading ? 'ПРИЈАВИТЕ СЕ' : <Loading height={25} />}
                    </Button>
                </form>
                <div
                    className="my-[10px] text-[#243964] text-[16px] underline leading-[24px] flex justify-start items-start text-left cursor-pointer"
                    onClick={() => navigate('/password/recovery')}
                >
                    Заборављена лозинка?
                </div>
                <Button
                    type="button"
                    variant="contained"
                    color="info"
                    fullWidth
                    sx={{ backgroundColor: '#0388d1' }}
                    onClick={() => (window.location.href = `${baseUrl}/oidc/login`)}
                >
                    Приступ путем eID налога
                </Button>
                <div className="flex flex-row mx-auto gap-[8px] mt-[20px]">
                    <p className="font-[16px] leading-[24px]">Немате налог?</p>
                    <Link to="/register" className="font-[16px] leading-[24px] underline">
                        Региструјте се
                    </Link>
                </div>
            </div>
            {!disabledTutorial && <TutorialModal open={openTutorial} onClose={() => setOpenTutorial(false)} segment="login" />}
        </div>
    )
}

export default Login
