import React from 'react'
import { Control, FieldValues, UseFieldArrayRemove } from 'react-hook-form'
import TextInputCell from '../../../components/Common/TextInputCell'
import { ReactComponent as Delete } from '../../../assets/svg/Delete.svg'
import { Button } from '@mui/material'

interface DocumentsTableInterface<T extends FieldValues> {
    control: Control<T> | undefined
    fields: any
    remove: UseFieldArrayRemove
    header: string[]
    errors?: any
    disabled?: boolean
    name: string
}

function DocumentsTable<T extends FieldValues>({ control, fields, remove, header, errors, disabled, name }: DocumentsTableInterface<T>) {
    return (
        <table className="simple-table mt-[20px] gap-[6px]">
            <tr className="simple-table-header">
                <th className=" w-[114px] h-[54px]">{header[0]}</th>
                <th className=" w-[600px] h-[54px]">{header[1]}</th>
                <th className=" w-[400px] h-[54px]">{header[2]}</th>
                <th className=" w-[500px] h-[54px]">{header[3]}</th>
                <td className=" pl-3 flex flex-row gap-6 items-center invisible" style={{ border: 0 }}>
                    {fields.length > 1 && (
                        <Button className="w-fit cursor-pointer" disabled={disabled}>
                            <Delete />
                        </Button>
                    )}
                </td>
            </tr>
            {fields.map((field: any, index: any) => (
                <tr className="simple-table-row" key={field.id}>
                    <td className=" disabled w-[114px] h-[54px]">{index + 1}</td>
                    <td className=" editable w-[600px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <TextInputCell
                            name={`${name}.${index}.naziv`}
                            control={control}
                            placeholder="Tекст (150 карактера)"
                            errors={errors}
                            isRequired={!disabled}
                            maxNumberOfCharacter={150}
                            disabled={disabled}
                        />
                    </td>
                    <td className=" editable w-[400px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <TextInputCell
                            name={`${name}.${index}.vrsta`}
                            control={control}
                            placeholder="Tекст (50 карактера)"
                            errors={errors}
                            isRequired={!disabled}
                            disabled={disabled}
                            maxNumberOfCharacter={50}
                        />
                    </td>
                    <td className=" editable w-[500px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <TextInputCell name={`${name}.${index}.link`} control={control} placeholder="Tекст (100 карактера)" disabled={disabled} maxNumberOfCharacter={100} />
                    </td>
                    <td className=" pl-3 flex flex-row gap-6 items-center " style={{ border: 0 }}>
                        {fields.length > 1 && !disabled && (
                            <Button className="w-fit cursor-pointer" onClick={() => remove(index)}>
                                <Delete />
                            </Button>
                        )}
                    </td>
                </tr>
            ))}
        </table>
    )
}

export default DocumentsTable
