import React from 'react'
import TextInputCell from '../../../components/Common/TextInputCell'
import { ReactComponent as Delete } from '../../../assets/svg/Delete.svg'
import { ReactComponent as Edit } from '../../../assets/svg/Edit.svg'
import { Control, FieldArrayWithId, UseFieldArrayRemove } from 'react-hook-form'
import { TblOblast5 } from '../../../models/Form5/Form5.entity'

export interface StepInterface {
    control?: Control<TblOblast5>
    errors?: any
    fields: FieldArrayWithId<TblOblast5, 'o5_k4_mere', 'id'>[]
    remove: UseFieldArrayRemove
    disabled?: boolean
}

function PlanTable({ control, errors, fields, remove, disabled = false }: StepInterface) {
    return (
        <table className="simple-table mt-[13px]" style={{ gap: 8 }}>
            <tr className="simple-table-header">
                <th className=" w-[114px] h-[54px]">Редни број</th>
                <th className=" w-[478px] h-[54px]">Посебне мере из плана деловања</th>
            </tr>
            {fields.map((field, index) => (
                <tr className="simple-table-row" key={field.id}>
                    <td className=" disabled w-[114px] h-[54px]">{index + 1}</td>
                    <td className=" editable w-[478px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <TextInputCell
                            disabled={disabled}
                            name={`o5_k4_mere.${index}.text_content`}
                            control={control}
                            placeholder="Tекст (100 карактера)"
                            errors={errors}
                            isRequired={disabled ? false : true}
                            maxNumberOfCharacter={100}
                        />
                    </td>
                    <td className="simple-table-cell pl-3 flex flex-row gap-6 items-center " style={{ border: 0 }}>
                        {/* <div className="w-fit cursor-pointer">
                            <Edit />
                        </div> */}
                        {fields.length > 1 && (
                            <div className="w-fit cursor-pointer" onClick={() => remove(index)}>
                                <Delete />
                            </div>
                        )}
                    </td>
                </tr>
            ))}
        </table>
    )
}

export default PlanTable
