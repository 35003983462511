import React, { useState } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Controller, useWatch } from 'react-hook-form'
import dayjs, { Dayjs } from 'dayjs'
import { TblOblast2 } from '../../models/Form2/Form2.entity'

interface HiddenDatePickerProps {
    control: any
    name: string
    width?: number
    errors?: any
    isRequired?: any
    value?: any
    isSecond?: any
    minDate?: any
    lastStep?: any
    disabled?: any
    onChangeFunc?: (date: Dayjs | null) => void
    o5?: any
    isArray?: any
    isFirst?: any
    maxDate?: any
    tabIndex?: number
}

const HiddenDatePicker = ({
    control,
    name,
    errors,
    width,
    isRequired = true,
    value,
    isSecond,
    minDate,
    isFirst,
    maxDate,
    lastStep = false,
    disabled = false,
    onChangeFunc,
    o5 = false,
    isArray = false,
    tabIndex = undefined,
}: HiddenDatePickerProps) => {
    const [open, setOpen] = useState(false)
    const [date, setDate] = useState<Dayjs | null>(dayjs())
    const parts = name.split('.')
    const arrayName = parts[0]
    const secondPart = parts[1]
    const index = parseInt(parts[1], 10)
    const fieldName = parts[2]
    const formattedValue = value && dayjs(value).format('DD/MM/YYYY')

    const handleClick = (e: React.SyntheticEvent) => {
        e.preventDefault()
        if (!disabled) {
            setOpen(true)
        }
    }

    const getBorderClass = () => {
        if (disabled) {
            return 'border-[#00000010]'
        } else if ((!isArray && errors && errors[name]) || (!isArray && errors && errors[arrayName]?.[secondPart])) {
            return 'border-[#d32f2f]'
        } else if (errors && !!errors[arrayName] && !!errors[arrayName][index] && !!errors[arrayName][index][fieldName]) {
            return 'border-[#d32f2f]'
        } else {
            return 'border-[#D9D9D9]'
        }
    }

    const getTextClass = () => {
        if (disabled) {
            return 'text-[#D9D9D9]'
        } else if ((!isArray && errors && errors[name]) || (!isArray && errors && errors[arrayName]?.[secondPart])) {
            return 'text-[#d32f2f85]'
        } else if (errors && !!errors[arrayName] && !!errors[arrayName][index] && !!errors[arrayName][index][fieldName]) {
            return 'text-[#d32f2f85]'
        } else if (formattedValue) {
            return ''
        } else {
            return 'text-disabled'
        }
    }

    return (
        <div className={`w-full h-full  flex flex-col items-center justify-center self-center flex-1 ${lastStep ? 'max-w-[186px] max-h-[50px]' : ''}`}>
            <div className={`w-full h-full `}>
                <div className="w-full h-full relative">
                    <div
                        tabIndex={tabIndex ? tabIndex : disabled ? -1 : 0}
                        className={`w-full ${lastStep ? '' : `min-h-[54px]`} h-full px-3 border ${getBorderClass()} ${
                            disabled ? 'cursor-default' : 'cursor-pointer'
                        } flex flex-row items-center justify-center`}
                        onClick={(e) => handleClick(e)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                handleClick(e)
                            }
                        }}
                    >
                        <p className={`text-[16px] leading-5 ${getTextClass()}`}>
                            <span>{formattedValue ? formattedValue : 'Датум'}</span>
                        </p>
                    </div>
                    <div className="absolute top-0 left-0 invisible">
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr-cyrl">
                            <Controller
                                control={control}
                                name={name}
                                rules={{ required: isRequired }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <DatePicker
                                            value={value ? dayjs(value) : null}
                                            open={open}
                                            onChange={(date: any) => {
                                                onChangeFunc ? onChangeFunc(date) : onChange(date)
                                            }}
                                            onClose={() => setOpen(false)}
                                            onMonthChange={(newDate) => {
                                                setDate(newDate)
                                            }}
                                            slotProps={{
                                                popper: {
                                                    onKeyDown: (e) => {
                                                        if (e.key === 'Enter') {
                                                            let newDate = date
                                                            const day = Number((e.target as HTMLElement).innerText)
                                                            if (newDate) {
                                                                newDate = newDate.set('date', day)
                                                            }
                                                            onChangeFunc ? onChangeFunc(newDate) : onChange(newDate)
                                                            setOpen(false)
                                                        }
                                                    },
                                                },
                                            }}
                                            minDate={isSecond && minDate ? dayjs(minDate) : undefined}
                                            maxDate={isFirst && maxDate ? dayjs(maxDate) : dayjs()}
                                            disabled={disabled}
                                        />
                                    )
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                {!disabled && o5 && (
                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{errors && errors?.[name]?.type === 'required' ? 'Попуните обавезно поље!' : ''}</span>
                )}
            </div>
        </div>
    )
}

export default HiddenDatePicker
