import { Button, Modal } from '@mui/material'
import React, { useState } from 'react'
import { ReactComponent as CloseFilled } from '../../assets/svg/CloseFilled.svg'

interface modalInterface {
    isOpen: boolean
    onClose: any
    link?: any
}
export const FileViewModal = ({ isOpen, onClose, link }: modalInterface) => {
    if (!isOpen) return null

    return (
        <Modal open={isOpen} onClose={onClose}>
            <div className="flex flex-col justify-center min-w-[90%] min-h-[90%] items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white max-w-[90%] h-full max-h-[90%] p-2 shadow-lg rounded-lg">
                <iframe src={link} title="PDF Viewer" width="100%" height="100%" className="mt-[30px]" />

                <Button
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        fontSize: '16px',
                        cursor: 'pointer',
                        color: 'black',
                    }}
                >
                    <CloseFilled />
                </Button>
            </div>
        </Modal>
    )
}
