export const FORM3_NAMES = [
    '01. Да ли орган јавне власти и послодавац има донет план или програм за остваривање родне равноправности у оквиру годишњих планова и програма рада?  ',
    '02. Унети кратку оцену стања у вези са положајем жена и мушкараца у органу јавне власти и послодавца, укључујући и списак посебних мера, разлоге за одређивање посебних мера и циљеве који се њима постижу, почетак примене, начин спровођења и контроле и престанак спровођења посебних мера ',
    '03. Списак посебних мера',
    '04. Разлози за одређивање посебних мера и циљеви који се њима постижу',
    '05. Почетак примене посебних мера',
    '06. Начин спровођења и контроле примене посебних мера',
    '07. Престанак спровођења посебних мера',
    '08. Унети разлоге због којих није остварена уравнотежена заступљеност полова у саставу органа јавне власти и послодавца (уколико уравнотежена заступљеност полова није остварена)',
    '09. Подаци о лицу задуженом за родну равноправност  код органа јавне власти који имају више од 50 запослених радно ангажованих лица (уколико постоје у бази за ово правно лице повући ће се аутоматски а коринсик може да едитује)',
    '10. Поштовани, кликом на заврши и пошаљи потврђујете да сте унели све потребне информације у дефинисана поља',
]

export const HELP_BUTTON_3 = ['o3-k1', 'o3-k2', 'o3-k3', 'o3-k4', 'o3-k5', 'o3-k6', 'o3-k7', 'o3-k8', 'o3-k9', 'o3-k10']
