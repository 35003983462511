import React, { useEffect, useState } from 'react'
import Stepper from '../../components/Common/Stepper/Stepper'
import Step1 from './Steps/Step1'
import { useForm } from 'react-hook-form'
import { TblOblast1 } from '../../models/Form1/Form1.entity'
import Step2 from './Steps/Step2'
import Step3 from './Steps/Step3'
import Step4 from './Steps/Step4'
import Step5 from './Steps/Step5'
import Step6 from './Steps/Step6'
import Step7 from './Steps/Step7'
import Step8 from './Steps/Step8'
import Step9 from './Steps/Step9'
import Step10 from './Steps/Step10'
import Step11 from './Steps/Step11'
import Step12 from './Steps/Step12'
import Step13 from './Steps/Step13'
import Step14 from './Steps/Step14'
import Step15 from './Steps/Step15'
import Step16 from './Steps/Step16'
import Step17 from './Steps/Step17'
import Step18 from './Steps/Step18'
import Step19 from './Steps/Step19'
import { getForm1, getUnfinishedForm1, saveForm1 } from '../../server/server'
import { FORM1_NAMES, HELP_BUTTON_1 } from '../../models/Form1/Form1Names'
import { FORM1_TITLE } from '../../models/FormTitles'
import FinishOfFormModal from '../../components/Common/FinishOfFormModal'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { isNotEmpty } from '../../utils/Validation'
import LoadingSpinner from '../../components/Common/LoadingSpinner'
import { useUser } from '../../auth/UseUser'
import HelpButton from '../../components/Common/HelpButton'
import TutorialModal from '../Tutorial/TutorialModal'

function Form1Screen() {
    let navigate = useNavigate()
    const [finishModalVisible, setFinishModalVisible] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    const [loading, setLoading] = useState(false)
    const [formCompleted, setFormCompleted] = useState(false)
    const [tutorialModalOpen, setTutorialModalOpen] = useState(true)
    let [searchParams, setSearchParams] = useSearchParams()
    const { user } = useUser()

    const formId = searchParams.get('formId')
    const adminView = user.admin

    const adminUsage = searchParams.get('adminUsage') === '1'
    const {
        handleSubmit,
        control,
        getValues,
        reset,
        setValue,
        register,
        formState: { errors },
    } = useForm<TblOblast1>({
        defaultValues: {
            o1_k8_obrazovanje: [
                { name: '0 Лице без стеченог основног образовања и васпитања', maleNumber: undefined, femaleNumber: undefined },
                { name: 'Први ниво (ниво 1) НОКС', maleNumber: undefined, femaleNumber: undefined },
                { name: 'Други ниво (ниво 2) НОКС', maleNumber: undefined, femaleNumber: undefined },
                { name: 'Трећи ниво (ниво 3) НОКС', maleNumber: undefined, femaleNumber: undefined },
                { name: 'Четврти ниво (ниво 4) НОКС', maleNumber: undefined, femaleNumber: undefined },
                { name: 'Пети ниво (ниво 5) НОКС', maleNumber: undefined, femaleNumber: undefined },
                { name: 'Шести ниво, подниво један (ниво 6.1) и подниво два (ниво 6.2) НОКС', maleNumber: undefined, femaleNumber: undefined },
                { name: 'Седми ниво, подниво један (ниво 7.1) и подниво два (ниво 7.2) НОКС', maleNumber: undefined, femaleNumber: undefined },
                { name: 'Осми ниво (ниво 8) НОКС', maleNumber: undefined, femaleNumber: undefined },
            ],
        },
    })

    const decimalKeys = ['o1_k5_mi', 'o1_k5_mr', 'o1_k5_zi', 'o1_k5_zr']

    useEffect(() => {
        const formValues = getValues()
        const isNewForm = isNotEmpty(formValues.o1_k1_m)
        if (!isNewForm) {
            getUnfinishedForm()
        }
    }, [])

    const getUnfinishedForm = async () => {
        setLoading(true)
        let resp
        if (!adminView && !formId) {
            resp = await getUnfinishedForm1()
        } else {
            console.log('Loading form1 with id: ', formId)

            resp = await getForm1(formId)
        }
        const form = resp?.data?.form

        if (resp.status === 200 && resp?.data) {
            console.log(resp.data)
            Object.keys(form).forEach((key) => {
                setValue(key as keyof TblOblast1, form[key])
                if (decimalKeys.includes(key)) {
                    setValue(key as keyof TblOblast1, parseFloat(form[key]))
                } else {
                    setValue(key as keyof TblOblast1, form[key])
                }
                if (key === 'report') {
                    setValue('submitted_by.firstName', form[key].submittedBy.firstName)
                    setValue('submitted_by.lastName', form[key].submittedBy.lastName)
                    setValue('submitted_by.phoneNumber', form[key].submittedBy.phoneNumber)
                    setValue('submitted_by.email', form[key].submittedBy.email)
                    setValue('submitted_by.has_education', form[key].submittedBy.has_education)
                    setValue('submitted_by.education_date', form[key].submittedBy.education_date)
                    setValue('submitted_by.education_name', form[key].submittedBy.education_name)
                    setValue('submitted_by.education_org', form[key].submittedBy.education_org)
                    setValue('submitted_by.education_teacher', form[key].submittedBy.education_teacher)
                    setValue('submitted_by.files', form[key].submittedBy.files)
                }
            })
            setFormCompleted(resp.data.form.status !== 0) // Status 0 is for started
            setActiveStep(Number(resp.data.currentStep))
        }
        setLoading(false)
    }

    const onSelectStep = (step: number) => setActiveStep(step)

    const nextStep = async (skip?: boolean) => {
        const form = getValues()
        if (!adminView) {
            await save()
            if (activeStep === 0 && form.status === undefined) getUnfinishedForm()
        }
        if (activeStep + 1) {
            setActiveStep(skip ? activeStep + 2 : activeStep + 1)
        }
    }
    const previousStep = () => setActiveStep(activeStep - 1)

    const save = async () => {
        const resp = await saveForm1(getValues(), (activeStep + 1) as number)
        console.log('🚀 ~ save ~ resp:', resp)
        if (resp.status == 201) {
            setValue('report_id', resp.data.report_id)
        }
    }

    const finish = async () => {
        if (!adminView) {
            await save()
        }
        setFinishModalVisible(true)
    }

    const resetValuesAndGoToFirstStep = () => {
        setFinishModalVisible(false)
        setActiveStep(0)
        reset()
        navigate('/')
    }

    const stepComponent = () => {
        switch (activeStep) {
            case 0:
                return <Step1 control={control} nextStep={nextStep} previousStep={previousStep} previousButtonDisabled={activeStep === 0} errors={errors} adminView={adminView} />
            case 1:
                return <Step2 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 2:
                return <Step3 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 3:
                return <Step4 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 4:
                return <Step5 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 5:
                return <Step6 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 6:
                return <Step7 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 7:
                return <Step8 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 8:
                return <Step9 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 9:
                return <Step10 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 10:
                return <Step11 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 11:
                return <Step12 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 12:
                return <Step13 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 13:
                return <Step14 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 14:
                return <Step15 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 15:
                return <Step16 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 16:
                return <Step17 control={control} nextStep={nextStep} previousStep={previousStep} adminView={adminView} errors={errors} />
            case 17:
                return <Step18 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 18:
                return <Step19 control={control} nextStep={finish} previousStep={previousStep} errors={errors} adminView={adminView} setValue={setValue} />
            default:
                return null
        }
    }

    const onSubmit = async () => {
        // console.log('get values ', getValues())
    }

    return (
        <div className="max-w-[1556px] mx-[auto] px-[40px] pb-[20px]">
            <div className="pt-[40px] pb-[55px] flex flex-row gap-5 items-start justify-between">
                <h1 className="text-[24px] font-light">{FORM1_TITLE}</h1>
                <HelpButton name={HELP_BUTTON_1[activeStep]} marginTop={5} />
            </div>
            <div>
                <Stepper formName={FORM1_NAMES} numberOfSteps={19} onSelectStep={onSelectStep} activeStep={activeStep} unlockEveryStep={true} />
            </div>
            {/* !!adminUsage || adminView || formCompleted */}
            {!loading ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-[55px]">{stepComponent()}</div>
                </form>
            ) : (
                <div className="flex justify-center items-center h-full mt-[100px]">
                    <LoadingSpinner />
                </div>
            )}
            <FinishOfFormModal modalIsOpen={finishModalVisible} closeModal={resetValuesAndGoToFirstStep} infoText={FORM1_TITLE} adminView={adminView} />
            <TutorialModal open={tutorialModalOpen} onClose={() => setTutorialModalOpen(false)} segment="form" />
        </div>
    )
}

export default Form1Screen
