import React from 'react'
import { Control, useWatch } from 'react-hook-form'
import { ReactComponent as Delete } from '../../../assets/svg/Delete.svg'
import { Button } from '@mui/material'
import SelectBody from '../../Form2/Components/SelectBody'
import { getEnumValues, getPercentage } from '../../../utils/mathUtils'
import { Form7_11Entity, OblikEksploatacije, PolPocinioca, UzrastZrtve } from '../../../models/Form7/Entities/Form7_11.entity'
import PositiveNumberInputCell from '../../Form2/Components/PositiveNumberInputCell'

interface RiskTableInterface {
    control: Control<Form7_11Entity> | undefined
    fields: any[]
    remove: any
    header: string[]
    errors?: any
    disabled?: boolean
}

const oblikEksploatacijeEnumValues = getEnumValues(OblikEksploatacije)
const polPociniocaEnumValues = getEnumValues(PolPocinioca)
const uzrastZrtveEnumValues = getEnumValues(UzrastZrtve)

function Form7_11_k3Table({ control, fields, remove, header, errors, disabled }: RiskTableInterface) {
    const watchedValues = useWatch<Form7_11Entity>({ control })

    const total = watchedValues.o7_11_k3_oblici?.reduce((sum, item) => sum + (!!item.brojZrtava ? item.brojZrtava : 0), 0)

    return (
        <table className="simple-table mt-[50px] gap-[6px]">
            <tr className="simple-table-header">
                {header.map((head, index) => (
                    <th className={`${index == 0 ? 'w-[274px]' : 'w-[186px]'}  h-[54px]`} key={index}>
                        {head}
                    </th>
                ))}

                <td className=" pl-3 flex flex-row gap-6 items-center invisible" style={{ border: 0 }}>
                    {fields.length > 1 && (
                        <Button className="w-fit cursor-pointer" disabled={disabled}>
                            <Delete />
                        </Button>
                    )}
                </td>
            </tr>
            {fields.map((field, index) => (
                <tr className="simple-table-row" key={field.id}>
                    <td className=" editable w-[126px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <PositiveNumberInputCell
                            name={`o7_11_k3_oblici.${index}.brojZrtava`}
                            control={control}
                            isMultipleTable={true}
                            errors={errors}
                            isRequired={!disabled}
                            disabled={disabled}
                            isArray={true}
                            min={1}
                        />
                    </td>
                    <td className="complex-table-cell disabled  min-w-[136px] h-[54px]">
                        {getPercentage(!!watchedValues.o7_11_k3_oblici ? watchedValues.o7_11_k3_oblici[index]?.brojZrtava : 0, total)}%
                    </td>
                    <td className=" editable w-[186px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <SelectBody
                            control={control}
                            name={`o7_11_k3_oblici.${index}.oblikEksploatacije`}
                            value={!!watchedValues.o7_11_k3_oblici ? watchedValues.o7_11_k3_oblici[index]?.oblikEksploatacije : ''}
                            items={oblikEksploatacijeEnumValues}
                            errors={errors}
                            isArray={true}
                            isRequired={!disabled}
                            disabled={disabled}
                            minWidth={''}
                        />
                    </td>
                    <td className=" editable w-[186px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <SelectBody
                            control={control}
                            name={`o7_11_k3_oblici.${index}.polPocinioca`}
                            value={!!watchedValues.o7_11_k3_oblici ? watchedValues.o7_11_k3_oblici[index]?.polPocinioca : ''}
                            items={polPociniocaEnumValues}
                            errors={errors}
                            isArray={true}
                            isRequired={!disabled}
                            disabled={disabled}
                            minWidth={''}
                        />
                    </td>
                    <td className=" editable w-[186px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <SelectBody
                            control={control}
                            name={`o7_11_k3_oblici.${index}.uzrastZrtve`}
                            value={!!watchedValues.o7_11_k3_oblici ? watchedValues.o7_11_k3_oblici[index]?.uzrastZrtve : ''}
                            items={uzrastZrtveEnumValues}
                            errors={errors}
                            isArray={true}
                            isRequired={!disabled}
                            disabled={disabled}
                            minWidth={''}
                        />
                    </td>

                    <td className=" pl-3 flex flex-row gap-6 items-center " style={{ border: 0 }}>
                        {/* <div className="w-fit cursor-pointer">
                            <Edit />
                        </div> */}
                        {fields.length > 1 && !disabled && (
                            <Button className="w-fit cursor-pointer" onClick={() => remove(index)}>
                                <Delete />
                            </Button>
                        )}
                    </td>
                </tr>
            ))}
        </table>
    )
}

export default Form7_11_k3Table
