import React from 'react'
import { Form7_14_2Entity } from '../../../../../models/Form7/Entities/Form7_14.entity'
import { Control, useWatch } from 'react-hook-form'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import SimpleTable from '../../../../../components/Tables/SimpleTable'
import ActionButtons from '../../../../../components/ActionButtons'
import { isNotEmpty } from '../../../../../utils/Validation'
export interface StepInterface {
    control?: Control<Form7_14_2Entity>
    nextStep: () => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
    clearErrors?: any
}

export interface RowInterface {
    label: string
    field: keyof Form7_14_2Entity
}

const rows: RowInterface[] = [
    { label: 'Мушкарци', field: 'o7_14_2_k1_m' },
    { label: 'Жене', field: 'o7_14_2_k1_z' },
]

export const Step1 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_14_2Entity>({ control })
    const fields: ('o7_14_2_k1_m' | 'o7_14_2_k1_z')[] = ['o7_14_2_k1_m', 'o7_14_2_k1_z']

    const areFieldsFilled = fields.every((field) => watchedValues[field] !== null && watchedValues[field] !== undefined && watchedValues[field]?.toString() !== '')
    const isObukeFieldFilled = isNotEmpty(watchedValues.o7_14_2_k1_obuke)

    const disabledNext = !areFieldsFilled || !isObukeFieldFilled

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) {
            nextStep()
        }
    }

    return (
        <div>
            <h1 className="step-name mb-[10px]">Број реализованих обука у области родне равноправности у години извештавања</h1>
            <div className="max-w-[200px] flex flex-col">
                <PositiveNumberInputCell
                    name={`o7_14_2_k1_obuke`}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    height={62}
                    disabled={adminView}
                    form7
                    clearErrors={clearErrors}
                />

                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_14_2_k1_obuke ? 'Попуните обавезно поље!' : ''}</span>
            </div>

            <h1 className="step-name">Број полазника обука у области родне равноправности за годину извештавања разврстаних по полу:</h1>
            <div className="flex flex-row gap-2 ">
                <SimpleTable control={control} header={['Пол', 'Број', 'Проценат']} rows={rows} fields={fields} errors={errors} disabled={adminView} />
            </div>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
