import { Input } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
interface TextInputInterface {
    name: string
    maxNumberOfCharacter?: number
    control: any
    disabled?: boolean
    placeholder?: string
    height?: number
    borderColor?: string
    errors?: any
    isRequired?: any
}
function TextInputCellForField({
    control,
    name,
    disabled,
    maxNumberOfCharacter,
    placeholder,
    height = 54,
    borderColor = '#00000010',
    errors = {},
    isRequired = false,
}: TextInputInterface) {
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }
    return (
        <div className="min-w-[100px] border flex items-center p-[15px]" style={{ height, borderColor: !!errors[name] ? '#d32f2f' : borderColor }}>
            <Controller
                name={name}
                control={control}
                rules={{ required: isRequired }}
                render={({ field }) => (
                    <Input
                        {...field}
                        onChange={(event) => {
                            const newValue = event.target.value
                            field.onChange(newValue)
                        }}
                        onKeyDown={handleKeyDown}
                        className={`!bg-[transparent] border-none w-full `}
                        placeholder={placeholder}
                        disabled={disabled}
                        inputProps={{ maxLength: maxNumberOfCharacter }}
                        // error={true}
                        sx={{
                            textAlign: 'left',
                            '& .MuiInputBase-input': {
                                textAlign: 'left',
                                color: !!errors[name] ? '#d32f2f' : 'inherit',
                            },
                            '&.MuiInputBase-root:before': {
                                borderBottom: 'none',
                            },
                            '&.MuiInputBase-root:after': {
                                borderBottom: 'none',
                            },
                            '&.MuiInputBase-root:hover:not(.Mui-disabled):before': {
                                borderBottom: 'none',
                            },
                        }}
                    />
                )}
            />
        </div>
    )
}

export default TextInputCellForField
