import React, { useState } from 'react'
import { Control, useWatch } from 'react-hook-form'
import ComplexTable from '../../../../../components/Tables/ComplexTable'
import ActionButtons from '../../../../../components/ActionButtons'
import { Form7_2Entity } from '../../../../../models/Form7/Entities/Form7_2.entity'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { calculateDifference } from '../../../../../utils/mathUtils'

export interface StepInterface {
    control?: Control<Form7_2Entity>
    nextStep: (skip?: boolean) => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
}

interface RowInterface {
    label: string
    fields: (keyof Form7_2Entity)[]
}

const rowsTable: RowInterface[] = [
    { label: 'У браку/заједници без детета до 17 година', fields: ['o7_2_k4_z_ubraku_bezdeteta_17', 'o7_2_k4_m_ubraku_bezdeteta_17'] },
    { label: 'У браку/заједници са најмлађим дететом старости 0 до 6 година', fields: ['o7_2_k4_z_ubraku_dete_0_6', 'o7_2_k4_m_ubraku_dete_0_6'] },
    { label: 'У браку/заједници са најмлађим дететом старости 7 до 17 година', fields: ['o7_2_k4_z_ubraku_dete_7_17', 'o7_2_k4_m_ubraku_dete_7_17'] },
    { label: 'Лице ван брака са дететом до 17 година ', fields: ['o7_2_k4_z_vanbraka_dete_17', 'o7_2_k4_m_vanbraka_dete_17'] },
    { label: 'Лице ван брака без детета до 17 година', fields: ['o7_2_k4_z_vanbraka_bezdeteta_17', 'o7_2_k4_m_vanbraka_bezdeteta_17'] },
]

const header: string[] = ['', 'Број сати жене', 'Број сати мушкарци', 'Разлика сати жене и мушкарци']

const allFieldsTable = [
    'o7_2_k4_z_ubraku_bezdeteta_17',
    'o7_2_k4_z_ubraku_dete_0_6',
    'o7_2_k4_z_ubraku_dete_7_17',
    'o7_2_k4_z_vanbraka_dete_17',
    'o7_2_k4_z_vanbraka_bezdeteta_17',

    'o7_2_k4_m_ubraku_bezdeteta_17',
    'o7_2_k4_m_ubraku_dete_0_6',
    'o7_2_k4_m_ubraku_dete_7_17',
    'o7_2_k4_m_vanbraka_dete_17',
    'o7_2_k4_m_vanbraka_bezdeteta_17',
] as const

type AllFieldsType = (typeof allFieldsTable)[number]

function Step4({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_2Entity>({ control })
    const fieldsTable: AllFieldsType[] = [...allFieldsTable]
    const [disabled, setDisabled] = useState(false)
    const hasRowErrors = rowsTable.some((row) => row.fields.some((field) => errors?.[field]))

    const disabledNext = !fieldsTable.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">4. Просечно време проведено у неплаћеним активностима према породичном саставу и полу - сви дани:</h1>
            <table className="complex-table mt-[30px]">
                <tr className="complex-table-header">
                    {header.map((head, index) => (
                        <th className="complex-table-cell" style={{ width: index === 0 ? 450 : 140 }} key={index}>
                            {head}
                        </th>
                    ))}
                </tr>
                {rowsTable.map((row, index) => (
                    <tr className="complex-table-row" key={index}>
                        <td className="complex-table-cell  disabled" style={{ width: 450 }}>
                            {row.label}
                        </td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0 }}>
                            <PositiveNumberInputCell
                                name={row.fields[0] as string}
                                control={control}
                                decimal={true}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={disabled}
                            />
                        </td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0 }}>
                            <PositiveNumberInputCell
                                name={row.fields[1] as string}
                                control={control}
                                decimal={true}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={disabled}
                            />
                        </td>
                        <td className="complex-table-cell disabled">{calculateDifference(watchedValues[row.fields[0]], watchedValues[row.fields[1]], true)}</td>
                    </tr>
                ))}
                <div className="h-[20px]"> {hasRowErrors && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            </table>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step4
