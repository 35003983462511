import '../../../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../../../components/ActionButtons'
import { Control, useFieldArray, useWatch } from 'react-hook-form'
import Textbox from '../../../../Contact/TextBoxAutoSize'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { Form7_18Entity } from '../../../../../models/Form7/Entities/Form7_18.entity'
import { StepInterface } from './Step1'
import SimpleTable from '../../../../../components/Tables/SimpleTable'
import { useEffect, useState } from 'react'
import RadioButtonsControl from '../../../../../components/Common/RadioButtonsControlWithError'
import { Button } from '@mui/material'
import TextInputCell from '../../../../../components/Common/TextInputCell'

export function Step3({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_18Entity>({ control })

    const fields: ('o7_18_k3_ukuonoPrograma' | 'o7_18_k3_rodnoOsetljiviProgrami' | 'o7_18_k3_posedujeAkte' | 'o7_18_k3_opis')[] = [
        'o7_18_k3_ukuonoPrograma',
        'o7_18_k3_rodnoOsetljiviProgrami',
        'o7_18_k3_posedujeAkte',
        'o7_18_k3_opis',
    ]

    const headers = ['Циљ', 'Индикатор']

    const [emptyArrayError, setEmptyArrayError] = useState(false)

    const field_name = 'o7_18_k3_akti'

    const {
        fields: fieldsArray,
        append,
        remove,
    } = useFieldArray({
        control,
        name: field_name,
    })

    useState(() => {
        if (fieldsArray.length === 0) {
            append({ id: '', cilj: '', indikator: '' })
        }
    })

    const handleAddArrayField = () => {
        setEmptyArrayError(false)
        if (fieldsArray.length >= 10) return
        append({ id: '', cilj: '', indikator: '' })
    }

    const validateEmptyArray = () => {
        if (watchedValues[field_name]?.length === 0 || watchedValues[field_name] === undefined) {
            setEmptyArrayError(true)
            return true
        }
        return false
    }

    const goToNextStep = () => {
        const disabledNext =
            (watchedValues.o7_18_k3_posedujeAkte == true &&
                (fieldsArray.length === 0 ||
                    fieldsArray.some((field) => {
                        const profiles = watchedValues[field_name]
                        return profiles === undefined || profiles.length === 0 || profiles.some((profile) => !profile.cilj || !profile.indikator)
                    }))) ||
            !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

        validateEmptyArray()

        if (!disabledNext && !errors?.o7_18_k4_brojObuka) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name mb-[20px] ">Да ли примењујете родно одговорно буџетирање? </h1>
            <div className="flex flex-row gap-2 mb-[30px]">
                <RadioButtonsControl
                    control={control}
                    name={'o7_18_k3_posedujeAkte'}
                    options={['Не', 'Да']}
                    errors={watchedValues.o7_18_k3_posedujeAkte == undefined ? errors : {}}
                    boolean
                    disabled={adminView}
                />
            </div>
            <h1 className="step-name mb-[30px] ">Број програма у којима је примењен РОБ од укупног броја програма </h1>
            {watchedValues.o7_18_k3_posedujeAkte == true && (
                <div className="mt-[50px] ">
                    <h4 className="mb-[10px]">Број и врста програма у буџету који су уродњени тј. Имају родно одговорне индикаторе</h4>
                    <div className="flex flex-row justify-between">
                        <h1>Молим Вас наведите нам до 10 студијских програма у које доминантно уписују особе женског пола</h1>
                    </div>

                    <table className="multiple-input-table mt-[20px]">
                        <tr className="multiple-input-table-header">
                            {headers.map((head, index) => (
                                <th className={`multiple-input-table-cell !min-w-[450px]`} key={index}>
                                    {head}
                                </th>
                            ))}
                        </tr>
                        {fieldsArray.map((field, index) => {
                            return (
                                <div className="flex flex-row" key={index}>
                                    <tr key={field.id} className="multiple-input-table-row">
                                        <td className="multiple-input-table-cell editable !border-none !min-w-[450px]">
                                            <TextInputCell
                                                name={`${field_name}.${index}.cilj`}
                                                control={control}
                                                placeholder="Текст (100 карактера)*"
                                                errors={errors}
                                                isRequired={!adminView}
                                                maxNumberOfCharacter={100}
                                                height={62}
                                                disabled={adminView}
                                            />
                                        </td>
                                        <td className={`multiple-input-table-cell !border-none !min-w-[450px]`}>
                                            <TextInputCell
                                                name={`${field_name}.${index}.indikator`}
                                                control={control}
                                                placeholder="Текст (150 карактера)*"
                                                errors={errors}
                                                isRequired={!adminView}
                                                maxNumberOfCharacter={150}
                                                height={62}
                                                disabled={adminView}
                                            />
                                        </td>
                                    </tr>
                                    {fieldsArray.length >= 2 ? (
                                        <div className="ml-[20px] flex justify-center items-center ">
                                            <Button color="error" variant="contained" onClick={() => remove(index)} disabled={adminView}>
                                                Обриши
                                            </Button>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            )
                        })}
                        <div className="h-[2px]"> {errors[field_name] && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
                    </table>

                    <div className="flex flex-col mt-[56px]">
                        <Button variant="contained" className="max-w-[172px] mt-[56px]" onClick={handleAddArrayField} disabled={adminView}>
                            Додај профил
                        </Button>
                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError ? 'Додајте барем једну активност!' : ''}</span>
                    </div>
                </div>
            )}
            <h1 className="step-name mb-[20px] ">Укупно програма</h1>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell name="o7_18_k3_ukuonoPrograma" control={control} errors={errors} isRequired={!adminView} height={52} disabled={adminView} />
                </div>
            </div>
            <h1 className="step-name mb-[20px] ">Родно осетљиви програми</h1>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell name="o7_18_k3_rodnoOsetljiviProgrami" control={control} errors={errors} isRequired={!adminView} height={52} disabled={adminView} />
                </div>
            </div>
            <div className="mt-[50px] ">
                <h4 className="mb-[10px]">
                    Млимо Вас да опишете примену РОБ у вашој организацији, са којим изазовима се соучавате, и који је степен успеха реализације примене РОБ.
                </h4>
                <div className="flex flex-col">
                    <Textbox
                        name="o7_18_k3_opis"
                        control={control}
                        placeholder="Текст (2048 карактера)*"
                        errors={errors}
                        isRequired={adminView ? false : 'Попуните обавезно поље.'}
                        maxNumberOfCharacters={2048}
                        disabled={adminView}
                    />
                </div>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
