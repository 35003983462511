import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { resendValidationEmail } from '../../../server/server'

const ValidationSent = () => {
    let [searchParams, setSearchParams] = useSearchParams()
    const [errorMessage, setErrorMessage] = useState('')
    const emailParam = searchParams.get('email')
    const email = emailParam ? emailParam.replace(/\s/g, '+') : null
    const login = searchParams.get('login')
    const [resendMessage, setResendMessage] = useState('')
    const [countdown, setCountdown] = useState(login ? 0 : 180)
    const navigate = useNavigate()

    const handleResendEmail = async () => {
        const resp = await resendValidationEmail(email)
        if (resp.status === 201) {
            setErrorMessage('')
            setResendMessage('sent')
            setCountdown(180)
        } else {
            setResendMessage('sendError')
        }
    }

    useEffect(() => {
        let timer: any
        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prev) => prev - 1)
            }, 1000)
        }

        return () => clearInterval(timer)
    }, [countdown])

    return (
        <div className="h-full w-full flex items-center justify-center flex-col gap-[10px]">
            <p className="w-[700px] text-center">
                Поштовани корисниче, на имејл адресу коју сте унели ({email}) Вам је послат имејл који у себи садржи линк за верификацију имејл адресе. Молимо Вас да проверите и
                спам фолдер уколико не можете да пронађете овај имејл.
            </p>
            <p className="w-[700px] text-center">
                Потребно је да одете на пријављену имејл адресу и да кликнете на линк у имејл-у за верификацију. Након верификације бићете преусмерени на страницу за пријаву.
            </p>
            <p className="w-[700px] text-center">Уколико вам имејл није стигао на адресу ({email}) кликните на дугме пошаљи опет и сачекајте неколико минута.</p>
            {countdown !== 0 && (
                <span>
                    {resendMessage === 'sent' && 'Послат вам је емаил за активацију налога. Молимо пратите упутства која се налазе у емаилу.'}
                    {resendMessage === 'error' && 'Грешка при слању емаила, контактирајте администратора'}
                </span>
            )}
            <div className="flex flex-row max-w-[700px] gap-[50px] mt-[20px]">
                {/* <Button
                    style={{
                        width: '100%',
                        minWidth: '150px',
                        height: '52px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        border: '1px solid #243964',
                        color: '#243964',
                        fontSize: '15px',
                        fontWeight: '500',
                        lineHeight: '26px',
                    }}
                    onClick={() => navigate('/login')}
                >
                    пријава
                </Button> */}
                <Button
                    disabled={countdown > 0}
                    className="w-full h-[52px] !min-w-[150px] flex items-center justify-center rounded-[4px] !bg-[#243964] text-[15px] !text-[#ffffff] font-medium leading-[26px] cursor-pointer mt-[43px] max-w-[520px]"
                    onClick={async () => {
                        handleResendEmail()
                    }}
                >
                    {countdown > 0 ? `Сачекајте ${countdown} сек` : 'Пошаљи опет'}
                </Button>
            </div>
        </div>
    )
}

export default ValidationSent
