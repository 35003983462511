import React from 'react'
import { Select, MenuItem } from '@mui/material'
import { Form } from './types'

interface FormSelectorProps {
    forms: Form[]
    selectedForm: Form | null
    onSelectForm: (form: Form) => void
    text: string
}

export const FormSelector: React.FC<FormSelectorProps> = ({ text, forms, selectedForm, onSelectForm }) => {
    return (
        <div className="flex flex-row gap-[10px] items-center">
            <label htmlFor="Odaberi obrazac">{text}</label>
            <Select
                labelId="select-component"
                id="select-component"
                value={selectedForm?.code || ''}
                onChange={(e) => onSelectForm(forms.find((form) => form.code === e.target.value) as Form)}
                className={`border-[#00000010] min-w-[186px] max-w-[186px] h-[50px] select-component-css`}
                sx={{
                    '.MuiOutlinedInput-notchedOutline border-[#00000010]': {
                        borderColor: '#00000010',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#00000010',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#00000010',
                    },
                    '.MuiSvgIcon-root ': {
                        fill: '#0000008F !important',
                    },
                }}
                displayEmpty
            >
                {forms?.map((item) => (
                    <MenuItem key={item.code} value={item.code} style={{ textAlign: 'center', height: 50, justifyContent: 'center' }}>
                        {item?.name}
                    </MenuItem>
                ))}
            </Select>
        </div>
    )
}
