import { Controller, useWatch } from 'react-hook-form'
import { TextareaAutosize } from '@mui/material'
import { isNotEmpty } from '../../utils/Validation'
interface TextboxInterface {
    name: string
    control: any
    defaultValue?: string
    placeholder?: string
    maxNumberOfCharacters?: number
    width?: number
    disabled?: boolean
    isMultipleTable?: boolean
    isO7?: boolean
    errors?: any
    isRequired?: any
    msg?: any
    rules?: any
    fileName?: any
    clearErrors?: any
    o7k1?: boolean
}

const Textbox = ({
    name,
    control,
    defaultValue = '',
    placeholder,
    width = 1028,
    isMultipleTable,
    disabled = false,
    maxNumberOfCharacters = 100,
    isO7,
    errors = {},
    isRequired = false,
    msg,
    rules,
    fileName,
    clearErrors,
    o7k1 = false,
}: TextboxInterface) => {
    const getBorderClass = () => {
        if (disabled) {
            return 'border-[#00000010]'
        } else if (errors?.[name]) {
            return 'border-[#d32f2f] placeholder-[#d32f2f85]'
        } else {
            return 'border-[#D9D9D9]'
        }
    }

    const getTextClass = () => {
        if (disabled) {
            return 'text-[#D9D9D9] placeholder-[#D9D9D9]'
        } else if (errors && !!errors[name]) {
            return 'text-[#D9D9D9] placeholder-[#d32f2f85]'
        } else {
            return 'inherit'
        }
    }

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={{ required: isRequired, ...rules }}
            render={({ field }) => (
                <>
                    <TextareaAutosize
                        disabled={disabled}
                        {...field}
                        minRows={4}
                        // onChange={(e) => {
                        //     field.onChange(e.target.value)
                        //     if (fileName && clearErrors) clearErrors(fileName)
                        //     if (o7k1 && clearErrors) {
                        //         const k1FileFields = Object.keys(watchedValues).filter((key) => key.includes('k1_fajl'))

                        //         k1FileFields.forEach((key) => {
                        //             const value = watchedValues[key as keyof TblOblast7]
                        //             if (!isNotEmpty(value)) {
                        //                 clearErrors(key)
                        //             }
                        //         })
                        //     }
                        // }}
                        placeholder={placeholder === undefined ? `Текст (${maxNumberOfCharacters} карактера)` : placeholder}
                        maxLength={maxNumberOfCharacters}
                        className={`${isMultipleTable ? 'text-center items-center' : `border ${getBorderClass()}`} ${getTextClass()}`}
                        style={{
                            width: '100%',
                            padding: '12px 16px',
                            boxSizing: 'border-box',
                            // borderRadius: '8px',
                            resize: 'none',
                            maxWidth: width,
                            maxHeight: isMultipleTable ? (isO7 ? '50px' : '54px') : '',
                            outline: 'none',
                        }}
                    />
                    {errors && errors[name] && errors[name].message && <p className="min-h-5 text-[#d32f2f] text-[0.75rem] leading-5 ml-[50px]">{errors[name].message}</p>}
                </>
            )}
        />
    )
}

export default Textbox

interface TextboxStateInterface {
    onChange?: (value: string) => void
    value: string
    disabled?: boolean
    maxNumberOfCharacters?: number
    error?: boolean
}

export const TextboxState = ({ value, onChange = () => null, disabled = false, maxNumberOfCharacters = 100, error }: TextboxStateInterface) => {
    const getBorderClass = () => {
        if (disabled) {
            return 'border-[#00000010]'
        } else if (error) {
            return 'border-[#d32f2f] placeholder-[#d32f2f]'
        } else {
            return 'border-[#D9D9D9]'
        }
    }

    const getTextClass = () => {
        if (disabled) {
            return 'placeholder-[#00000010]'
        } else if (error) {
            return 'placeholder-[#d32f2f80]'
        } else {
            return 'placeholder-[#D9D9D9]'
        }
    }

    return (
        <>
            <TextareaAutosize
                disabled={disabled}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                minRows={4}
                onFocus={(e) => e.target.select()}
                placeholder={`Текст (${maxNumberOfCharacters} карактера)`}
                className={`border ${getBorderClass()} ${getTextClass()}`}
                maxLength={maxNumberOfCharacters}
                style={{
                    width: '100%',
                    padding: '12px 16px',
                    boxSizing: 'border-box',
                    borderRadius: '8px',
                    resize: 'none',
                    outline: 'none',
                    color: disabled ? '#00000080' : '#000000',
                }}
            />
            {error && <p className="min-h-5 text-[#d32f2f] text-[0.75rem] text leading-5 ml-[10px]"> * Попуните обавезно поље </p>}
        </>
    )
}
