import { File } from '../../File.entity'
import { Organization } from '../../Organization.entity'
import { SubmittedBy } from '../../SubmittedBy.entity'
import { EducationalProfile } from './Common/EducationalProfile.entity'
import { FileDescription } from './Common/FileDescription.entity'

export const NumberOfSteps = 4

export interface Form7_9Entity {
    report_id: string
    rep_year: number
    submitted_date?: Date
    submitted_place?: Date
    category: string
    organisation: Organization
    status: string

    o7_9_k1_acts: number
    o7_9_k1_student_m_budzet: number
    o7_9_k1_student_z_budzet: number
    o7_9_k1_studies_in_books: number
    o7_9_k1_research: number

    o7_9_k2_profiles_m: EducationalProfile[]
    o7_9_k2_profiles_z: EducationalProfile[]

    o7_9_k3_dokumentaIzvestavanja: FileDescription[]
    o7_9_k3_files: File[]


    submitted_by?: SubmittedBy
}
