import React from 'react'
import { Control, useWatch } from 'react-hook-form'
import ComplexTable from '../../../../../components/Tables/ComplexTable'
import ActionButtons from '../../../../../components/ActionButtons'
import { Form7_2Entity } from '../../../../../models/Form7/Entities/Form7_2.entity'
import { sumValues } from '../../../../../utils/mathUtils'

export interface StepInterface {
    control?: Control<Form7_2Entity>
    nextStep: (skip?: boolean) => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
}

interface RowInterface {
    label: string
    fields: (keyof Form7_2Entity)[]
}

const rowsTable1: RowInterface[] = [
    { label: 'Плаћени посао', fields: ['o7_2_k3_t1_z_placeni', 'o7_2_k3_t1_m_placeni'] },
    { label: 'Неплаћени посао', fields: ['o7_2_k3_t1_z_neplaceni', 'o7_2_k3_t1_m_neplaceni'] },
    { label: 'Учење', fields: ['o7_2_k3_t1_z_ucenje', 'o7_2_k3_t1_m_ucenje'] },
    { label: 'Личне потребе', fields: ['o7_2_k3_t1_z_licne', 'o7_2_k3_t1_m_licne'] },
    { label: 'Слободно време', fields: ['o7_2_k3_t1_z_slobodno', 'o7_2_k3_t1_m_slobodno'] },
    { label: 'Остало', fields: ['o7_2_k3_t1_z_ostalo', 'o7_2_k3_t1_m_ostalo'] },
]

const allFieldsTable1 = [
    'o7_2_k3_t1_z_placeni',
    'o7_2_k3_t1_z_neplaceni',
    'o7_2_k3_t1_z_ucenje',
    'o7_2_k3_t1_z_licne',
    'o7_2_k3_t1_z_slobodno',
    'o7_2_k3_t1_z_ostalo',

    'o7_2_k3_t1_m_placeni',
    'o7_2_k3_t1_m_neplaceni',
    'o7_2_k3_t1_m_ucenje',
    'o7_2_k3_t1_m_licne',
    'o7_2_k3_t1_m_slobodno',
    'o7_2_k3_t1_m_ostalo',
] as const

const allMaleFieldsTable1 = [...allFieldsTable1.filter((e) => e.includes('_m_'))] as const
const allFemaleFieldsTable1 = [...allFieldsTable1.filter((e) => e.includes('_z_'))] as const

const rowsTable2: RowInterface[] = [
    { label: 'Плаћени посао', fields: ['o7_2_k3_t2_z_placeni', 'o7_2_k3_t2_m_placeni'] },
    { label: 'Неплаћени посао', fields: ['o7_2_k3_t2_z_neplaceni', 'o7_2_k3_t2_m_neplaceni'] },
    { label: 'Учење', fields: ['o7_2_k3_t2_z_ucenje', 'o7_2_k3_t2_m_ucenje'] },
    { label: 'Личне потребе', fields: ['o7_2_k3_t2_z_licne', 'o7_2_k3_t2_m_licne'] },
    { label: 'Слободно време', fields: ['o7_2_k3_t2_z_slobodno', 'o7_2_k3_t2_m_slobodno'] },
    { label: 'Остало', fields: ['o7_2_k3_t2_z_ostalo', 'o7_2_k3_t2_m_ostalo'] },
]

const allFieldsTable2 = [
    'o7_2_k3_t2_z_placeni',
    'o7_2_k3_t2_z_neplaceni',
    'o7_2_k3_t2_z_ucenje',
    'o7_2_k3_t2_z_licne',
    'o7_2_k3_t2_z_slobodno',
    'o7_2_k3_t2_z_ostalo',

    'o7_2_k3_t2_m_placeni',
    'o7_2_k3_t2_m_neplaceni',
    'o7_2_k3_t2_m_ucenje',
    'o7_2_k3_t2_m_licne',
    'o7_2_k3_t2_m_slobodno',
    'o7_2_k3_t2_m_ostalo',
] as const

const allMaleFieldsTable2 = [...allFieldsTable2.filter((e) => e.includes('_m_'))] as const
const allFemaleFieldsTable2 = [...allFieldsTable2.filter((e) => e.includes('_z_'))] as const

const joinValues = [...allFieldsTable1, ...allFieldsTable2] as const
type AllFieldsType = (typeof joinValues)[number]

function Step2({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_2Entity>({ control })
    const fieldsTable: AllFieldsType[] = [...joinValues]
    const sumMaleTable1 = sumValues(watchedValues, [...allMaleFieldsTable1])
    const sumFemaleTable1 = sumValues(watchedValues, [...allFemaleFieldsTable1])
    const sumMaleTable2 = sumValues(watchedValues, [...allMaleFieldsTable2])
    const sumFemaleTable2 = sumValues(watchedValues, [...allFemaleFieldsTable2])

    const disabledNext = !fieldsTable.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const disabledTable1 = !allFieldsTable1.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const disabledTable2 = !allFieldsTable2.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const errorMessageTable1 = (sumMaleTable1 != 24 || sumFemaleTable1 != 24) && !disabledTable1
    const errorMessageTable2 = (sumMaleTable2 != 24 || sumFemaleTable2 != 24) && !disabledTable2

    const goToNextStep = () => {
        if (!errorMessageTable1 && !errorMessageTable2 && !disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">3. Просечно време проведено у активностима према типу насеља и полу; сви дани:</h1>
            <h1 className="step-name mt-5">Градска насеља</h1>
            <ComplexTable
                rows={rowsTable1}
                control={control}
                allFemaleFields={[...allFemaleFieldsTable1]}
                allMaleFields={[...allMaleFieldsTable1]}
                allFields={[...allFieldsTable1]}
                header={['', 'Број сати жене', 'Жене %', 'Број сати мушкарци', 'Мушкарци %', 'Укупно', 'Укупно %']}
                errors={errors}
                disabled={adminView}
                decimal
            />
            <p className={`mt-5 h-5 text leading-5 ml-[50px] ${errorMessageTable1 ? 'text-[#d32f2f]' : 'text-[#000000]'} text-[12px]`}>
                {!errorMessageTable1 && !disabledTable1 ? '' : 'Збир броја сати у колони за жене и у колони за мушкарце мора бити 24 појединачно.'}
            </p>
            <h1 className="step-name mt-10">Остала насеља</h1>
            <ComplexTable
                rows={rowsTable2}
                control={control}
                allFemaleFields={[...allFemaleFieldsTable2]}
                allMaleFields={[...allMaleFieldsTable2]}
                allFields={[...allFieldsTable2]}
                header={['', 'Број сати жене', 'Жене %', 'Број сати мушкарци', 'Мушкарци %', 'Укупно', 'Укупно %']}
                errors={errors}
                disabled={adminView}
                decimal
            />
            <p className={`mt-5 h-5 text leading-5 ml-[50px] ${errorMessageTable2 ? 'text-[#d32f2f]' : 'text-[#000000]'} text-[12px]`}>
                {!errorMessageTable2 && !disabledTable2 ? '' : 'Збир броја сати у колони за жене и у колони за мушкарце мора бити 24 појединачно.'}
            </p>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step2
