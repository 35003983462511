import React, { useEffect } from 'react'
import ReactModal from 'react-modal'
import { ReactComponent as TickMark } from '../../assets/svg/ModalTickMark.svg'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

interface ModalInterface {
    modalIsOpen: boolean
    closeModal: () => void
    infoText?: string
    infoText1?: string
    infoText2?: string
}

function PrivacyAndPolicyModal({ modalIsOpen, closeModal, infoText, infoText1, infoText2 }: ModalInterface) {
    const navigate = useNavigate()

    useEffect(() => {
        if (modalIsOpen) {
            ReactModal.setAppElement('#root')
        }
    }, [modalIsOpen])

    return (
        <ReactModal
            ariaHideApp={false}
            //closeTimeoutMS={600}
            isOpen={modalIsOpen}
            onAfterOpen={() => {}}
            onAfterClose={() => {}}
            onRequestClose={closeModal}
            className="no-scrollbar border-0"
            style={{
                overlay: {
                    zIndex: 5,
                    background: '#000000A6',
                },
                content: {
                    padding: 0,
                    opacity: 1,
                    width: 650,
                    margin: 'auto',
                    backgroundColor: 'transparent',
                    height: '100%',
                    maxHeight: '100%',
                    border: '0px',
                    boxShadow: 'none',
                    paddingTop: 15,
                    paddingBottom: 15,
                },
            }}
        >
            <div className="w-full max-h-full overflow-auto bg-[white] px-[30px] py-[40px] rounded-[4px] flex flex-col items-center gap-[20px] no-scrollbar border-0">
                <h2 className="font-bold text-[20px] text-center">УСЛОВИМА КОРИШЋЕЊА ПОРТАЛА И ПОЛИТИКА ПРИВАТНОСТИ</h2>

                {infoText && (
                    <div className="text-left mt-[20px]">
                        <ReactMarkdown>{infoText}</ReactMarkdown>
                    </div>
                )}

                {infoText1 && (
                    <div className="text-left mt-[20px]">
                        <ReactMarkdown>{infoText1}</ReactMarkdown>
                    </div>
                )}
                {infoText2 && (
                    <div className="text-left mt-[20px]">
                        <ReactMarkdown>{infoText2}</ReactMarkdown>
                    </div>
                )}

                <div className="flex flex-col gap-[20px] mt-[30px]">
                    <Button variant="contained" size="medium" onClick={closeModal}>
                        Затвори
                    </Button>
                </div>
            </div>
        </ReactModal>
    )
}

export default PrivacyAndPolicyModal
