import React from 'react'
import { useForm, Controller, FieldErrors } from 'react-hook-form'
import { Input, Box, Button, Typography } from '@mui/material'

interface PositiveNumberInputInterface {
    name: string
    control: any
    defaultValue?: string
    decimal?: boolean
    isMultipleTable?: boolean
    precentage?: boolean
    disabled?: boolean
    errors?: FieldErrors<any>
    isRequired?: boolean
    align?: 'center' | 'right' | 'left'
}

function PositiveNumberInput({
    name,
    control,
    defaultValue = '',
    decimal = false,
    isMultipleTable,
    precentage,
    disabled = false,
    errors,
    isRequired = false,
    align = 'right',
}: PositiveNumberInputInterface) {
    const regexString = decimal ? /^\d*\.?\d*$/ : /^\d*$/
    const hasError = errors && errors[name]

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required: isRequired }}
            defaultValue={defaultValue}
            render={({ field }) => (
                <>
                    {decimal ? (
                        <Input
                            {...field}
                            onBlur={(event) => {
                                const newValue = event.target.value
                                if (regexString.test(newValue)) {
                                    field.onChange(!!newValue ? parseFloat(newValue) : null)
                                }
                            }}
                            onKeyDown={handleKeyDown}
                            disabled={disabled}
                            type="tel"
                            className="!bg-[transparent] border-none"
                            placeholder="Децимални броj*"
                            style={{ fontSize: isMultipleTable ? '14px' : '' }}
                            sx={{
                                textAlign: align,
                                '& .MuiInputBase-input': {
                                    textAlign: align,
                                    '&::placeholder': {
                                        color: hasError ? '#d32f2f' : 'inherit',
                                    },
                                },
                                '&.MuiInputBase-root:before': {
                                    borderBottom: 'none',
                                },
                                '&.MuiInputBase-root:after': {
                                    borderBottom: 'none',
                                },
                                '&.MuiInputBase-root:hover:not(.Mui-disabled):before': {
                                    borderBottom: 'none',
                                },
                            }}
                        />
                    ) : (
                        <Input
                            {...field}
                            onChange={(event) => {
                                const newValue = event.target.value
                                if (regexString.test(newValue)) {
                                    field.onChange(!!newValue ? parseFloat(newValue) : null)
                                }
                            }}
                            type="tel"
                            className="!bg-[transparent] border-none"
                            placeholder={!precentage ? 'Цео број*' : ''}
                            style={{ fontSize: isMultipleTable ? '14px' : '' }}
                            disabled={disabled}
                            sx={{
                                textAlign: align,
                                '& .MuiInputBase-input': {
                                    textAlign: align,
                                    '&::placeholder': {
                                        color: hasError ? '#d32f2f' : 'inherit',
                                    },
                                },
                                '&.MuiInputBase-root:before': {
                                    borderBottom: 'none',
                                },
                                '&.MuiInputBase-root:after': {
                                    borderBottom: 'none',
                                },
                                '&.MuiInputBase-root:hover:not(.Mui-disabled):before': {
                                    borderBottom: 'none',
                                },
                            }}
                        />
                    )}
                </>
            )}
        />
    )
}

export default PositiveNumberInput

interface PositiveNumberInputStateInterface {
    onChange: (text: string) => void
    value: string
    decimal?: boolean
    disabled?: boolean
}
export const PositiveNumberInputState = ({ onChange, value, decimal = false, disabled }: PositiveNumberInputStateInterface) => {
    const regexString = decimal ? /^\d*\.?\d*$/ : /^\d*$/
    return (
        <Input
            disabled={disabled}
            onChange={(event) => {
                const newValue = event.target.value
                if (regexString.test(newValue)) {
                    onChange(newValue)
                }
            }}
            value={value}
            className="!bg-[transparent] border-none"
            placeholder="Цео број"
            sx={{
                textAlign: 'center',
                '& .MuiInputBase-input': {
                    textAlign: 'center',
                },
                '&.MuiInputBase-root:before': {
                    borderBottom: 'none',
                },
                '&.MuiInputBase-root:after': {
                    borderBottom: 'none',
                },
                '&.MuiInputBase-root:hover:not(.Mui-disabled):before': {
                    borderBottom: 'none',
                },
            }}
        />
    )
}
