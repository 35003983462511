import { TblOblast1 } from '../../../../models/Form1/Form1.entity'
import ActionButtons from '../../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import { StepInterface } from './Step1'
import PositiveNumberInputCell from '../../../Form2/Components/PositiveNumberInputCell'
import HelpButton from '../../../../components/Common/HelpButton'
import { useEffect } from 'react'
import { getAnalyticsDataForForm1 } from '../../../../server/server'

function Step11({
    control,
    nextStep,
    previousStep,
    previousButtonDisabled = false,
    errors,
    adminView,
    countType,
    setValue,
    selectedCategories,
    selectedSubCategories,
}: StepInterface) {
    const watchedValues = useWatch<TblOblast1>({ control })
    const fields: ['o1_k11_br_spor'] = ['o1_k11_br_spor']

    const value = watchedValues['o1_k11_br_spor']

    useEffect(() => {
        loadData()
    }, [countType, selectedCategories, selectedSubCategories])

    const loadData = async () => {
        const data = {
            type: countType,
            fields: fields,
            category: selectedCategories?.map((e) => e.name),
            subCategory: selectedSubCategories?.map((e) => e.name),
        }
        const resp = await getAnalyticsDataForForm1(data)
        if (resp.status === 200) {
            const data = resp.data
            fields.forEach((field) => {
                setValue(field, parseFloat(data[field]))
            })
        }
    }

    return (
        <div>
            <h1 className="step-name">11. Број судских спорова који су вођени у вези са дискриминацијом на основу пола, односно рода, у години вођења евиденције:</h1>
            <div className="flex flex-row gap-2 ">
                <div className=" w-[140px] h-[50px] flex items-center justify-center mt-[40px]">
                    <PositiveNumberInputCell name={'o1_k11_br_spor'} control={control} defaultValue="" errors={errors} isRequired={!adminView} height={52} disabled={true} />
                </div>
                <HelpButton name="o1-k11" marginTop={40} />
            </div>
            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step11
