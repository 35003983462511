import React from 'react'
import image8 from '../../../../assets/images/FormTutorialImages/8.png'
import image9 from '../../../../assets/images/FormTutorialImages/9.png'
import image10 from '../../../../assets/images/FormTutorialImages/10.png'
import image11 from '../../../../assets/images/FormTutorialImages/11.png'
import image12 from '../../../../assets/images/FormTutorialImages/12.png'

import Actions from '../../Components/Actions'
import { TutorialStepInterface } from './Step1'

const Step3 = ({ nextStep, previousStep, previousButtonDisabled = false, skipClick, onClose }: TutorialStepInterface) => {
    return (
        <div className="flex h-full w-full flex-col items-center">
            <div className="relative w-full h-full">
                <img src={image8} alt="Образац слика 8" width={1000} className="w-full" />
                <img src={image9} alt="Образац слика 9" width={100} className="absolute right-[150px] top-[240px] z-50" />
            </div>
            <div className="mt-10 gap-10 items-start w-full">
                <div className="flex flex-col ml-[100px] gap-6">
                    <div className="flex flex-row items-center gap-4">
                        <img src={image10} alt="Образац слика 10" width={200} />
                        <p className="text-[16px] max-w-[700px]">Када корисник кликне на ово дугме, враћа се на претходни корак.</p>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                        <img src={image11} alt="Образац слика 11" width={200} />
                        <p className="text-[16px] max-w-[700px]">Овако изгледа претходни корак који је успешно сачуван.</p>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                        <img src={image12} alt="Образац слика 12" width={200} />
                        <p className="text-[16px] max-w-[700px]">
                            Када корисник кликне на ово дугме врши се додавање још једног инпут поља „НАЧИН ПОСТУПАЊА“ у које се може унети текст.
                        </p>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                        <img src={image9} alt="Образац слика 13" width={200} />
                        <p className="text-[16px] max-w-[700px]">
                            Када корисник кликне на ово дугме врши се додавање још једног инпут поља „НАЧИН ПОСТУПАЊА“ у које се може унети текст.
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-10 w-full max-w-[70%]">
                <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={false} skipButton={skipClick} closeModal={onClose} />
            </div>
        </div>
    )
}

export default Step3
