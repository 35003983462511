import React, { useEffect, useRef, useState } from 'react'
import Step from './Step'
// import Step from './Step'

interface StepperInterface {
    numberOfSteps: number
    activeStep: number
    onSelectStep: (step: number) => void
    formName?: string[]
    unlockEveryStep?: boolean
}
function Stepper({ numberOfSteps, onSelectStep, activeStep, formName, unlockEveryStep = false }: StepperInterface) {
    const steps = new Array(numberOfSteps).fill(0).map((e, i) => i + 1)

    const [lastStep, setLastStep] = useState(0)

    const containerRef = useRef<any>(null)
    const selectedItemRef = useRef<any>(null)

    useEffect(() => {
        if (selectedItemRef.current && containerRef.current) {
            selectedItemRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center',
            })
        }
    }, [activeStep])

    useEffect(() => {
        setLastStep(unlockEveryStep ? numberOfSteps : lastStep)
    }, [unlockEveryStep])

    useEffect(() => {
        setLastStep(Math.max(lastStep, activeStep))
    }, [activeStep])

    return (
        <div>
            <div ref={containerRef} className="flex flex-row overflow-x-auto overflow-y-hidden pb-[5px]">
                {steps.map((step, index) => (
                    <Step
                        key={step}
                        label="Koрак"
                        number={step}
                        formNames={formName}
                        active={index === activeStep}
                        completed={index < lastStep}
                        locked={unlockEveryStep ? false : index > lastStep}
                        isFirst={index === 0}
                        isLast={index === steps.length - 1}
                        onClick={() => onSelectStep(index)}
                        selelectedItemRef={index === activeStep ? selectedItemRef : null}
                    />
                ))}
            </div>

            <div className="mt-[10px]">
                <span className="text-[16px] font-light">
                    Корак {activeStep + 1} од {numberOfSteps}
                </span>
            </div>
        </div>
    )
}

export default Stepper
