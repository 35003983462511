import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { passwordStrength } from 'check-password-strength'
import { Checkbox } from '@mui/material'
import { changePassword } from '../../server/server'
import Loading from '../../components/Common/LoadingSpinner'
import { resolve } from 'path'
import HelpButton from '../../components/Common/HelpButton'
export type PassInputs = {
    oldPassword: string
    password: string
    repeatPassword: string
}

const getPasswordStrengthLabel = (strengthId: number) => {
    switch (strengthId) {
        case 0:
            return 'ПРЕСЛАБА'
        case 1:
            return 'СЛАБА'
        case 2:
            return 'СРЕДНЈА'
        case 3:
            return 'ЈАКА'
        default:
            return ''
    }
}

const ChangePassword = () => {
    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        control,
        formState: { errors },
    } = useForm<PassInputs>({
        defaultValues: {
            password: '',
        },
    })
    const [loading, setLoading] = useState(false)
    const watchedValues = useWatch<PassInputs>({ control })
    const navigate = useNavigate()

    const pwReset = async () => {
        setLoading(true)
        const data = { currentPassword: getValues('oldPassword'), newPassword: getValues('password') }
        const resp = await changePassword(data)
        if (resp.status === 201) {
            alert('Лозинка успешно измењена')
            navigate('/')
            setLoading(false)
        } else if (resp.status === 403) {
            alert('Тренутна лозинка није исправна')
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const validatePassword = (password: any) => {
        if (password.length === 0) {
            return ''
        }

        const hasLength = password.length >= 8
        const hasNumber = /\d/.test(password)
        const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password)
        const hasUppercase = /[A-Z]/.test(password)
        const hasLowercase = /[a-z]/.test(password)

        if (!hasLength || !hasNumber || !hasSymbol || !hasUppercase || !hasLowercase) {
            return 'Лозинка није исправно попуњена'
        }

        return ''
    }

    const password = watchedValues?.password || ''

    const strengthId = passwordStrength(password).id
    const strengthLabel = getPasswordStrengthLabel(strengthId)

    const onSubmit: SubmitHandler<PassInputs> = async () => {
        pwReset()
    }
    return (
        <div className="flex items-center justify-center w-full h-full flex-col relative">
            <div className="absolute top-5 right-10 ">
                <HelpButton name="change_password_help" />
            </div>
            <h2 className="my-[32px] text-[24px] leading-[28px] font-light max-w-[552px]">Измена лозинке</h2>
            <form className="flex flex-col items-center justify-center w-full max-w-[552px]" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="w-full flex flex-col">
                    <div className="flex flex-col w-full">
                        <div className="border border-[#0000003B] rounded-[4px] min-w-[520px] min-h-[56px] w-full px-3 flex flex-row items-center ">
                            <input
                                className="text-[16px] leading-[24px] w-full outline-none "
                                placeholder="Стара лозинка"
                                type="password"
                                {...register('oldPassword', {
                                    required: 'Стара лозинка је обавезно поље',
                                })}
                                autoComplete="off"
                            />
                        </div>
                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] text-left">
                            {errors.oldPassword?.type === 'required' ? 'Обавезно попуните ово поље!' : ''}
                        </span>
                    </div>
                    <div className="flex flex-col w-full">
                        <div className="border border-[#0000003B] rounded-[4px] min-w-[520px] min-h-[56px] w-full px-3 flex flex-row items-center ">
                            <input
                                className="text-[16px] leading-[24px] w-full outline-none "
                                placeholder="Нова лозинка"
                                type="password"
                                {...register('password', {
                                    required: 'Нова лозинка је обавезно поље',
                                    minLength: {
                                        value: 8,
                                        message: '',
                                    },
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/,
                                        message: '',
                                    },
                                })}
                                autoComplete="off"
                            />
                        </div>
                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] text-left">
                            {errors.password?.type === 'required' ? 'Обавезно попуните ово поље!' : ''}
                            {errors.password ? validatePassword(watchedValues?.password) : ''}
                        </span>
                    </div>

                    <div className="flex flex-col w-full">
                        <div className="border border-[#0000003B] rounded-[4px] min-w-[520px] min-h-[56px] w-full px-3 flex flex-row items-center ">
                            <input
                                className="text-[16px] leading-[24px] w-full outline-none "
                                placeholder="Потврда лозинке"
                                type="password"
                                {...register('repeatPassword', {
                                    validate: {
                                        obavezno: (value: any) => value.length > 0,
                                        corect: (val: string) => getValues('password') === val,
                                    },
                                })}
                                autoComplete="off"
                            />
                        </div>
                        {errors.repeatPassword?.type === 'corect' && (
                            <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] text-left ">
                                {errors.repeatPassword?.type === 'corect' ? 'Лозинке се не слажу молим Вас проверите' : ''}
                            </span>
                        )}
                        {errors.repeatPassword?.type === 'obavezno' && (
                            <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] text-left">
                                {errors.repeatPassword?.type === 'obavezno' ? 'Обавезно попуните ово поље!' : ''}
                            </span>
                        )}
                    </div>

                    <button
                        type="submit"
                        className="w-full h-[42px] flex items-center justify-center rounded-[4px] bg-[#243964] text-[15px] text-[#ffffff] font-medium leading-[26px] cursor-pointer mt-[46px]"
                    >
                        {!loading ? 'ПОШАЉИ' : <Loading height={25} />}
                    </button>
                </div>

                <div className="mt-[23px] flex place-self-start flex-col">
                    <div className="flex flex-row items-center">
                        <Checkbox
                            checked={Boolean(
                                watchedValues?.password &&
                                    watchedValues.password.length >= 8 &&
                                    /\d/.test(watchedValues.password) &&
                                    /[a-z]/.test(watchedValues.password) &&
                                    /[A-Z]/.test(watchedValues.password) &&
                                    /[!@#$%^&*(),.?":{}|<>]/.test(watchedValues.password)
                            )}
                            size="large"
                            sx={{
                                color: '#A2CEFF',
                                '&.Mui-checked': {
                                    color: '#A2CEFF',
                                },
                            }}
                        />
                        <p className="text-[14px] leading-[20px] font-light">ЈАЧИНА ЛОЗИНКЕ: {strengthLabel}</p>
                    </div>
                    <div className="flex flex-row items-center">
                        <Checkbox
                            readOnly
                            checked={Boolean(watchedValues?.password && watchedValues.password.length >= 8)}
                            size="large"
                            sx={{
                                color: '#A2CEFF',
                                '&.Mui-checked': {
                                    color: '#A2CEFF',
                                },
                            }}
                        />
                        <p className="text-[14px] leading-[20px] font-light">НАЈМАЊЕ 8 КАРАКТЕРА</p>
                    </div>
                    <div className="flex flex-row items-center">
                        <Checkbox
                            readOnly
                            checked={Boolean(watchedValues?.password && /\d/.test(watchedValues.password))}
                            size="large"
                            sx={{
                                color: '#A2CEFF',
                                '&.Mui-checked': {
                                    color: '#A2CEFF',
                                },
                            }}
                        />
                        <p className="text-[14px] leading-[20px] font-light">САДРЖИ БРОЈ</p>
                    </div>
                    <div className="flex flex-row items-center">
                        <Checkbox
                            readOnly
                            checked={Boolean(watchedValues?.password && /[a-z]/.test(watchedValues.password))}
                            size="large"
                            sx={{
                                color: '#A2CEFF',
                                '&.Mui-checked': {
                                    color: '#A2CEFF',
                                },
                            }}
                        />
                        <p className="text-[14px] leading-[20px] font-light">САДРЖИ МАЛО СЛОВО</p>
                    </div>
                    <div className="flex flex-row items-center">
                        <Checkbox
                            readOnly
                            checked={Boolean(watchedValues?.password && /[A-Z]/.test(watchedValues.password))}
                            size="large"
                            sx={{
                                color: '#A2CEFF',
                                '&.Mui-checked': {
                                    color: '#A2CEFF',
                                },
                            }}
                        />
                        <p className="text-[14px] leading-[20px] font-light">САДРЖИ ВЕЛИКО СЛОВО</p>
                    </div>
                    <div className="flex flex-row items-center">
                        <Checkbox
                            readOnly
                            checked={Boolean(watchedValues?.password && /[!@#$%^&*(),.?":{}|<>]/.test(watchedValues.password))}
                            size="large"
                            sx={{
                                color: '#A2CEFF',
                                '&.Mui-checked': {
                                    color: '#A2CEFF',
                                },
                            }}
                        />
                        <p className="text-[14px] leading-[20px] font-light">САДРЖИ СИМБОЛ</p>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ChangePassword
