import React from 'react'
import { StepInterface } from './Step1'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import ActionButtons from '../../../../../components/ActionButtons'
import { Form7_14_1Entity } from '../../../../../models/Form7/Entities/Form7_14.entity'
import { useWatch } from 'react-hook-form'

const headers = [
    'Број ресорних јавних политика које садрже родно одговорне мере доношених у години за коју се извештава',
    'Број обука и обучених за родно одговорно планирање ресорних политика',
    'Број упутстава, смерница и водича за спровођење родно одговорних еx-post и ex-ante родне анализе мера.',
]

export const Step2 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_14_1Entity>({ control })

    const fields: ('o7_14_1_k2_politike' | 'o7_14_1_k2_obuke' | 'o7_14_1_k2_obuceni' | 'o7_14_1_k2_uputstva')[] = [
        'o7_14_1_k2_politike',
        'o7_14_1_k2_obuke',
        'o7_14_1_k2_obuceni',
        'o7_14_1_k2_uputstva',
    ]

    const disabledNext = !fields.every((e) => {
        return watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== ''
    })

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name mb-[10px]">{headers[0]}</h1>
            <div className="max-w-[200px] flex flex-col">
                <PositiveNumberInputCell
                    name={`${fields[0]}`}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    height={62}
                    disabled={adminView}
                    form7
                    clearErrors={clearErrors}
                />

                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_14_1_k2_politike ? 'Попуните обавезно поље!' : ''}</span>
            </div>
            <h1 className="step-name mb-[10px]">{headers[1]}</h1>
            <div className="max-w-[600px] flex flex-col">
                <div className="flex flex-row gap-10">
                    <div className="flex flex-row gap-5 items-center">
                        <p>Обуке:</p>
                        <div className="max-w-[200px] flex flex-col">
                            <PositiveNumberInputCell
                                name={`${fields[1]}`}
                                control={control}
                                errors={errors}
                                isRequired={!adminView}
                                height={62}
                                disabled={adminView}
                                form7
                                clearErrors={clearErrors}
                            />
                            <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{errors.o7_14_1_k2_obuke ? 'Попуните обавезно поље!' : ''}</span>
                        </div>
                    </div>

                    <div className="flex flex-row gap-5 items-center">
                        <p>Обучени:</p>
                        <div className="max-w-[200px] flex flex-col">
                            <PositiveNumberInputCell
                                name={`${fields[2]}`}
                                control={control}
                                errors={errors}
                                isRequired={!adminView}
                                height={62}
                                disabled={adminView}
                                form7
                                clearErrors={clearErrors}
                            />
                            <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{errors.o7_14_1_k2_obuceni ? 'Попуните обавезно поље!' : ''}</span>
                        </div>
                    </div>
                </div>
            </div>
            <h1 className="step-name mb-[10px] mt-10">{headers[2]}</h1>
            <div className="max-w-[200px] flex flex-col">
                <PositiveNumberInputCell
                    name={`${fields[3]}`}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    height={62}
                    disabled={adminView}
                    form7
                    clearErrors={clearErrors}
                />
                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{errors.o7_14_1_k2_uputstva ? 'Попуните обавезно поље!' : ''}</span>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
