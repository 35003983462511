import React from 'react'
import { StepInterface } from './Step1'
import NoAnalytics from '../../Common/NoAnalytics'
import ActionButtons from '../../../../components/ActionButtons'

const Step11 = ({
    control,
    nextStep,
    previousStep,
    previousButtonDisabled = false,
    errors,
    adminView,
    countType,
    setValue,
    selectedCategories,
    selectedSubCategories,
}: StepInterface) => {
    return (
        <div>
            <h1 className="step-name">
                Број и степен укључености организација цивилног друштва (удружења) у активности тела за родну равноправност везаних за промоцију родне равноправности, спречавање и
                сузбијање родно заснованог насиља и насиља према женама, као и борбу против дискриминације на основу пола, односно рода (у областима: рада, запошљавања,
                самозапошљавања, социјалног осигурања, социјалне и здравствене заштите, образовања, одбране и безбедности, саобраћаја, енергетике и комуникација, заштите животне
                средине, културе, јавног информисања, спорта, политичког деловања и јавних послова, репродуктивних и сексуалних права и приступу роби и услугама) у години вођења
                евиденцијe
            </h1>

            <NoAnalytics />

            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step11
