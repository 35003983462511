import { GRADE } from '../../CommonTypes'
import { File } from '../../File.entity'
import { Organization } from '../../Organization.entity'
import { SubmittedBy } from '../../SubmittedBy.entity'
import { FileDescription } from './Common/FileDescription.entity'

export const NumberOfSteps = 10

export interface Form7_15Entity {
    report_id: string
    rep_year: number
    submitted_date?: Date
    submitted_place?: Date
    category: string
    organisation: Organization
    status: string
    submitted_by?: SubmittedBy

    o7_15_k1_usluge: number | undefined
    o7_15_k1_z: number | undefined
    o7_15_k1_m: number | undefined
    o7_15_k1_ocena: GRADE
    o7_15_k1_opis: string //max400

    o7_15_k2_ukupno: number | undefined
    o7_15_k2_korisnici: number | undefined

    o7_15_k3_ukupno: number | undefined
    o7_15_k3_korisnici: number | undefined

    o7_15_k4_ukupno: number | undefined
    o7_15_k4_korisnici: number | undefined

    o7_15_k5_pregledUkupno: number | undefined
    o7_15_k5_pregledObuhvaceni: number | undefined

    o7_15_k5_skriningUkupno: number | undefined
    o7_15_k5_skriningObuhvaceni: number | undefined

    o7_15_k6_pregledUkupno: number | undefined
    o7_15_k6_pregledObuhvaceni: number | undefined

    o7_15_k7_akti: number | undefined
    o7_15_k7_fileDescription: FileDescription[]
    o7_15_k7_file: File[]

    o7_15_k8_radnici: number | undefined
    o7_15_k8_budzet: number | undefined
    o7_15_k8_procenat_budzet: number | undefined
    o7_15_k8_ukupan_budzet: number | undefined
    o7_15_k8_kampanje_javne: number | undefined
    o7_15_k8_kampanje_informativne: number | undefined

    o7_15_k9_fileDescription: FileDescription[]
    o7_15_k9_file: File[]
}
