import React, { useEffect, useState } from 'react'
import { StepInterface } from './Step1'
import { useFieldArray, useWatch } from 'react-hook-form'
import { Form7_4_2Entity } from '../../../../../models/Form7/Entities/Form7_4.entity'
import TextInputCell from '../../../../../components/Common/TextInputCell'
import { Button } from '@mui/material'
import ActionButtons from '../../../../../components/ActionButtons'
import UploadFile from '../../../../../components/UploadFile'
import FileDescriptionTable from '../../../Components/FileDescriptionTable'

const Step4 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_4_2Entity>({ control })
    const [emptyArrayError, setEmptyArrayError] = useState(false)
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o7_4_2_k4_fileDescriptions',
    })
    const name = 'o7_4_2_k4_fileDescriptions'

    let disabledNext = watchedValues[name]?.length === 0

    let emptyLinkCount = 0
    let filesUploaded = 0

    useEffect(() => {
        if (emptyLinkCount === filesUploaded) {
            clearErrors('o7_4_2_k4_file')
        }
    })

    fields.forEach((item, index) => {
        const { fileName, type, link } = watchedValues[name]?.[index] || {}

        if (fileName === undefined || fileName === null || fileName === '') {
            disabledNext = true
        } else if (type === undefined || type === null || type === '') {
            disabledNext = true
        } else disabledNext = false

        if (link === '' || link === undefined) {
            emptyLinkCount += 1
            if (watchedValues['o7_4_2_k4_file']?.[index]) {
                filesUploaded += 1
            }
        }

        if (emptyLinkCount !== filesUploaded) {
            disabledNext = true
        }
    })

    const header = ['Бр.', 'Назив документа', 'Врста', 'Линк ако постоји']

    const handleAddArrayField = () => {
        setEmptyArrayError(false)
        append({ fileName: '', type: '', link: '' })
    }

    const validateEmptyArray = () => {
        if (watchedValues[name]?.length === 0 || watchedValues[name] === undefined) {
            setEmptyArrayError(true)
            return true
        }
        return false
    }

    const goToNextStep = () => {
        if (!disabledNext && !validateEmptyArray()) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <div className="flex flex-row justify-between">
                <h1>Уколико имате документе у вези програма подршке предузетништву жена за које сматрате да су релевантни за извештавање молимо Вас да их приложите:</h1>
            </div>
            <div className="mt-5">
                <span>
                    На пример: Извештаји Министарства привреде, Привредне коморе Србије, статистички подаци РЗС, базе АПР–а, Увид у садржај програма које успостављају актери
                    релевантни за реализацију мере, Увид у буџетске планове и извештаје о потрошњи који су у вези са женским предузетништвом, Извештаји и анализе актера задужених
                    за примену мерe, Независне процене и извештаји института
                </span>
            </div>
            <FileDescriptionTable header={header} fields={fields} control={control} errors={errors} name={name} adminView={adminView} remove={remove} />

            <div className="flex flex-row mt-[52px] gap-4">
                <div className="flex flex-col">
                    <Button variant="contained" className="max-w-[172px] mt-[56px]" onClick={handleAddArrayField} disabled={adminView}>
                        Додај датотеку
                    </Button>
                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError ? 'Додајте барем једну активност!' : ''}</span>
                </div>
                <p>* линк није обавезан</p>
            </div>

            <div className="mt-10 gap-10">
                <h1>Молимо Вас да приложите документа за горе наведене програме ако нема јавно доступног линка:</h1>
                <UploadFile control={control} name={'o7_4_2_k4_file'} disabled={adminView} isRequired={emptyLinkCount > filesUploaded} errors={errors} form7 />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step4
