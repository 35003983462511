import React, { useState } from 'react'
import { Control, useWatch } from 'react-hook-form'
import ActionButtons from '../../../../../components/ActionButtons'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { calculateDifference } from '../../../../../utils/mathUtils'
import { Form7_3Entity } from '../../../../../models/Form7/Entities/Form7_3.entity'

export interface StepInterface {
    control?: Control<Form7_3Entity>
    nextStep: (skip?: boolean) => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
}

interface RowInterface {
    label: string
    fields: (keyof Form7_3Entity)[]
}

const rowsTable: RowInterface[] = [
    { label: '15-29 година', fields: ['o7_3_k2_z_15_29', 'o7_3_k2_m_15_29', 'o7_3_k2_jaz_15_29'] },
    { label: '30-39 година', fields: ['o7_3_k2_z_30_39', 'o7_3_k2_m_30_39', 'o7_3_k2_jaz_30_39'] },
    { label: '40-49 година', fields: ['o7_3_k2_z_40_49', 'o7_3_k2_m_40_49', 'o7_3_k2_jaz_40_49'] },
    { label: '50-59 година', fields: ['o7_3_k2_z_50_59', 'o7_3_k2_m_50_59', 'o7_3_k2_jaz_50_59'] },
    { label: '60 и више година', fields: ['o7_3_k2_z_60', 'o7_3_k2_m_60', 'o7_3_k2_jaz_60'] },
]

const header: string[] = [
    'Старосна доб',
    'Учешће запослених са ниским зарадама у укупном броју запослених Жене %',
    'Учешће запослених са ниским зарадама у укупном броју запослених Мушкарци %',
    'Платни јаз између жена и мушкараца %',
]

const allFieldsTable = [
    'o7_3_k2_z_15_29',
    'o7_3_k2_z_30_39',
    'o7_3_k2_z_40_49',
    'o7_3_k2_z_50_59',
    'o7_3_k2_z_60',

    'o7_3_k2_m_15_29',
    'o7_3_k2_m_30_39',
    'o7_3_k2_m_40_49',
    'o7_3_k2_m_50_59',
    'o7_3_k2_m_60',

    'o7_3_k2_jaz_15_29',
    'o7_3_k2_jaz_30_39',
    'o7_3_k2_jaz_40_49',
    'o7_3_k2_jaz_50_59',
    'o7_3_k2_jaz_60',
] as const

type AllFieldsType = (typeof allFieldsTable)[number]

function Step2({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_3Entity>({ control })
    const fieldsTable: AllFieldsType[] = [...allFieldsTable]
    const [disabled, setDisabled] = useState(false)
    const hasRowErrors = rowsTable.some((row) => row.fields.some((field) => errors?.[field]))

    const disabledNext = !fieldsTable.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">2.Платни јаз и учешће запослених са ниским зарадама у укупном броју запослених по полу и старосним групама:</h1>
            <h1 className="step-name mt-5">* Под запосленима са ниском зарадом сматрају се лица која зарађују мање или једнако 2/3 медијане зараде по часу.</h1>
            <table className="complex-table mt-[30px]">
                <tr className="complex-table-header">
                    {header.map((head, index) => (
                        <th className="complex-table-cell" style={{ width: index === 0 ? 250 : 200 }} key={index}>
                            {head}
                        </th>
                    ))}
                </tr>
                {rowsTable.map((row, index) => (
                    <tr className="complex-table-row" key={index}>
                        <td className="complex-table-cell  disabled" style={{ width: 250 }}>
                            {row.label}
                        </td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0, width: 200 }}>
                            <PositiveNumberInputCell
                                name={row.fields[0] as string}
                                control={control}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={disabled}
                                decimal={true}
                                precentage={true}
                                form7
                            />
                        </td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0, width: 200 }}>
                            <PositiveNumberInputCell
                                name={row.fields[1] as string}
                                control={control}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={disabled}
                                decimal={true}
                                precentage={true}
                                form7
                            />
                        </td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0, width: 200 }}>
                            <PositiveNumberInputCell
                                name={row.fields[2] as string}
                                control={control}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={disabled}
                                decimal={true}
                            />
                        </td>
                    </tr>
                ))}
                <div className="h-[20px]"> {hasRowErrors && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            </table>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step2
