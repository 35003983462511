import React from 'react'
import { Autocomplete, TextField, Chip } from '@mui/material'

interface Category {
    id: string
    name: string
}

interface CategorySelectorProps {
    categories: Category[]
    selectedCategories: Category[]
    onSelectCategory: (categories: Category[]) => void
    text: string
    placeholder?: string
    subCategory?: boolean
}

export const CategorySelector = ({ text, categories, onSelectCategory, selectedCategories, placeholder = 'Одабери категорију', subCategory = false }: CategorySelectorProps) => {
    const getFilteredOptions = () => {
        return categories.filter((category) => !selectedCategories.some((selected) => selected.id === category.id))
    }

    return (
        <div className="flex flex-row gap-[10px] items-center flex-1">
            <label htmlFor="category-select">{text}:</label>
            <Autocomplete
                multiple
                id="category-select"
                options={getFilteredOptions()}
                value={selectedCategories}
                noOptionsText={subCategory ? 'Нема доступних подкатегорија' : 'Нема доступних категорија'}
                onChange={(_, newValue) => onSelectCategory(newValue)}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder={selectedCategories.length === 0 ? placeholder : ''}
                        sx={{
                            minWidth: '286px',
                            maxWidth: '286px',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#00000010',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#00000010',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#00000010',
                                },
                            },
                        }}
                    />
                )}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => <Chip {...getTagProps({ index })} key={option.id} label={option.name} size="small" className="m-0.5" />)
                }
                sx={{
                    '& .MuiAutocomplete-endAdornment': {
                        '& .MuiSvgIcon-root': {
                            fill: '#0000008F !important',
                        },
                    },
                }}
            />
        </div>
    )
}

export default CategorySelector
