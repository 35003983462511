import React, { useState, useEffect, useRef } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, TablePagination, Box } from '@mui/material'
import { searchMessages } from '../../server/server'
import { dateAndTimeFormat, formatCyrillicDateWithCapitalizedMonth } from '../../utils/getDate'
import Loading from '../../components/Common/LoadingSpinner'
import HelpButton from '../../components/Common/HelpButton'

const MessageManagement = () => {
    const [messages, setMessages] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [totalMessages, setTotalMessages] = useState(0)
    const [searchTerm, setSearchTerm] = useState('')
    const [loading, setLoading] = useState(false)
    const timeout = useRef<any>(null)

    useEffect(() => {
        loadMessages()
    }, [page, rowsPerPage])

    const loadMessages = async (text = searchTerm) => {
        setLoading(true)
        const response = await searchMessages(text, rowsPerPage, page + 1)
        if (response.status === 200) {
            setMessages(response.data.messages)
            setTotalMessages(response.data.total)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleChange = (e: any) => {
        e.preventDefault()
        setSearchTerm(e.target.value)
        if (timeout !== null) {
            clearTimeout(timeout.current)
        }
        timeout.current = setTimeout(() => {
            if (page === 0) loadMessages(e.target.value)
            else setPage(0)
        }, 700)
    }

    return (
        <div className="!w-full px-[100px] bg-[#ffffff] py-[52px] relative">
            <div className="absolute top-5 right-10 ">
                <HelpButton name="message-management" />
            </div>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <h2 className="text-[24px] font-light max-w-[800px]">Управљање порукама</h2>
            </Box>
            <div className="flex flex-row  justify-between mb-2">
                <TextField label="Претрага порука" variant="outlined" value={searchTerm} onChange={(e) => handleChange(e)} style={{ width: 350 }} />
            </div>
            {!loading && (
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="border border-[#E0E0E0]">Име</TableCell>
                                    <TableCell className="border border-[#E0E0E0]">Електронска адреса</TableCell>
                                    <TableCell className="border border-[#E0E0E0]">Датум</TableCell>
                                    <TableCell className="border border-[#E0E0E0]" style={{ width: '40%' }}>
                                        Порука
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {messages.map((message: any) => (
                                    <TableRow key={message.id}>
                                        <TableCell className="border border-[#E0E0E0]" sx={{ paddingY: '10px' }}>
                                            {message.name}
                                        </TableCell>
                                        <TableCell className="border border-[#E0E0E0]" sx={{ paddingY: '10px' }}>
                                            {message.email}
                                        </TableCell>
                                        <TableCell className="border border-[#E0E0E0]" sx={{ paddingY: '10px' }}>
                                            {formatCyrillicDateWithCapitalizedMonth(message.createdAt, dateAndTimeFormat)}
                                        </TableCell>
                                        <TableCell className="border border-[#E0E0E0]" sx={{ paddingY: '10px' }} style={{ maxWidth: '40%', width: '40%' }}>
                                            <MessageTable messageText={message.text} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={totalMessages}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[10, 25, 50]}
                        labelRowsPerPage="Број редова по страници"
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} од ${count}`}
                    />
                </Paper>
            )}
            {loading && (
                <div className="flex-1 h-full flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    )
}

export default MessageManagement

const MessageTable = ({ messageText }: { messageText: string }) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const [isTruncated, setIsTruncated] = useState(false)
    const textRef = useRef<any>(null)

    const toggleText = () => {
        setIsExpanded(!isExpanded)
    }
    useEffect(() => {
        const lineHeight = 20
        const maxHeight = lineHeight * 3
        if (textRef.current.scrollHeight > maxHeight) {
            setIsTruncated(true)
        }
    }, [messageText])

    return (
        <div>
            <p ref={textRef} className={`${isExpanded ? '' : 'text-limit'} leading-5  overflow-hidden break-words max-w-[650px]`}>
                {messageText}
            </p>
            {isTruncated && (
                <button className="text-[#5555ff]" onClick={toggleText}>
                    {isExpanded ? 'Прикажи мање' : 'Прикажи више'}
                </button>
            )}
        </div>
    )
}
