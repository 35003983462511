import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Input, Box, Button, Typography } from '@mui/material'
import { removeLeadingZeros } from '../../../utils/mathUtils'

interface PositiveNumberInputInterface {
    name: string
    control: any
    defaultValue?: string
    decimal?: boolean
    isMultipleTable?: boolean
    height?: number
    borderColor?: string
    errors?: any
    isRequired?: any
    isArray?: any
    isStep5?: boolean
    precentage?: boolean
    max?: number
    min?: number
    maxW?: number
    disabled?: boolean
    clearErrors?: any
    form7?: boolean
    placeholder?: string
}

function PositiveNumberInputCell({
    name,
    control,
    defaultValue = '',
    decimal = false,
    isMultipleTable,
    errors = {},
    isRequired = false,
    height,
    borderColor = '#00000010',
    isArray = false,
    precentage = false,
    max,
    min,
    disabled = false,
    maxW = 75,
    clearErrors = () => {},
    form7 = false,
    placeholder = 'Цео број*',
}: PositiveNumberInputInterface) {
    const [shownValue, setShowValue] = useState<string | undefined>(defaultValue)
    const regexString = decimal ? /^[\d,.]*$/ : /^[\d]*$/

    const parts = name.split('.')
    const arrayName = parts[0]
    const index = parseInt(parts[1], 10)
    const fieldName = parts[2]

    const getBorderClass = () => {
        if (disabled) {
            return 'border-[#00000010]'
        } else if ((!isArray && errors && errors[name]) || (!isArray && errors && errors[arrayName])) {
            return 'border-[#d32f2f]'
        } else if (errors && !!errors[arrayName] && !!errors[arrayName][index] && !!errors[arrayName][index][fieldName]) {
            return 'border-[#d32f2f]'
        } else {
            return 'border-[#D9D9D9]'
        }
    }

    function getPlaceholder(decimal: boolean, percentage: boolean, isRequired: boolean) {
        const suffix = isRequired ? '*' : ''
        if (placeholder === 'Цео број*' || placeholder === 'Цео број') {
            if (percentage) {
                return `0-100${suffix}`
            } else if (decimal) {
                return `0.00${suffix}`
            } else {
                return `Цео број${suffix}`
            }
        } else {
            return placeholder
        }
    }

    const getColor = () => {
        if (isArray) {
            return !!errors[arrayName] && !!errors[arrayName][index] && !!errors[arrayName][index][fieldName] ? '#d32f2f' : 'inherit'
        } else {
            return !!errors[arrayName] ? '#d32f2f' : 'inherit'
        }
    }

    return (
        <div className="flex flex-row h-full w-full">
            <div
                className={`border ${getBorderClass()} w-full flex justify-center items-center ${maxW ? `max-w-[${maxW}px] min-w-[${maxW}px] p-[1px]` : 'min-w-[100px] p-[15px]'}`}
                style={{ height: height ? height : '100%' }}
            >
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    rules={{ required: isRequired, max: max, min: min }}
                    render={({ field }) => (
                        <>
                            {disabled ? (
                                // When disabled, render a div instead of input to allow copying
                                <div
                                    className="w-full text-right px-3 py-[12px] select-text cursor-default"
                                    style={{
                                        color: getColor(),
                                        fontSize: isMultipleTable ? '14px' : '',
                                        backgroundColor: 'transparent',
                                        opacity: 0.5, // Makes it look disabled
                                    }}
                                >
                                    {field.value || shownValue || placeholder}
                                </div>
                            ) : (
                                <Input
                                    {...field}
                                    onChange={(event) => {
                                        let newValue = event.target.value
                                        const decimalCount = (newValue.match(/\./g) || []).length
                                        if (decimalCount > 1) {
                                            return
                                        }

                                        if (newValue === '') {
                                            field.onChange(null)
                                            setShowValue('')
                                            return
                                        }

                                        if (newValue.startsWith('.')) {
                                            newValue = `0${newValue}`
                                        }

                                        if (decimal && newValue.includes('.') && newValue.includes(',')) {
                                            return
                                        }

                                        if (decimal && newValue.includes(',')) {
                                            const updatedValue = newValue.replace(',', '.')
                                            field.onChange(parseFloat(updatedValue))
                                            setShowValue(removeLeadingZeros(updatedValue))
                                            return
                                        }

                                        if (precentage && regexString.test(newValue)) {
                                            let value = parseFloat(newValue)
                                            if (value >= 0 && value <= 100) {
                                                const parts = newValue.split('.')
                                                if (parts[1]?.length > 2) {
                                                    newValue = `${parts[0]}.${parts[1].slice(0, 2)}`
                                                }
                                                field.onChange(!!newValue ? parseFloat(parseFloat(newValue).toFixed(2)) : null)
                                                if (clearErrors) clearErrors(name)
                                                setShowValue(removeLeadingZeros(newValue))
                                            }
                                        } else if (regexString.test(newValue)) {
                                            const parts = newValue.split('.')
                                            if (parts[1]?.length > 2) {
                                                newValue = `${parts[0]}.${parts[1].slice(0, 2)}`
                                            }
                                            field.onChange(!!newValue ? parseFloat(parseFloat(newValue).toFixed(2)) : null)
                                            setShowValue(removeLeadingZeros(newValue))
                                        }
                                    }}
                                    onBlur={() => {
                                        if (shownValue && (decimal || precentage)) {
                                            let updatedValue = shownValue
                                            if (!shownValue.includes('.')) {
                                                updatedValue = `${shownValue}.00`
                                            } else {
                                                const parts = shownValue.split('.')
                                                updatedValue = `${parts[0]}.${parts[1].slice(0, 2).padEnd(2, '0')}`
                                            }
                                            setShowValue(updatedValue)
                                        }
                                    }}
                                    type="tel"
                                    value={shownValue !== '' || undefined ? shownValue : field.value}
                                    className="!bg-[transparent] border-none"
                                    onKeyDown={(event) => {
                                        const allowedKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight', '.']

                                        if ((!allowedKeys.includes(event.key) && !regexString.test(event.key)) || event.key === ',' || (event.key === '.' && !decimal)) {
                                            event.preventDefault()
                                        }
                                    }}
                                    placeholder={getPlaceholder(decimal, precentage, isRequired)}
                                    style={{ fontSize: isMultipleTable ? '14px' : '', width: '100%' }}
                                    sx={{
                                        textAlign: 'right',
                                        '& .MuiInputBase-input': {
                                            textAlign: 'right',
                                            padding: '12px',
                                            color: getColor(),
                                            '&::placeholder': {
                                                color: getColor(),
                                            },
                                        },
                                        '&.MuiInputBase-root:before': {
                                            borderBottom: 'none',
                                        },
                                        '&.MuiInputBase-root:after': {
                                            borderBottom: 'none',
                                        },
                                        '&.MuiInputBase-root:hover:not(.Mui-disabled):before': {
                                            borderBottom: 'none',
                                        },
                                    }}
                                />
                            )}
                        </>
                    )}
                />
            </div>
            {precentage && !form7 && (
                <Typography
                    variant="body1"
                    style={{
                        color: getColor(),
                        marginLeft: '8px',
                        marginTop: '12px',
                        fontSize: '18px',
                        opacity: disabled ? 0.5 : 1,
                    }}
                >
                    %
                </Typography>
            )}
        </div>
    )
}

export default PositiveNumberInputCell
