import React from 'react'
import { TblOblast5 } from '../../../models/Form5/Form5.entity'
import { Control } from 'react-hook-form'
import CheckboxCell from '../../../components/Common/CheckboxCell'
import TextInputCell from '../../../components/Common/TextInputCell'
import { ReactComponent as Delete } from '../../../assets/svg/Delete.svg'
import { ReactComponent as Edit } from '../../../assets/svg/Edit.svg'
import { Button } from '@mui/material'
import HelpButton from '../../../components/Common/HelpButton'

interface ManagementPositionTableInterface {
    control: Control<TblOblast5> | undefined
    fields: any[]
    remove: any
    errors?: any
    handleCheckBox?: any
    watchedValues?: any
    getValues?: any
    disabled?: boolean
    showHelpMessage?: boolean
    helpName?: string
}

function ManagementPositionTable({
    control,
    fields,
    remove,
    errors,
    handleCheckBox,
    watchedValues,
    getValues,
    disabled,
    showHelpMessage = false,
    helpName = '',
}: ManagementPositionTableInterface) {
    return (
        <table className="simple-table mt-[13px] " style={{ gap: 8 }}>
            <tr className="simple-table-header">
                <th className=" w-[60%] h-[54px]">Кључне руководеће позиције</th>
                <th className=" min-w-[379px] h-[54px]">На тим позицијама се налазе:</th>
                {showHelpMessage && <HelpButton name="o5-k5" />}
            </tr>
            {fields.map((field, index) => (
                <tr className="simple-table-row" key={field.id}>
                    <td className=" min-w-[60%] w-[60%] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <TextInputCell
                            name={`o5_k6_pozicije.${index}.text`}
                            control={control}
                            placeholder="Tекст (50 карактера)"
                            maxNumberOfCharacter={50}
                            errors={errors}
                            isRequired={!disabled}
                            disabled={disabled}
                        />
                    </td>
                    <td className="min-w-[186px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <CheckboxCell
                            name={`o5_k6_pozicije.${index}.man`}
                            control={control}
                            label="Мушкарац"
                            handleCheckBox={handleCheckBox}
                            errors={errors}
                            watchedValues={watchedValues}
                            disabled={disabled}
                        />
                    </td>
                    <td className=" min-w-[186px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <CheckboxCell
                            name={`o5_k6_pozicije.${index}.woman`}
                            control={control}
                            label="Жена"
                            handleCheckBox={handleCheckBox}
                            errors={errors}
                            watchedValues={watchedValues}
                            disabled={disabled}
                        />
                    </td>
                    <td className="simple-table-cell pl-3 flex flex-row gap-6 items-center " style={{ border: 0 }}>
                        {/* <div className="w-fit cursor-pointer">
                            <Edit />
                        </div> */}
                        {fields.length > 1 && (
                            <Button className="w-fit cursor-pointer" onClick={() => remove(index)} disabled={disabled}>
                                <Delete />
                            </Button>
                        )}
                    </td>
                </tr>
            ))}
        </table>
    )
}

export default ManagementPositionTable
