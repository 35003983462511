import React, { useState } from 'react'
import { useUser } from '../../auth/UseUser'
import { ReactComponent as PersonSvg } from '../../assets/svg/PersonFilled.svg'
import { ReactComponent as EmailIcon } from '../../assets/svg/EmailIcon.svg'
import { ReactComponent as HamburgerMenu } from '../../assets/svg/HamburgerMenu.svg'
import { ReactComponent as ArrowUp } from '../../assets/svg/SortUpArrowSVG.svg'
import grbImage from '../../assets/images/grb.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DropdownMenu } from './Components/DropDownMenu'
import { baseUrl } from '../../server/connections'

const NavBar = () => {
    const { user, setUser } = useUser()
    const navigate = useNavigate()
    const [showDropdown, setShowDropdown] = useState(false)
    const [hamburgerDropDown, setHamburgerDropdown] = useState(false)

    const location = useLocation()
    const isActive = (path: string) => location.pathname === path

    const toggleDropdown = () => {
        setShowDropdown((prevState) => !prevState)
    }

    const logout = () => {
        if (user?.oidcEnabled) {
            localStorage.clear()
            setUser({})
            window.location.href = `${baseUrl}/signout-callback-oidc`
        } else {
            localStorage.clear()
            setUser({})
        }
    }

    const adminLinks = [
        { label: 'Аналитика', value: '/admin/analytics' },
        { label: 'Управљање извештајима', value: '/admin/dashboard' },
        { label: 'Управљање местима', value: '/admin/cities' },
        { label: 'Управљање корисницима', value: '/admin/users' },
        { label: 'Управљање обвезницима', value: '/admin/legal-entities' },
        { label: 'Управљање порукама', value: '/admin/messages' },
        { label: 'Управљање упутствима', value: '/admin/help/all' },
        { label: 'Управљање категоријама', value: '/admin/categories' },
        { label: 'Привредни ентитети', value: '/admin/corporateEntities' },
        { label: 'Услови коришћења и политика приватности', value: '/admin/legal' },
    ]
    const openContactModal = () => {
        navigate('/contact')
    }

    return (
        <div>
            <div className="bg-[#243964] min-h-[105px] flex items-center justify-between px-[120px]">
                {/* INVIS */}
                <img src={grbImage} alt="Грб Републике Србије" width={45} onClick={() => !!user.subjectName && navigate('/')} className="hover:cursor-pointer" />
                <div className="flex justify-end invisible">
                    {user.admin && (
                        <div className="flex flex-row items-center text-[#ffffffdd] hover:text-white cursor-pointer gap-1" onClick={() => setHamburgerDropdown(!hamburgerDropDown)}>
                            <HamburgerMenu className="fill-[#ffffffdd] group-hover:fill-white transition-colors duration-300 svg-fill-current h-6 w-6" />
                            <ArrowUp
                                className={`transition-transform duration-300 ${!hamburgerDropDown ? 'rotate-180' : ''} fill-[#ffffffdd] group-hover:fill-white h-4 w-4`}
                                style={{
                                    transformOrigin: 'center',
                                }}
                            />
                        </div>
                    )}
                    {!!user?.email ? (
                        <div className="text-[#FFFFFF] flex flex-row items-center">
                            <div className="mr-[10px] flex items-center">
                                <PersonSvg fill="#FFFFFF" />
                                <div className="flex flex-row gap-[2px]">
                                    {!!user.admin ? (
                                        <>
                                            <p className="ml-[5px] font-[16px] leading-[24px]">{user.firstName}</p>
                                            <p className="ml-[5px] font-[16px] leading-[24px]">{user.lastName}</p>
                                        </>
                                    ) : (
                                        <p className="ml-[5px] font-[16px] leading-[24px] max-w-[150px] text-ellipsis overflow-hidden whitespace-nowrap">{user.subjectName}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="text-[#FFFFFF] flex flex-row items-center">
                            <div className="mr-[10px] flex items-center">
                                <PersonSvg fill="#FFFFFF" />
                                <ArrowUp />
                            </div>
                            <div>
                                <Link to="/login">Пријавите се</Link>
                            </div>
                        </div>
                    )}
                    {!user.admin && (
                        <div className="flex flex-row items-center text-[#ffffffdd] hover:text-white ml-5 cursor-pointer">
                            <EmailIcon />
                            <p className="ml-[5px] font-[18px] leading-[24px] ">Контакт</p>
                        </div>
                    )}
                </div>
                {/* /INVIS */}
                <div className="flex flex-1 justify-center">
                    <div className="cursor-pointer" onClick={() => !!user.subjectName && navigate('/')}>
                        <h2 className="text-[#FFFFFF] text-[24px] leading-6 cursor-pointer">Родна равноправност</h2>
                    </div>
                </div>
                {/* INVIS */}
                <div className="invisible">
                    <img src={grbImage} alt="Грб Републике Србије" width={45} onClick={() => !!user.subjectName && navigate('/')} className="hover:cursor-pointer" />
                </div>
                {/* /INVIS */}
                <div className="flex justify-end h-full">
                    {user.admin && (
                        <div className="relative mt-[2px]" onMouseLeave={() => setHamburgerDropdown(false)}>
                            <div className="flex flex-row items-center cursor-pointer gap-1 group" onClick={() => setHamburgerDropdown(!hamburgerDropDown)}>
                                <HamburgerMenu className="fill-[#ffffffdd] group-hover:fill-white transition-colors duration-300 svg-fill-current h-6 w-6" />
                                <ArrowUp
                                    className={`transition-transform duration-300 ${!hamburgerDropDown ? 'rotate-180' : ''} fill-[#ffffffdd] group-hover:fill-white h-4 w-4`}
                                    style={{
                                        transformOrigin: 'center',
                                    }}
                                />
                            </div>

                            {hamburgerDropDown && (
                                <div
                                    className="absolute right-0 top-full w-48 bg-white shadow-lg rounded-lg z-10 flex items-center overflow-hidden"
                                    onMouseLeave={() => setShowDropdown(false)}
                                >
                                    <ul className="w-full">
                                        {adminLinks.map((link) => (
                                            <li
                                                className={`px-4 py-2 cursor-pointer hover:bg-[rgba(36,57,100,0.2)] text-[#243964] w-full text-center ${
                                                    isActive(link.value) ? 'bg-[#24396433]' : ''
                                                }`}
                                                onClick={() => {
                                                    navigate(link.value)
                                                    setHamburgerDropdown(false)
                                                }}
                                            >
                                                {link.label}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    )}
                    {!!user?.email ? (
                        <div className="text-[#FFFFFF] flex flex-row items-center" onMouseLeave={() => setShowDropdown(false)}>
                            <div className="relative ml-[10px]">
                                <div className="mr-[10px] flex items-center cursor-pointer text-[#ffffffdd] hover:text-white gap-3 group" onClick={toggleDropdown}>
                                    <div className="flex flex-row items-center">
                                        <PersonSvg className="fill-[#ffffffdd] group-hover:fill-white transition-colors duration-300 svg-fill-current h-6 w-6" />
                                        <ArrowUp
                                            className={`transition-transform duration-300 ${!showDropdown ? 'rotate-180' : ''} fill-[#ffffffdd] group-hover:fill-white h-4 w-4`}
                                            style={{
                                                transformOrigin: 'center',
                                            }}
                                        />
                                    </div>
                                    <div className="flex flex-row items-center gap-[2px] h-full">
                                        {!!user.subjectName ? (
                                            <p className="ml-[5px] font-[16px] leading-[24px] max-w-[150px] text-ellipsis overflow-hidden whitespace-nowrap">{user.subjectName}</p>
                                        ) : (
                                            <>
                                                <p className="ml-[5px] font-[16px] leading-[24px]">{user.firstName}</p>
                                                <p className="ml-[5px] font-[16px] leading-[24px]">{user.lastName}</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <DropdownMenu showDropdown={showDropdown} setShowDropdown={setShowDropdown} user={user} navigate={navigate} logout={logout} />
                            </div>
                        </div>
                    ) : (
                        <div className={`text-[#FFFFFF] flex flex-row items-center ${location.pathname === '/login' ? 'invisible' : ''}`}>
                            <div className="mr-[10px] flex items-center">
                                <PersonSvg fill="#FFFFFF" />
                            </div>
                            <div>
                                <Link to="/login">Пријавите се</Link>
                            </div>
                        </div>
                    )}
                </div>
                {!user.admin && (
                    <div className="flex flex-row items-center text-[#ffffffdd] hover:text-white ml-5 cursor-pointer" onClick={() => openContactModal()}>
                        <EmailIcon />
                        <p className="ml-[5px] font-[18px] leading-[24px] ">Контакт</p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default NavBar
