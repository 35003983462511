import React from 'react'

function StepButton({ borderColor = '', fill = '' }) {
    return (
        <svg width="188" height="60" viewBox="0 0 188 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_21_764)">
                <mask id="path-1-inside-1_21_764" fill="white">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M171.152 3.01544C170.439 1.76914 169.114 1 167.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H167.679C169.114 57 170.439 56.2309 171.152 54.9846L184.866 30.9846C185.569 29.7548 185.569 28.2452 184.866 27.0154L171.152 3.01544Z"
                    />
                </mask>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M171.152 3.01544C170.439 1.76914 169.114 1 167.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H167.679C169.114 57 170.439 56.2309 171.152 54.9846L184.866 30.9846C185.569 29.7548 185.569 28.2452 184.866 27.0154L171.152 3.01544Z"
                    fill={fill}
                />
                <path
                    d="M14.8659 27.0154L13.9976 27.5116L13.9976 27.5116L14.8659 27.0154ZM14.8659 30.9846L13.9976 30.4884L13.9976 30.4884L14.8659 30.9846ZM184.866 30.9846L185.734 31.4807L185.734 31.4807L184.866 30.9846ZM184.866 27.0154L185.734 26.5193L185.734 26.5193L184.866 27.0154ZM171.152 3.01544L172.02 2.51931L171.152 3.01544ZM171.152 54.9846L172.02 55.4807L171.152 54.9846ZM6.89263 2H167.679V0H6.89263V2ZM15.7341 26.5193L4.2879 6.48842L2.55141 7.4807L13.9976 27.5116L15.7341 26.5193ZM15.7341 31.4807C16.6125 29.9435 16.6125 28.0565 15.7341 26.5193L13.9976 27.5116C14.5247 28.4339 14.5247 29.5661 13.9976 30.4884L15.7341 31.4807ZM4.2879 51.5116L15.7341 31.4807L13.9976 30.4884L2.55141 50.5193L4.2879 51.5116ZM167.679 56H6.89263V58H167.679V56ZM183.998 30.4884L170.283 54.4884L172.02 55.4807L185.734 31.4807L183.998 30.4884ZM183.998 27.5116C184.525 28.4339 184.525 29.5661 183.998 30.4884L185.734 31.4807C186.612 29.9435 186.612 28.0565 185.734 26.5193L183.998 27.5116ZM170.283 3.51158L183.998 27.5116L185.734 26.5193L172.02 2.51931L170.283 3.51158ZM2.55141 50.5193C0.64668 53.8526 3.05352 58 6.89263 58V56C4.58916 56 3.14506 53.5116 4.2879 51.5116L2.55141 50.5193ZM167.679 2C168.755 2 169.749 2.57686 170.283 3.51158L172.02 2.51931C171.13 0.96143 169.473 0 167.679 0V2ZM167.679 58C169.473 58 171.13 57.0386 172.02 55.4807L170.283 54.4884C169.749 55.4231 168.755 56 167.679 56V58ZM6.89263 0C3.05352 0 0.646681 4.14741 2.55141 7.4807L4.2879 6.48842C3.14506 4.48845 4.58916 2 6.89263 2V0Z"
                    fill="#C4C4C4"
                    mask="url(#path-1-inside-1_21_764)"
                />
            </g>
            <defs>
                <filter id="filter0_d_21_764" x="0.886719" y="0" width="186.506" height="60" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.215686 0 0 0 0 0.254902 0 0 0 0 0.317647 0 0 0 0.08 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21_764" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_21_764" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export default StepButton
