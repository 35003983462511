import { useUser } from '../../../auth/UseUser'

export const DropdownItem = ({ onClick, children, hightlight }: any) => (
    <li className={`px-4 py-2 cursor-default  text-[#243964] w-full text-center ${hightlight && 'bg-[rgba(36,57,100,0.2)]'}`}>{children}</li>
)

// export const TutorialNavDropdownMenu = ({ showDropdown, setShowDropdown, user, navigate, logout }: any) => {
//     return (
//         showDropdown && (
//             <div className="absolute right-0 w-48 bg-white shadow-lg rounded-lg z-10 flex items-center overflow-hidden">
//                 <ul className="w-full">
//                     {!user.admin && (
//                         <>
//                             {!!user.legalEntityId && <DropdownItem>Одабир законског обвезника</DropdownItem>}
//                             <DropdownItem hightlight>Измена профила</DropdownItem>
//                             <DropdownItem>Измена лозинке</DropdownItem>
//                             <DropdownItem>Измена имејла</DropdownItem>
//                         </>
//                     )}
//                     <DropdownItem>Одјава</DropdownItem>
//                 </ul>
//             </div>
//         )
//     )
// }
