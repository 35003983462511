import React from 'react'
import { StepInterface } from './Step1'
import '../../../../../assets/styles/SimpleTable.css'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { Form7_15Entity } from '../../../../../models/Form7/Entities/Form7_15.entity'
import { useWatch } from 'react-hook-form'
import ActionButtons from '../../../../../components/ActionButtons'
import { getPercentage } from '../../../../../utils/mathUtils'

export const Step5 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_15Entity>({ control })

    const fields: ('o7_15_k5_pregledUkupno' | 'o7_15_k5_pregledObuhvaceni' | 'o7_15_k5_skriningUkupno' | 'o7_15_k5_skriningObuhvaceni')[] = [
        'o7_15_k5_pregledUkupno',
        'o7_15_k5_pregledObuhvaceni',
        'o7_15_k5_skriningUkupno',
        'o7_15_k5_skriningObuhvaceni',
    ]
    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }

    console.log('Condition in working case:', !disabledNext || adminView)
    console.log('Condition in non-working case:', !disabledNext || adminView)

    console.log('watchedValues at render:', watchedValues)

    console.log('disabledNext:', disabledNext)
    console.log('adminView:', adminView)
    console.log('watchedValues.pregledObuhvaceni:', watchedValues.o7_15_k5_pregledObuhvaceni)
    console.log('watchedValues.pregledUkupno:', watchedValues.o7_15_k5_pregledUkupno)
    console.log('watchedValues.skriningObuhvaceni:', watchedValues.o7_15_k5_skriningObuhvaceni)
    console.log('watchedValues.skriningUkupno:', watchedValues.o7_15_k5_skriningUkupno)

    console.log('Condition result:', !disabledNext || adminView)

    if (watchedValues.o7_15_k5_pregledObuhvaceni !== undefined && watchedValues.o7_15_k5_pregledUkupno !== undefined) {
        console.log('Calculating percentage')
    }
    return (
        <div>
            {/* pregled */}
            <h1 className="step-name mb-[10px]">Оквирни проценат жена обухваћених обавезним организованим превентивним прегледима скрининг на рак дојке</h1>
            <div>
                <h1 className="step-name mb-[10px]">Укупан број корисника женског пола</h1>
                <div className="max-w-[200px] flex flex-col">
                    <PositiveNumberInputCell
                        name={`o7_15_k5_pregledUkupno`}
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        height={62}
                        disabled={adminView}
                        form7
                        clearErrors={clearErrors}
                    />

                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_15_k5_pregledUkupno ? 'Попуните обавезно поље!' : ''}</span>
                </div>
            </div>
            <div>
                <h1 className="step-name mb-[10px]">Број жена обухваћених обавезним организованим превентивним прегледима скрининг на рак дојке</h1>
                <div className="max-w-[200px] flex flex-col">
                    <PositiveNumberInputCell
                        name={`o7_15_k5_pregledObuhvaceni`}
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        height={62}
                        disabled={adminView}
                        form7
                        clearErrors={clearErrors}
                    />

                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_15_k5_pregledObuhvaceni ? 'Попуните обавезно поље!' : ''}</span>
                </div>
            </div>
            <div>
                <h1 className="step-name mb-[10px]">Проценат жена обухваћених обавезним организованим превентивним прегледима скрининг на рак дојке</h1>
                <div className="border border-[#00000010] h-[62px] w-full flex justify-center items-center max-w-[200px] hover:none hover:cursor-default">
                    {`${
                        watchedValues.o7_15_k5_pregledObuhvaceni && watchedValues.o7_15_k5_pregledUkupno
                            ? getPercentage(watchedValues.o7_15_k5_pregledObuhvaceni, watchedValues.o7_15_k5_pregledUkupno)
                            : '0'
                    }%`}
                </div>
            </div>

            {/* skrining */}
            <h1 className="step-name mb-[10px] mt-[30px]">Оквирни проценат жена обухваћених обавезним организованим превентивним прегледима скрининг на рак грлића материце</h1>
            <div>
                <h1 className="step-name mb-[10px]">Укупан број корисника женског пола</h1>
                <div className="max-w-[200px] flex flex-col">
                    <PositiveNumberInputCell
                        name={`o7_15_k5_skriningUkupno`}
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        height={62}
                        disabled={adminView}
                        form7
                        clearErrors={clearErrors}
                    />

                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_15_k5_skriningUkupno ? 'Попуните обавезно поље!' : ''}</span>
                </div>
            </div>
            <div>
                <h1 className="step-name mb-[10px]">Број жена обухваћених обавезним организованим превентивним прегледима скрининг на рак грлића материце</h1>
                <div className="max-w-[200px] flex flex-col">
                    <PositiveNumberInputCell
                        name={`o7_15_k5_skriningObuhvaceni`}
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        height={62}
                        disabled={adminView}
                        form7
                        clearErrors={clearErrors}
                    />

                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_15_k5_skriningObuhvaceni ? 'Попуните обавезно поље!' : ''}</span>
                </div>
            </div>
            <div>
                <h1 className="step-name mb-[10px]">Проценат жена обухваћених обавезним организованим превентивним прегледимаскрининг на рак грлића материце</h1>
                <div className="border border-[#00000010] h-[62px] w-full flex justify-center items-center max-w-[200px] hover:none hover:cursor-default">
                    {`${!disabledNext || adminView ? getPercentage(watchedValues.o7_15_k5_skriningObuhvaceni, watchedValues.o7_15_k5_skriningUkupno) : '0'}%`}
                </div>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
