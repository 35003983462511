import React from 'react'
import { StepInterface } from './Step1'
import NoAnalytics from '../../Common/NoAnalytics'
import ActionButtons from '../../../../components/ActionButtons'

const Step14 = ({
    control,
    nextStep,
    previousStep,
    previousButtonDisabled = false,
    errors,
    adminView,
    countType,
    setValue,
    selectedCategories,
    selectedSubCategories,
}: StepInterface) => {
    return (
        <div>
            <h1 className="step-name">
                Број и назив активности тела за родну равноправност реализованих у вези случајева насиља по основу пола, односно рода, у години вођења евиденције
            </h1>

            <NoAnalytics />

            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step14
