import React from 'react'
import { Form7_1Entity } from '../../../../../models/Form7/Entities/Form7_1.entity'
import { StepInterface } from './Step1'
import { useWatch } from 'react-hook-form'
import ComplexTable from '../../../../../components/Tables/ComplexTable'
import ActionButtons from '../../../../../components/ActionButtons'

interface RowInterface {
    label: string
    fields: (keyof Form7_1Entity)[]
}

const rowsTable1: RowInterface[] = [
    { label: 'Сектор пољопривреде', fields: ['o7_1_k5_z_poljoprivreda', 'o7_1_k5_m_poljoprivreda'] },
    { label: 'Сектор индустрије', fields: ['o7_1_k5_z_industrija', 'o7_1_k5_m_industrija'] },
    { label: 'Сектор грађевине', fields: ['o7_1_k5_z_gradjevina', 'o7_1_k5_m_gradjevina'] },
    { label: 'Сектор услуга', fields: ['o7_1_k5_z_usluge', 'o7_1_k5_m_usluge'] },
]

const allFieldsTable1 = [
    'o7_1_k5_z_poljoprivreda',
    'o7_1_k5_z_industrija',
    'o7_1_k5_z_gradjevina',
    'o7_1_k5_z_usluge',

    'o7_1_k5_m_poljoprivreda',
    'o7_1_k5_m_industrija',
    'o7_1_k5_m_gradjevina',
    'o7_1_k5_m_usluge',
] as const

const allMaleFieldsTable1 = [...allFieldsTable1.filter((e) => e.includes('_m_'))] as const
const allFemaleFieldsTable1 = [...allFieldsTable1.filter((e) => e.includes('_z_'))] as const

const rowsTable2: RowInterface[] = [
    { label: 'Приватна', fields: ['o7_1_k5_z_zap_privatna', 'o7_1_k5_m_zap_privatna'] },
    { label: 'Државна', fields: ['o7_1_k5_z_zap_drzavna', 'o7_1_k5_m_zap_drzavna'] },
    { label: 'Остало', fields: ['o7_1_k5_z_zap_ostalo', 'o7_1_k5_m_zap_ostalo'] },
]

const allFieldsTable2 = [
    'o7_1_k5_z_zap_privatna',
    'o7_1_k5_z_zap_drzavna',
    'o7_1_k5_z_zap_ostalo',

    'o7_1_k5_m_zap_privatna',
    'o7_1_k5_m_zap_drzavna',
    'o7_1_k5_m_zap_ostalo',
] as const

const allMaleFieldsTable2 = [...allFieldsTable2.filter((e) => e.includes('_m_'))] as const
const allFemaleFieldsTable2 = [...allFieldsTable2.filter((e) => e.includes('_z_'))] as const

const rowsTable3: RowInterface[] = [
    { label: 'Приватна', fields: ['o7_1_k5_z_samo_privatna', 'o7_1_k5_m_samo_privatna'] },
    { label: 'Државна', fields: ['o7_1_k5_z_samo_drzavna', 'o7_1_k5_m_samo_drzavna'] },
    { label: 'Остало', fields: ['o7_1_k5_z_samo_ostalo', 'o7_1_k5_m_samo_ostalo'] },
]

const allFieldsTable3 = [
    'o7_1_k5_z_samo_privatna',
    'o7_1_k5_z_samo_drzavna',
    'o7_1_k5_z_samo_ostalo',

    'o7_1_k5_m_samo_privatna',
    'o7_1_k5_m_samo_drzavna',
    'o7_1_k5_m_samo_ostalo',
] as const

const allMaleFieldsTable3 = [...allFieldsTable3.filter((e) => e.includes('_m_'))] as const
const allFemaleFieldsTable3 = [...allFieldsTable3.filter((e) => e.includes('_z_'))] as const

const joinValues = [...allFieldsTable1, ...allFieldsTable2, ...allFieldsTable3] as const
type AllFieldsType = (typeof joinValues)[number]

function Step5({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_1Entity>({ control })
    const fieldsTable: AllFieldsType[] = [...joinValues]

    const disabledNext = !fieldsTable.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">5. Самозапослени према секторима делатности</h1>
            <ComplexTable
                rows={rowsTable1}
                control={control}
                allFemaleFields={[...allFemaleFieldsTable1]}
                allMaleFields={[...allMaleFieldsTable1]}
                allFields={[...allFieldsTable1]}
                header={['Сектор', 'Жене', 'Жене %', 'Мушкарци', 'Мушкарци %', 'Укупно', 'Укупно %']}
                errors={errors}
                disabled={adminView}
                decimal
            />
            <h1 className="step-name mt-10">Запослени и радно ангажовани према својини </h1>
            <ComplexTable
                rows={rowsTable2}
                control={control}
                allFemaleFields={[...allFemaleFieldsTable2]}
                allMaleFields={[...allMaleFieldsTable2]}
                allFields={[...allFieldsTable2]}
                header={['Сектор', 'Жене', 'Жене %', 'Мушкарци', 'Мушкарци %', 'Укупно', 'Укупно %']}
                errors={errors}
                disabled={adminView}
                decimal
            />
            <h1 className="step-name mt-10">Самозапослени према својини </h1>
            <ComplexTable
                rows={rowsTable3}
                control={control}
                allFemaleFields={[...allFemaleFieldsTable3]}
                allMaleFields={[...allMaleFieldsTable3]}
                allFields={[...allFieldsTable3]}
                header={['Сектор', 'Жене', 'Жене %', 'Мушкарци', 'Мушкарци %', 'Укупно', 'Укупно %']}
                errors={errors}
                disabled={adminView}
                decimal
            />
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step5
