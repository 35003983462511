import React, { useState } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { sendRegisterToken, singUp, whoAmI } from '../../server/server'
import { useUser } from '../../auth/UseUser'
import { Checkbox } from '@mui/material'
import { passwordStrength } from 'check-password-strength'
import Loading from '../Common/LoadingSpinner'
import HelpButton from '../Common/HelpButton'
import { ReactComponent as ShowPasswordIcon } from '../../assets/svg/EyeFilled.svg'
import { ReactComponent as HidePasswordIcon } from '../../assets/svg/EyeHidden.svg'

export type SignUpInputs = {
    email: string
    password: string
    repeatPassword: string
    firstName: string
    lastName: string
}

const getPasswordStrengthLabel = (strengthId: number) => {
    switch (strengthId) {
        case 0:
            return 'ПРЕСЛАБА'
        case 1:
            return 'СЛАБА'
        case 2:
            return 'СРЕДНЈА'
        case 3:
            return 'ЈАКА'
        default:
            return ''
    }
}

const SignUp = () => {
    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        control,
        formState: { errors },
    } = useForm<SignUpInputs>({
        defaultValues: {
            email: '',
            password: '',
            firstName: '',
            lastName: '',
        },
    })
    const navigate = useNavigate()
    const { user, setUser } = useUser()
    const watchedValues = useWatch<SignUpInputs>({ control })
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showRepeatPassword, setShowRepeatPassword] = useState(false)

    const onSubmit: SubmitHandler<SignUpInputs> = async () => {
        nextStep()
    }

    const nextStep = async () => {
        setLoading(true)
        const resp = await singUp({ email: getValues('email'), password: getValues('password'), firstName: getValues('firstName'), lastName: getValues('lastName') })
        const data = resp.data
        if (resp.status == 201) {
            setMessage('success')
            setLoading(false)
            navigate(`/validation/send?email=${getValues('email')}`)
        } else if (resp.status == 409) {
            setMessage('conflict')
            setLoading(false)
        }
    }

    const validatePassword = (password: any) => {
        if (password.length === 0) {
            return ''
        }

        const hasLength = password.length >= 8
        const hasNumber = /\d/.test(password)
        const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password)
        const hasUppercase = /[A-Z]/.test(password)
        const hasLowercase = /[a-z]/.test(password)

        if (!hasLength || !hasNumber || !hasSymbol || !hasUppercase || !hasLowercase) {
            return 'Лозинка није исправно попуњена'
        }

        return ''
    }

    const password = watchedValues?.password || ''

    const strengthId = passwordStrength(password).id
    const strengthLabel = getPasswordStrengthLabel(strengthId)

    const preventNumbers = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const key = event.key
        if (!/[A-Za-zА-Ша-шЉљЊњЏџЂђЈјЋћ\s]/.test(key) && key !== 'Backspace' && key !== 'Tab' && key !== 'Enter') {
            event.preventDefault()
        }
    }

    return (
        <div className="items-center justify-center flex mb-[50px] relative">
            <div className="absolute top-5 right-10 ">
                <HelpButton name="user_registration_help" />
            </div>
            <div className="px-[99px] py-[45px] bg-[#ffffff] items-start flex flex-col">
                <h2 className="mb-[32px] text-[24px] leading-[28px] font-light min-w-[520px] max-w-[520px] text-center">Регистрација</h2>

                <form className="flex flex-col items-center w-full min-w-[520px] max-w-[520px]" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="w-full flex flex-col">
                        <input
                            className="border border-[#0000003B] min-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                            placeholder="Име корисника"
                            {...register('firstName', {
                                required: true,
                            })}
                            onKeyDown={(event) => {
                                preventNumbers(event)
                            }}
                        />
                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] mb-[10px]">{errors.firstName?.type === 'required' ? 'Име је обавезно поље' : ''}</span>
                        <input
                            className="border border-[#0000003B] min-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                            placeholder="Презиме корисника"
                            {...register('lastName', {
                                required: true,
                            })}
                            onKeyDown={(event) => {
                                preventNumbers(event)
                            }}
                        />
                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] mb-[10px]">
                            {errors.lastName?.type === 'required' ? 'Презиме је обавезно поље' : ''}
                        </span>
                        <input
                            className="border border-[#0000003B] min-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                            placeholder="Електронска адреса"
                            {...register('email', {
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'invalid email address',
                                },
                            })}
                        />
                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] mb-[10px]">
                            {errors.email?.type === 'required' ? 'Електронска адреса је обавезно поље' : ''}
                            {errors.email?.type === 'pattern' ? 'Електронска адреса није исправно попуњена' : ''}
                        </span>

                        <div className="flex flex-col w-full">
                            <div className="border border-[#0000003B] rounded-[4px] min-w-[520px] min-h-[56px] w-full px-3 flex flex-row items-center ">
                                <input
                                    className="text-[16px] leading-[24px] w-full outline-none "
                                    placeholder="Лозинка"
                                    type={showPassword ? 'text' : 'password'}
                                    {...register('password', {
                                        required: 'Лозинка је обавезно поље',
                                        minLength: {
                                            value: 8,
                                            message: '',
                                        },
                                        pattern: {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/,
                                            message: '',
                                        },
                                    })}
                                    autoComplete="off"
                                />
                                <div className="cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                                    {!showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                                </div>
                            </div>
                            <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] ">
                                {errors.password?.type === 'required' ? 'Лозинка је обавезно поље' : ''}
                                {errors.password ? validatePassword(watchedValues?.password) : ''}
                            </span>
                        </div>

                        <div className="flex flex-col w-full mt-[16px]">
                            <div className="border border-[#0000003B] rounded-[4px] min-w-[520px] min-h-[56px] w-full px-3 flex flex-row items-center ">
                                <input
                                    className="text-[16px] leading-[24px] w-full outline-none "
                                    placeholder="Потврда лозинке"
                                    type={showRepeatPassword ? 'text' : 'password'}
                                    {...register('repeatPassword', {
                                        validate: {
                                            obavezno: (value: any) => value.length > 0,
                                            corect: (val: string) => getValues('password') === val,
                                        },
                                    })}
                                    autoComplete="off"
                                />
                                <div className="cursor-pointer" onClick={() => setShowRepeatPassword(!showRepeatPassword)}>
                                    {!showRepeatPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                                </div>
                            </div>
                            {errors.repeatPassword?.type === 'corect' && (
                                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] ">
                                    {errors.repeatPassword?.type === 'corect' ? 'Лозинке се не слажу молим Вас проверите' : ''}
                                </span>
                            )}
                            {errors.repeatPassword?.type === 'obavezno' && (
                                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] ">
                                    {errors.repeatPassword?.type === 'obavezno' ? 'Обавезно попуните ово поље!' : ''}
                                </span>
                            )}
                        </div>

                        {message === 'success' ? (
                            <></>
                        ) : (
                            <span className={`${message !== 'conflict' ? '' : 'text-[#d32f2f]'} max-w-[500px] text-center h-[30px] mt-[20px]`}>
                                {message === 'conflict' && 'Емаил адреса је већ у употреби.'}
                            </span>
                        )}

                        <button
                            type="submit"
                            className="w-full h-[42px] flex items-center justify-center rounded-[4px] bg-[#243964] text-[15px] text-[#ffffff] font-medium leading-[26px] cursor-pointer mt-[30px]"
                        >
                            {!loading ? 'НАСТАВИ СА РЕГИСТРАЦИЈОМ' : <Loading height={25} />}
                        </button>
                    </div>

                    <div className="mt-[23px] flex place-self-start flex-col">
                        <div className="flex flex-row items-center">
                            <Checkbox
                                checked={Boolean(watchedValues?.password === watchedValues.repeatPassword)}
                                size="large"
                                sx={{
                                    color: '#A2CEFF',
                                    '&.Mui-checked': {
                                        color: '#A2CEFF',
                                    },
                                }}
                            />
                            <p className="text-[14px] leading-[20px] font-light">ПОКЛАПАЊЕ ЛОЗИНКЕ</p>
                        </div>
                        <div className="flex flex-row items-center">
                            <Checkbox
                                checked={Boolean(
                                    watchedValues?.password &&
                                        watchedValues.password.length >= 8 &&
                                        /\d/.test(watchedValues.password) &&
                                        /[a-z]/.test(watchedValues.password) &&
                                        /[A-Z]/.test(watchedValues.password) &&
                                        /[!@#$%^&*(),.?":{}|<>]/.test(watchedValues.password)
                                )}
                                size="large"
                                sx={{
                                    color: '#A2CEFF',
                                    '&.Mui-checked': {
                                        color: '#A2CEFF',
                                    },
                                }}
                            />
                            <p className="text-[14px] leading-[20px] font-light">ЈАЧИНА ЛОЗИНКЕ: {strengthLabel}</p>
                        </div>
                        <div className="flex flex-row items-center">
                            <Checkbox
                                readOnly
                                checked={Boolean(watchedValues?.password && watchedValues.password.length >= 8)}
                                size="large"
                                sx={{
                                    color: '#A2CEFF',
                                    '&.Mui-checked': {
                                        color: '#A2CEFF',
                                    },
                                }}
                            />
                            <p className="text-[14px] leading-[20px] font-light">НАЈМАЊЕ 8 КАРАКТЕРА</p>
                        </div>
                        <div className="flex flex-row items-center">
                            <Checkbox
                                readOnly
                                checked={Boolean(watchedValues?.password && /\d/.test(watchedValues.password))}
                                size="large"
                                sx={{
                                    color: '#A2CEFF',
                                    '&.Mui-checked': {
                                        color: '#A2CEFF',
                                    },
                                }}
                            />
                            <p className="text-[14px] leading-[20px] font-light">САДРЖИ БРОЈ</p>
                        </div>
                        <div className="flex flex-row items-center">
                            <Checkbox
                                readOnly
                                checked={Boolean(watchedValues?.password && /[a-z]/.test(watchedValues.password))}
                                size="large"
                                sx={{
                                    color: '#A2CEFF',
                                    '&.Mui-checked': {
                                        color: '#A2CEFF',
                                    },
                                }}
                            />
                            <p className="text-[14px] leading-[20px] font-light">САДРЖИ МАЛО СЛОВО</p>
                        </div>
                        <div className="flex flex-row items-center">
                            <Checkbox
                                readOnly
                                checked={Boolean(watchedValues?.password && /[A-Z]/.test(watchedValues.password))}
                                size="large"
                                sx={{
                                    color: '#A2CEFF',
                                    '&.Mui-checked': {
                                        color: '#A2CEFF',
                                    },
                                }}
                            />
                            <p className="text-[14px] leading-[20px] font-light">САДРЖИ ВЕЛИКО СЛОВО</p>
                        </div>
                        <div className="flex flex-row items-center">
                            <Checkbox
                                readOnly
                                checked={Boolean(watchedValues?.password && /[!@#$%^&*(),.?":{}|<>]/.test(watchedValues.password))}
                                size="large"
                                sx={{
                                    color: '#A2CEFF',
                                    '&.Mui-checked': {
                                        color: '#A2CEFF',
                                    },
                                }}
                            />
                            <p className="text-[14px] leading-[20px] font-light">САДРЖИ СИМБОЛ</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SignUp
