import React from 'react'

function NoAnalytics() {
    return (
        <div className="mt-[20px] h-[200px] flex items-center justify-center">
            <p className="italic text-inactive">За овај корак нема аналитике пошто је у питању текстуални унос</p>
        </div>
    )
}

export default NoAnalytics
