export const FORM7_1NAMES = [
    '1. Стопе запослености, незапослености и становништва ван радне снаге, старости 15 и више година за годину извештавања',
    '2. Активно становништвo према старосним групама и полу',
    '3. Запослени према старосним групама и полу',
    '4. Запослени и радно ангажовани према уобичајеним сатима рада и полу, за годину извештавања',
    '5. Самозапослени према секторима делатности',
    '6. Запослени и радно ангажовани према главним групама занимања и полу',
    '7. Поштовани, кликом на заврши и пошаљи потврђујете да сте унели све потребне информације у дефинисана поља',
]
export const HELP_BUTTON_7_1 = ['o7_1-k1', 'o7_1-k2', 'o7_1-k3', 'o7_1-k4', 'o7_1-k5', 'o7_1-k6', 'o7_1-k7']
