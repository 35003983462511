import React, { useEffect, useState } from 'react'
import { Control, useFieldArray, useWatch } from 'react-hook-form'
import ActionButtons from '../../../../../components/ActionButtons'
import { Form7_10Entity } from '../../../../../models/Form7/Entities/Form7_10.entity'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { ReactComponent as Delete } from '../../../../../assets/svg/Delete.svg'
import { Button } from '@mui/material'
import TextInputCell from '../../../../../components/Common/TextInputCell'

export interface StepInterface {
    control?: Control<Form7_10Entity>
    nextStep: (skip?: boolean) => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
}

const HEADER_LIST = ['Редни број', 'Назив документа']
const arrayName = 'o7_10_k1_aktivnosti'

function Step1({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_10Entity>({ control })

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: arrayName,
    })

    useEffect(() => {
        if (!fields.length) append([{ text_content: '' }])
    }, [])

    const handleAddField = () => {
        append({ text_content: '' })
    }

    const validateFields = (): boolean => {
        if (adminView) {
            return true
        }
        const newMap = watchedValues.o7_10_k1_aktivnosti?.map((field) => field.text_content != '')
        return newMap?.includes(false) ? true : false
    }

    const disabledNext = validateFields()
    const disabledAddMore = validateFields()

    const goToNexStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <div className="mt-[50px]">
                <h4 className="mb-[10px]">
                    Број и назив усвојених или измењених прописа у години за коју се извештава, који регулишу независност судства, самосталност тужилаштва, бесплатну правну помоћ,
                    доступне и делотворне инструменте за ефикасну заштиту права у складу са међународним стандардима ЕУ, УН, СЕ:
                </h4>
                <div className="mt-[50px]">
                    <div>
                        <table className="simple-table mt-[50px] gap-[6px]">
                            <tr className="simple-table-header">
                                <th className=" w-[114px] h-[54px]">{HEADER_LIST[0]}</th>
                                <th className=" w-[1100px] h-[54px]">{HEADER_LIST[1]}</th>
                                <td className=" pl-3 flex flex-row gap-6 items-center invisible" style={{ border: 0 }}>
                                    {fields.length > 1 && (
                                        <Button className="w-fit cursor-pointer" disabled={adminView}>
                                            <Delete />
                                        </Button>
                                    )}
                                </td>
                            </tr>
                            {fields.map((field, index) => (
                                <tr className="simple-table-row" key={field.id}>
                                    <td className=" disabled w-[114px] h-[54px]">{index + 1}</td>
                                    <td className=" editable w-[1100px] h-[54px]" style={{ padding: 0, border: 0 }}>
                                        <TextInputCell
                                            name={`${arrayName}.${index}.text_content`}
                                            control={control}
                                            placeholder="Tекст (150 карактера)"
                                            errors={errors}
                                            isRequired={!adminView}
                                            maxNumberOfCharacter={150}
                                            disabled={adminView}
                                            isTextTable={true}
                                        />
                                    </td>

                                    <td className=" pl-3 flex flex-row gap-6 items-center " style={{ border: 0 }}>
                                        {fields.length > 1 && !adminView && (
                                            <Button className="w-fit cursor-pointer" onClick={() => remove(index)}>
                                                <Delete />
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </table>

                        <p className="ml-5 min-h-[30px] text-textError text-[0.75rem] text leading-[30px]">{errors[arrayName] ? 'Попуните обавезно поље.' : ''}</p>
                    </div>
                    <div className="flex flex-row items-center gap-10 mt-5">
                        <Button variant="contained" onClick={handleAddField} disabled={disabledAddMore}>
                            Додај још
                        </Button>
                    </div>
                </div>
            </div>
            <ActionButtons nextButton={goToNexStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step1
