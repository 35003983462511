import { useWatch } from 'react-hook-form'
import ActionButtons from '../../../../../components/ActionButtons'
import { format } from 'date-fns'
import TextInput from '../../../../../components/Common/TextInput'
import { StepInterface } from './Step1'
import { useUser } from '../../../../../auth/UseUser'
import { useEffect } from 'react'
import HelpButton from '../../../../../components/Common/HelpButton'
import { Form7_16Entity } from '../../../../../models/Form7/Entities/Form7_16.entity'

export function Step8({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, setValue }: StepInterface) {
    const watchedValues = useWatch<Form7_16Entity>({ control })
    const disabledNext = false // !watchedValues['submitted_place'] && !adminView

    const { user } = useUser()
    const city = user?.city

    useEffect(() => {
        if (setValue) setValue('submitted_place', city)
    }, [])
    const goToNexStep = () => {
        nextStep()
    }
    return (
        <div>
            <h1 className="text-[18px] leading-6 text-textPrimary ">Поштовани, кликом на заврши и пошаљи потврђујете да сте унели све потребне информације у дефинисана поља</h1>
            <div className="flex flex-row items-center mt-[100px] gap-[5px]">
                <p>У</p>
                <TextInput control={control} name="submitted_place" placeholder="Град" isName disabled={true} />
                <p>, дана {format(new Date(), 'dd.MM.yyyy.')}</p>
                <div className="ml-3">
                    <HelpButton name="o6-k9" />
                </div>
            </div>
            <ActionButtons nextButton={goToNexStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={false} lastStep isAdmin={adminView} />
        </div>
    )
}
