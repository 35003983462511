import React, { useEffect } from 'react'
import './App.css'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Layout from './components/Layout'
import PrivateRoute from './components/router/PrivateRoute'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import HomeScreen from './screens/Home/HomeScreen'
import Form1Screen from './screens/Form1/Form1Screen'
import Form2Screen from './screens/Form2/Form2Screen'
import Form3Screen from './screens/Form3/Form3Screen'
import Form4Screen from './screens/Form4/Form4Screen'
import Form5Screen from './screens/Form5/Form5Screen'
import Form6Screen from './screens/Form6/Form6Screen'
import PublicRoute from './components/router/PublicRoute'
import Login from './components/Login/Login'
import SignUp from './components/Login/SignUp'
import AnketaScreen from './screens/Anketa/AnketaScreen'

import ValidateEmail from './components/Login/SignUpSteps/ValidateEmail'
import SignUpStep2 from './components/Login/SignUpSteps/SignUpStep2'

import Dashboard from './screens/Dashboard/Dashboard'
import ForgotPassword from './components/Login/ForgotPassword'
import ResetPassword from './components/Login/SignUpSteps/ResetPassword'
import CityScreen from './screens/City/CityScreen'
import UserManagementScreen from './screens/User/UserManagementScreen'
import AdminUser from './screens/User/AdminUser'
import ContactScreen from './screens/Contact/ContactScreen'
import { useUser } from './auth/UseUser'
import MessageManagement from './screens/Contact/MessageManagement'
import AdminAllHelp from './screens/Help/AdminAllHelp'
import ValidationSent from './components/Login/SignUpSteps/ValidationSent'
import UserProfile from './screens/Profile/UserProfile'
import ChangePassword from './screens/Profile/ChangePassword'
import EmailChangeStep1 from './screens/Profile/EmailChangeSteps/EmailChangeStep1'
import EmailChangeStep2 from './screens/Profile/EmailChangeSteps/EmailChangeStep2'
import LegalInfo from './screens/Dashboard/LegalInfo'
import UserDetails from './screens/User/UserDetails'
import AuthComplete from './components/Login/oidc/AuthComplete'

import CategorySelectionScreen from './screens/User/Category/CategorySelectionScreen'

import CorporateEntitiesSearch from './screens/Dashboard/Tables/CorporateEntitiesSearch'
import ConnectCategoriesWIthForms from './screens/Categories/ConnectCategoriesWIthForms'
import CategoriesTable from './screens/Categories/CategoriesTable'
import SSOLogin from './components/Login/oidc/SSOLogin'
import AnalyticsScreen from './screens/Analytics/AnalyticsScreen'
import Form1AnalyticsScreen from './screens/Analytics/Form1/Form1AnalyticsScreen'

import Form7_4_1Screen from './screens/Form7/Forms/Form7_4_1/Form7_4_1Screen'
import Form7_4_2Screen from './screens/Form7/Forms/Form7_4_2/Form7_4_2Screen'
import Form7_5Screen from './screens/Form7/Forms/Form7_5/Form7_5Screen'
import Form7_6Screen from './screens/Form7/Forms/Form7_6/Form7_6Screen'

import Form7_1Screen from './screens/Form7/Forms/Form7_1/Form7_1Screen'
import Form7_2Screen from './screens/Form7/Forms/Form7_2/Form7_2Screen'
import Form7_3Screen from './screens/Form7/Forms/Form7_3/Form7_3Screen'
import Form7_10Screen from './screens/Form7/Forms/Form7_10/Form7_10Screen'
import Form7_11Screen from './screens/Form7/Forms/Form7_11/Form7_11Screen'
import Form7_7Screen from './screens/Form7/Forms/Form7_7/Form7_7Screen'
import Form7_8Screen from './screens/Form7/Forms/Form7_8/Form7_8Screen'
import Form7_9Screen from './screens/Form7/Forms/Form7_9/Form7_9Screen'
import Form7_13Screen from './screens/Form7/Forms/Form7_13/Form7_13Screen'
import Form7_14_1Screen from './screens/Form7/Forms/Form7_14_1/Form7_14_1Screen'
import Form7_14_2Screen from './screens/Form7/Forms/Form7_14_2/Form7_14_2Screen'
import Form7_15Screen from './screens/Form7/Forms/Form7_15/Form7_15Screen'
import Form7_12Screen from './screens/Form7/Forms/Form7_12/Form7_12Screen'
import Form2AnalyticsScreen from './screens/Analytics/Form2/Form2AnalyticsScren'
import SSOLogout from './components/Login/oidc/SSOLogout'
import Form4AnalyticsScreen from './screens/Analytics/Form4/Form4AnalyticsScreen'

import SelectLegalEntityScreen from './screens/SelectLegalEntity/SelectLegalEntityScreen'
import LegalEntitiesManagementScreen from './screens/User/LegalEntityManagementScreen'
import LegalEntityDetails from './screens/User/LegalEntityDetails'

import Form7_16Screen from './screens/Form7/Forms/Form7_16/Form7_16Screen'
import Form7_17Screen from './screens/Form7/Forms/Form7_17/Form7_17Screen'
import Form7_18Screen from './screens/Form7/Forms/Form7_18/Form7_18Screen'
import UpdateLegalEntity from './screens/Profile/UpdateLegalEntity'

function App() {
    const { user, setUser, isSignedIn } = useUser()
    const navigate = useNavigate()

    useEffect(() => {
        const checkUser = () => {
            if (user.admin) {
                return
            }
            if (user.legalEntityId && !user.category) {
                navigate('/user/category-selection')
            } else if (user.numberOfEntities > 1) {
                navigate('/select-legal-entity')
            } else if (user.numberOfEntities === 0) {
                navigate('/register/step2')
            }
        }

        if (isSignedIn) {
            checkUser()
        }
    }, [user.subjectName, user.pib, user.category, user.admin, isSignedIn])
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route path="/login" element={<PublicRoute Component={Login} />} />
                <Route path="/select-legal-entity" element={<PrivateRoute Component={SelectLegalEntityScreen} />} />
                <Route path="/sso-login" element={<PublicRoute Component={SSOLogin} />} />
                <Route path="/sso-logout" element={<PublicRoute Component={SSOLogout} />} />
                <Route path="/register" element={<PublicRoute Component={SignUp} />} />
                <Route path="/auth-complete" element={<PublicRoute Component={AuthComplete} />} />
                <Route path="/validation/send" element={user.valid ? <PrivateRoute Component={ValidationSent} /> : <PublicRoute Component={ValidationSent} />} />
                <Route path="/user/verify/email" element={user.valid ? <PrivateRoute Component={EmailChangeStep2} /> : <PublicRoute Component={ValidateEmail} />} />
                <Route path="/register/step2" element={<PrivateRoute Component={SignUpStep2} />} />
                <Route path="/password/recovery" element={<PublicRoute Component={ForgotPassword} />} />
                <Route path="/user/password/reset/email" element={<PublicRoute Component={ResetPassword} />} />
                <Route path="" element={user.admin ? <PrivateRoute Component={Dashboard} /> : <PrivateRoute Component={HomeScreen} />} />
                <Route path="obrazac1" element={<PrivateRoute Component={Form1Screen} />} />
                <Route path="obrazac2" element={<PrivateRoute Component={Form2Screen} />} />
                <Route path="obrazac3" element={<PrivateRoute Component={Form3Screen} />} />
                <Route path="obrazac4" element={<PrivateRoute Component={Form4Screen} />} />
                <Route path="obrazac5/:category" element={<PrivateRoute Component={Form5Screen} />} />
                <Route path="obrazac6" element={<PrivateRoute Component={Form6Screen} />} />
                <Route path="obrazac7/1" element={<PrivateRoute Component={Form7_1Screen} />} />
                <Route path="obrazac7/2" element={<PrivateRoute Component={Form7_2Screen} />} />
                <Route path="obrazac7/3" element={<PrivateRoute Component={Form7_3Screen} />} />

                <Route path="/obrazac7/4-1" element={<PrivateRoute Component={Form7_4_1Screen} />} />
                <Route path="/obrazac7/4-2" element={<PrivateRoute Component={Form7_4_2Screen} />} />
                <Route path="/obrazac7/5" element={<PrivateRoute Component={Form7_5Screen} />} />
                <Route path="/obrazac7/6" element={<PrivateRoute Component={Form7_6Screen} />} />
                <Route path="obrazac7/10" element={<PrivateRoute Component={Form7_10Screen} />} />
                <Route path="obrazac7/11" element={<PrivateRoute Component={Form7_11Screen} />} />
                <Route path="obrazac7/12" element={<PrivateRoute Component={Form7_12Screen} />} />
                <Route path="/obrazac7/13" element={<PrivateRoute Component={Form7_13Screen} />} />
                <Route path="/obrazac7/14-1" element={<PrivateRoute Component={Form7_14_1Screen} />} />
                <Route path="/obrazac7/14-2" element={<PrivateRoute Component={Form7_14_2Screen} />} />
                <Route path="/obrazac7/15" element={<PrivateRoute Component={Form7_15Screen} />} />
                <Route path="/obrazac7/16" element={<PrivateRoute Component={Form7_16Screen} />} />
                <Route path="/obrazac7/17" element={<PrivateRoute Component={Form7_17Screen} />} />
                <Route path="/obrazac7/18" element={<PrivateRoute Component={Form7_18Screen} />} />

                <Route path="/obrazac7/7" element={<PrivateRoute Component={Form7_7Screen} />} />
                <Route path="/obrazac7/8" element={<PrivateRoute Component={Form7_8Screen} />} />
                <Route path="/obrazac7/9" element={<PrivateRoute Component={Form7_9Screen} />} />

                <Route path="anketa" element={<PrivateRoute Component={AnketaScreen} />} />
                <Route path="admin/dashboard" element={user.admin ? <PrivateRoute Component={Dashboard} /> : <PrivateRoute Component={HomeScreen} />} />
                <Route path="admin/analytics" element={<PrivateRoute Component={AnalyticsScreen} />} />
                <Route path="admin/analytics/form1" element={<PrivateRoute Component={Form1AnalyticsScreen} />} />
                <Route path="admin/analytics/form2" element={<PrivateRoute Component={Form2AnalyticsScreen} />} />
                <Route path="admin/analytics/form4" element={<PrivateRoute Component={Form4AnalyticsScreen} />} />

                <Route path="admin/cities" element={user.admin ? <PrivateRoute Component={CityScreen} /> : <PrivateRoute Component={HomeScreen} />} />
                <Route path="admin/messages" element={user.admin ? <PrivateRoute Component={MessageManagement} /> : <PrivateRoute Component={HomeScreen} />} />
                <Route path="admin/legal" element={user.admin ? <PrivateRoute Component={LegalInfo} /> : <PrivateRoute Component={HomeScreen} />} />
                <Route path="admin/categories" element={<PrivateRoute Component={ConnectCategoriesWIthForms} />} />
                <Route path="contact" element={isSignedIn ? <PrivateRoute Component={ContactScreen} /> : <PublicRoute Component={ContactScreen} />} />
                <Route path="admin/users" element={user.admin ? <PrivateRoute Component={UserManagementScreen} /> : <PrivateRoute Component={HomeScreen} />} />
                <Route path="admin/legal-entities" element={user.admin ? <PrivateRoute Component={LegalEntitiesManagementScreen} /> : <PrivateRoute Component={HomeScreen} />} />
                <Route path="admin/legal-entities/details" element={<PrivateRoute Component={LegalEntityDetails} />} />
                <Route path="admin/user" element={user.admin ? <PrivateRoute Component={AdminUser} /> : <PrivateRoute Component={HomeScreen} />} />
                <Route path="admin/help/all" element={user.admin ? <PrivateRoute Component={AdminAllHelp} /> : <PrivateRoute Component={HomeScreen} />} />
                <Route path="admin/categories_table" element={user.admin ? <PrivateRoute Component={CategoriesTable} /> : <PrivateRoute Component={HomeScreen} />} />
                {/* <Route path="admin/help" element={user.admin ? <PrivateRoute Component={AdminHelp} /> : <PrivateRoute Component={HomeScreen} />} /> */}
                <Route path="admin/corporateEntities" element={<PrivateRoute Component={CorporateEntitiesSearch} />} />
                <Route path="user/profile" element={<PrivateRoute Component={UserProfile} />} />
                <Route path="user/details" element={<PrivateRoute Component={UserDetails} />} />
                <Route path="user/password" element={<PrivateRoute Component={ChangePassword} />} />
                <Route path="user/email-change" element={<PrivateRoute Component={EmailChangeStep1} />} />
                <Route path="user/category-selection" element={<PrivateRoute Component={CategorySelectionScreen} />} />
                <Route path="user/update-legal-entity" element={<PrivateRoute Component={UpdateLegalEntity} />} />
            </Route>
            {/* <Route path='*' element={<Redirect Component={LoginScreen} />} /> */}
        </Routes>
    )
}

export default App
