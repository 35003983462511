import React, { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { createMessage } from '../../server/server'
import { useUser } from '../../auth/UseUser'
import { Checkbox } from '@mui/material'
import Loading from '../../components/Common/LoadingSpinner'
import PrivacyAndPolicyModal from '../../components/Common/PrivacyAndPolicyModal'
import TextBoxAutoSize from './TextBoxAutoSize'
import HelpButton from '../../components/Common/HelpButton'

export type MessageInputs = {
    name: string
    email: string
    phone: string
    pib: string
    text: string
    userId: string
    agree?: boolean
}

function ContactScreen() {
    const navigate = useNavigate()
    const { user, setUser } = useUser()
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const regexString = /^\d*$/
    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        control,
        formState: { errors },
    } = useForm<MessageInputs>({
        defaultValues: {
            name: '',
            email: '',
            phone: '',
            pib: '',
            text: '',
            userId: '',
            agree: false,
        },
    })

    useEffect(() => {
        if (user?.id) {
            setValue('userId', user.id)
            setValue('email', user.email)
            setValue('name', user.subjectName)
            setValue('pib', user.pib?.toString())
            setValue('phone', user.phoneNumber)
        }
    }, [user])

    const onSubmit: SubmitHandler<MessageInputs> = async () => {
        setLoading(true)
        const data: any = getValues()
        if (!getValues('userId')) delete data.userId
        delete data.agree
        const resp = await createMessage(data)
        if (resp.status === 201) {
            setMessage(resp.data?.message)
            setLoading(false)
            alert('Успешно сте послали поруку. Наш тим ће Вас контактирати у најкраћем могућем року.')
            setHasError(false)
            navigate(-1)
        } else {
            setHasError(true)
            setMessage(resp.data?.message)
            setLoading(false)
        }
    }
    const closePrivacyModal = () => {
        setOpenModal(false)
    }
    return (
        <div className="items-center justify-center flex mb-[20px] relative">
            <div className="px-[99px] pt-[30px] pb-[10px] bg-[#ffffff] items-start flex flex-col">
                <h2 className="mb-[25px] text-[24px] leading-[28px] font-light max-w-[552px] text-center w-full">Контактирајте нас</h2>
                <form className="flex flex-col items-center w-full min-w-[660px] max-w-[660px] " onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="w-full flex flex-col">
                        <div className="flex flex-col ">
                            <input
                                className="border border-[#0000003B] min-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                                placeholder="Пун назив обвезника примене закона*"
                                {...register('name', {
                                    required: true,
                                    maxLength: 50,
                                })}
                                disabled={user?.id}
                            />
                            <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] mb-[10px]">
                                {errors.name?.type === 'required' ? 'Обавезно попуните ово поље!' : ''}
                                {errors.name?.type === 'maxLength' ? 'Дужина текста је ограничена на 50 карактера' : ''}
                            </span>
                        </div>
                        <div className="flex flex-col ">
                            <input
                                className="border border-[#0000003B] min-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                                placeholder="Електронска адреса*"
                                {...register('email', {
                                    required: true,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'invalid email address',
                                    },
                                })}
                                disabled={user?.id}
                            />
                            <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] mb-[10px]">
                                {errors.email?.type === 'required' ? 'Обавезно попуните ово поље!' : ''}
                                {errors.email?.type === 'pattern' ? 'Електронска адреса није исправно попуњена' : ''}
                            </span>
                        </div>
                        <div className="flex flex-col ">
                            <Controller
                                name="pib"
                                control={control}
                                rules={{ maxLength: 50 }}
                                render={({ field }) => (
                                    <input
                                        className="border border-[#0000003B] min-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                                        placeholder="ПИБ"
                                        value={field.value || ''}
                                        onChange={(event) => {
                                            const newValue = event.target.value
                                            if (regexString.test(newValue)) {
                                                field.onChange(newValue)
                                            } else {
                                                const filteredValue = newValue.replace(/\D/g, '')
                                                field.onChange(filteredValue)
                                            }
                                        }}
                                        disabled={user?.id}
                                    />
                                )}
                            />
                            <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] mb-[10px]">
                                {errors.pib?.type === 'maxLength' ? 'Дужина текста је ограничена на 50 карактера' : ''}
                            </span>
                        </div>
                        <div className="flex flex-col ">
                            <input
                                className="border border-[#0000003B] min-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                                placeholder="Телефон"
                                {...register('phone', {
                                    maxLength: 50,
                                })}
                                disabled={user?.id}
                            />
                            <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] mb-[10px]">
                                {errors.phone?.type === 'maxLength' ? 'Дужина текста је ограничена на 50 карактера' : ''}
                            </span>
                        </div>
                        <div className="">
                            <TextBoxAutoSize
                                name="text"
                                disabled={false}
                                control={control}
                                placeholder="Tекст поруке (2048 карактера)*"
                                maxNumberOfCharacters={2048}
                                errors={errors}
                                isRequired={'Обавезно попуните ово поље!'}
                            />
                        </div>

                        <div className="flex flex-row items-center mt-2 max-w-[660px]">
                            <Controller
                                name={'agree'}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Checkbox
                                        size="large"
                                        onChange={(e) => {
                                            field.onChange(e.target.checked)
                                        }}
                                        sx={{
                                            color: errors.agree ? '#d32f2f' : '#A2CEFF',
                                            '&.Mui-checked': {
                                                color: errors.agree ? '#d32f2f' : '#A2CEFF',
                                            },
                                        }}
                                    />
                                )}
                            />
                            <span className={`text-[14px] leading-[18px] font-light  ${errors.agree && 'text-[#d32f2f]'}`}>
                                Слањем ове форме, сагласан/сагласна сам да Министарство за људска и мањинска права и друштвени дијалог Републике Србије може користити моје податке,
                                укључујући ПИБ, назив организације, имејл адресу, број телефона и садржај поруке, искључиво у сврху пружања подршке и остваривања комуникације.
                                Министарство неће достављати наведене податке трећим лицима, у складу са Законом о заштити података о личности.
                            </span>
                        </div>

                        <span className={`${hasError ? 'text-[#d32f2f]' : ''} max-w-[650px] text-[14px] leading-[16px] text-center min-h-[16px] mt-[15px]`}>{message}</span>

                        <button
                            type="submit"
                            className="w-full h-[42px] flex items-center justify-center rounded-[4px] bg-[#243964] text-[15px] text-[#ffffff] font-medium leading-[26px] cursor-pointer mt-[10px]"
                            disabled={loading}
                        >
                            {!loading ? 'ПОШАЉИ' : <Loading height={25} />}
                        </button>
                    </div>
                </form>
            </div>
            <div className="absolute top-5 right-10 ">
                <HelpButton name="contact_form_help" />
            </div>
            <PrivacyAndPolicyModal modalIsOpen={openModal} closeModal={closePrivacyModal} infoText={''} />
        </div>
    )
}

export default ContactScreen
