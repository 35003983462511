import { useEffect } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import Textbox from '../../../components/Common/Textbox'
import { TblOblast5, YESNO } from '../../../models/Form5/Form5.entity'
import ActionButtons from '../../../components/ActionButtons'
import 'dayjs/locale/sr-cyrl'
import RadioButtonsControl from '../../../components/Common/RadioButtonsControl'
import PlanTable from '../Components/PlanTable'
import { Button } from '@mui/material'
import HiddenDatePicker from '../../../components/Common/DatePicker'
import dayjs from 'dayjs'
import { StepInterface } from './Step1'

function Step3({ control, nextStep, previousStep, previousButtonDisabled = false, setValue, isParty, errors, trigger, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast5>({ control })
    const fields: ('o5_k3_r1' | 'o5_k3_submitted_date' | 'o5_k3_r2' | 'o5_k3_link')[] = ['o5_k3_r1', 'o5_k3_submitted_date', 'o5_k3_r2', 'o5_k3_link']

    const fieldsForValidate: ('o5_k3_r1' | 'o5_k3_submitted_date' | 'o5_k3_r2' | 'o5_k3_link')[] = ['o5_k3_r1']

    const {
        fields: tableArrayFields,
        replace,
        remove,
        append,
    } = useFieldArray({
        control,
        name: 'o5_k4_mere',
    })

    const disabledNext =
        !fieldsForValidate?.every((e) => (e in watchedValues ? !!watchedValues[e] : false)) ||
        (watchedValues['o5_k3_r1'] === 'Да' && !watchedValues['o5_k3_submitted_date']) ||
        (watchedValues['o5_k3_r2'] === 'Да' && !watchedValues['o5_k3_link']) ||
        (watchedValues['o5_k3_r1'] === 'Да' && watchedValues.o5_k4_mere?.map((field) => field.text_content != '').includes(false) ? true : false)

    const disableDateButton = () => {
        if (watchedValues['o5_k3_r1'] === YESNO.Da || !adminView) return false
        else return true
    }

    const disableTextLink = () => {
        if (watchedValues['o5_k3_r2'] === YESNO.Da) return false
        else return true
    }

    const validateFields = (): boolean => {
        const newMap = watchedValues.o5_k4_mere?.map((field) => field.text_content != '')
        return newMap?.includes(false) ? true : false
    }
    const disabledAddMore = validateFields()

    useEffect(() => {
        if (watchedValues['o5_k3_r1'] === YESNO.Ne) {
            setValue('o5_k3_submitted_date', null, { shouldValidate: true })
            setValue('o5_k3_r2', undefined, { shouldValidate: true })
            setValue('o5_k3_link', '', { shouldValidate: true })
            replace([{ text_content: '' }])
            trigger('o5_k4_mere')
        }
    }, [watchedValues['o5_k3_r1']])

    useEffect(() => {
        if (watchedValues['o5_k3_r2'] === YESNO.Ne) setValue('o5_k3_link', '', { shouldValidate: true })
    }, [watchedValues['o5_k3_r2']])

    const handleAddField = () => {
        append({ text_content: '' })
    }
    useEffect(() => {
        if (!tableArrayFields.length) append([{ text_content: '' }])
    }, [])

    const goToNexStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }

    const checkDisabled = () => {
        return watchedValues['o5_k3_r1'] === YESNO.Ne || watchedValues['o5_k3_r1'] === undefined || adminView
    }

    return (
        <div>
            <div className="flex flex-col">
                <div>
                    <RadioButtonsControl
                        control={control}
                        name={fields[0]}
                        options={[YESNO.Da, YESNO.Ne]}
                        errors={errors}
                        label={
                            isParty
                                ? 'Да ли је и када донет План деловања (који садржи посебне мере за подстицање и унапређивање родне равноправности и уравнотежене заступљености полова у органима ваше политичке странке и обезбеђивање активног учешћа мање заступљеног пола у саставу и раду тих органа)'
                                : ' Да ли је и када донет План деловања (који садржи посебне мере за подстицање и унапређивање родне равноправности и уравнотежене заступљености полова у органима ваше синдикалне организације и обезбеђивање активног учешћа мање заступљеног пола у саставу и раду тих органа)'
                        }
                        isDisabled={adminView}
                    />
                </div>

                <div className="mt-5">
                    <h4 className="mb-[10px] text-[18px] leading-6 text-textPrimary ">Уколико је одговор Да, унесите датум доношења Плана деловања </h4>
                    <div className="mt-5">
                        {/* <SimpleDatePicker
                            control={control}
                            disableDateButton={disableDateButton}
                            name={'o5_k3_submitted_date'}
                            errors={errors}
                            isRequired={disableDateButton() ? false : 'Попуните обавезно поље.'}
                        /> */}
                        <div className="flex flex-row gap-[10px] h-[50px]">
                            <HiddenDatePicker
                                name={`o5_k3_submitted_date`}
                                value={watchedValues?.o5_k3_submitted_date}
                                control={control}
                                errors={errors}
                                isRequired={checkDisabled() ? false : true}
                                lastStep
                                disabled={checkDisabled()}
                                maxDate={dayjs()}
                                isFirst={true}
                                o5
                            />
                        </div>
                    </div>
                </div>

                <div className="mt-[35px]">
                    <RadioButtonsControl
                        control={control}
                        name={fields[2]}
                        options={[YESNO.Da, YESNO.Ne]}
                        errors={errors}
                        isDisabled={checkDisabled()}
                        isRequired={checkDisabled() ? false : 'Изаберите једну од понуђених опција.'}
                        label="Да ли је План деловања јавно објављен и уколико јесте, уписати на којем линку се може преузети"
                    />
                </div>
                <div className="mt-[30px]">
                    <h4 className="mb-[10px] text-[18px] leading-6 text-textPrimary">Уколико је одговор Да, молим Вас унесите линк на којем се може преузети објављени план:</h4>
                    <Textbox
                        name="o5_k3_link"
                        disabled={disableTextLink()}
                        control={control}
                        placeholder="Tекст (200 карактера)"
                        maxNumberOfCharacters={200}
                        errors={errors}
                        isRequired={disableTextLink() || disableDateButton() ? false : 'Попуните обавезно поље.'}
                    />
                </div>
                <div className="mt-[30px]">
                    <h1 className="text-[18px] leading-6 text-textPrimary ">Које посебне мере садржe План деловања</h1>
                    <PlanTable control={control} errors={errors} fields={tableArrayFields} remove={remove} disabled={checkDisabled()} />
                    <p className="ml-5 min-h-[30px] text-textError text-[0.75rem] text leading-[30px]">{errors['o5_k4_mere'] ? 'Попуните обавезно поље.' : ''}</p>
                    <Button variant="contained" onClick={handleAddField} disabled={disabledAddMore} style={{ marginTop: 20 }}>
                        Додај меру
                    </Button>
                </div>
            </div>
            <ActionButtons nextButton={goToNexStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step3
