import React, { useState } from 'react'
import { StepInterface } from './Step1'
import { useFieldArray, useWatch } from 'react-hook-form'
import { Form7_4_1Entity } from '../../../../../models/Form7/Entities/Form7_4.entity'
import HelpButton from '../../../../../components/Common/HelpButton'
import { getPercentage } from '../../../../../utils/mathUtils'
import TextInputCell from '../../../../../components/Common/TextInputCell'
import { Button } from '@mui/material'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import ActionButtons from '../../../../../components/ActionButtons'
import { ReactComponent as Delete } from '../../../../../assets/svg/Delete.svg'

const Step6 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_4_1Entity>({ control })
    const [emptyArrayError, setEmptyArrayError] = useState(false)
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o7_4_1_k6_sektori',
    })
    const name = 'o7_4_1_k6_sektori'

    let disabledNext = watchedValues[name]?.length === 0

    fields.forEach((item, index) => {
        const { femaleNumber, maleNumber, level } = watchedValues[name]?.[index] || {}

        if (femaleNumber === undefined || femaleNumber === null || femaleNumber?.toString() === '') {
            disabledNext = true
        } else if (maleNumber === undefined || maleNumber === null || maleNumber?.toString() === '') {
            disabledNext = true
        } else if (level === undefined || level === null || level === '') {
            disabledNext = true
        } else disabledNext = false
    })

    const header = ['Сектор', 'Профитабилност жене %', 'Профитабилност мушкарци %', 'Разлика М-Ж']

    const handleAddArrayField = () => {
        setEmptyArrayError(false)
        append({ level: '', maleNumber: undefined, femaleNumber: undefined })
    }

    const validateEmptyArray = () => {
        if (watchedValues[name]?.length === 0 || watchedValues[name] === undefined) {
            setEmptyArrayError(true)
            return true
        }
        return false
    }

    const goToNextStep = () => {
        if (!disabledNext && !validateEmptyArray()) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <div className="flex flex-row justify-between">
                <h1>Стопа профитабилности предузећа у већинском власништву жена по секторима</h1>
                <HelpButton name={'o7_4_1_k6'} marginTop={0} />
            </div>
            <table className="multiple-input-table mt-[20px]">
                <tr className="multiple-input-table-header">
                    {header.map((head, index) => (
                        <th className={`multiple-input-table-cell ${index === 0 && '!min-w-[920px]'}`} key={index}>
                            {head}
                        </th>
                    ))}
                </tr>
                {fields.map((field, index) => {
                    const femaleValue = Number(watchedValues[name]?.[index]?.femaleNumber) || 0
                    const maleValue = Number(watchedValues[name]?.[index]?.maleNumber) || 0
                    const total = maleValue - femaleValue
                    return (
                        <div className="flex flex-row" key={index}>
                            <tr key={field.id} className="multiple-input-table-row">
                                <td className={`multiple-input-table-cell !border-none !min-w-[920px]`}>
                                    <TextInputCell
                                        name={`${name}.${index}.level`}
                                        control={control}
                                        placeholder="Назив сектора (100 карактера)*"
                                        errors={errors}
                                        isRequired={!adminView}
                                        maxNumberOfCharacter={100}
                                        height={62}
                                        disabled={adminView}
                                    />
                                </td>
                                <td className="multiple-input-table-cell editable !border-none">
                                    <PositiveNumberInputCell
                                        name={`${name}.${index}.femaleNumber`}
                                        control={control}
                                        errors={errors}
                                        isRequired={!adminView}
                                        height={62}
                                        isArray={true}
                                        disabled={adminView}
                                        precentage={true}
                                        isMultipleTable
                                        decimal
                                        form7
                                        clearErrors={clearErrors}
                                    />
                                </td>
                                <td className="multiple-input-table-cell editable !border-none">
                                    <PositiveNumberInputCell
                                        name={`${name}.${index}.maleNumber`}
                                        control={control}
                                        precentage={true}
                                        errors={errors}
                                        isRequired={!adminView}
                                        height={62}
                                        isArray={true}
                                        disabled={adminView}
                                        isMultipleTable
                                        decimal
                                        form7
                                        clearErrors={clearErrors}
                                    />
                                </td>
                                <td className="multiple-input-table-cell disabled">{total !== undefined && total !== null ? total.toFixed(2) : '0-100'}%</td>
                            </tr>
                            {fields.length >= 2 ? (
                                <div className="ml-[20px] flex justify-center items-center">
                                    <button onClick={() => remove(index)} disabled={adminView}>
                                        <Delete />
                                    </button>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    )
                })}
                <div className="h-[2px]"> {errors[name] && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            </table>

            <div className="flex flex-col mt-[56px]">
                <Button variant="contained" className="max-w-[172px] mt-[56px]" onClick={handleAddArrayField} disabled={adminView}>
                    Додај сектор
                </Button>
                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError ? 'Додајте барем једну активност!' : ''}</span>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step6
