import '../../../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../../../components/ActionButtons'
import { Control, useWatch } from 'react-hook-form'
import Textbox from '../../../../Contact/TextBoxAutoSize'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { Form7_16Entity } from '../../../../../models/Form7/Entities/Form7_16.entity'
import { StepInterface } from './Step1'
import SimpleTable from '../../../../../components/Tables/SimpleTable'

export interface RowInterface {
    label: string
    field: keyof Form7_16Entity
}

const rows1: RowInterface[] = [
    { label: 'Мушкарци', field: 'o7_16_k3_t1_m' },
    { label: 'Жене', field: 'o7_16_k3_t1_z' },
]
const fields1: ('o7_16_k3_t1_m' | 'o7_16_k3_t1_z')[] = ['o7_16_k3_t1_m', 'o7_16_k3_t1_z']

const rows2: RowInterface[] = [
    { label: 'Мушкарци', field: 'o7_16_k3_t2_m' },
    { label: 'Жене', field: 'o7_16_k3_t2_z' },
]
const fields2: ('o7_16_k3_t2_m' | 'o7_16_k3_t2_z')[] = ['o7_16_k3_t2_m', 'o7_16_k3_t2_z']

export function Step3({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_16Entity>({ control })

    const disabledNext =
        !fields1.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '') ||
        !fields2.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name mb-[0px] ">Број именованих особа од поверења у вашој институцији разврстаних по полу</h1>
            <div className="flex flex-row gap-2 mb-[20px]">
                <SimpleTable control={control} header={['Пол', 'Број', 'Проценат']} rows={rows1} fields={fields1} errors={errors} disabled={adminView} />
            </div>

            <h1 className="step-name mb-[0px] ">Број чланова аналитичких група или истраживачких тимова у вашој институцији разврстаних по полу </h1>
            <div className="flex flex-row gap-2 mb-[20px]">
                <SimpleTable control={control} header={['Пол', 'Број', 'Проценат']} rows={rows2} fields={fields2} errors={errors} disabled={adminView} />
            </div>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
