import React from 'react'

type SortArrowProps = {
    sortField: string
    currentField: string
    sortOrder: 'ASC' | 'DESC' | null | string
    isActive: boolean
    colorActive?: string
    colorInactive?: string
}

const SortArrow: React.FC<SortArrowProps> = ({ sortField, currentField, sortOrder, isActive, colorActive = '#000000', colorInactive = '#d3d3d3' }) => {
    const arrowClasses = isActive ? 'text-black' : 'text-gray-400' // Apply gray color when inactive
    const activeFillColor = isActive ? colorActive : colorInactive // Set fill color based on isActive

    return (
        <div className="flex flex-col items-center justify-center cursor-pointer">
            <svg className={`w-5 h-5 ${arrowClasses}`} fill={sortOrder === 'ASC' ? activeFillColor : colorInactive} viewBox="-96 0 512 512" style={{ marginBottom: '-6px' }}>
                <path d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z" />
            </svg>
            <svg
                className={`w-5 h-5 ${arrowClasses} rotate-180`}
                fill={sortOrder === 'DESC' ? activeFillColor : colorInactive}
                viewBox="-96 0 512 512"
                style={{ marginTop: '-6px' }}
            >
                <path d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z" />
            </svg>
        </div>
    )
}

export default SortArrow
