import { TblOblast4 } from '../../../models/Form4/Form4.entity'
import '../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import Textbox from '../../../components/Common/Textbox'
import PositiveNumberInputCell from '../../Form2/Components/PositiveNumberInputCell'
import { StepInterface } from './Step1'

function Step7({ control, nextStep, previousStep, previousButtonDisabled = false, errors, clearErrors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast4>({ control })
    const disabledNext = watchedValues?.o4_k7_procenat === undefined || watchedValues?.o4_k7_obrazlozenje?.length === 0 || typeof watchedValues.o4_k7_procenat !== 'number'

    const goToNextStep = () => {
        if (!disabledNext && !errors?.o4_k7_procenat) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name mb-[20px] ">Проценат реализованог буџета на годишњем нивоу са становишта родне перспективе</h1>
            <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                <PositiveNumberInputCell
                    name="o4_k7_procenat"
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    height={52}
                    max={100}
                    precentage={true}
                    decimal
                    clearErrors={clearErrors}
                    disabled={adminView}
                />
            </div>
            {errors?.o4_k7_procenat?.type === 'max' && <p className="min-h-5 text-[#d32f2f] text-[0.75rem] text leading-5 ">Број не може да буде већи од 100</p>}
            <h1 className="step-name mb-[10px] mt-[40px] opacity-[60%]">Образложење:</h1>
            <Textbox
                name="o4_k7_obrazlozenje"
                control={control}
                maxNumberOfCharacters={2048}
                errors={errors}
                isRequired={adminView ? false : 'Попуните обавезно поље.'}
                disabled={adminView}
            />
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step7
