import '../../../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../../../components/ActionButtons'
import { Control, useWatch } from 'react-hook-form'
import Textbox from '../../../../Contact/TextBoxAutoSize'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { Form7_16Entity } from '../../../../../models/Form7/Entities/Form7_16.entity'
import { StepInterface } from './Step1'
import SimpleTable from '../../../../../components/Tables/SimpleTable'
import { useEffect, useState } from 'react'

export function Step6({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_16Entity>({ control })

    const [percentageNAP, setPercentageNAP] = useState<number | null>(null)
    const [percentageOCD, setPercentageOCD] = useState<number | null>(null)

    const calculatePercentage = (value: number, total: number) => {
        if (total === 0) return 0
        return Math.round((value / total) * 10000) / 100
    }

    useEffect(() => {
        if (watchedValues.o7_16_k6_budzet && watchedValues.o7_16_k6_sredstvaNAP) {
            setPercentageNAP(calculatePercentage(watchedValues.o7_16_k6_sredstvaNAP, watchedValues.o7_16_k6_budzet))
        } else {
            setPercentageNAP(null)
        }
        if (watchedValues.o7_16_k6_budzet && watchedValues.o7_16_k6_sredstvaOCD) {
            setPercentageOCD(calculatePercentage(watchedValues.o7_16_k6_sredstvaOCD, watchedValues.o7_16_k6_budzet))
        } else {
            setPercentageOCD(null)
        }
    }, [watchedValues.o7_16_k6_budzet, watchedValues.o7_16_k6_sredstvaNAP, watchedValues.o7_16_k6_sredstvaOCD])

    const fields: ('o7_16_k6_budzet' | 'o7_16_k6_sredstvaNAP' | 'o7_16_k6_sredstvaOCD' | 'o7_16_k6_brojOCD')[] = [
        'o7_16_k6_budzet',
        'o7_16_k6_sredstvaNAP',
        'o7_16_k6_sredstvaOCD',
        'o7_16_k6_brojOCD',
    ]

    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')
    const goToNextStep = () => {
        if (!disabledNext && !errors?.o7_16_k4_brojObuka) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name mb-[20px] ">
                Износ/проценат средстава намењен финансирању примене НАП 1325 у буџетима на свим нивоима власти, као и проценат средстава који се издваја за финансирање услуга које
                пружају ОЦД:
            </h1>
            <h1 className="step-name mb-[20px] ">Укупан износ буџета</h1>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell name="o7_16_k6_budzet" control={control} errors={errors} isRequired={!adminView} height={52} disabled={adminView} decimal />
                </div>
            </div>
            <h1 className="step-name mb-[20px] ">Износ средстава намењен за НАП 1325</h1>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell name="o7_16_k6_sredstvaNAP" control={control} errors={errors} isRequired={!adminView} height={52} disabled={adminView} decimal />
                </div>
            </div>
            <h1 className="step-name mb-[20px] ">Проценат средстава намењен за НАП 1325</h1>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell
                        name=""
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        height={52}
                        disabled={true}
                        precentage
                        decimal
                        placeholder={percentageNAP !== null ? percentageNAP.toString() : ''}
                    />
                </div>
            </div>
            <h1 className="step-name mb-[20px] ">Износ средстава намењен за сарадњу са ОЦД</h1>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell name="o7_16_k6_sredstvaOCD" control={control} errors={errors} isRequired={!adminView} height={52} disabled={adminView} decimal />
                </div>
            </div>
            <h1 className="step-name mb-[20px] ">Проценат средстава за сарадњу са ОЦД</h1>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell
                        name=""
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        height={52}
                        disabled={true}
                        precentage
                        decimal
                        placeholder={percentageOCD !== null ? percentageOCD.toString() : ''}
                    />
                </div>
            </div>
            <h1 className="step-name mb-[20px] ">Број ОЦД са којима сарађујете у креирању и примени активности садржаних у НАП 1325 </h1>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell name="o7_16_k6_brojOCD" control={control} errors={errors} isRequired={!adminView} height={52} disabled={adminView} decimal />
                </div>
            </div>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
