import React, { useEffect } from 'react'
import { StepInterface } from './Step1'
import { TblOblast2 } from '../../../../models/Form2/Form2.entity'
import { useWatch } from 'react-hook-form'
import ActionButtons from '../../../../components/ActionButtons'
import ComplexTable from '../../../../components/Tables/ComplexTable'
import { getAnalyticsDataForForm2 } from '../../../../server/server'

interface RowInterface {
    label: string
    fields: (keyof TblOblast2)[]
}

const rows: RowInterface[] = [
    { label: 'Од 15-30 година живота', fields: ['o2_k2_z_15_30', 'o2_k2_m_15_30'] },
    { label: 'Од 31-40 година живота', fields: ['o2_k2_z_31_40', 'o2_k2_m_31_40'] },
    { label: 'Од 41-50 година живота', fields: ['o2_k2_z_41_50', 'o2_k2_m_41_50'] },
    { label: 'Од 51-60 година живота', fields: ['o2_k2_z_51_60', 'o2_k2_m_51_60'] },
    { label: 'Од 61-70 година живота и више', fields: ['o2_k2_z_61_70', 'o2_k2_m_61_70'] },
]

const allFields = [
    'o2_k2_m_15_30',
    'o2_k2_z_15_30',
    'o2_k2_m_31_40',
    'o2_k2_z_31_40',
    'o2_k2_m_41_50',
    'o2_k2_z_41_50',
    'o2_k2_m_51_60',
    'o2_k2_z_51_60',
    'o2_k2_m_61_70',
    'o2_k2_z_61_70',
] as const

const allMaleFields = [...allFields.filter((e) => e.includes('_m_'))] as const
const allFemaleFields = [...allFields.filter((e) => e.includes('_z_'))] as const

type AllFieldsType = (typeof allFields)[number]
const Step2 = ({
    control,
    nextStep,
    previousStep,
    previousButtonDisabled = false,
    errors,
    adminView,
    countType,
    setValue,
    selectedCategories,
    selectedSubCategories,
}: StepInterface) => {
    const fields: AllFieldsType[] = [...allFields]

    useEffect(() => {
        loadData()
    }, [countType, selectedCategories, selectedSubCategories])

    const loadData = async () => {
        const data = {
            type: countType,
            fields: fields,
            category: selectedCategories?.map((e) => e.name),
            subCategory: selectedSubCategories?.map((e) => e.name),
        }
        const resp = await getAnalyticsDataForForm2(data)
        if (resp.status === 200) {
            const data = resp.data
            fields.forEach((field) => {
                setValue(field, parseFloat(data[field]))
            })
        }
    }
    return (
        <div>
            <h1 className="step-name">Старосна структура свих чланова/чланица тела за родну равноправност</h1>
            <div className="flex flex-row gap-2 ">
                <ComplexTable
                    rows={rows}
                    control={control}
                    allFemaleFields={[...allFemaleFields]}
                    allMaleFields={[...allMaleFields]}
                    allFields={[...allFields]}
                    header={['Старост', 'Жене', 'Жене%', 'Мушкарци', 'Мушкарци%', 'Укупно', 'Укупно%']}
                    errors={errors}
                    disabled={true}
                />
            </div>
            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step2
