import { Organization } from '../Organization.entity'
import { SubmittedBy } from '../SubmittedBy.entity'
import { TextDescription } from '../TextDescription.entity'

export const NumberOfSteps = 9
export type TblOblast6Keys = keyof TblOblast6

export interface TblOblast6 {
    report_id?: string
    rep_year?: number // year
    submitted_date?: Date
    submitted_place?: string
    category?: string // category
    organization?: Organization
    status?: number // rep_status

    // Korak 1
    o6_k1_broj_plana?: string
    o6_k1_datum?: Date
    o6_k1_mesto?: string
    o6_k1_plan_file?: File[]

    // Korak 2
    o6_k2_t1?: string | null
    o6_k2_t2?: string | null

    // Korak 3
    o6_k3_rizici?: Risk[]

    // Korak 4
    o6_k4_t1?: string | null
    o6_k4_t2?: string | null
    o6_k4_t3?: string | null
    o6_k4_t4?: string | null
    o6_k4_t5?: string | null
    o6_k4_t6?: string | null

    // Korak 5
    o6_k5_mere?: TextDescription[]

    // Korak 6
    o6_k6_informacije?: TextDescription[]

    // Korak 7
    o6_k7_razlozi?: TextDescription[]

    // Korak 8
    submitted_by?: SubmittedBy
}
export interface Risk {
    id?: string
    text?: string
    tezina?: string
    verovatnoca?: ProbabilityEnum
    uticaj?: ImpactEnum
    nivoRizika?: RiskLevelEnum
}

export enum ProbabilityEnum {
    Zanemarljiva = 'Занемарљива',
    Mala = 'Мала',
    Srednja = 'Средња',
    Velika = 'Велика',
    'Izrazito velika' = 'Изразито велика',
}

export enum ImpactEnum {
    Minimalna = 'Минимална',
    Mala = 'Мала',
    Umerena = 'Умерена',
    Ozbiljna = 'Озбиљна',
    Katastrofalna = 'Катастрофална',
}

export enum RiskLevelEnum {
    Nizak = 'Низак',
    Umereni = 'Умерени',
    Visok = 'Висок',
    'Veoma visok' = 'Веома висок',
}
