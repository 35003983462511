import React from 'react'
import image1 from '../../../../assets/images/FormTutorialImages/1.jpg'
import image2 from '../../../../assets/images/FormTutorialImages/2.png'
import image3 from '../../../../assets/images/FormTutorialImages/3.png'
import image4 from '../../../../assets/images/FormTutorialImages/4.png'
import image5 from '../../../../assets/images/FormTutorialImages/5.png'
import image6 from '../../../../assets/images/FormTutorialImages/6.png'
import image7 from '../../../../assets/images/FormTutorialImages/7.png'
import Actions from '../../Components/Actions'
import { TutorialStepInterface } from './Step1'

const Step2 = ({ nextStep, previousStep, previousButtonDisabled = false, skipClick, onClose }: TutorialStepInterface) => {
    return (
        <div className="flex h-full w-full flex-col items-center">
            <div className="relative w-full h-full">
                <img src={image1} alt="Образац слика 1" width={1000} className="w-full" />
                <img src={image2} alt="Образац слика 2" width={300} className="absolute right-[200px] top-[250px] z-50" />
            </div>
            <div className="mt-10 gap-10 items-start w-full">
                <div className="flex flex-col ml-[100px] gap-6">
                    <div className="flex flex-row items-center gap-4">
                        <img src={image3} alt="Образац слика 3" width={200} />
                        <p className="text-[16px]">Aктивни корак на тренутној страници.</p>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                        <div className="flex gap-2 flex-col">
                            <img src={image4} alt="Образац слика 4" width={200} />
                            <img src={image5} alt="Образац слика 5" width={200} />
                        </div>
                        <p className="text-[16px] max-w-[700px]">
                            Обавезна поља која су обележена звездицом у која треба унети вредност, уколико не унесете вредност, поља су црвене боје и не можете прећи на следећи
                            корак.
                        </p>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                        <img src={image6} alt="Образац слика 6" width={200} />
                        <p className="text-[16px]">Поља која су обележена сивом бојом се не попуњавају.</p>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                        <img src={image7} alt="Образац слика 7" width={200} />
                        <p className="text-[16px] max-w-[700px]">
                            Уколико сте унели вредности у сва обавезна поља и кликнете на поменуто дугме, подаци су сачувани и прелазите на следећи корак.{' '}
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-10 w-full max-w-[70%]">
                <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={false} skipButton={skipClick} closeModal={onClose} />
            </div>
        </div>
    )
}

export default Step2
