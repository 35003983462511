import { Modal, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TutorialRegister from './TutorialSegments/TutorialRegister'
import { ReactComponent as CloseFilled } from '../../assets/svg/CloseFilled.svg'
import TutorialDashboard from './TutorialSegments/TutorialDashboard'
import TutorialForm from './TutorialSegments/TutorialForm'

interface TutorialInterface {
    segment: string
    open: boolean
    onClose: () => void
}

const TutorialModal = ({ segment, open, onClose }: TutorialInterface) => {
    const [isSkipped, setIsSkipped] = useState<boolean>(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        const tutorialSkipped = localStorage.getItem(`tutorialSkipped-${segment}`)
        if (tutorialSkipped === 'true') {
            setIsSkipped(true)
        }
        setLoading(false)
    }, [segment])

    const skipClick = () => {
        localStorage.setItem(`tutorialSkipped-${segment}`, 'true')
    }

    if (isSkipped || loading) return null

    return (
        <Modal open={open} onClose={onClose}>
            <div
                className={`flex flex-col outline-none absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4 bg-white w-auto max-w-[90%] max-h-[90%] min-w-[60%] p-5 shadow-lg rounded-lg overflow-hidden`}
            >
                <Button
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '30px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        fontSize: '16px',
                        cursor: 'pointer',
                        color: 'black',
                    }}
                >
                    <CloseFilled />
                </Button>
                <div className="flex-1 overflow-auto">{segment === 'login' && <TutorialRegister skipClick={skipClick} onClose={onClose} />}</div>
                <div className="flex-1 overflow-auto">{segment === 'home' && <TutorialDashboard skipClick={skipClick} onClose={onClose} />}</div>
                <div className="flex-1 overflow-auto">{segment === 'form' && <TutorialForm skipClick={skipClick} onClose={onClose} />}</div>
            </div>
        </Modal>
    )
}

export default TutorialModal
