import React from 'react'
import { Form7_1Entity } from '../../../../../models/Form7/Entities/Form7_1.entity'
import { StepInterface } from './Step1'
import { useWatch } from 'react-hook-form'
import ComplexTable from '../../../../../components/Tables/ComplexTable'
import ActionButtons from '../../../../../components/ActionButtons'

interface RowInterface {
    label: string
    fields: (keyof Form7_1Entity)[]
}

const rows: RowInterface[] = [
    { label: 'Од 15-19 година живота', fields: ['o7_1_k2_z_15_19', 'o7_1_k2_m_15_19'] },
    { label: 'Од 20-24 година живота', fields: ['o7_1_k2_z_20_24', 'o7_1_k2_m_20_24'] },
    { label: 'Од 25-29 година живота', fields: ['o7_1_k2_z_25_29', 'o7_1_k2_m_25_29'] },
    { label: 'Од 30-34 година живота', fields: ['o7_1_k2_z_30_34', 'o7_1_k2_m_30_34'] },
    { label: 'Од 35-39 година живота', fields: ['o7_1_k2_z_35_39', 'o7_1_k2_m_35_39'] },
    { label: 'Од 40-44 година живота', fields: ['o7_1_k2_z_40_44', 'o7_1_k2_m_40_44'] },
    { label: 'Од 45-49 година живота', fields: ['o7_1_k2_z_45_49', 'o7_1_k2_m_45_49'] },
    { label: 'Од 50-54 година живота', fields: ['o7_1_k2_z_50_54', 'o7_1_k2_m_50_54'] },
    { label: 'Од 55-59 година живота', fields: ['o7_1_k2_z_55_59', 'o7_1_k2_m_55_59'] },
    { label: 'Од 60-64 година живота', fields: ['o7_1_k2_z_60_64', 'o7_1_k2_m_60_64'] },
    { label: 'Од 65-69 година живота', fields: ['o7_1_k2_z_65_69', 'o7_1_k2_m_65_69'] },
    { label: 'Од 70-74 година живота', fields: ['o7_1_k2_z_70_74', 'o7_1_k2_m_70_74'] },
    { label: 'Од 75 година живота и више', fields: ['o7_1_k2_z_75', 'o7_1_k2_m_75'] },
]

const allFields = [
    'o7_1_k2_z_15_19',
    'o7_1_k2_m_15_19',
    'o7_1_k2_z_20_24',
    'o7_1_k2_m_20_24',
    'o7_1_k2_z_25_29',
    'o7_1_k2_m_25_29',
    'o7_1_k2_z_30_34',
    'o7_1_k2_m_30_34',
    'o7_1_k2_z_35_39',
    'o7_1_k2_m_35_39',

    'o7_1_k2_z_40_44',
    'o7_1_k2_m_40_44',
    'o7_1_k2_z_45_49',
    'o7_1_k2_m_45_49',
    'o7_1_k2_z_50_54',
    'o7_1_k2_m_50_54',
    'o7_1_k2_z_55_59',
    'o7_1_k2_m_55_59',
    'o7_1_k2_z_60_64',
    'o7_1_k2_m_60_64',

    'o7_1_k2_z_65_69',
    'o7_1_k2_m_65_69',
    'o7_1_k2_z_70_74',
    'o7_1_k2_m_70_74',
    'o7_1_k2_z_75',
    'o7_1_k2_m_75',
] as const

const allMaleFields = [...allFields.filter((e) => e.includes('_m_'))] as const
const allFemaleFields = [...allFields.filter((e) => e.includes('_z_'))] as const

type AllFieldsType = (typeof allFields)[number]

function Step2({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_1Entity>({ control })
    const fields: AllFieldsType[] = [...allFields]

    const disabledNext = !fields.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">2. Активно становништвo према старосним групама и полу:</h1>
            <ComplexTable
                rows={rows}
                control={control}
                allFemaleFields={[...allFemaleFields]}
                allMaleFields={[...allMaleFields]}
                allFields={[...allFields]}
                header={['Старосна доб', 'Жене', 'Жене %', 'Мушкарци', 'Мушкарци %', 'Укупно', 'Укупно %']}
                errors={errors}
                disabled={adminView}
                decimal
            />
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step2
