import { useState } from 'react'
import { StepInterfaceObl2 } from './Step1'
import { useFieldArray, useWatch } from 'react-hook-form'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import { Button } from '@mui/material'
import HiddenDatePicker from '../../../components/Common/DatePicker'
import ActionButtons from '../../../components/ActionButtons'
import TextInputCell from '../../../components/Common/TextInputCell'

const Step14 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterfaceObl2) => {
    const watchedValues = useWatch<TblOblast2>({ control })
    const [emptyArrayError, setEmptyArrayError] = useState(false)
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o2_k14_realisations',
    })
    const arrayName = 'o2_k14_realisations'

    let disabledNext = watchedValues[arrayName]?.length === 0

    fields.forEach((item, index) => {
        const { name, from, to, indicator } = watchedValues[arrayName]?.[index] || {}

        if (!name || !from || !to || !indicator) {
            disabledNext = true
            return
        }
    })

    const handleAddArrayField = () => {
        setEmptyArrayError(false)
        append({ name: '', from: null, to: null, indicator: '' })
    }

    const validateEmptyArray = () => {
        if (watchedValues[arrayName]?.length === 0 || watchedValues[arrayName] === undefined) {
            setEmptyArrayError(true)
            return true
        }
        return false
    }

    const goToNextStep = () => {
        if (!disabledNext && !validateEmptyArray()) nextStep()
        else if (adminView) nextStep()
    }

    const header = ['Редни број', 'Назив активности', 'Период реализације од', 'Период реализације до', 'Индикатор резултата']
    return (
        <div>
            <h1 className="step-name">
                Број и назив активности тела за родну равноправност реализованих у вези случајева насиља по основу пола, односно рода, у години вођења евиденције
            </h1>
            <table className="multiple-input-table mt-[20px] !w-full">
                <tr className="multiple-input-table-header !w-[90%]">
                    {header.map((head, index) => (
                        <th className={`multiple-input-table-cell ${index === 1 || index === 4 ? '!w-full' : '!min-w-[100px]'}`} key={index}>
                            {head}
                        </th>
                    ))}
                </tr>
                {fields.map((field, index) => {
                    return (
                        <div className="flex flex-row !w-full">
                            <tr key={field.id} className="multiple-input-table-row !w-[90%]">
                                <td className="multiple-input-table-cell disabled !min-w-[100px]" style={{ border: 'none' }}>
                                    {index + 1}
                                </td>
                                <td className="multiple-input-table-cell !border-none !w-full" style={{ border: 'none' }}>
                                    {/* <Textbox name={`${name}.${index}.activityName`} control={control} placeholder="(Текст,50 карактера)*" /> */}
                                    <TextInputCell
                                        name={`${arrayName}.${index}.name`}
                                        control={control}
                                        placeholder="Текст (50 карактера)*"
                                        errors={errors}
                                        isRequired={!adminView}
                                        maxNumberOfCharacter={50}
                                        height={80}
                                        isMultipleTable={true}
                                        disabled={adminView}
                                    />
                                </td>
                                <td className="multiple-input-table-cell editable !min-w-[100px]" style={{ border: 'none' }}>
                                    <HiddenDatePicker
                                        tabIndex={0}
                                        name={`${arrayName}.${index}.from`}
                                        value={watchedValues[arrayName]?.[index]?.from}
                                        control={control}
                                        errors={errors}
                                        isRequired={!adminView}
                                        isArray={true}
                                        isFirst={true}
                                        maxDate={watchedValues && watchedValues[arrayName]?.[index]?.to}
                                        disabled={adminView}
                                    />
                                </td>
                                <td className="multiple-input-table-cell editable !min-w-[100px]" style={{ border: 'none' }}>
                                    <HiddenDatePicker
                                        tabIndex={0}
                                        name={`${arrayName}.${index}.to`}
                                        control={control}
                                        value={watchedValues[arrayName]?.[index]?.to}
                                        errors={errors}
                                        isRequired={!adminView}
                                        isSecond
                                        minDate={watchedValues && watchedValues[arrayName]?.[index]?.from}
                                        isArray={true}
                                        disabled={adminView}
                                    />
                                </td>
                                <td className="multiple-input-table-cell !border-none !w-full" style={{ border: 'none' }}>
                                    <TextInputCell
                                        name={`${arrayName}.${index}.indicator`}
                                        control={control}
                                        placeholder="Текст (100 карактера)*"
                                        errors={errors}
                                        isRequired={!adminView}
                                        maxNumberOfCharacter={100}
                                        height={80}
                                        isMultipleTable={true}
                                        disabled={adminView}
                                    />
                                </td>
                            </tr>
                            {fields.length > 1 ? (
                                <div className="ml-[20px] flex items-center justify-center">
                                    <Button color="error" variant="contained" onClick={() => remove(index)} disabled={adminView}>
                                        Обриши
                                    </Button>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    )
                })}
            </table>
            <div className="h-[20px]">{Object.keys(errors)?.length > 0 && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            <div className="flex flex-col  mt-[56px]">
                <Button variant="contained" className="max-w-[172px]" onClick={handleAddArrayField} disabled={adminView}>
                    Додај активност
                </Button>
                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError ? 'Додајте барем једну активност!' : ''}</span>
            </div>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step14
