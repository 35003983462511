import { TblOblast1 } from '../../../models/Form1/Form1.entity'
import '../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import SimpleTable from '../../../components/Tables/SimpleTable'
import { StepInterface } from './Step1'
export interface RowInterface {
    label: string
    field: keyof TblOblast1
}

const rows: RowInterface[] = [
    { label: 'Мушкарци', field: 'o1_k16_m_tr_bpp' },
    { label: 'Жене', field: 'o1_k16_z_tr_bpp' },
]
function Step16({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast1>({ control })
    const fields: ('o1_k16_m_tr_bpp' | 'o1_k16_z_tr_bpp')[] = ['o1_k16_m_tr_bpp', 'o1_k16_z_tr_bpp']
    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">16. Број тражилаца и корисника бесплатне правне помоћи разврстаних по полу:</h1>

            <SimpleTable control={control} header={['Пол', 'Број', 'Проценат']} rows={rows} fields={fields} errors={errors} disabled={adminView} />
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={false} />
        </div>
    )
}

export default Step16
