export const latinToCyrillicMap: { [key: string]: string } = {
    Nj: 'Њ',
    nj: 'њ',
    Lj: 'Љ',
    lj: 'љ',
    Dj: 'Ђ',
    dj: 'ђ',
    Dž: 'Џ',
    dž: 'џ',
    Đ: 'Ђ',
    đ: 'ђ',
    A: 'А',
    B: 'Б',
    C: 'Ц',
    Č: 'Ч',
    Ć: 'Ћ',
    D: 'Д',
    E: 'Е',
    F: 'Ф',
    G: 'Г',
    H: 'Х',
    I: 'И',
    J: 'Ј',
    K: 'К',
    L: 'Л',
    M: 'М',
    N: 'Н',
    O: 'О',
    P: 'П',
    R: 'Р',
    S: 'С',
    Š: 'Ш',
    T: 'Т',
    U: 'У',
    V: 'В',
    Z: 'З',
    Ž: 'Ж',
    a: 'а',
    b: 'б',
    c: 'ц',
    č: 'ч',
    ć: 'ћ',
    d: 'д',
    e: 'е',
    f: 'ф',
    g: 'г',
    h: 'х',
    i: 'и',
    j: 'ј',
    k: 'к',
    l: 'л',
    m: 'м',
    n: 'н',
    o: 'о',
    p: 'п',
    r: 'р',
    s: 'с',
    š: 'ш',
    t: 'т',
    u: 'у',
    v: 'в',
    z: 'з',
    ž: 'ж',
}

export const latinToCyrillic = (text: string): string => {
    text = text
        .replace(/Nj/g, 'Њ')
        .replace(/nj/g, 'њ')
        .replace(/Lj/g, 'Љ')
        .replace(/lj/g, 'љ')
        .replace(/Dj/g, 'Ђ')
        .replace(/dj/g, 'ђ')
        .replace(/Dž/g, 'Џ')
        .replace(/dž/g, 'џ')
        .replace(/Đ/g, 'Ђ')
        .replace(/đ/g, 'ђ')

    return text
        .split('')
        .map((char) => latinToCyrillicMap[char] || char)
        .join('')
}

const cyrillicToLatinMap: { [key: string]: string } = {}
for (const [latin, cyrillic] of Object.entries(latinToCyrillicMap)) {
    cyrillicToLatinMap[cyrillic] = latin
}

export function convertToCyrillic(str: string): string {
    return str
        .split('')
        .map((char) => latinToCyrillicMap[char] || char)
        .join('')
}
export function convertToLatin(str: string): string {
    return str
        .split('')
        .map((char) => cyrillicToLatinMap[char] || char)
        .join('')
}
