import React from 'react'
import { Controller } from 'react-hook-form'
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Button, FormHelperText } from '@mui/material'
import HelpButton from './HelpButton'

interface RadioComponentInterface {
    control: any
    errors?: any
    isRequired?: boolean | string
    name: string
    options: string[]
    label?: string
    fontSize?: number
    color?: string
    marginBottom?: number
    marginLeft?: number
    isDisabled?: boolean
    showHelpMessage?: boolean
    helpName?: string
}

function RadioButtonsControl({
    control,
    errors,
    isRequired = 'Изаберите једну од понуђених опција.',
    name,
    options,
    label,
    fontSize = 18,
    color = '#000000DE',
    marginBottom = 20,
    marginLeft = 20,
    isDisabled = false,
    showHelpMessage = false,
    helpName = '',
}: RadioComponentInterface) {
    return (
        <FormControl disabled={isDisabled} component="fieldset" error={!!errors[name]}>
            <FormLabel style={{ color, fontSize, marginBottom }} component="legend">
                {label}
            </FormLabel>
            <div className="flex flex-row gap-5">
                <Controller
                    name={name}
                    control={control}
                    rules={{ required: isRequired }}
                    defaultValue={''}
                    render={({ field }) => (
                        <RadioGroup {...field} style={{ marginLeft }}>
                            {options.map((option, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={option}
                                    control={
                                        <Radio
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#A2CEFF',
                                                },
                                            }}
                                        />
                                    }
                                    label={option}
                                    className={`${errors[name] ? 'text-[#d32f2f]' : ''}`}
                                />
                            ))}
                        </RadioGroup>
                    )}
                />
                {showHelpMessage && <HelpButton name={helpName} marginTop={6} />}
            </div>
            <FormHelperText style={{ minHeight: 20 }}>{!!errors[name] ? errors[name]?.message : ''}</FormHelperText>
        </FormControl>
    )
}

export default RadioButtonsControl

interface RadioButtonsControlStateInterface {
    onChange?: (value: string) => void
    value: string
    options: string[]
    label?: string
    fontSize?: number
    disabled?: boolean
    color?: string
    marginBottom?: number
    marginLeft?: number
    error?: boolean
}

export const RadioButtonsControlState = ({
    onChange = () => null,
    value,
    options,
    label,
    fontSize = 18,
    color = '#000000DE',
    marginBottom = 20,
    marginLeft = 20,
    disabled = false,
    error = false,
}: RadioButtonsControlStateInterface) => {
    return (
        <FormControl component="fieldset" error={error}>
            <FormLabel style={{ color, fontSize, marginBottom }} component="legend">
                {label}
            </FormLabel>
            <RadioGroup value={value} onChange={(e) => onChange(e.target.value)} style={{ marginLeft }}>
                {options.map((option, index) => (
                    <FormControlLabel
                        disabled={disabled}
                        key={index}
                        value={option}
                        control={
                            <Radio
                                sx={{
                                    '&.Mui-checked': {
                                        color: '#A2CEFF',
                                    },
                                }}
                            />
                        }
                        label={option}
                        className={`${error ? 'text-[#d32f2f]' : ''}`}
                    />
                ))}
            </RadioGroup>
            <FormHelperText style={{ minHeight: 20 }}>{error ? '* Изаберите једну од понуђених опција.' : ''}</FormHelperText>
        </FormControl>
    )
}
