import React from 'react'
import { StepInterface } from './Step1'
import '../../../../../assets/styles/SimpleTable.css'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { Form7_15Entity } from '../../../../../models/Form7/Entities/Form7_15.entity'
import { useWatch } from 'react-hook-form'
import ActionButtons from '../../../../../components/ActionButtons'
import { getPercentage } from '../../../../../utils/mathUtils'

export const Step4 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_15Entity>({ control })

    const fields: ('o7_15_k4_ukupno' | 'o7_15_k4_korisnici')[] = ['o7_15_k4_ukupno', 'o7_15_k4_korisnici']
    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <h1 className="step-name mb-[10px]">Број и проценат жена обухваћених обавезним организованим превентивним прегледима (скрининг)</h1>
            <div>
                <h1 className="step-name mb-[10px]">Укупан број корисника женског пола у свим старосним добима</h1>
                <div className="max-w-[200px] flex flex-col">
                    <PositiveNumberInputCell
                        name={`o7_15_k4_ukupno`}
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        height={62}
                        disabled={adminView}
                        form7
                        clearErrors={clearErrors}
                    />

                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_15_k4_ukupno ? 'Попуните обавезно поље!' : ''}</span>
                </div>
            </div>
            <div>
                <h1 className="step-name mb-[10px]">Број жена обухваћених обавезним организованим превентивним прегледима (скрининг)</h1>
                <div className="max-w-[200px] flex flex-col">
                    <PositiveNumberInputCell
                        name={`o7_15_k4_korisnici`}
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        height={62}
                        disabled={adminView}
                        form7
                        clearErrors={clearErrors}
                    />

                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_15_k4_korisnici ? 'Попуните обавезно поље!' : ''}</span>
                </div>
            </div>
            <div>
                <h1 className="step-name mb-[10px]">Проценат жена обухваћених обавезним организованим превентивним прегледима (скрининг)</h1>
                <div className="border border-[#00000010] h-[62px] w-full flex justify-center items-center max-w-[200px] hover:none hover:cursor-default">
                    {`${!disabledNext || adminView ? getPercentage(watchedValues.o7_15_k4_korisnici, watchedValues.o7_15_k4_ukupno) : '0'}%`}
                </div>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
