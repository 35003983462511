import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function SSOLogout() {
    const navigate = useNavigate()

    useEffect(() => {
        navigate('/login')
    }, [navigate])

    return null
}

export default SSOLogout