import ActionButtons from '../../../../components/ActionButtons'
import { StepInterface } from './Step1'
import NoAnalytics from '../../Common/NoAnalytics'

function Step13({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, countType, setValue }: StepInterface) {
    return (
        <div>
            <h1 className="step-name">13. Начин поступања одговорног лица у органима јавне власти и послодавца по тим судским одлукама</h1>

            <NoAnalytics />
            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step13
