import { File } from '../../File.entity'
import { Organization } from '../../Organization.entity'
import { SubmittedBy } from '../../SubmittedBy.entity'
import { EducationalProfile } from './Common/EducationalProfile.entity'
import { FileDescription } from './Common/FileDescription.entity'

export const NumberOfSteps = 5

export interface Form7_8Entity {
    report_id: string
    rep_year: number
    submitted_date?: Date
    submitted_place?: Date
    category: string
    organisation: Organization
    status: string

    o7_8_k1_affirming_programs: number
    o7_8_k1_revised_books: number
    o7_8_k1_sexual_health_content: number
    o7_8_k1_woman_dropout: number
    o7_8_k1_woman_dropout_percent: number

    o7_8_k2_profiles_m: EducationalProfile[]
    o7_8_k2_profiles_z: EducationalProfile[]
    
    o7_8_k3_t1_m_romi: number
    o7_8_k3_t1_z_romi: number
    o7_8_k3_t1_m_invalid: number
    o7_8_k3_t1_z_invalid: number

    o7_8_k3_t2_m_romi: number
    o7_8_k3_t2_z_romi: number
    o7_8_k3_t2_m_invalid: number
    o7_8_k3_t2_z_invalid: number

    o7_8_k4_dokumentaIzvestavanja: FileDescription[]
    o7_8_k4_files: File[]


    submitted_by?: SubmittedBy
}
