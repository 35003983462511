import { useState } from 'react'
import { StepInterfaceObl2 } from './Step1'
import { useFieldArray, useWatch } from 'react-hook-form'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import ActionButtons from '../../../components/ActionButtons'
import TextTable from '../Components/TextTable'
import { Button } from '@mui/material'

const Step16 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterfaceObl2) => {
    const watchedValues = useWatch<TblOblast2>({ control })
    const [emptyArray1Error, setEmptyArray1Error] = useState(false)
    const [emptyArray2Error, setEmptyArray2Error] = useState(false)

    const {
        fields: firstArrayFields,
        append: appendToFirstArray,
        remove: removeFromFirstArray,
    } = useFieldArray({
        control,
        name: 'o2_k16_programskeAktivnosti',
    })
    const firstArrayName = 'o2_k16_programskeAktivnosti'

    const handleAddFirstArrayField = () => {
        setEmptyArray1Error(false)
        appendToFirstArray({ text_content: '' })
    }

    const handleAddSecondArrayField = () => {
        setEmptyArray2Error(false)
        appendToSecondArray({ text_content: '' })
    }

    const {
        fields: secondArrayFields,
        append: appendToSecondArray,
        remove: removeFromSecondArray,
    } = useFieldArray({
        control,
        name: 'o2_k16_nosioci',
    })

    const secondArrayName = 'o2_k16_nosioci'
    const validateFirstArrayFields = (): boolean => {
        if (!watchedValues.o2_k16_programskeAktivnosti || watchedValues.o2_k16_programskeAktivnosti.length === 0) {
            return true
        }

        const newMap = watchedValues.o2_k16_programskeAktivnosti.map((field) => field.text_content !== '')
        return newMap.includes(false)
    }

    const validateSecondArrayFields = (): boolean => {
        if (!watchedValues.o2_k16_nosioci || watchedValues.o2_k16_nosioci.length === 0) {
            return true
        }

        const newMap = watchedValues.o2_k16_nosioci.map((field) => field.text_content !== '')
        return newMap.includes(false)
    }

    const disabledNext = validateFirstArrayFields() || validateSecondArrayFields()

    const validateEmptyArray = () => {
        if (watchedValues[firstArrayName]?.length === 0 || watchedValues[firstArrayName] === undefined) {
            setEmptyArray1Error(true)
            return true
        }
        if (watchedValues[secondArrayName]?.length === 0 || watchedValues[secondArrayName] === undefined) {
            setEmptyArray2Error(true)
            return true
        }
        return false
    }

    const goToNextStep = () => {
        if (!disabledNext && !validateEmptyArray()) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <h1 className="step-name">
                Програмске активности и пројекти на остваривању и унапређивању родне равноправности у чију реализацију је укључено тело за родну равноправност
            </h1>

            <div className="mt-[50px]">
                <h4 className="mb-[10px]">Назив програмских активности и пројеката:*</h4>
                <div>
                    <TextTable
                        control={control}
                        name={firstArrayName}
                        errors={errors}
                        fields={firstArrayFields}
                        remove={removeFromFirstArray}
                        label={'Назив програмских активности и пројеката'}
                        disabled={adminView}
                    />

                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{errors && errors?.[firstArrayName] ? 'Попуните обавезно поље!' : ''}</span>
                </div>
                <div className="flex flex-col">
                    <Button variant="contained" className="max-w-[172px]" onClick={handleAddFirstArrayField} style={{ marginTop: 20 }} disabled={adminView}>
                        Додај назив
                    </Button>
                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArray1Error ? 'Додајте барем један назив!' : ''}</span>
                </div>
            </div>
            <div className="mt-[50px]">
                <h4 className="mb-[10px] text-disabled">Носиоци и сарадници у реализацији програмских активности и пројеката:*</h4>
                <div>
                    <TextTable
                        name={secondArrayName}
                        control={control}
                        errors={errors}
                        fields={secondArrayFields}
                        remove={removeFromSecondArray}
                        label={'Носиоци и сарадници у реализацији програмских активности и пројеката'}
                        disabled={adminView}
                    />
                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{errors && errors?.[secondArrayName] ? 'Попуните обавезно поље!' : ''}</span>
                </div>
                <div className="flex flex-col">
                    <Button variant="contained" className="max-w-[260px]" onClick={handleAddSecondArrayField} style={{ marginTop: 20 }} disabled={adminView}>
                        Додај носиоце и сараднике
                    </Button>
                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArray2Error ? 'Додајте барем једног носиоца или сарадника!' : ''}</span>
                </div>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step16
