import React from 'react'
import { Control, useWatch } from 'react-hook-form'
import { Form7_4_1Entity } from '../../../../../models/Form7/Entities/Form7_4.entity'
import ComplexReductionTable from '../../../Components/ComplexSubtractionTable'
import ActionButtons from '../../../../../components/ActionButtons'

export interface StepInterface {
    control?: Control<Form7_4_1Entity>
    nextStep: () => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
}

interface RowInterface {
    label: string
    fields: (keyof Form7_4_1Entity)[]
}

const rows1: RowInterface[] = [
    { label: 'Број ТГ', fields: ['o7_4_1_k4_t1_z1', 'o7_4_1_k4_t1_m1'] },
    { label: 'Број ТГ-1', fields: ['o7_4_1_k4_t1_z2', 'o7_4_1_k4_t1_m2'] },
]
const rows2: RowInterface[] = [
    { label: 'Број ТГ', fields: ['o7_4_1_k4_t2_z1', 'o7_4_1_k4_t2_m1'] },
    { label: 'Број ТГ-1', fields: ['o7_4_1_k4_t2_z2', 'o7_4_1_k4_t2_m2'] },
]

const allFields1 = ['o7_4_1_k4_t1_z1', 'o7_4_1_k4_t1_m1', 'o7_4_1_k4_t1_z2', 'o7_4_1_k4_t1_m2'] as const

const allFields2 = ['o7_4_1_k4_t2_z1', 'o7_4_1_k4_t2_m1', 'o7_4_1_k4_t2_z2', 'o7_4_1_k4_t2_m2'] as const

const allMaleFields1 = [...allFields1.filter((e) => e.includes('_m'))] as const
const allFemaleFields1 = [...allFields1.filter((e) => e.includes('_z'))] as const

const allMaleFields2 = [...allFields2.filter((e) => e.includes('_m'))] as const
const allFemaleFields2 = [...allFields2.filter((e) => e.includes('_z'))] as const

type AllFieldsType1 = (typeof allFields1)[number]
type AllFieldsType2 = (typeof allFields2)[number]

const Step4 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) => {
    const watchedValues = useWatch<Form7_4_1Entity>({ control })
    const allFields = [...allFields1, ...allFields2]

    const disabledNext = !allFields.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">Регистар задужбина и фондација - оснивачи</h1>
            <ComplexReductionTable
                rows={rows1}
                control={control}
                allFemaleFields={[...allFemaleFields1]}
                allMaleFields={[...allMaleFields1]}
                allFields={[...allFields1]}
                header={['Број', 'Жене', 'Жене%', 'Мушкарци', 'Мушкарци%', 'Број жена заступника на 100 мушких']}
                errors={errors}
                disabled={adminView}
            />
            <h1 className="step-name mt-10">Регистар задужбина и фондација - заступници</h1>
            <ComplexReductionTable
                rows={rows2}
                control={control}
                allFemaleFields={[...allFemaleFields2]}
                allMaleFields={[...allMaleFields2]}
                allFields={[...allFields2]}
                header={['Број', 'Жене', 'Жене%', 'Мушкарци', 'Мушкарци%', 'Број жена заступника на 100 мушких']}
                errors={errors}
                disabled={adminView}
            />
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step4
