import React from 'react'
import { Control, useWatch } from 'react-hook-form'
import ActionButtons from '../../../../../components/ActionButtons'
import { Form7_10Entity } from '../../../../../models/Form7/Entities/Form7_10.entity'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'

export interface StepInterface {
    control?: Control<Form7_10Entity>
    nextStep: (skip?: boolean) => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
}

function Step4({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_10Entity>({ control })
    const fields: ['o7_10_k4_broj_obucenih', 'o7_10_k4_procenat_obucenih', 'o7_10_k4_broj_informativnih'] = [
        'o7_10_k4_broj_obucenih',
        'o7_10_k4_procenat_obucenih',
        'o7_10_k4_broj_informativnih',
    ]

    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">4. Број запослених обучених професионалаца/ки из области правосуђа на теме родне равноправности:</h1>

            <div className=" w-[140px] h-[50px] flex items-center justify-center mt-[40px]">
                <PositiveNumberInputCell
                    name={'o7_10_k4_broj_obucenih'}
                    control={control}
                    defaultValue=""
                    errors={errors}
                    isRequired={!adminView}
                    height={52}
                    disabled={adminView}
                />
            </div>
            <h1 className="step-name mt-6">Проценат обучених професионалаца и професионалки који имају унапређена знања и вештине везане за родну равноправност:</h1>

            <div className=" w-[161px] h-[50px] flex items-center justify-center mt-[40px]">
                <PositiveNumberInputCell
                    name={'o7_10_k4_procenat_obucenih'}
                    control={control}
                    defaultValue=""
                    precentage
                    decimal
                    errors={errors}
                    isRequired={!adminView}
                    height={52}
                    disabled={adminView}
                />
            </div>
            <h1 className="step-name mt-6">Број информативних јавних кампања о остваривању родне равноправности у години извештавања у области правосуђа:</h1>

            <div className=" w-[140px] h-[50px] flex items-center justify-center mt-[40px]">
                <PositiveNumberInputCell
                    name={'o7_10_k4_broj_informativnih'}
                    control={control}
                    defaultValue=""
                    errors={errors}
                    isRequired={!adminView}
                    height={52}
                    disabled={adminView}
                />
            </div>

            <div className="h-[2px] mt-1"> {errors?.o7_10_k1 && <p className="text leading-5 text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step4
