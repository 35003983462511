import { Input } from '@mui/material'
import { Controller, FieldErrors } from 'react-hook-form'

interface SimpleInputRowInterface {
    name: string
    maxNumberOfCharacter?: number
    control: any
    title: string
    disabled?: boolean
    type?: 'text' | 'date' | 'tel' | 'number'
    errors?: any
    isRequired?: boolean
}

function SimpleInputRow({ name, title, maxNumberOfCharacter = 50, control, disabled = false, type = 'text', errors, isRequired = false }: SimpleInputRowInterface) {
    let inputWidth = 186
    let titleWidth = 1028

    if (type === 'number' || type === 'tel') {
        ;[inputWidth, titleWidth] = [titleWidth, inputWidth]
    }

    return (
        <div className={`flex flex-row items-center gap-[10px] h-[${type === 'number' || type === 'tel' ? 100 : 50}px]`}>
            <div className={`w-[${inputWidth}px] border flex items-center justify-center bg-[#F5F5F5] text-disabled border-[#00000010] h-[100%]`}>
                <p className="text-center mx-auto">{title}</p>
            </div>
            <div className={`w-[${titleWidth}px] border flex items-center p-[15px] h-[100%] ${errors && errors[name] ? 'border-[#d32f2f]' : 'border-[#00000010]'}`}>
                <Controller
                    name={name}
                    control={control}
                    rules={{ required: isRequired }}
                    render={({ field }) => (
                        <>
                            <Input
                                {...field}
                                onChange={(event) => {
                                    const newValue = event.target.value
                                    if ((type !== 'number' && type !== 'tel') || /^[0-9]*$/.test(newValue)) {
                                        field.onChange(!!newValue ? parseFloat(newValue) : null)
                                    }
                                }}
                                type={type}
                                className={`!bg-[transparent] border-none w-full`}
                                placeholder={type === 'number' || type === 'tel' ? 'Цео број' : `Текст, ${maxNumberOfCharacter} карактера`}
                                disabled={disabled}
                                inputProps={{ maxLength: maxNumberOfCharacter }}
                                onWheel={(e) => e.preventDefault()}
                                onKeyDown={(event) => {
                                    const allowedKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight']

                                    if ((type === 'number' || type === 'tel') && !/^[0-9]*$/.test(event.key) && !allowedKeys.includes(event.key)) {
                                        event.preventDefault()
                                    }
                                }}
                                sx={{
                                    textAlign: type == 'number' || type == 'tel' ? 'center' : 'left',
                                    '& .MuiInputBase-input': {
                                        textAlign: type == 'number' || type == 'tel' ? 'center' : 'left',
                                        '&::placeholder': {
                                            color: errors && errors[name] ? '#d32f2f' : 'inherit',
                                        },
                                    },
                                    '&.MuiInputBase-root:before': {
                                        borderBottom: 'none',
                                    },
                                    '&.MuiInputBase-root:after': {
                                        borderBottom: 'none',
                                    },
                                    '&.MuiInputBase-root:hover:not(.Mui-disabled):before': {
                                        borderBottom: 'none',
                                    },
                                    'input[type=number]': {
                                        MozAppearance: 'textfield',
                                    },
                                }}
                            />
                            {errors && errors[name] && <p className="text-[#d32f2f] text-[0.75rem] mt-1">{errors[name]?.message?.toString()}</p>}
                        </>
                    )}
                />
            </div>
        </div>
    )
}

export default SimpleInputRow

interface SimpleInputRowStateInterface {
    onChange?: (text: string | Date) => void
    value: string | Date
    title: string
    type?: 'text' | 'date' | 'tel' | 'number'
    disabled?: boolean
    errors?: FieldErrors<any>
    isRequired?: boolean
    error?: any
    placeholder?: any
    maxNumberOfCharacter?: number
}

export const SimpleInputRowState = ({
    onChange = () => null,
    value,
    title,
    type = 'text',
    disabled = false,
    errors,
    isRequired = false,
    error,
    placeholder,
    maxNumberOfCharacter,
}: SimpleInputRowStateInterface) => {
    return (
        <div className={`flex flex-row items-center gap-[10px] h-[${type === 'number' ? 100 : 50}px]`}>
            <div className={`w-[186px] border flex items-center justify-center h-[50px] bg-[#F5F5F5] text-disabled border-[#00000010] h-[100%]`}>
                <p className="text-center mx-auto">{title}</p>
            </div>
            <div className={`w-[1028px] border flex items-center p-[15px] h-[100%] ${error ? 'border-[#d32f2f]' : 'border-[#00000010]'}`}>
                <Input
                    onChange={(event) => {
                        const newValue = event.target.value
                        if (type !== 'number' || /^[0-9]*$/.test(newValue)) {
                            // Samo nenegativni celi brojevi
                            onChange(newValue)
                        }
                    }}
                    value={value}
                    className="!bg-[transparent] border-none w-full"
                    placeholder={type === 'number' ? 'Цео број' : placeholder}
                    onFocus={(e) => e.target.select()}
                    type={type}
                    disabled={disabled}
                    inputProps={{ maxLength: maxNumberOfCharacter }}
                    sx={{
                        textAlign: type !== 'number' ? 'left' : 'center',
                        '& .MuiInputBase-input': {
                            textAlign: type !== 'number' ? 'left' : 'center',
                            '&::placeholder': {
                                color: error ? '#d32f2f' : 'inherit',
                            },
                        },
                        '&.MuiInputBase-root:before': {
                            borderBottom: 'none',
                        },
                        '&.MuiInputBase-root:after': {
                            borderBottom: 'none',
                        },
                        '&.MuiInputBase-root:hover:not(.Mui-disabled):before': {
                            borderBottom: 'none',
                        },
                    }}
                />
                {error && <p className="text-[#d32f2f] text-[0.75rem]"> * Попуните обавезно поље </p>}
            </div>
        </div>
    )
}
