import React from 'react'
import { ReactComponent as ExpandIcon } from '../../../assets/svg/ExpandIcon.svg'

function ExpandButton({ open, onClick }: { open: boolean; onClick: () => void }) {
    return (
        <button
            className={`flex items-center justify-center rounded-md border  ${open ? '-rotate-180 bg-[#F5F5F5] border-[#A2CEFF]' : 'rotate-0 border-white'} transition`}
            onClick={onClick}
        >
            <ExpandIcon />
        </button>
    )
}

export default ExpandButton
