import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Button, FormHelperText } from '@mui/material'

interface RadioComponentInterface {
    control: any
    name: string
    options: string[]
    label?: string
    fontSize?: number
    color?: string
    marginBottom?: number
    marginLeft?: number
    errors?: any
    enumerated?: boolean
    boolean?: boolean
    isSubBy?: boolean
    disabled?: boolean
}

function RadioButtonsControl({
    control,
    name,
    options,
    label,
    fontSize = 18,
    color = '#000000DE',
    marginBottom = 20,
    marginLeft = 20,
    errors,
    enumerated = false,
    boolean = false,
    isSubBy = false,
    disabled,
}: RadioComponentInterface) {
    const splitName = name.split('.')
    const error = isSubBy ? errors?.[splitName[0]]?.[splitName[1]] : errors?.[name]
    return (
        <FormControl component="fieldset" error={error}>
            {label && (
                <FormLabel style={{ color, fontSize, marginBottom }} component="legend">
                    {label}
                </FormLabel>
            )}
            <Controller
                name={name}
                control={control}
                rules={{
                    required: !disabled ? 'Изаберите једну од понуђених опција.' : false,
                }}
                render={({ field }) => {
                    const getValue = () => {
                        if (field.value === null || field.value === undefined) return ''
                        if (enumerated) return options[field.value]
                        if (boolean && field.value !== undefined) return options[Number(field.value)]
                        return field.value
                    }
                    return (
                        <RadioGroup
                            {...field}
                            style={{ marginLeft }}
                            onChange={(e) => {
                                if (enumerated) {
                                    field.onChange(options.indexOf(e.target.value))
                                } else if (boolean) {
                                    field.onChange(Boolean(options.indexOf(e.target.value)))
                                }
                            }}
                            value={getValue()}
                        >
                            {options.map((option, index) => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        value={option}
                                        control={
                                            <Radio
                                                disabled={disabled}
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: '#A2CEFF',
                                                    },
                                                }}
                                            />
                                        }
                                        label={option}
                                        className={`${error ? 'text-[#d32f2f]' : ''}`}
                                    />
                                )
                            })}
                        </RadioGroup>
                    )
                }}
            />
            {error && <FormHelperText style={{ minHeight: 20, marginLeft: 20 }}>{error?.message}</FormHelperText>}
        </FormControl>
    )
}

export default RadioButtonsControl
