import { Checkbox, FormControlLabel, Input, Radio } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
interface CheckboxInterface {
    name: string
    control: any
    disabled?: boolean
    label?: string
    errors?: any
    height?: number
    borderColor?: string
    handleCheckBox: any
    watchedValues?: any
}
function CheckboxCell({ control, name, disabled, label = 'label', height = 54, borderColor = '#00000010', errors, handleCheckBox, watchedValues }: CheckboxInterface) {
    const parts = name.split('.')

    const arrayName = parts[0]
    const index = parseInt(parts[1], 10)
    const fieldName = parts[2]

    const checkValidation = () => {
        return (
            !!watchedValues &&
            !!watchedValues[arrayName] &&
            !!watchedValues[arrayName][index] &&
            !watchedValues[arrayName][index]['woman'] &&
            !watchedValues[arrayName][index]['man'] &&
            !!errors[arrayName] &&
            !!errors[arrayName][index]
        )
    }
    return (
        <div
            className="min-w-[100px] border flex items-center p-[15px]"
            style={{
                height,
                borderColor: checkValidation() ? '#d32f2f' : borderColor,
            }}
        >
            <Controller
                name={name}
                control={control}
                rules={{
                    required: true,
                }}
                render={({ field }) => (
                    <FormControlLabel
                        control={
                            <Radio
                                disabled={disabled}
                                {...field}
                                checked={field.value}
                                onChange={(event) => {
                                    field.onChange(event.target.checked)
                                    if (handleCheckBox) {
                                        handleCheckBox(name, event.target.checked)
                                    }
                                }}
                                sx={{
                                    '&.Mui-checked': {
                                        color: '#A2CEFF',
                                    },
                                    '&:hover': {
                                        color: '#A2CEFF',
                                    },
                                }}
                            />
                        }
                        label={label}
                    />
                )}
            />
        </div>
    )
}

export default CheckboxCell
