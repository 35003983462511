import '../../../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../../../components/ActionButtons'
import { Control, useWatch } from 'react-hook-form'
import Textbox from '../../../../Contact/TextBoxAutoSize'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { Form7_7Entity } from '../../../../../models/Form7/Entities/Form7_7.entity'
import { StepInterface } from './Step1'
import SimpleTable from '../../../../../components/Tables/SimpleTable'

export interface RowInterface {
    label: string
    field: keyof Form7_7Entity
}

const rows: RowInterface[] = [
    { label: 'Мушкарци', field: 'o7_7_k3_m' },
    { label: 'Жене', field: 'o7_7_k3_z' },
]
const fields: ('o7_7_k3_m' | 'o7_7_k3_z')[] = ['o7_7_k3_m', 'o7_7_k3_z']
    

export function Step3({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_7Entity>({ control })

    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '') ||
                         watchedValues?.o7_7_k2_accredited_trainings === undefined || typeof watchedValues.o7_7_k2_accredited_trainings !== 'number'

    const goToNextStep = () => {
        if (!disabledNext && !errors?.o4_k7_procenat) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name mb-[0px] ">Број одраслих лица којa су укљученa у програм функционалног основног образовања одраслих (ФООО). Разврстани по полу.</h1>
            <div className="flex flex-row gap-2 mb-[20px]">
                <SimpleTable control={control} header={['Пол', 'Број', 'Проценат']} rows={rows} fields={fields} errors={errors} disabled={adminView} />
            </div>
            
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
