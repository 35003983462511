import React, { useEffect, useState } from 'react'
import { useForm, Controller, FieldErrors } from 'react-hook-form'
import { Input } from '@mui/material'

interface PositiveDecimalInputSeparateInterface {
    name: string
    control: any
    defaultValue?: string
    errors?: FieldErrors<any>
    isRequired?: boolean
    placeholder?: string
    validationFunction?: (value: number) => boolean
    disabled?: boolean
}

function PositiveDecimalInputSeparate({
    name,
    control,
    defaultValue = '',
    errors,
    isRequired = false,
    placeholder,
    validationFunction,
    disabled,
}: PositiveDecimalInputSeparateInterface) {
    const hasError = errors && errors[name]

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }

    return (
        <div className={`w-[140px] h-[50px] border ${hasError ? 'border-[#d32f2f]' : 'border-[#00000030]'} rounded-md`}>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={{ required: isRequired }}
                render={({ field }) => (
                    <Input
                        {...field}
                        onChange={(event) => {
                            const newValue = event.target.value
                            if (newValue === '') {
                                field.onChange('')
                                return
                            }
                            if (/^\d*$/.test(newValue)) {
                                const numberValue = Number(newValue)
                                if (validationFunction) {
                                    if (validationFunction(numberValue)) {
                                        field.onChange(numberValue)
                                    }
                                } else {
                                    field.onChange(numberValue)
                                }
                            }
                        }}
                        disabled={disabled}
                        value={field.value === undefined ? '' : field.value}
                        onKeyDown={handleKeyDown}
                        className="!bg-[transparent] border-none h-[100%]"
                        placeholder={placeholder || 'Цео број'}
                        sx={{
                            textAlign: 'right',
                            '& .MuiInputBase-input': {
                                textAlign: 'right',
                                padding: '12px',
                                '&::placeholder': {
                                    color: hasError ? '#d32f2f' : 'inherit',
                                },
                            },
                            '&.MuiInputBase-root:before': {
                                borderBottom: 'none',
                            },
                            '&.MuiInputBase-root:after': {
                                borderBottom: 'none',
                            },
                            '&.MuiInputBase-root:hover:not(.Mui-disabled):before': {
                                borderBottom: 'none',
                            },
                        }}
                    />
                )}
            />
        </div>
    )
}

export default PositiveDecimalInputSeparate

interface PositiveDecimalInputSeparateStateInterface {
    onChange?: (value: number | undefined) => void
    value?: string
    disabled?: boolean
    error?: boolean
    isRequired?: boolean
    decimal?: boolean
}

export const PositiveDecimalInputSeparateState = ({
    value,
    onChange = () => null,
    disabled = false,
    error,
    isRequired = false,
    decimal = false,
}: PositiveDecimalInputSeparateStateInterface) => {
    const [shownValue, setShowValue] = useState<string | undefined>(disabled ? value : undefined)
    const regexString = decimal ? /^[\d,.]*$/ : /^[\d]*$/
    useEffect(() => {
        let formattedValue = value

        if (formattedValue && decimal) {
            if (!formattedValue.includes('.')) {
                formattedValue = `${formattedValue}.00`
            } else {
                const parts = formattedValue.split('.')
                formattedValue = `${parts[0]}.${parts[1].slice(0, 2).padEnd(2, '0')}`
            }
        }

        setShowValue(formattedValue)
    }, [value, decimal])

    return (
        <div className={`w-[155px] h-[50px] border ${error ? 'border-[#d32f2f]' : 'border-[#00000030]'} rounded-md`}>
            <Input
                value={shownValue}
                onChange={(event) => {
                    let newValue = event.target.value.replace(',', '.')
                    if (newValue === '.') {
                        newValue = '0.'
                    }
                    if (newValue === '') {
                        onChange(undefined)
                        setShowValue(undefined)
                        return
                    }

                    if (regexString.test(newValue) && Number(newValue) >= 0) {
                        onChange(Number(newValue))
                        setShowValue(newValue)
                    } else {
                        onChange(undefined)
                        setShowValue(undefined)
                    }
                }}
                onBlur={() => {
                    if (shownValue) {
                        let updatedValue = shownValue
                        if (!shownValue.includes('.')) {
                            updatedValue = `${shownValue}.00`
                        } else {
                            const parts = shownValue.split('.')
                            updatedValue = `${parts[0]}.${parts[1].slice(0, 2).padEnd(2, '0')}`
                        }
                        setShowValue(updatedValue)
                    }
                }}
                onKeyDown={(event) => {
                    const allowedKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight', '.']

                    if ((!allowedKeys.includes(event.key) && !regexString.test(event.key)) || event.key === ',') {
                        event.preventDefault()
                    }
                }}
                type="tel"
                className="!bg-[transparent] border-none h-[50px]"
                placeholder={decimal ? '0.00' : 'Цео број'}
                disabled={disabled}
                sx={{
                    textAlign: 'right',
                    '& .MuiInputBase-input': {
                        textAlign: 'right',
                        padding: '12px',
                        '&::placeholder': {
                            color: error ? '#d32f2f' : 'inherit',
                        },
                    },
                    '&.MuiInputBase-root:before': {
                        borderBottom: 'none',
                    },
                    '&.MuiInputBase-root:after': {
                        borderBottom: 'none',
                    },
                    '&.MuiInputBase-root:hover:not(.Mui-disabled):before': {
                        borderBottom: 'none',
                    },
                }}
            />
            {/* error text */}
            <p className="text-[#d32f2f] text-[12px] ml-[10px] mt-[5px]">{error ? '* Унесите валидан број.' : ''}</p>
        </div>
    )
}
