import { GRADE } from '../../CommonTypes'
import { File } from '../../File.entity'
import { Organization } from '../../Organization.entity'
import { SubmittedBy } from '../../SubmittedBy.entity'
import { FileDescription } from './Common/FileDescription.entity'

export const NumberOfSteps = 8

export interface Form7_16Entity {
    report_id: string
    rep_year: number
    submitted_date?: Date
    submitted_place?: Date
    category: string
    organisation: Organization
    status: string
    submitted_by?: SubmittedBy

    o7_16_k1_ocena: GRADE
    o7_16_k1_opis: string //max400

    o7_16_k2_t1_m: number
    o7_16_k2_t1_z: number
    o7_16_k2_t2_m: number
    o7_16_k2_t2_z: number

    o7_16_k3_t1_m: number
    o7_16_k3_t1_z: number
    o7_16_k3_t2_m: number
    o7_16_k3_t2_z: number

    o7_16_k4_brojObuka: number
    o7_16_k4_t1_m: number
    o7_16_k4_t1_z: number

    o7_16_k5_brojObuka: number
    o7_16_k5_brojKontanta: number
    o7_16_k5_brojMera: number

    o7_16_k6_budzet: number
    o7_16_k6_sredstvaNAP: number
    o7_16_k6_sredstvaOCD: number
    o7_16_k6_brojOCD: number

    o7_16_k7_fileDescription: FileDescription[]
    o7_16_k7_file: File[]
}
