import React from 'react'
import { Form7_1Entity } from '../../../../../models/Form7/Entities/Form7_1.entity'
import { StepInterface } from './Step1'
import { useWatch } from 'react-hook-form'
import ComplexTable from '../../../../../components/Tables/ComplexTable'
import ActionButtons from '../../../../../components/ActionButtons'

interface RowInterface {
    label: string
    fields: (keyof Form7_1Entity)[]
}

const rowsTable1: RowInterface[] = [
    { label: '1-14 сати', fields: ['o7_1_k4_z_1_14', 'o7_1_k4_m_1_14'] },
    { label: '15-35 сати', fields: ['o7_1_k4_z_15_35', 'o7_1_k4_m_15_35'] },
    { label: '36-48 сати', fields: ['o7_1_k4_z_36_48', 'o7_1_k4_m_36_48'] },
    { label: '49-59 сати', fields: ['o7_1_k4_z_49_59', 'o7_1_k4_m_49_59'] },
    { label: '60 сати и више', fields: ['o7_1_k4_z_60', 'o7_1_k4_m_60'] },
    { label: 'Сати варирају од седмице до седмице', fields: ['o7_1_k4_z_varira', 'o7_1_k4_m_varira'] },
]

const allFieldsTable1 = [
    'o7_1_k4_z_1_14',
    'o7_1_k4_z_15_35',
    'o7_1_k4_z_36_48',
    'o7_1_k4_z_49_59',
    'o7_1_k4_z_60',
    'o7_1_k4_z_varira',

    'o7_1_k4_m_1_14',
    'o7_1_k4_m_15_35',
    'o7_1_k4_m_36_48',
    'o7_1_k4_m_49_59',
    'o7_1_k4_m_60',
    'o7_1_k4_m_varira',
] as const

const allMaleFieldsTable1 = [...allFieldsTable1.filter((e) => e.includes('_m_'))] as const
const allFemaleFieldsTable1 = [...allFieldsTable1.filter((e) => e.includes('_z_'))] as const

const rowsTable2: RowInterface[] = [
    { label: 'Сектор пољопривреде', fields: ['o7_1_k4_z_poljoprivreda', 'o7_1_k4_m_poljoprivreda'] },
    { label: 'Сектор индустрије', fields: ['o7_1_k4_z_industrija', 'o7_1_k4_m_industrija'] },
    { label: 'Сектор грађевине', fields: ['o7_1_k4_z_gradjevina', 'o7_1_k4_m_gradjevina'] },
    { label: 'Сектор услуга', fields: ['o7_1_k4_z_usluge', 'o7_1_k4_m_usluge'] },
]

const allFieldsTable2 = [
    'o7_1_k4_z_poljoprivreda',
    'o7_1_k4_z_industrija',
    'o7_1_k4_z_gradjevina',
    'o7_1_k4_z_usluge',

    'o7_1_k4_m_poljoprivreda',
    'o7_1_k4_m_industrija',
    'o7_1_k4_m_gradjevina',
    'o7_1_k4_m_usluge',
] as const

const allMaleFieldsTable2 = [...allFieldsTable2.filter((e) => e.includes('_m_'))] as const
const allFemaleFieldsTable2 = [...allFieldsTable2.filter((e) => e.includes('_z_'))] as const

const joinValues = [...allFieldsTable1, ...allFieldsTable2] as const
type AllFieldsType = (typeof joinValues)[number]

function Step4({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_1Entity>({ control })
    const fieldsTable: AllFieldsType[] = [...joinValues]

    const disabledNext = !fieldsTable.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">4. Запослени и радно ангажовани према уобичајеним сатима рада и полу, за годину извештавања:</h1>
            <ComplexTable
                rows={rowsTable1}
                control={control}
                allFemaleFields={[...allFemaleFieldsTable1]}
                allMaleFields={[...allMaleFieldsTable1]}
                allFields={[...allFieldsTable1]}
                header={['Број сати у недељи', 'Жене', 'Жене %', 'Мушкарци', 'Мушкарци %', 'Укупно', 'Укупно %']}
                errors={errors}
                disabled={adminView}
                decimal
            />
            <h1 className="step-name mt-10">Запослени и радно ангажовани према секторима делатности</h1>
            <ComplexTable
                rows={rowsTable2}
                control={control}
                allFemaleFields={[...allFemaleFieldsTable2]}
                allMaleFields={[...allMaleFieldsTable2]}
                allFields={[...allFieldsTable2]}
                header={['Сектор', 'Жене', 'Жене %', 'Мушкарци', 'Мушкарци %', 'Укупно', 'Укупно %']}
                errors={errors}
                disabled={adminView}
                decimal
            />
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step4
