import React, { useEffect, useState } from 'react'
import { getChildCategories, getParentCategories } from '../../../server/server'
import Loading from '../../../components/Common/LoadingSpinner'
import { Button, FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material'

const CategorySelectionStep2 = ({ selected, nextStep, prevStep, setSubSelected, specialCase }: any) => {
    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    useEffect(() => {
        getChildren()
    }, [])

    const getChildren = async () => {
        setLoading(true)
        const resp = await getChildCategories(selected?.id)
        if (resp.status === 200) {
            setCategories(resp.data)
        }
        setLoading(false)
    }

    const onChange = (cat: any) => {
        setSelectedCategory(cat.id)
        setSubSelected(cat)
        setErrorMessage(null)
    }

    const handleNext = () => {
        if (selectedCategory) {
            nextStep()
        } else {
            setErrorMessage('Молимо вас да одаберете категорију.')
        }
    }

    const renderSpecialCaseMessage = (caseType: string) => {
        switch (caseType) {
            case 'employers':
                return (
                    <p className="mt-[40px]">
                        У складу са члановима 65. и 66. Закона о родној равноправности, као и чланом 2. Правилника о вођењу евиденције и извештавању о остваривању родне
                        равноправности, послодавци који запошљавају или радно ангажују мање од 50 лица нису у обавези да евидентирају податке о остваривању родне равноправности.
                        Ипак, позивамо Вас да поднесете извештај и тиме допринесете остваривању родне равноправности.
                    </p>
                )
            case 'region':
                return (
                    <p className="mt-[40px]">
                        У складу са члановима 65. и 66. Закона о родној равноправности, као и чланом 2. Правилника о вођењу евиденције и извештавању о остваривању родне
                        равноправности, ОЈБ чији је оснивач Регион тј. који су регионалног карактера нису у обавези да евидентирају податке о остваривању родне равноправности.
                        Ипак, позивамо Вас да поднесете извештај и тиме допринесете остваривању родне равноправности.
                    </p>
                )
            case 'associations':
                return (
                    <p className="mt-[40px]">
                        У складу са члановима 65. и 66. Закона о родној равноправности, као и чланом 2. Правилника о вођењу евиденције и извештавању о остваривању родне
                        равноправности, удружења која нису носиоци или партнери у реализацији мера и активности Закона о родној равноправности нису у обавези да евидентирају
                        податке о остваривању родне равноправности. Ипак, позивамо Вас да поднесете извештај и тиме допринесете остваривању родне равноправности.
                    </p>
                )
            default:
                return null
        }
    }

    return (
        <div className="w-full h-full flex items-center justify-center flex-col">
            <div className="max-w-[650px] min-w-[600px]">
                {!specialCase ? (
                    <>
                        <span>
                            <p>Уколико вам је оснивач {selected?.name} молимо Вас да одаберете категорију</p>
                        </span>
                        {loading ? (
                            <Loading height={50} />
                        ) : (
                            <FormControl className="!mt-[40px]">
                                <p className="mb-[20px]">Да ли сте:</p>
                                <RadioGroup>
                                    {categories.map((cat: any) => (
                                        <FormControlLabel value={cat.id} control={<Radio />} onChange={() => onChange(cat)} label={cat.name} />
                                    ))}
                                </RadioGroup>
                                {errorMessage && <FormHelperText className="!text-textError !text-[15px] !text-center">{errorMessage}</FormHelperText>}
                            </FormControl>
                        )}
                        <div className="flex flex-row justify-between mt-[30px] w-full max-w-[600px]">
                            <button
                                className="w-full h-[42px] flex items-center justify-center rounded-[4px] border border-[#243964] text-[15px] text-[#243964] font-medium leading-[26px] cursor-pointer mt-[43px] max-w-[200px]"
                                onClick={prevStep}
                            >
                                НАЗАД
                            </button>
                            <button
                                className="w-full h-[42px] flex items-center justify-center rounded-[4px] bg-[#243964] text-[15px] text-[#ffffff] font-medium leading-[26px] cursor-pointer mt-[43px] max-w-[200px]"
                                onClick={handleNext}
                            >
                                ДАЉЕ
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <span>
                            <p>Уколико вам је оснивач {selected?.name} </p>
                            {renderSpecialCaseMessage(specialCase)}
                        </span>
                        {loading ? (
                            <Loading height={50} />
                        ) : (
                            <FormControl className="!mt-[40px]">
                                <p className="mb-[20px]">Да ли сте:</p>
                                <RadioGroup>
                                    {categories.map((cat: any) => (
                                        <FormControlLabel value={cat.id} control={<Radio />} onChange={() => onChange(cat)} label={cat.name} />
                                    ))}
                                </RadioGroup>
                                {errorMessage && <FormHelperText className="!text-textError !text-[15px] !text-center">{errorMessage}</FormHelperText>}
                            </FormControl>
                        )}
                        <div className="flex flex-row justify-between mt-[30px] w-full max-w-[600px]">
                            <button
                                className="w-full h-[42px] flex items-center justify-center rounded-[4px] border border-[#243964] text-[15px] text-[#243964] font-medium leading-[26px] cursor-pointer mt-[43px] max-w-[200px]"
                                onClick={prevStep}
                            >
                                НАЗАД
                            </button>
                            <button
                                className="w-full h-[42px] flex items-center justify-center rounded-[4px] !bg-[#243964] text-[15px] text-[#ffffff] font-medium leading-[26px] cursor-pointer' mt-[43px] max-w-[200px]"
                                onClick={handleNext}
                            >
                                ДАЉЕ
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default CategorySelectionStep2
