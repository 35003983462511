import '../../../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../../../components/ActionButtons'
import { Control, useWatch } from 'react-hook-form'
import Textbox from '../../../../Contact/TextBoxAutoSize'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { Form7_8Entity } from '../../../../../models/Form7/Entities/Form7_8.entity'

export interface StepInterface {
    control?: Control<Form7_8Entity>
    nextStep: () => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
    clearErrors?: any
}

export function Step1({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_8Entity>({ control })
    const disabledNext =
        watchedValues?.o7_8_k1_affirming_programs === undefined ||
        typeof watchedValues.o7_8_k1_affirming_programs !== 'number' ||
        watchedValues?.o7_8_k1_revised_books === undefined ||
        typeof watchedValues.o7_8_k1_revised_books !== 'number' ||
        watchedValues?.o7_8_k1_sexual_health_content === undefined ||
        typeof watchedValues.o7_8_k1_sexual_health_content !== 'number' ||
        watchedValues?.o7_8_k1_woman_dropout === undefined ||
        typeof watchedValues.o7_8_k1_woman_dropout !== 'number' ||
        watchedValues?.o7_8_k1_woman_dropout_percent === undefined ||
        typeof watchedValues.o7_8_k1_woman_dropout_percent !== 'number'

    const goToNextStep = () => {
        if (!disabledNext && !errors?.o4_k7_procenat) nextStep()
        else if (adminView) nextStep()
    }

    const inputConfigs = [
        {
            name: 'o7_8_k1_affirming_programs',
            label: 'Број програма наставе и учења са садржајима који афирмишу родну равноправност и повећавају видљивост женског доприноса у науци и култури.',
        },
        {
            name: 'o7_8_k1_revised_books',
            label: 'Број ревидираних уџбеника за средње школе ради елиминисања родних стереотипа, дискриминаторних садржаја и дискриминаторног језика.',
        },
        {
            name: 'o7_8_k1_sexual_health_content',
            label: 'Број садржаја о сексуалном и репродуктивном здрављу и правима, као обавезних у програмима средњих школа.',
        },
        {
            name: 'o7_8_k1_woman_dropout',
            label: 'Број  жена које су напустиле средње образовање у години извештавања.',
        },
    ]

    return (
        <div>
            <div>
                {inputConfigs.map((config, index) => (
                    <>
                        <h1 className="step-name mb-[20px] ">{config.label}</h1>
                        <div className=" flex flex-row gap-5 mb-[40px]">
                            <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                                <PositiveNumberInputCell name={config.name} control={control} errors={errors} isRequired={!adminView} height={52} disabled={adminView} />
                            </div>
                        </div>
                    </>
                ))}
                <h1 className="step-name mb-[20px] "> Проценат жена које су напустиле средње образовање у години извештавања.</h1>
                <div className=" flex flex-row gap-5 mb-[40px]">
                    <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                        <PositiveNumberInputCell
                            name={'o7_8_k1_woman_dropout_percent'}
                            control={control}
                            errors={errors}
                            isRequired={!adminView}
                            height={52}
                            precentage={true}
                            decimal={true}
                            max={100}
                            disabled={adminView}
                        />
                    </div>
                </div>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
