import React, { useState } from 'react'
import Tour from 'reactour'
import loginImage from '../../../../assets/images/LoginTutorialImages/Login.png'
import Actions from '../../Components/Actions'
import { TutorialStepInterface } from './Step1'
import { ReactComponent as ShowPasswordIcon } from '../../../../assets/svg/EyeFilled.svg'
import { ReactComponent as HidePasswordIcon } from '../../../../assets/svg/EyeHidden.svg'
import { Button } from '@mui/material'

const Step2 = ({ nextStep, previousStep, previousButtonDisabled = false, skipClick, onClose }: TutorialStepInterface) => {
    const [isTourOpen, setIsTourOpen] = useState(true)

    const tourSteps = [
        {
            selector: 'form[id="main"]',
            content: 'Овај образац служи да се пријавите у систем за подношење извештаја.',
        },
        {
            selector: 'div[id="email"]',
            content: 'Овде унесите своју регистровану е-mail адресу. Уколико се нисте регистровали, молимо Вас да се прво региструјете.',
        },
        {
            selector: 'span[id="error"]',
            content: 'Уколико тражене информације нису унете или постоји грешка са њима појавиће се текстуална порука са објашњењем грешке.',
        },
        {
            selector: 'div[id="password"]',
            content: ' Унесите лозинку повезану са вашим налогом. Кликом на икону „око” поред поља можете приказати или сакрити лозинку.',
        },
        {
            selector: 'Button[id="loginButton"]',
            content: 'Кликните на ово дугме након што унесете своје податке како бисте се пријавили у систем.',
        },
        {
            selector: 'div[id="forgot"]',
            content: 'Ако сте заборавили лозинку, кликните на овај линк како бисте започели процес њеног опоравка.',
        },
        {
            selector: 'Button[id="eid"]',
            content: 'Ако имате ЕИД налог, можете користити ово дугме за пријаву.',
        },
        {
            selector: 'div[id="register"]',
            content: 'Ако још увек немате налог, кликните на овај линк како бисте се регистровали',
        },
    ]

    return (
        <div className="flex justify-center h-full relative flex-col items-center">
            <Tour steps={tourSteps} isOpen={isTourOpen} onRequestClose={() => setIsTourOpen(false)} />

            <div className="px-[99px] bg-[#ffffff]  items-center flex flex-col">
                <h2 className="mb-[32px] text-[24px] leading-[28px] font-light cursor-default">Пријава</h2>

                <form className="flex flex-col items-center w-full " autoComplete="off">
                    <div id="email" className="w-full flex flex-col">
                        <input
                            disabled
                            className="border border-[#0000003B] min-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px] cursor-default"
                            placeholder="Емаил"
                        />
                        <span id="error" className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] cursor-default">
                            Емаил је обавезно поље
                        </span>
                    </div>
                    <div className="flex flex-col w-full mt-[16px]">
                        <div id={'password'} className="border border-[#0000003B] rounded-[4px] min-w-[520px] min-h-[56px] w-full px-3 flex flex-row items-center">
                            <input disabled className="text-[16px] leading-[24px] w-full outline-none cursor-default" placeholder="Лозинка" type="text" autoComplete="off" />
                            <div className="cursor-default">
                                <ShowPasswordIcon />
                            </div>
                        </div>
                    </div>
                    <Button
                        id={'loginButton'}
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{
                            backgroundColor: '#243964',
                            '&.Mui-disabled': {
                                backgroundColor: '#243964',
                                color: 'white',
                            },
                            marginTop: '20px',
                        }}
                        disabled
                    >
                        ПРИЈАВИТЕ СЕ
                    </Button>
                </form>
                <div id="forgot" className="my-[10px] text-[#243964] text-[16px] underline leading-[24px] flex justify-start items-start text-left cursor-default">
                    Заборављена лозинка?
                </div>
                <Button
                    id={'eid'}
                    type="button"
                    variant="contained"
                    color="info"
                    fullWidth
                    sx={{
                        backgroundColor: '#0388d1',
                        '&.Mui-disabled': {
                            backgroundColor: '#0388d1',
                            color: 'white',
                        },
                    }}
                    disabled
                >
                    Приступ путем eID налога
                </Button>
                <div id="register" className="flex flex-row mx-auto gap-[8px] mt-[20px]">
                    <p className="font-[16px] leading-[24px] cursor-default">Немате налог?</p>
                    <p className="font-[16px] leading-[24px] underline cursor-default">Региструјте се</p>
                </div>
            </div>
            <div className="w-full max-w-[70%]">
                <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={true} skipButton={skipClick} closeModal={onClose} />
            </div>
        </div>
    )
}

//         <div className="w-full h-full flex items-center flex-col">
//             <div className="flex items-center justify-center w-full">
//                 <img src={loginImage} alt="Страница за пријаву" width={500} />
//             </div>
//             <div className="w-full flex flex-col items-center h-full justify-center">
//                 <p className="font-bold text-[18px]">Пријава на систем</p>
//                 <div className="w-full flex flex-col gap-2 mt-2">
//                     {steps.map((step, index) => (
//                         <div key={index} className="flex flex-row gap-2 items-start">
//                             <span className="font-bold text-[16px]">{step.title}</span>–<span className="text-[16px] flex-1">{step.description}</span>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//             <div className="mt-10 w-full max-w-[70%]">
//                 <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={false} skipButton={skipClick} />
//             </div>
//         </div>
//     )
// }

export default Step2
