import React, { useEffect } from 'react'
import { StepInterface } from './Step1'
import PositiveNumberInputCell from '../../../Form2/Components/PositiveNumberInputCell'
import ActionButtons from '../../../../components/ActionButtons'
import { getAnalyticsDataForForm2 } from '../../../../server/server'

const Step9 = ({
    control,
    nextStep,
    previousStep,
    previousButtonDisabled = false,
    errors,
    adminView,
    countType,
    setValue,
    selectedCategories,
    selectedSubCategories,
}: StepInterface) => {
    const fields: 'o2_k9_broj'[] = ['o2_k9_broj']

    useEffect(() => {
        loadData()
    }, [countType, selectedCategories, selectedSubCategories])

    const loadData = async () => {
        const data = {
            type: countType,
            fields: fields,
            category: selectedCategories?.map((e) => e.name),
            subCategory: selectedSubCategories?.map((e) => e.name),
        }
        const resp = await getAnalyticsDataForForm2(data)
        if (resp.status === 200) {
            const data = resp.data
            fields.forEach((field) => {
                setValue(field, parseFloat(data[field]))
            })
        }
    }
    return (
        <div>
            <h1 className="step-name">Број иницираних и предложених мера за унапређење родне равноправности од стране тела за родну равноправност у години вођења евиденције:</h1>
            <div className="flex flex-row gap-2 ">
                <div className="mt-[20px] w-[140px] py-[15px] flex justify-end">
                    <PositiveNumberInputCell name={'o2_k9_broj'} control={control} decimal={false} errors={errors} isRequired={false} height={52} disabled={true} />
                </div>
            </div>
            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step9
