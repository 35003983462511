import { Organization } from '../../Organization.entity'
import { SubmittedBy } from '../../SubmittedBy.entity'

export const NumberOfSteps = 5

export interface Form7_18Entity {
    report_id: string
    rep_year: number
    submitted_date?: Date
    submitted_place?: Date
    category: string
    organisation: Organization
    status: string
    submitted_by?: SubmittedBy

    o7_18_k1_budzet: boolean

    o7_18_k2_rodniBudzet?: boolean
    o7_18_k2_ukupanBudzet?: number
    o7_18_k2_deoBudzeta?: number
    o7_18_k2_vrstePrograma?: Programs[]

    o7_18_k3_posedujeAkte: boolean
    o7_18_k3_akti: Acts[]
    o7_18_k3_rodnoOsetljiviProgrami: number
    o7_18_k3_ukuonoPrograma: number
    o7_18_k3_opis: string //max2048

    o7_18_k4_prepreke: Obstacles
    o7_18_k4_opis: string //max200
}

interface Obstacles {
    nedostatakSaznanja: boolean
    nejasnaRegulativa: boolean
    losSoftverZaPlaniranje: boolean
    nedostatakObuke: boolean
    organizacionePrepreke: boolean
    nedovoljnoVremena: boolean
    nedovoljnoSredstvaUBudzetu: boolean
}

interface Programs {
    id: string
    vrsta: string
    naziv: string
}

interface Acts {
    id: string
    cilj: string
    indikator: string
}
