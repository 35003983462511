import ActionButtons from '../../../../components/ActionButtons'
import NoAnalytics from '../../Common/NoAnalytics'
import { StepInterface } from './Step1'

function Step10({ control, nextStep, previousStep, previousButtonDisabled = false, adminView }: StepInterface) {
    return (
        <div>
            <h1 className="step-name">
                10. Начин поступања одговорног лица у органима јавне власти и послодавца по поднетим пријавама запослених и радно ангажованих лица о њиховој изложености
                узнемиравању, сексуалном узнемиравању или уцењивању или неком другом поступању које има за последицу дискриминацију на основу пола, односно рода, разврстаних по
                полу и старосној доби.
            </h1>

            <NoAnalytics />

            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step10
