import React, { useState } from 'react'
import { StepInterface } from './Step1'
import { useFieldArray, useWatch } from 'react-hook-form'
import { Form7_4_2Entity } from '../../../../../models/Form7/Entities/Form7_4.entity'
import HelpButton from '../../../../../components/Common/HelpButton'
import { getPercentage } from '../../../../../utils/mathUtils'
import TextInputCell from '../../../../../components/Common/TextInputCell'
import { Button } from '@mui/material'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import ActionButtons from '../../../../../components/ActionButtons'
import SelectBody from '../../../../Form2/Components/SelectBody'
import { ReactComponent as Delete } from '../../../../../assets/svg/Delete.svg'

const Step3 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_4_2Entity>({ control })
    const [emptyArrayError, setEmptyArrayError] = useState(false)
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o7_4_2_k3_programs',
    })
    const name = 'o7_4_2_k3_programs'

    let disabledNext = watchedValues[name]?.length === 0

    fields.forEach((item, index) => {
        const { type, plannedExpendature, actualExpendature, description, financialSources } = watchedValues[name]?.[index] || {}

        if (plannedExpendature === undefined || plannedExpendature === null || plannedExpendature?.toString() === '') {
            disabledNext = true
        } else if (actualExpendature === undefined || actualExpendature === null || actualExpendature?.toString() === '') {
            disabledNext = true
        } else if (type === undefined || type === null || type === '') {
            disabledNext = true
        } else if (description === undefined || description === null || description === '') {
            disabledNext = true
        } else if (financialSources === undefined || financialSources === null || financialSources === '') {
            disabledNext = true
        } else disabledNext = false
    })

    const header = ['Врста програма', 'Планирана средства', 'Утрошена средства', 'Опис програма', 'Начин финансирања']

    const handleAddArrayField = () => {
        setEmptyArrayError(false)
        append({ type: '', plannedExpendature: undefined, actualExpendature: undefined, description: '', financialSources: undefined })
    }

    const validateEmptyArray = () => {
        if (watchedValues[name]?.length === 0 || watchedValues[name] === undefined) {
            setEmptyArrayError(true)
            return true
        }
        return false
    }

    const goToNextStep = () => {
        if (!disabledNext && !validateEmptyArray()) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <div className="flex flex-row justify-between">
                <h1>Број и врста стално успостављених програма подршке предузетништву жена</h1>
                <HelpButton name={'o7_4_1_k6'} marginTop={0} />
            </div>
            <table className="multiple-input-table mt-[20px]">
                <tr className="multiple-input-table-header">
                    {header.map((head, index) => (
                        <th
                            className={`multiple-input-table-cell ${index === 0 && '!min-w-[350px]'} ${index === 3 && 'min-w-[500px]'} ${index === 4 && '!min-w-[200px]'}`}
                            key={index}
                        >
                            {head}
                        </th>
                    ))}
                </tr>
                {fields.map((field, index) => {
                    return (
                        <div className="flex flex-row" key={index}>
                            <tr key={field.id} className="multiple-input-table-row">
                                <td className={`multiple-input-table-cell !border-none !min-w-[350px]`}>
                                    <TextInputCell
                                        name={`${name}.${index}.type`}
                                        control={control}
                                        placeholder="Текст (50 карактера)*"
                                        errors={errors}
                                        isRequired={!adminView}
                                        maxNumberOfCharacter={50}
                                        height={62}
                                        disabled={adminView}
                                    />
                                </td>
                                <td className="multiple-input-table-cell editable !border-none">
                                    <PositiveNumberInputCell
                                        name={`${name}.${index}.plannedExpendature`}
                                        control={control}
                                        errors={errors}
                                        isRequired={!adminView}
                                        height={62}
                                        isArray={true}
                                        disabled={adminView}
                                        decimal
                                        isMultipleTable
                                        clearErrors={clearErrors}
                                    />
                                </td>
                                <td className="multiple-input-table-cell editable !border-none">
                                    <PositiveNumberInputCell
                                        name={`${name}.${index}.actualExpendature`}
                                        control={control}
                                        errors={errors}
                                        isRequired={!adminView}
                                        height={62}
                                        isArray={true}
                                        disabled={adminView}
                                        isMultipleTable
                                        clearErrors={clearErrors}
                                        decimal
                                    />
                                </td>
                                <td className={`multiple-input-table-cell !border-none !min-w-[500px]`}>
                                    <TextInputCell
                                        name={`${name}.${index}.description`}
                                        control={control}
                                        placeholder="Текст (200 карактера)*"
                                        errors={errors}
                                        isRequired={!adminView}
                                        maxNumberOfCharacter={200}
                                        height={62}
                                        disabled={adminView}
                                    />
                                </td>
                                <td className="multiple-input-table-cell editable !min-w-[200px] !border-none">
                                    <SelectBody
                                        control={control}
                                        isRequired={true}
                                        name={`${name}.${index}.financialSources`}
                                        items={['Буџет', 'Донације']}
                                        value={watchedValues?.[name]?.[index]?.financialSources || ''}
                                        errors={errors}
                                        disabled={adminView}
                                        isArray={true}
                                    />
                                </td>
                            </tr>
                            {fields.length >= 2 ? (
                                <div className="ml-[20px] flex justify-center items-center ">
                                    <button onClick={() => remove(index)} disabled={adminView}>
                                        <Delete />
                                    </button>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    )
                })}

                <div className="h-[2px]"> {errors[name] && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            </table>

            <div className="flex flex-col mt-[56px]">
                <Button variant="contained" className="max-w-[172px] mt-[56px]" onClick={handleAddArrayField} disabled={adminView}>
                    Додај сектор
                </Button>
                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError ? 'Додајте барем једну активност!' : ''}</span>
            </div>

            <div className="mt-10">
                <h1>
                    Проценат учешћа жена у секторима дигиталне, циркуларне и зелене економије (Базна вредност за индикатор и детаљан начин израчунавања ће се разрадити у оквиру
                    првог акционог плана).
                </h1>
                <div className="max-w-[200px] mt-5">
                    <PositiveNumberInputCell
                        name={'o7_4_2_k3_z'}
                        control={control}
                        errors={errors}
                        isRequired={false}
                        height={52}
                        isArray={true}
                        disabled={adminView}
                        decimal
                        precentage
                        isMultipleTable
                        clearErrors={clearErrors}
                    />
                </div>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step3
