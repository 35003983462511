import { useWatch } from 'react-hook-form'
import ActionButtons from '../../../components/ActionButtons'
import { TblOblast6 } from '../../../models/Form6/Form6.entity'
import Textbox from '../../../components/Common/Textbox'
import TextInputCellForField from '../Components/TextInputCellForField'
import { StepInterface } from './Step1'

export interface RowInterface {
    label: string
    field: keyof TblOblast6
}

const rows: RowInterface[] = [
    { label: 'Катастрофалне', field: 'o6_k4_t1' },
    { label: 'Озбиљне', field: 'o6_k4_t2' },
    { label: 'Умерене', field: 'o6_k4_t3' },
    { label: 'Мале', field: 'o6_k4_t4' },
    { label: 'Минималне', field: 'o6_k4_t5' },
]

function Step4({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast6>({ control })

    const fields: ('o6_k4_t1' | 'o6_k4_t2' | 'o6_k4_t3' | 'o6_k4_t4' | 'o6_k4_t5' | 'o6_k4_t6')[] = ['o6_k4_t1', 'o6_k4_t2', 'o6_k4_t3', 'o6_k4_t4', 'o6_k4_t5', 'o6_k4_t6']

    const disabledNext = !fields?.every((e) => (e in watchedValues ? !!watchedValues[e] : false))
    const showErrorMessage = errors.o6_k4_t1 || errors.o6_k4_t2 || errors.o6_k4_t3 || errors.o6_k4_t4 || errors.o6_k4_t5
    const goToNexStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <h1 className="text-[18px] leading-6 text-textPrimary ">
                Навести процену последица које су наступиле услед повреде принципа родне равноправности код запослених лица и/или странака у процесу рада
            </h1>
            <table className="simple-table mt-[13px] gap-2">
                <tr className="simple-table-header">
                    <th className=" w-[141px] h-[54px]"></th>
                    <th className=" w-[700px] h-[54px]">Процена последица</th>
                </tr>
                {rows.map((row, index) => (
                    <tr className="simple-table-row" key={row.field}>
                        <td className=" disabled w-[141px] h-[54px]">{row.label}</td>
                        <td className=" editable w-[700px] h-[54px]" style={{ padding: 0, border: 0 }}>
                            <TextInputCellForField
                                name={row.field}
                                control={control}
                                placeholder="Tекст (50 карактера)"
                                errors={errors}
                                isRequired={!adminView}
                                disabled={adminView}
                                maxNumberOfCharacter={50}
                            />
                        </td>
                    </tr>
                ))}
            </table>
            <p className="ml-5 min-h-[30px] text-textError text-[0.75rem] text leading-[30px]">{showErrorMessage ? 'Попуните обавезно поље.' : ''}</p>
            <div className="mt-[20px]">
                <h4 className="mb-[10px] text-[18px] leading-6 text-textPrimary">
                    Навести резултате процене потреба и могућности за заштиту запослених лица и/или странака у процесу рада од повреде принципа родне равноправности
                </h4>
                <Textbox
                    name="o6_k4_t6"
                    control={control}
                    placeholder="Tекст (2048 карактера)"
                    errors={errors}
                    isRequired={adminView ? false : 'Попуните обавезно поље.'}
                    disabled={adminView}
                    maxNumberOfCharacters={2048}
                />
            </div>
            <ActionButtons nextButton={goToNexStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step4
