import React, { useState } from 'react'
import { TblOblast4 } from '../../../models/Form4/Form4.entity'
import '../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useFieldArray, useWatch } from 'react-hook-form'
import { Button, Stack } from '@mui/material'
import { TextboxState } from '../../../components/Common/Textbox'
import { SimpleInputRowState } from '../../../components/Common/SimpleInputRow'
import { PositiveDecimalInputSeparateState } from '../../../components/Common/PositiveDecimalInputSeparate'
import { RadioButtonsControlState } from '../../../components/Common/RadioButtonsControl'
import { GenderEqualityCooperation } from '../../../models/GenderEqualityCooperation.entity'
import HiddenDatePicker from '../../../components/Common/DatePicker'
import dayjs from 'dayjs'
import { StepInterface } from './Step1'
import { isNotEmpty } from '../../../utils/Validation'

function Step9({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, isCommission }: StepInterface) {
    const watchedValues = useWatch<TblOblast4>({ control })
    const [newEntity, setNewEntity] = useState<GenderEqualityCooperation>({})
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o4_k9_saradnja',
    })
    const [submitError, setSubmitError] = useState<string | undefined>()
    const [fieldErrors, setFieldErrors] = useState<boolean>(false)

    const updateEntity = (value: any, field: 'activity_name' | 'activity_holders' | 'partners' | 'start_date' | 'end_date' | 'resources' | 'used_funds' | 'grade') => {
        setNewEntity((prev) => ({ ...prev, [field]: value }))
    }

    const grades = ['Одлично', 'Врло добро', 'Задовољава', 'Недовољно']

    const allFields: ('activity_name' | 'activity_holders' | 'partners' | 'start_date' | 'end_date' | 'resources' | 'used_funds')[] = [
        'activity_name',
        'activity_holders',
        'partners',
        'start_date',
        'end_date',
        'resources',
        'used_funds',
    ]

    const disabledNext = watchedValues.o4_k9_saradnja === undefined || watchedValues.o4_k9_saradnja?.length === 0
    const disabledAdd = !allFields.every((e) => isNotEmpty(newEntity)) || newEntity.grade === undefined
    const activityBeingFilled = newEntity.grade !== undefined || allFields.some((e) => !!newEntity[e])

    const goToNextStep = () => {
        if (adminView) nextStep()
        else if (!disabledNext) {
            if (activityBeingFilled) {
                setSubmitError('Морате сачувати тренутну активност притиском на дугме „Додај активност“ или поништити унос притиском на дугме „Прекини унос активности“.')
            } else {
                nextStep()
            }
        } else {
            if (disabledAdd) {
                setSubmitError('Морате додати најмање једну активност.')
            } else {
                setSubmitError('Морате сачувати тренутну активност притиском на дугме „Додај Активност“.')
            }
        }
    }

    const handleCancelInput = () => {
        setNewEntity({})
        setFieldErrors(false)
        setSubmitError(undefined)
    }

    const handleAddField = () => {
        if (!disabledAdd) {
            setFieldErrors(false)
            setSubmitError(undefined)
            append(newEntity)
            setNewEntity({})
        } else {
            setFieldErrors(true)
        }
    }

    return (
        <div>
            <h1 className="step-name mb-[20px]">
                {isCommission
                    ? 'Остварени степен и квалитет сарадње комисије за родну равноправност са другим радним телима или органима на републичком, покрајинском и локалном нивоу по питању остваривања и унапређивања родне равноправности'
                    : 'Остварени степен и квалитет сарадње савета за родну равноправност са другим радним телима или органима на републичком, покрајинском и локалном нивоу по питању остваривања и унапређивања родне равноправности'}
            </h1>
            <hr className="mt-[50px] mb-[50px] bg-[#ffffff] " />
            {fields.map((field, index) => (
                <div key={index} className="mb-[50px]">
                    <div className="mt-[20px] mb-[50px] flex flex-col gap-[15px] ml-[5px]">
                        <SimpleInputRowState title="Назив активности" value={`${field.activity_name ?? ''}`} disabled />
                        <SimpleInputRowState title="Носиоци активности" value={`${field.activity_holders ?? ''}`} disabled />
                        <SimpleInputRowState title="Партнери" value={`${field.partners ?? ''}`} disabled />
                        <SimpleInputRowState title="Трајање од" value={field.start_date ? dayjs(field.start_date).format('DD/MM/YYYY') : ''} disabled />
                        <SimpleInputRowState title="Трајање до" value={field.end_date ? dayjs(field.end_date).format('DD/MM/YYYY') : ''} disabled />
                    </div>
                    <p className="opacity-[60%] mb-[16px]"> Ангажовани ресурси: </p>
                    <TextboxState value={field.resources ?? ''} disabled />
                    <h1 className="opacity-[60%] mt-[16px] mb-[20px]"> Утрошена средства </h1>
                    <PositiveDecimalInputSeparateState value={field.used_funds?.toString() ?? ''} disabled decimal />
                    <h1 className="opacity-[60%] mt-[30px]"> Оцену квалитета и степена реализације активности </h1>
                    <RadioButtonsControlState options={grades} value={field?.grade !== undefined ? grades[field.grade] : ''} disabled />
                    <div className="mt-[20px]">
                        <Button color="error" variant="contained" onClick={() => remove(index)}>
                            Обриши ову активност
                        </Button>
                    </div>
                    <hr className="mt-[50px] mb-[50px] bg-[#ffffff] " />
                </div>
            ))}

            <h1 className="step-name "> Додајте активност: </h1>
            <div className="mt-[20px] mb-[50px] flex flex-col gap-[15px] ml-[5px]">
                <SimpleInputRowState
                    title="Назив активности"
                    value={`${newEntity?.activity_name ?? ''}`}
                    error={fieldErrors && !newEntity.activity_name}
                    onChange={(text) => updateEntity(text, 'activity_name')}
                    placeholder={'Текст (300 карактера)'}
                    maxNumberOfCharacter={300}
                    disabled={adminView}
                />
                <SimpleInputRowState
                    title="Носиоци активности"
                    value={`${newEntity?.activity_holders ?? ''}`}
                    error={fieldErrors && !newEntity.activity_holders}
                    onChange={(text) => updateEntity(text, 'activity_holders')}
                    placeholder={'Текст (300 карактера)'}
                    maxNumberOfCharacter={300}
                    disabled={adminView}
                />
                <SimpleInputRowState
                    title="Партнери"
                    value={`${newEntity?.partners ?? ''}`}
                    error={fieldErrors && !newEntity.partners}
                    onChange={(text) => updateEntity(text, 'partners')}
                    placeholder={'Текст (300 карактера)'}
                    maxNumberOfCharacter={300}
                    disabled={adminView}
                />
                <div className="flex flex-row gap-[10px] h-[50px]">
                    <div className="w-[186px] border flex items-center justify-center h-[50px] bg-[#F5F5F5] text-disabled border-[#00000010]">
                        <p className="text-center mx-auto">Трајање од</p>
                    </div>
                    <HiddenDatePicker
                        tabIndex={0}
                        name={'od'}
                        value={newEntity && newEntity?.start_date}
                        control={control}
                        errors={(fieldErrors && newEntity.start_date) ?? { od: { type: 'required' } }}
                        isRequired={true}
                        isFirst={true}
                        maxDate={newEntity && newEntity.end_date}
                        lastStep
                        onChangeFunc={(date: any) => updateEntity(date, 'start_date')}
                        disabled={adminView}
                    />
                </div>
                <div className="flex flex-row gap-[10px] h-[50px]">
                    <div className="w-[186px] border flex items-center justify-center h-[50px] bg-[#F5F5F5] text-disabled border-[#00000010]">
                        <p className="text-center mx-auto">Трајање до</p>
                    </div>
                    <HiddenDatePicker
                        tabIndex={0}
                        name={'do'}
                        value={newEntity && newEntity?.end_date}
                        control={control}
                        errors={(fieldErrors && newEntity.end_date) ?? { do: { type: 'required' } }}
                        isRequired={true}
                        minDate={newEntity && newEntity?.start_date}
                        lastStep
                        isSecond
                        onChangeFunc={(date: any) => updateEntity(date, 'end_date')}
                        disabled={adminView}
                    />
                </div>
            </div>

            <p className="mb-[16px]"> Ангажовани ресурси: </p>
            <TextboxState
                value={newEntity?.resources ?? ''}
                onChange={(text) => updateEntity(text, 'resources')}
                maxNumberOfCharacters={300}
                error={fieldErrors && !newEntity.resources}
                disabled={adminView}
            />

            <h1 className="mt-[16px] mb-[20px]"> Утрошена средства: </h1>
            <PositiveDecimalInputSeparateState
                value={newEntity?.used_funds ? undefined : ''}
                onChange={(number) => {
                    updateEntity(number, 'used_funds')
                }}
                error={(fieldErrors && typeof newEntity.used_funds !== 'number') || Number.isNaN(newEntity.used_funds)}
                decimal
                disabled={adminView}
            />

            <h1 className="mt-[50px]"> Оцену квалитета и степена реализације активности </h1>
            <RadioButtonsControlState
                options={grades}
                value={newEntity?.grade !== undefined ? grades[newEntity.grade] : ''}
                onChange={(text) => {
                    updateEntity(grades.indexOf(text), 'grade')
                }}
                error={fieldErrors && newEntity.grade === undefined}
                disabled={adminView}
            />

            <Stack spacing={'10px'} direction="row" className="mt-[50px]">
                <Button variant="contained" onClick={handleAddField} disabled={adminView}>
                    Додај активност
                </Button>
                {fields.length > 0 && (
                    <Button variant="contained" onClick={handleCancelInput} disabled={adminView}>
                        Прекини унос активности
                    </Button>
                )}
            </Stack>

            {submitError && <p className="mt-[50px] min-h-5 text-textError text-[1rem] text leading-5">{submitError}</p>}

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step9
