import React from 'react'
import { Control, FieldValues, useWatch } from 'react-hook-form'
import { getPercentage, subtractValues, sumValues } from '../../../utils/mathUtils'
import PositiveNumberInputCell from '../../Form2/Components/PositiveNumberInputCell'

interface RowInterface<T> {
    label: string
    fields: (keyof T)[]
}
interface ComplexReductionTableInterface<T extends FieldValues> {
    rows: RowInterface<T>[]
    control: Control<T> | undefined
    allFields: (keyof T)[]
    allMaleFields: (keyof T)[]
    allFemaleFields: (keyof T)[]
    header: string[]
    decimal?: boolean
    errors?: any
    disabled?: boolean
}

function ComplexReductionTable<T extends FieldValues>({
    rows,
    control,
    header,
    allFields,
    allMaleFields,
    allFemaleFields,
    decimal = false,
    errors,
    disabled,
}: ComplexReductionTableInterface<T>) {
    type AllFieldsType = (typeof allFields)[number]
    type MaleFieldsType = (typeof allMaleFields)[number]
    type FemaleFieldsType = (typeof allFemaleFields)[number]

    const watchedValues = useWatch({ control }) as T
    const fields: AllFieldsType[] = allFields
    const maleFields: MaleFieldsType[] = [...allMaleFields]
    const femaleFields: FemaleFieldsType[] = [...allFemaleFields]
    const subtractedMale = subtractValues(watchedValues, maleFields)
    const subtractedFemale = subtractValues(watchedValues, femaleFields)
    const totalSum = sumValues(watchedValues, fields)
    const sumMale = sumValues(watchedValues, maleFields)
    const sumFemale = sumValues(watchedValues, femaleFields)

    let womenPer100MenTotal = 0
    let womenPer100Men = 0
    if (subtractedMale > 0) {
        womenPer100MenTotal = (subtractedFemale / subtractedMale) * 100
    } else if (subtractedMale === 0 && subtractedFemale === 0) {
        womenPer100MenTotal = 100
    }

    const hasRowErrors = rows.some((row) => row.fields.some((field) => errors?.[field]))

    return (
        <table className="complex-table mt-[30px]">
            <tr className="complex-table-header">
                {header.map((head, index) => (
                    <th className="complex-table-cell" key={index}>
                        {head}
                    </th>
                ))}
            </tr>
            {rows.map((row, index) => {
                const maleValue = watchedValues[row.fields[1]] || 0
                const femaleValue = watchedValues[row.fields[0]] || 0

                const sumMaleForRow = sumValues(watchedValues, [row.fields[1]])
                const sumFemaleForRow = sumValues(watchedValues, [row.fields[0]])

                if (sumMaleForRow > 0) {
                    womenPer100Men = (femaleValue / sumMaleForRow) * 100
                } else if (sumMaleForRow === 0 && sumFemaleForRow === 0) {
                    womenPer100Men = 0
                }
                return (
                    <tr className="complex-table-row" key={row.label}>
                        <td className="complex-table-cell disabled">{row.label}</td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0 }}>
                            <PositiveNumberInputCell
                                name={row.fields[0] as string}
                                control={control}
                                decimal={decimal}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={disabled}
                            />
                        </td>
                        <td className="complex-table-cell disabled">{getPercentage(watchedValues[row.fields[0]], sumFemale)}%</td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0 }}>
                            <PositiveNumberInputCell
                                name={row.fields[1] as string}
                                control={control}
                                decimal={decimal}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={disabled}
                            />
                        </td>
                        <td className="complex-table-cell disabled">{getPercentage(watchedValues[row.fields[1]], sumMale)}%</td>
                        <td className="complex-table-cell disabled">{(womenPer100Men || 0).toFixed(2)}</td>
                    </tr>
                )
            })}
            <tr className="complex-table-footer">
                <td className="complex-table-cell">Разлика</td>
                <td className="complex-table-cell">{decimal ? (subtractedFemale || 0).toFixed(2) : subtractedFemale || 0}</td>
                <td className="complex-table-cell">{getPercentage(subtractedFemale || 0, sumFemale || 0)}%</td>
                <td className="complex-table-cell">{decimal ? (subtractedMale || 0).toFixed(2) : subtractedMale || 0}</td>
                <td className="complex-table-cell">{getPercentage(subtractValues(watchedValues, maleFields), sumValues(watchedValues, maleFields))}%</td>
                <td className="complex-table-cell">{(womenPer100MenTotal || 0).toFixed(2)}</td>
            </tr>
            <div className="h-[2px]">{hasRowErrors && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
        </table>
    )
}

export default ComplexReductionTable
