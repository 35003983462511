import { TblOblast1 } from '../../../../models/Form1/Form1.entity'
import ActionButtons from '../../../../components/ActionButtons'
import { Control, useWatch } from 'react-hook-form'
import SimpleTable from '../../../../components/Tables/SimpleTable'
import HelpButton from '../../../../components/Common/HelpButton'
import { useEffect } from 'react'
import { getAnalyticsDataForForm2 } from '../../../../server/server'
import { TblOblast2 } from '../../../../models/Form2/Form2.entity'

export interface StepInterface {
    control?: Control<TblOblast2>
    nextStep: (skip?: boolean) => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
    countType?: 'total' | 'average'
    selectedCategories?: any[]
    selectedSubCategories?: any[]
}
export interface RowInterface {
    label: string
    field: keyof TblOblast2
}

const rows: RowInterface[] = [
    { label: 'Мушкарци', field: 'o2_k1_m' },
    { label: 'Жене', field: 'o2_k1_z' },
]

function Step1({
    control,
    nextStep,
    previousStep,
    previousButtonDisabled = false,
    errors,
    adminView,
    countType,
    setValue,
    selectedCategories,
    selectedSubCategories,
}: StepInterface) {
    const watchedValues = useWatch<TblOblast2>({ control })
    const fields: ('o2_k1_m' | 'o2_k1_z')[] = ['o2_k1_m', 'o2_k1_z']

    useEffect(() => {
        loadData()
    }, [countType, selectedCategories, selectedSubCategories])

    const loadData = async () => {
        const data = {
            type: countType,
            fields: fields,
            category: selectedCategories?.map((e) => e.name),
            subCategory: selectedSubCategories?.map((e) => e.name),
        }
        const resp = await getAnalyticsDataForForm2(data)
        if (resp.status === 200) {
            const data = resp.data
            fields.forEach((field) => {
                setValue(field, parseFloat(data[field]))
            })
        }
    }

    return (
        <div>
            <h1 className="step-name">Број и проценат лица именованих у састав тела за родну равноправност</h1>
            <div className="flex flex-row gap-2 ">
                <SimpleTable
                    control={control}
                    header={['Пол', countType == 'total' ? 'Ukupan zbir' : 'Srednja vrednost', 'Проценат']}
                    rows={rows}
                    fields={fields}
                    errors={errors}
                    disabled={true}
                />
            </div>
            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step1
