import { MenuItem, Select } from '@mui/material'
import React from 'react'

interface Option {
    value: number | undefined
    label: string
}

interface DashboardSelectProps {
    selectedFilters?: any
    setSelectedFilters?: any
    options: Option[]
    setObrazacSelect?: any
    obrazacIndex?: any
    formIdentifier?: any
    value: any
    borderColor?: string
}

const DashboardSelectComponent = ({
    selectedFilters,
    setSelectedFilters,
    options,
    setObrazacSelect,
    obrazacIndex,
    formIdentifier,
    value,
    borderColor = '#00000010',
}: DashboardSelectProps) => {
    return (
        <Select
            className="w-full"
            value={value}
            onChange={(e) => {
                const value = e.target.value === '' ? undefined : Number(e.target.value)

                if (obrazacIndex === undefined) {
                    setSelectedFilters((prevFilters: any) => ({
                        ...prevFilters,
                        statuses: value,
                    }))
                }

                if (setObrazacSelect && obrazacIndex) {
                    setSelectedFilters((prevFilters: any) => {
                        const isSubmitted = value === undefined || isNaN(value) ? null : value === 0
                        const newForms = [...prevFilters.forms]
                        const formIndex = newForms.findIndex((form) => form.index === formIdentifier)

                        if (formIndex !== -1) {
                            newForms[formIndex] = { index: formIdentifier, submitted: isSubmitted }
                        } else {
                            newForms.push({ index: formIdentifier, submitted: isSubmitted })
                        }
                        return {
                            ...prevFilters,
                            forms: newForms,
                        }
                    })

                    setObrazacSelect((prev: any) => ({ ...prev, [obrazacIndex]: value }))
                }
            }}
            sx={{
                borderRadius: 0,
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: borderColor
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: borderColor
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: borderColor
                },
                '.MuiSvgIcon-root ': {
                    fill: '#0000008F !important',
                },
            }}
        >
            {options.map((opt: any) => (
                <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                </MenuItem>
            ))}
        </Select>
    )
}

export default DashboardSelectComponent
