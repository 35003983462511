import { File } from '../../File.entity'
import { Organization } from '../../Organization.entity'
import { SubmittedBy } from '../../SubmittedBy.entity'

export const NumberOfSteps = 7

export interface Form7_11Entity {
    report_id?: string
    rep_year?: number
    submitted_date?: Date
    submitted_place?: Date
    category?: string
    organisation?: Organization
    status?: string

    // Step 1
    o7_11_k1_nazivi?: Naziv[]
    o7_11_k1_files?: File[]

    //Step 2
    o7_11_k2_prijave?: Prijave[]

    //Step 3
    o7_11_k3_oblici?: Oblik[]
    //Step4
    o7_11_k4_krivicnePrijave?: NumberTextType[]
    o7_11_k4_pravosnaznePresude?: NumberTextType[]
    // Step 5
    o7_11_k5_organizacije?: NumberTextType[]

    // Step 6
    o7_11_k6_programi?: NumberTextType[]

    submitted_by?: SubmittedBy
}

export interface Naziv {
    id?: string
    naziv?: string
    vrsta?: string
    link?: string
}
export interface Prijave {
    id?: string
    prijavljeniSlucajevi?: number | undefined
    vrstaNasilja?: VrstaNasilja
    uzrastZrtve?: UzrastZrtve
    polPocinioca?: PolPocinioca
    medjusobniOdnos?: MedjusobniOdnos
    napomena?: string
}

export interface Oblik {
    id?: string
    brojZrtava?: number
    oblikEksploatacije?: OblikEksploatacije
    polPocinioca?: PolPocinioca
    uzrastZrtve?: UzrastZrtve
}

// klasa za vise polja u entity forma7-11
export interface NumberTextType {
    id?: string
    broj?: number
    vrsta?: string
}

export enum VrstaNasilja {
    Option1 = 'Опција 1',
    Option2 = 'Опција 2',
    Option3 = 'Опција 3',
    Option4 = 'Опција 4',
    Option5 = 'Опција 5',
}
export enum UzrastZrtve {
    Option1 = 'Опција 1',
    Option2 = 'Опција 2',
    Option3 = 'Опција 3',
    Option4 = 'Опција 4',
    Option5 = 'Опција 5',
}
export enum PolPocinioca {
    Muski = 'Мушки',
    Zenski = 'Женски',
}
export enum MedjusobniOdnos {
    Option1 = 'Опција 1',
    Option2 = 'Опција 2',
    Option3 = 'Опција 3',
    Option4 = 'Опција 4',
    Option5 = 'Опција 5',
}

export enum OblikEksploatacije {
    Option1 = 'Опција 1',
    Option2 = 'Опција 2',
    Option3 = 'Опција 3',
    Option4 = 'Опција 4',
    Option5 = 'Опција 5',
}
