import React from 'react'
import { StepInterface } from './Step1'
import { Form7_5Entity } from '../../../../../models/Form7/Entities/Form7_5.entity'
import ComplexTable from '../../../../../components/Tables/ComplexTable'
import { useWatch } from 'react-hook-form'
import ActionButtons from '../../../../../components/ActionButtons'

export interface RowInterface {
    label: string
    fields: (keyof Form7_5Entity)[]
}

const rows: RowInterface[] = [
    { label: '16-30 година живота', fields: ['o7_5_k2_16_30_z', 'o7_5_k2_16_30_m'] },
    { label: '31-40 година живота', fields: ['o7_5_k2_31_40_z', 'o7_5_k2_31_40_m'] },
    { label: '41-50 година живота', fields: ['o7_5_k2_41_50_z', 'o7_5_k2_41_50_m'] },
    { label: '51-60 година живота', fields: ['o7_5_k2_51_61_z', 'o7_5_k2_51_61_m'] },
    { label: '61-70 година живота', fields: ['o7_5_k2_61_71_z', 'o7_5_k2_61_71_m'] },
]

const allFields = [
    'o7_5_k2_16_30_z',
    'o7_5_k2_16_30_m',
    'o7_5_k2_31_40_z',
    'o7_5_k2_31_40_m',
    'o7_5_k2_41_50_z',
    'o7_5_k2_41_50_m',
    'o7_5_k2_51_61_z',
    'o7_5_k2_51_61_m',
    'o7_5_k2_61_71_z',
    'o7_5_k2_61_71_m',
] as const

const allMaleFields = [...allFields.filter((e) => e.includes('_m'))] as const
const allFemaleFields = [...allFields.filter((e) => e.includes('_z'))] as const

type AllFieldsType = (typeof allFields)[number]

export const Step2 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_5Entity>({ control })
    const fields: AllFieldsType[] = [...allFields]

    const disabledNext = !fields.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">Број корисника услуга социјалне заштите разврстан по полу и по годинама старости</h1>
            <ComplexTable
                rows={rows}
                control={control}
                allFemaleFields={[...allFemaleFields]}
                allMaleFields={[...allMaleFields]}
                allFields={[...allFields]}
                header={['Старост', 'Жене', 'Жене%', 'Мушкарци', 'Мушкарци%', 'Укупно', 'Укупно%']}
                errors={errors}
                disabled={adminView}
            />
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
