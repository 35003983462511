import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const CategorySelectionScreen3 = ({ finish, subSelected, prevStep, selected, specialCase }: any) => {
    const [countdown, setCountdown] = useState(10)
    useEffect(() => {
        finishCategorySelection()
    }, [finish])

    const finishCategorySelection = async () => {
        const resp = await finish()

        if (resp !== 'error') {
            const timer = setInterval(() => {
                setCountdown((prev) => {
                    if (prev <= 1) {
                        clearInterval(timer)
                        navigate('/')
                        return 0
                    }
                    return prev - 1
                })
            }, 1000)

            return () => clearInterval(timer)
        } else {
            alert('Грешка приликом чувања категорије, молимо Вас покушајте поново')
            window.location.reload()
        }
    }

    const navigate = useNavigate()
    return (
        <div className="h-full w-full flex items-center justify-center flex-col">
            <div className="flex flex-col max-w-[600px] text-center">
                <p className="mt-[20px]">Честитамо!</p>
                <p className="mt-[20px] font-bold">Успешно сте се разврстали као {subSelected ? subSelected.name : selected ? selected.name : 'непознате категорије'}.</p>
                <p className="mt-[20px]">Бићете аутоматски преусмерени даље за {countdown} секунди.</p>
                <p className="mt-[20px]">Уколико преусмеравање није успело кликните на дугме Заврши.</p>
            </div>
            <div className="flex flex-row justify-between mt-[30px] w-full max-w-[600px]">
                <button
                    className="w-full h-[42px] flex items-center justify-center rounded-[4px] border border-[#243964] text-[15px] text-[#243964] font-medium leading-[26px] cursor-pointer mt-[43px] max-w-[200px]"
                    onClick={prevStep}
                >
                    НАЗАД
                </button>
                <button
                    className={`w-full h-[42px] flex items-center justify-center rounded-[4px] !bg-[#243964] text-[15px] text-[#ffffff] font-medium leading-[26px] cursor-pointer mt-[43px] max-w-[200px]`}
                    onClick={() => navigate('/')}
                >
                    ЗАВРШИ
                </button>
            </div>
        </div>
    )
}

export default CategorySelectionScreen3
