import React, { useState } from 'react'
import { ReactComponent as PersonSvg } from '../../../../assets/svg/PersonFilled.svg'
import { ReactComponent as EmailIcon } from '../../../../assets/svg/EmailIcon.svg'
import { ReactComponent as HamburgerMenu } from '../../../../assets/svg/HamburgerMenu.svg'
import { ReactComponent as ArrowUp } from '../../../../assets/svg/SortUpArrowSVG.svg'
import grbImage from '../../../../assets/images/grb.png'
import { Link } from 'react-router-dom'
import { TutorialStepInterface } from './Step1'
import Actions from '../../Components/Actions'
import Tour from 'reactour'
import { DropdownItem } from '../../Components/TutorialNavDropdownMenu'

const Step2 = ({ nextStep, previousStep, previousButtonDisabled = false, skipClick, onClose }: TutorialStepInterface) => {
    const [isTourOpen, setIsTourOpen] = useState(true)
    const [showDropdown, setShowDropdown] = useState(true)
    const hamburgerDropDown = true
    const user = {
        admin: false,
        firstName: 'Пера',
        lastName: 'Перић',
        subjectName: null,
        email: 'pera@peric.com',
        legalEntityId: '123',
    }
    const adminLinks = [
        { label: 'Аналитика', value: '/admin/analytics' },
        { label: 'Управљање извештајима', value: '/admin/dashboard' },
        { label: 'Управљање местима', value: '/admin/cities' },
        { label: 'Управљање корисницима', value: '/admin/users' },
        { label: 'Управљање обвезницима', value: '/admin/legal-entities' },
        { label: 'Управљање порукама', value: '/admin/messages' },
        { label: 'Управљање упутствима', value: '/admin/help/all' },
        { label: 'Управљање категоријама', value: '/admin/categories' },
        { label: 'Привредни ентитети', value: '/admin/corporateEntities' },
        { label: 'Услови коришћења и политика приватности', value: '/admin/legal' },
    ]

    const tourSteps = [
        {
            selector: 'div[id="main"]',
            content: 'Навигациона трака',
        },
        {
            selector: 'img[id="navHome"]',
            content: 'Кликом на грб можете да се вратите на почетну страницу.',
        },
        {
            selector: 'div[id="navHome"]',
            content: 'Кликом на текстуални наслов такође можете да се вратите на почетну страницу.',
        },
        {
            selector: 'div[id="profile"]',
            content:
                'Овде је приказано ваше име или назив обвезника за којег сте изабрали да попуњавате извештај. Кликом на иконицу или име/назив отвориће вам се падајући мени са опцијама.',
        },
        {
            selector: 'div[id="dropdown"]',
            content:
                'У падајућем менију имате виште опција. Уколико је нека од опција одабрана тј. налазите се на страници нпр. "Измена профила" позадинска боја те опције је измењена да би вам приказала где се налазите.',
        },
        {
            selector: 'div[id="legal"]',
            content: 'Опција омогућава повратак на претходну страницу и промену активног обвезника',
        },
        {
            selector: 'div[id="profileNav"]',
            content: 'Опција омогућава приступ детаљима профила и изменама истог.',
        },
        {
            selector: 'div[id="pass"]',
            content: 'Опција за промену тренутне лозинке',
        },
        {
            selector: 'div[id="email"]',
            content: 'Опција за промену регистроване имејл адресе.',
        },
        {
            selector: 'div[id="logout"]',
            content: 'Кликом на ову опцију одјављујете се са платформе.',
        },
        {
            selector: 'div[id="contact"]',
            content:
                'Кликом на дугме контакт или иконицу отвориће вам се прозор за контакт. Информације у контакт прозору се пуне из система. Ту сте у могућности да унесете и пошаљете вашу поруку. ',
        },
    ]
    return (
        <div className="flex flex-col items-center">
            <Tour steps={tourSteps} isOpen={isTourOpen} onRequestClose={() => setIsTourOpen(false)} />
            <div className="min-h-[50%]">
                <div id="main" className="bg-[#243964] min-h-[105px] h-full w-full flex items-center justify-between px-[120px]">
                    {/* INVIS */}
                    <img id="navHome" src={grbImage} alt="Грб Републике Србије" width={45} />
                    <div className="flex justify-end invisible">
                        {user.admin && (
                            <div className="flex flex-row items-center text-[#ffffffdd] hover:text-white cursor-default gap-1">
                                <HamburgerMenu className="fill-[#ffffffdd] group-hover:fill-white transition-colors duration-300 svg-fill-current h-6 w-6" />
                                <ArrowUp
                                    className={`transition-transform duration-300 ${!hamburgerDropDown ? 'rotate-180' : ''} fill-[#ffffffdd] group-hover:fill-white h-4 w-4`}
                                    style={{
                                        transformOrigin: 'center',
                                    }}
                                />
                            </div>
                        )}
                        {!!user?.email ? (
                            <div className="text-[#FFFFFF] flex flex-row items-center">
                                <div className="mr-[10px] flex items-center">
                                    <PersonSvg fill="#FFFFFF" />
                                    <div className="flex flex-row gap-[2px]">
                                        {!!user.admin ? (
                                            <>
                                                <p className="ml-[5px] font-[16px] leading-[24px]">{user.firstName}</p>
                                                <p className="ml-[5px] font-[16px] leading-[24px]">{user.lastName}</p>
                                            </>
                                        ) : (
                                            <p className="ml-[5px] font-[16px] leading-[24px] max-w-[150px] text-ellipsis overflow-hidden whitespace-nowrap">{user.subjectName}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="text-[#FFFFFF] flex flex-row items-center">
                                <div className="mr-[10px] flex items-center">
                                    <PersonSvg fill="#FFFFFF" />
                                    <ArrowUp />
                                </div>
                                <div>
                                    <Link to="/login">Пријавите се</Link>
                                </div>
                            </div>
                        )}
                        {!user.admin && (
                            <div className="flex flex-row items-center text-[#ffffffdd] hover:text-white ml-5 cursor-pointer">
                                <EmailIcon />
                                <p className="ml-[5px] font-[18px] leading-[24px] ">Контакт</p>
                            </div>
                        )}
                    </div>
                    {/* /INVIS */}
                    <div className="flex flex-1 justify-center">
                        <div id="navHome" className="cursor-default">
                            <h2 className="text-[#FFFFFF] text-[24px] leading-6 cursor-default">Родна равноправност</h2>
                        </div>
                    </div>
                    {/* INVIS */}
                    <div className="invisible">
                        <img src={grbImage} alt="Грб Републике Србије" width={45} className="hover:cursor-defautl" />
                    </div>
                    {/* /INVIS */}
                    <div className="flex justify-end h-full">
                        {user.admin && (
                            <div className="relative mt-[2px]">
                                <div className="flex flex-row items-center cursor-default gap-1 group">
                                    <HamburgerMenu className="fill-[#ffffffdd] group-hover:fill-white transition-colors duration-300 svg-fill-current h-6 w-6" />
                                    <ArrowUp
                                        className={`transition-transform duration-300 ${!hamburgerDropDown ? 'rotate-180' : ''} fill-[#ffffffdd] group-hover:fill-white h-4 w-4`}
                                        style={{
                                            transformOrigin: 'center',
                                        }}
                                    />
                                </div>

                                {hamburgerDropDown && (
                                    <div className="absolute right-0 top-full w-48 bg-white shadow-lg rounded-lg z-10 flex items-center overflow-hidden">
                                        <ul className="w-full">
                                            {adminLinks.map((link) => (
                                                <li className={`px-4 py-2 cursor-default hover:bg-[rgba(36,57,100,0.2)] text-[#243964] w-full text-center 'bg-[#24396433]`}>
                                                    {link.label}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        )}
                        {!!user?.email ? (
                            <div className="text-[#FFFFFF] flex flex-row items-center">
                                <div className="relative ml-[10px]">
                                    <div id="profile" className="mr-[10px] flex items-center cursor-default text-[#ffffffdd] gap-3 group">
                                        <div className="flex flex-row items-center">
                                            <PersonSvg className="fill-[#ffffffdd] transition-colors duration-300 svg-fill-current h-6 w-6" />
                                            <ArrowUp
                                                className={`transition-transform duration-300 ${!showDropdown ? 'rotate-180' : ''} fill-[#ffffffdd] h-4 w-4`}
                                                style={{
                                                    transformOrigin: 'center',
                                                }}
                                            />
                                        </div>
                                        <div className="flex flex-row items-center gap-[2px] h-full">
                                            {!!user.subjectName ? (
                                                <p className="ml-[5px] font-[16px] leading-[24px] max-w-[150px] text-ellipsis overflow-hidden whitespace-nowrap">
                                                    {user.subjectName}
                                                </p>
                                            ) : (
                                                <>
                                                    <p className="ml-[5px] font-[16px] leading-[24px]">{user.firstName}</p>
                                                    <p className="ml-[5px] font-[16px] leading-[24px]">{user.lastName}</p>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div id="dropdown" className="absolute right-0 w-48 bg-white shadow-lg rounded-lg z-10 flex items-center overflow-hidden">
                                        <ul className="w-full">
                                            {!user.admin && (
                                                <>
                                                    <div id="legal">{!!user.legalEntityId && <DropdownItem>Одабир законског обвезника</DropdownItem>}</div>
                                                    <div id="profileNav">
                                                        <DropdownItem hightlight>Измена профила</DropdownItem>
                                                    </div>
                                                    <div id="pass">
                                                        <DropdownItem>Измена лозинке</DropdownItem>
                                                    </div>
                                                    <div id="email">
                                                        <DropdownItem>Измена имејла</DropdownItem>
                                                    </div>
                                                </>
                                            )}
                                            <div id="logout">
                                                <DropdownItem>Одјава</DropdownItem>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={`text-[#FFFFFF] flex flex-row items-center invisible}`}>
                                <div className="mr-[10px] flex items-center">
                                    <PersonSvg fill="#FFFFFF" />
                                </div>
                                <div>
                                    <Link to="/login">Пријавите се</Link>
                                </div>
                            </div>
                        )}
                    </div>
                    {!user.admin && (
                        <div id="contact" className="flex flex-row items-center text-[#ffffffdd] ml-5 cursor-default">
                            <EmailIcon />
                            <p className="ml-[5px] font-[18px] leading-[24px] ">Контакт</p>
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-[100px] w-full max-w-[70%]">
                <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={false} skipButton={skipClick} closeModal={onClose} />
            </div>
        </div>
    )
}

export default Step2
