import { Input } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

interface TextInputInterface {
    name: string
    maxNumberOfCharacter?: number
    control: any
    disabled?: boolean
    placeholder?: string
    height?: number
    borderColor?: string
    errors?: any
    isRequired?: any
    isMultipleTable?: any
    isTextTable?: any
    isEducTable?: any
}

function TextInputCell({
    control,
    name,
    disabled,
    maxNumberOfCharacter,
    placeholder,
    height = 54,
    borderColor = '#00000010',
    errors = {},
    isRequired = false,
    isMultipleTable,
    isTextTable = true,
    isEducTable = false,
}: TextInputInterface) {
    const parts = name.split('.')
    const arrayName = parts[0]
    const index = parseInt(parts[1], 10)
    const fieldName = parts[2]

    const hasError = !!errors[arrayName] && !!errors[arrayName][index] && !!errors[arrayName][index][fieldName] && isRequired

    const getColor = (errorCheck: boolean, defaultColor: string, errorColor: string) => {
        return errorCheck ? errorColor : defaultColor
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }

    const getBorderClass = () => {
        if (disabled) {
            return 'border-[#00000010]'
        } else if (errors && errors[name] && errors && errors[arrayName]) {
            return 'border-[#d32f2f]'
        } else if (errors && !!errors[arrayName] && !!errors[arrayName][index] && !!errors[arrayName][index][fieldName]) {
            return 'border-[#d32f2f]'
        } else {
            return 'border-[#D9D9D9]'
        }
    }

    const getNumberOfRows = () => {
        if (isEducTable) {
            return 4
        } else if (isTextTable) {
            return 2
        } else {
            return 3
        }
    }

    return (
        <div
            className={`min-w-[100px] border ${getBorderClass()} flex items-center pl-[15px] justify-center ${
                (name === 'o6_k1_broj_plana' || name === 'o6_k1_mesto') && `min-w-[230px]`
            }`}
            style={{
                height: height ? height : '100%',
            }}
        >
            <Controller
                name={name}
                control={control}
                rules={{ required: isRequired }}
                render={({ field }) => (
                    <>
                        {disabled ? (
                            // When disabled, render a div instead of input to allow copying
                            <div
                                className={`w-full select-text cursor-default py-[12px] pr-[10px] ${isMultipleTable ? 'text-center' : 'text-left'}`}
                                style={{
                                    color: getColor(hasError, 'inherit', '#d32f2f'),
                                    opacity: 0.7, // Makes it look disabled
                                    whiteSpace: isMultipleTable || isTextTable ? 'pre-wrap' : 'nowrap',
                                    minHeight: isMultipleTable || isTextTable ? `${getNumberOfRows() * 1.5}em` : 'auto',
                                    backgroundColor: 'transparent',
                                }}
                            >
                                {field.value || ''}
                            </div>
                        ) : (
                            <Input
                                multiline={isMultipleTable || isTextTable ? true : false}
                                maxRows={getNumberOfRows()}
                                {...field}
                                onChange={(event) => {
                                    const newValue = event.target.value
                                    field.onChange(newValue)
                                }}
                                onKeyDown={handleKeyDown}
                                className={`!bg-[transparent] mr-[10px] border-none w-full ${isMultipleTable ? 'text-center items-center' : 'border border-[#D9D9D9]'}`}
                                placeholder={placeholder}
                                disabled={disabled}
                                inputProps={{ maxLength: maxNumberOfCharacter }}
                                sx={{
                                    textAlign: 'left',
                                    '& .MuiInputBase-input': {
                                        textAlign: isMultipleTable ? 'center' : 'left',
                                        color: getColor(hasError, 'inherit', '#d32f2f'),
                                        '&::placeholder': {
                                            color: getColor(!!errors[name], 'inherit', '#d32f2f'),
                                        },
                                    },
                                    '&.MuiInputBase-root:before': {
                                        borderBottom: 'none',
                                    },
                                    '&.MuiInputBase-root:after': {
                                        borderBottom: 'none',
                                    },
                                    '&.MuiInputBase-root:hover:not(.Mui-disabled):before': {
                                        borderBottom: 'none',
                                    },
                                }}
                            />
                        )}
                    </>
                )}
            />
        </div>
    )
}

export default TextInputCell
