import React from 'react'
import { Control, FieldValues, useWatch } from 'react-hook-form'
import { sumValues } from '../../../utils/mathUtils'
import PositiveNumberInputCell from '../../Form2/Components/PositiveNumberInputCell'

interface RowInterface<T> {
    label: string
    fields: (keyof T)[]
}
interface ComplexReductionTableInterface<T extends FieldValues> {
    rows: RowInterface<T>[]
    control: Control<T> | undefined
    allFields: (keyof T)[]
    allMaleFields: (keyof T)[]
    allFemaleFields: (keyof T)[]
    header: string[]
    decimal?: boolean
    errors?: any
    disabled?: boolean
    form7?: boolean
    clearErrors?: any
}

function SimpleSubtractionTable<T extends FieldValues>({
    rows,
    control,
    header,
    allFields,
    allMaleFields,
    allFemaleFields,
    decimal = false,
    errors,
    disabled,
    form7 = false,
    clearErrors,
}: ComplexReductionTableInterface<T>) {
    type AllFieldsType = (typeof allFields)[number]
    type MaleFieldsType = (typeof allMaleFields)[number]
    type FemaleFieldsType = (typeof allFemaleFields)[number]

    const watchedValues = useWatch({ control }) as T
    const fields: AllFieldsType[] = allFields
    const maleFields: MaleFieldsType[] = [...allMaleFields]
    const femaleFields: FemaleFieldsType[] = [...allFemaleFields]
    const sumMale = sumValues(watchedValues, maleFields)
    const sumFemale = sumValues(watchedValues, femaleFields)

    const hasRowErrors = rows.some((row) => row.fields.some((field) => errors?.[field]))

    return (
        <table className="complex-table mt-[30px]">
            <tr className="complex-table-header">
                {header.map((head, index) => (
                    <th className={`complex-table-cell ${index === 0 && 'min-w-[500px]'}`} key={index}>
                        {head}
                    </th>
                ))}
            </tr>
            {rows.map((row, index) => {
                const total =
                    watchedValues[row.fields[0]] !== undefined && watchedValues[row.fields[1]] !== undefined
                        ? Math.abs(Number(watchedValues[row.fields[1]]) - Number(watchedValues[row.fields[0]]))
                        : 0
                return (
                    <tr className="complex-table-row" key={row.label}>
                        <td className={`complex-table-cell disabled min-w-[500px]`}>{row.label}</td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0 }}>
                            <PositiveNumberInputCell
                                name={row.fields[0] as string}
                                control={control}
                                decimal={decimal}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={disabled}
                                precentage={true}
                                form7={form7}
                                clearErrors={clearErrors}
                            />
                        </td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0 }}>
                            <PositiveNumberInputCell
                                name={row.fields[1] as string}
                                control={control}
                                decimal={decimal}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={disabled}
                                precentage={true}
                                form7={form7}
                                clearErrors={clearErrors}
                            />
                        </td>
                        <td className="complex-table-cell disabled">{total !== undefined && total !== null ? total.toFixed(2) : '0-100'}%</td>
                    </tr>
                )
            })}
            <div className="h-[2px]">{hasRowErrors && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
        </table>
    )
}

export default SimpleSubtractionTable
