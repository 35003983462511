import { StepInterfaceObl2 } from './Step1'
import { useWatch } from 'react-hook-form'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import ActionButtons from '../../../components/ActionButtons'
import SelectBody from '../Components/SelectBody'

const Step4 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterfaceObl2) => {
    const watchedValues = useWatch<TblOblast2>({ control })

    const disabledNext = watchedValues.o2_k4_zamenik === undefined || watchedValues.o2_k4_predsednik === undefined || watchedValues.o2_k4_sekretar === undefined

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">Руководећи и извршни састав тела за родну равноправност, разврстан по полу</h1>
            <div>
                <table className="simple-table mt-[20px]">
                    <tr className="simple-table-header">
                        <th className="simple-table-cell max-w-[187px]">Функција/задужење</th>
                        <th className="simple-table-cell !w-[186px]">Одговор</th>
                    </tr>
                    <div className="simple-table-row !gap-[9px]">
                        <td className="simple-table-cell disabled !w-[185px] px-[3px] py-[10px] !h-auto">Председник/ца тела за родну равноправност</td>
                        <td className="!border-none">
                            <SelectBody
                                control={control}
                                isRequired={true}
                                name="o2_k4_predsednik"
                                items={['Мушкарац', 'Жена']}
                                errors={errors}
                                value={watchedValues.o2_k4_predsednik}
                                disabled={adminView}
                            />
                        </td>
                    </div>
                    <div className="simple-table-row !gap-[9px]">
                        <td className="simple-table-cell disabled !w-[185px] !min-h-[50px] px-[3px] py-[10px] !h-auto ">Заменик/ца председника/це тела за родну равноправност</td>
                        <td className="!border-none">
                            <SelectBody
                                control={control}
                                isRequired={true}
                                name="o2_k4_zamenik"
                                items={['Мушкарац', 'Жена']}
                                value={watchedValues.o2_k4_zamenik}
                                errors={errors}
                                disabled={adminView}
                            />
                        </td>
                    </div>
                    <div className="simple-table-row !gap-[9px]">
                        <td className="simple-table-cell disabled !w-[185px] px-[3px] py-[10px] !h-auto">Секретар/ка тела за родну равноправност</td>
                        <td className="!border-none">
                            <SelectBody
                                control={control}
                                isRequired={true}
                                errors={errors}
                                name="o2_k4_sekretar"
                                value={watchedValues.o2_k4_sekretar}
                                items={['Мушкарац', 'Жена']}
                                disabled={adminView}
                            />
                        </td>
                    </div>
                </table>
                <div className="h-[20px]">{Object.keys(errors).length > 0 && <p className="text-[0.75rem] ml-[50px] text-[#d32f2f]">Изаберите једну од понуђених опција.</p>}</div>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step4
