import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUser } from '../../../auth/UseUser'
import { verifyEmail } from '../../../server/server'
import Loading from '../../Common/LoadingSpinner'

const ValidateEmail = () => {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search)
    }
    let query1 = useQuery()
    const navigate = useNavigate()
    const { setUser } = useUser()
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        let email = query1.get('email') ?? ''
        let token = query1.get('token') ?? ''

        email = email.replace(/ /g, '+')

        verifyAccount(email, token)
    }, [])
    const verifyAccount = async (email: string, token: string) => {
        setLoading(true)
        const data = { email: email, token: token }
        const resp = await verifyEmail(data)
        if (resp.status == 201) {
            // navigate('/login', { state: { message: 'success' } })
            setSuccess(true)
            setLoading(false)
        } else {
            // navigate('/login', { state: { message: 'error' } })
            // setSuccess(false)
            // setLoading(false)
        }
    }

    return (
        <div className="w-full pb-3 h-full">
            <div className="flex flex-col w-[410px] m-auto items-center justify-center h-full text-center">
                <p className="text-[24px] leading-6 text-center font-semibold uppercase mt-4 ">Верификација налога</p>
                <div>
                    {success ? (
                        <div className="mt-[40px]">
                            <p>Поштовани, ваша имејл адреса је успешно валидирана. Да бисте се улоговали кликните на дугме "Пријава"</p>
                        </div>
                    ) : (
                        <div className="mt-[40px] ">
                            <p className="text-[18px]  font-bold text-textError text-center">Десила се грешка приликом валидације, молим Вас контактирајте администратора.</p>
                        </div>
                    )}

                    <button
                        type="button"
                        className="w-full h-[44px] px-2 flex items-center justify-center rounded-md border-2 border-greyLight hover:border-greyMedium bg-white text-[18px] text-greyMedium font-bold cursor-pointer mt-5 "
                        onClick={() => {
                            navigate('/login')
                        }}
                    >
                        Пријава
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ValidateEmail
