import React from 'react'

function FirstButton({ borderColor = '', fill = '' }) {
    return (
        <svg width="190" height="60" viewBox="0 0 190 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_20_700)">
                <mask id="path-1-inside-1_20_700" fill="white">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2 5C2 2.79086 3.79086 1 6 1H169.679C171.114 1 172.44 1.76914 173.152 3.01544L186.866 27.0154C187.569 28.2452 187.569 29.7548 186.866 30.9846L173.152 54.9846C172.44 56.2309 171.114 57 169.679 57H6C3.79086 57 2 55.2091 2 53V5Z"
                    />
                </mask>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 5C2 2.79086 3.79086 1 6 1H169.679C171.114 1 172.44 1.76914 173.152 3.01544L186.866 27.0154C187.569 28.2452 187.569 29.7548 186.866 30.9846L173.152 54.9846C172.44 56.2309 171.114 57 169.679 57H6C3.79086 57 2 55.2091 2 53V5Z"
                    fill={fill}
                />
                <path
                    d="M186.866 27.0154L187.734 26.5193L187.734 26.5193L186.866 27.0154ZM186.866 30.9846L187.734 31.4807L187.734 31.4807L186.866 30.9846ZM173.152 54.9846L174.02 55.4807L173.152 54.9846ZM173.152 3.01544L174.02 2.51931L173.152 3.01544ZM169.679 0H6V2H169.679V0ZM172.283 3.51158L185.998 27.5116L187.734 26.5193L174.02 2.51931L172.283 3.51158ZM185.998 27.5116C186.525 28.4339 186.525 29.5661 185.998 30.4884L187.734 31.4807C188.613 29.9435 188.613 28.0565 187.734 26.5193L185.998 27.5116ZM185.998 30.4884L172.283 54.4884L174.02 55.4807L187.734 31.4807L185.998 30.4884ZM6 58H169.679V56H6V58ZM1 5V53H3V5H1ZM172.283 54.4884C171.749 55.4231 170.755 56 169.679 56V58C171.473 58 173.13 57.0386 174.02 55.4807L172.283 54.4884ZM6 0C3.23858 0 1 2.23858 1 5H3C3 3.34315 4.34315 2 6 2V0ZM6 56C4.34315 56 3 54.6569 3 53H1C1 55.7614 3.23858 58 6 58V56ZM169.679 2C170.755 2 171.749 2.57686 172.283 3.51158L174.02 2.51931C173.13 0.96143 171.473 0 169.679 0V2Z"
                    fill="#CFD6DC"
                    mask="url(#path-1-inside-1_20_700)"
                />
            </g>
            <defs>
                <filter id="filter0_d_20_700" x="0" y="0" width="189.393" height="60" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.215686 0 0 0 0 0.254902 0 0 0 0 0.317647 0 0 0 0.08 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20_700" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_20_700" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export default FirstButton
