import { useEffect, useState } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import ActionButtons from '../../../components/ActionButtons'
import { ImpactEnum, ProbabilityEnum, RiskLevelEnum, TblOblast6 } from '../../../models/Form6/Form6.entity'
import RiskTable from '../Components/RiskTable'
import { Button } from '@mui/material'
import RiskDiagram from '../../../assets/images/RiskDiagram.png'
import RegisteredCasesTable from '../Components/RegisteredCasesTable'
import { getEnumValues } from '../../../utils/mathUtils'
import { StepInterface } from './Step1'
export interface RowInterface {
    label: string
    subtitle: string
    field: number
}

const matrix = [
    [0, 0, 1, 1, 1],
    [0, 1, 2, 2, 2],
    [0, 1, 2, 3, 3],
    [0, 1, 2, 3, 3],
    [0, 1, 2, 3, 3],
]

const HEADER_LIST = ['Назив ризика', 'Вероватноћа', 'Утицај', 'Тежина', 'Ниво ризика']

const probabilityValues = getEnumValues(ProbabilityEnum)
const impactValues = getEnumValues(ImpactEnum)
const riskLevelValues = getEnumValues(RiskLevelEnum)

function Step3({ control, nextStep, previousStep, previousButtonDisabled = false, errors, setValue, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast6>({ control })

    const [rows, setRows] = useState([
        { label: 'Веома висок', subtitle: '(црвена)', field: 0, color: '#FE0000' },
        { label: 'Висок', subtitle: '(наранџаста)', field: 0, color: '#FFC000' },
        { label: 'Умерени', subtitle: '(жута)', field: 0, color: '#FFFF01' },
        { label: 'Низак', subtitle: '(зелена)', field: 0, color: '#00AF50' },
    ])

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: 'o6_k3_rizici',
    })
    const calculateRisk = (verovatnoca: string | undefined | null, uticaj: string | undefined | null) => {
        const verovatnocaValue = probabilityValues.indexOf(verovatnoca as string)
        const uticajValue = impactValues.indexOf(uticaj as string)
        if (verovatnocaValue === -1 || uticajValue === -1) return ''
        return riskLevelValues[matrix[verovatnocaValue][uticajValue]]
    }

    useEffect(() => {
        if (!fields.length) append([{ text: '' }])
    }, [])

    useEffect(() => {
        validateRisk()
    }, [watchedValues.o6_k3_rizici])

    const handleAddField = () => {
        append({ text: '' })
    }

    const validateRisk = () => {
        let state = { nizak: 0, umereni: 0, visok: 0, veomaVisok: 0 }
        watchedValues.o6_k3_rizici?.forEach((element) => {
            const newRisk = calculateRisk(element.verovatnoca, element.uticaj)
            switch (newRisk) {
                case 'Низак':
                    state.nizak = state.nizak + 1
                    break
                case 'Умерени':
                    state.umereni = state.umereni + 1
                    break
                case 'Висок':
                    state.visok = state.visok + 1
                    break
                case 'Веома висок':
                    state.veomaVisok = state.veomaVisok + 1
                    break
                default:
                    break
            }
        })
        setRows((prev) => [
            (prev[0] = { ...prev[0], field: state.veomaVisok }),
            (prev[1] = { ...prev[1], field: state.visok }),
            (prev[2] = { ...prev[2], field: state.umereni }),
            (prev[3] = { ...prev[3], field: state.nizak }),
        ])
    }

    const setCalculatedValues = () => {
        watchedValues.o6_k3_rizici?.forEach((element: any, index: number) => {
            const newRisk = calculateRisk(element.verovatnoca, element.uticaj)
            setValue(`o6_k3_rizici.${index}.nivoRizika`, newRisk)
        })
    }

    const validateFields = (): boolean => {
        if (adminView) {
            return true
        }
        const newMap = watchedValues.o6_k3_rizici?.map((field) => field.text != '' && !!field.tezina && !!field.verovatnoca && !!field.uticaj)
        return newMap?.includes(false) ? true : false
    }

    const disabledNext = validateFields()
    const disabledAddMore = validateFields()

    const goToNexStep = () => {
        if (!disabledNext) {
            setCalculatedValues()
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="text-[18px] leading-6 text-textPrimary ">Молим Вас унесите назив ризика, вероватноћу и утицај</h1>
            <img width={532} height={411} src={RiskDiagram} alt="" style={{ marginTop: 20 }} />
            <RiskTable fields={fields} remove={remove} control={control} header={HEADER_LIST} errors={errors} disabled={adminView} />
            <p className="ml-5 min-h-[30px] text-textError text-[0.75rem] text leading-[30px]">
                {errors['o6_k3_rizici'] ? 'Попуните обавезно поље и изаберите једну од понуђених опција.' : ''}
            </p>
            <Button variant="contained" onClick={handleAddField} disabled={disabledAddMore} style={{ marginTop: 20 }}>
                Додај ризик
            </Button>
            <h4 className="text-[18px] leading-6 text-textPrimary mt-[50px]">
                Број регистрованих случајева угрожавања принципа родне равноправности у години извештавања, разврстаних према степену ризика
            </h4>
            <RegisteredCasesTable rows={rows} />
            <ActionButtons nextButton={goToNexStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step3
