import React, { useState } from 'react'
import { Control, useWatch } from 'react-hook-form'
import ActionButtons from '../../../../../components/ActionButtons'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { calculateDifference } from '../../../../../utils/mathUtils'
import { Form7_3Entity } from '../../../../../models/Form7/Entities/Form7_3.entity'

export interface StepInterface {
    control?: Control<Form7_3Entity>
    nextStep: (skip?: boolean) => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
}

interface RowInterface {
    label: string
    fields: (keyof Form7_3Entity)[]
}

const rowsTable: RowInterface[] = [
    {
        label: 'Све категорије - Старосне пензије',
        fields: ['o7_3_k6_z_broj_sve_starosne', 'o7_3_k6_z_prosek_sve_starosne', 'o7_3_k6_m_prosek_sve_starosne', 'o7_3_k6_m_prosek_penzije_sve_starosne'],
    },
    {
        label: 'Све категорије - Инвалидске пензије',
        fields: ['o7_3_k6_z_broj_sve_invalidske', 'o7_3_k6_z_prosek_sve_invalidske', 'o7_3_k6_m_prosek_sve_invalidske', 'o7_3_k6_m_prosek_penzije_sve_invalidske'],
    },
    {
        label: 'Запослени - Старосне пензије',
        fields: ['o7_3_k6_z_broj_zaposleni_starosne', 'o7_3_k6_z_prosek_zaposleni_starosne', 'o7_3_k6_m_prosek_zaposleni_starosne', 'o7_3_k6_m_prosek_penzije_zaposleni_starosne'],
    },
    {
        label: 'Запослени - Инвалидске пензије',
        fields: [
            'o7_3_k6_z_broj_zaposleni_invalidske',
            'o7_3_k6_z_prosek_zaposleni_invalidske',
            'o7_3_k6_m_prosek_zaposleni_invalidske',
            'o7_3_k6_m_prosek_penzije_zaposleni_invalidske',
        ],
    },
    {
        label: 'Самосталне делатности - Старосне пензије',
        fields: [
            'o7_3_k6_z_broj_samostalne_starosne',
            'o7_3_k6_z_prosek_samostalne_starosne',
            'o7_3_k6_m_prosek_samostalne_starosne',
            'o7_3_k6_m_prosek_penzije_samostalne_starosne',
        ],
    },
    {
        label: 'Самосталне делатности - Инвалидске пензије',
        fields: [
            'o7_3_k6_z_broj_samostalne_invalidske',
            'o7_3_k6_z_prosek_samostalne_invalidske',
            'o7_3_k6_m_prosek_samostalne_invalidske',
            'o7_3_k6_m_prosek_penzije_samostalne_invalidske',
        ],
    },
    {
        label: 'Пољопровредници - Старосне пензије',
        fields: ['o7_3_k6_z_broj_poljop_starosne', 'o7_3_k6_z_prosek_poljop_starosne', 'o7_3_k6_m_prosek_poljop_starosne', 'o7_3_k6_m_prosek_penzije_poljop_starosne'],
    },
    {
        label: 'Пољопривредници - Инвалидске пензије',
        fields: ['o7_3_k6_z_broj_poljop_invalidske', 'o7_3_k6_z_prosek_poljop_invalidske', 'o7_3_k6_m_prosek_poljop_invalidske', 'o7_3_k6_m_prosek_penzije_poljop_invalidske'],
    },
]

const header: string[] = [
    'Категорије',
    'Број жена корисника на 100 мушкараца корисника',
    'Просечна старост корисника Ж у годинама',
    'Просечна старост корисника М у годинама',
    'Просечна пензија жена у односу на просечну пензију мушкараца %',
]

const allFieldsTable = [
    'o7_3_k6_z_broj_sve_starosne',
    'o7_3_k6_z_broj_sve_invalidske',
    'o7_3_k6_z_broj_zaposleni_starosne',
    'o7_3_k6_z_broj_zaposleni_invalidske',
    'o7_3_k6_z_broj_samostalne_starosne',
    'o7_3_k6_z_broj_samostalne_invalidske',
    'o7_3_k6_z_broj_poljop_starosne',
    'o7_3_k6_z_broj_poljop_invalidske',

    'o7_3_k6_z_prosek_sve_starosne',
    'o7_3_k6_z_prosek_sve_invalidske',
    'o7_3_k6_z_prosek_zaposleni_starosne',
    'o7_3_k6_z_prosek_zaposleni_invalidske',
    'o7_3_k6_z_prosek_samostalne_starosne',
    'o7_3_k6_z_prosek_samostalne_invalidske',
    'o7_3_k6_z_prosek_poljop_starosne',
    'o7_3_k6_z_prosek_poljop_invalidske',

    'o7_3_k6_m_prosek_sve_starosne',
    'o7_3_k6_m_prosek_sve_invalidske',
    'o7_3_k6_m_prosek_zaposleni_starosne',
    'o7_3_k6_m_prosek_zaposleni_invalidske',
    'o7_3_k6_m_prosek_samostalne_starosne',
    'o7_3_k6_m_prosek_samostalne_invalidske',
    'o7_3_k6_m_prosek_poljop_starosne',
    'o7_3_k6_m_prosek_poljop_invalidske',

    'o7_3_k6_m_prosek_penzije_sve_starosne',
    'o7_3_k6_m_prosek_penzije_sve_invalidske',
    'o7_3_k6_m_prosek_penzije_zaposleni_starosne',
    'o7_3_k6_m_prosek_penzije_zaposleni_invalidske',
    'o7_3_k6_m_prosek_penzije_samostalne_starosne',
    'o7_3_k6_m_prosek_penzije_samostalne_invalidske',
    'o7_3_k6_m_prosek_penzije_poljop_starosne',
    'o7_3_k6_m_prosek_penzije_poljop_invalidske',
] as const

type AllFieldsType = (typeof allFieldsTable)[number]

function Step6({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_3Entity>({ control })
    const fieldsTable: AllFieldsType[] = [...allFieldsTable]
    const [disabled, setDisabled] = useState(false)
    const hasRowErrors = rowsTable.some((row) => row.fields.some((field) => errors?.[field]))

    const disabledNext = !fieldsTable.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">6.Корисници пензија по категоријама, према врсти пензије, просечној старости и полу:</h1>
            <table className="complex-table mt-[30px]">
                <tr className="complex-table-header">
                    {header.map((head, index) => (
                        <th className="complex-table-cell" style={{ width: index === 0 ? 300 : 200 }} key={index}>
                            {head}
                        </th>
                    ))}
                </tr>
                {rowsTable.map((row, index) => (
                    <tr className="complex-table-row" key={index}>
                        <td className="complex-table-cell  disabled" style={{ width: 300 }}>
                            {row.label}
                        </td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0, width: 200 }}>
                            <PositiveNumberInputCell
                                name={row.fields[0] as string}
                                control={control}
                                decimal={true}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={disabled}
                                form7={true}
                            />
                        </td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0, width: 200 }}>
                            <PositiveNumberInputCell
                                name={row.fields[1] as string}
                                control={control}
                                decimal={true}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={disabled}
                            />
                        </td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0, width: 200 }}>
                            <PositiveNumberInputCell
                                name={row.fields[2] as string}
                                control={control}
                                decimal={true}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={disabled}
                            />
                        </td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0, width: 200 }}>
                            <PositiveNumberInputCell
                                name={row.fields[3] as string}
                                control={control}
                                decimal={true}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={disabled}
                            />
                        </td>
                    </tr>
                ))}
                <div className="h-[20px]"> {hasRowErrors && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            </table>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step6
