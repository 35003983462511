import '../../../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../../../components/ActionButtons'
import { Control, useWatch } from 'react-hook-form'
import Textbox from '../../../../Contact/TextBoxAutoSize'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { Form7_7Entity } from '../../../../../models/Form7/Entities/Form7_7.entity'

export interface StepInterface {
    control?: Control<Form7_7Entity>
    nextStep: () => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
}

export function Step1({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_7Entity>({ control })
    const disabledNext = watchedValues?.o7_7_k1_bylaws_count === undefined || typeof watchedValues.o7_7_k1_bylaws_count !== 'number' ||
                        watchedValues?.o7_7_k1_gender_equality_programs_count === undefined || typeof watchedValues.o7_7_k1_gender_equality_programs_count !== 'number' ||
                        watchedValues?.o7_7_k1_professional_development_programs_count === undefined || typeof watchedValues.o7_7_k1_professional_development_programs_count !== 'number' ||
                        watchedValues?.o7_7_k1_revised_programs_count === undefined || typeof watchedValues.o7_7_k1_revised_programs_count !== 'number';

    const goToNextStep = () => {
        if (!disabledNext && !errors?.o4_k7_procenat) nextStep()
        else if (adminView) nextStep()
    }

    const inputConfigs = [
        {
            name: "o7_7_k1_revised_programs_count",
            label: "Број ревидираних програма наставе и учења и уџбеника из којих су уклоњени родно засновани стереотипи, дискриминаторни садржаји и дискриминаторни језик."
        },
        {
            name: "o7_7_k1_gender_equality_programs_count",
            label: "Број исхода и програма наставе и учења који афирмишу родну равноправност и повећавају видљивост женског доприноса на нивоу предшколског и основношколског васпитања и образовања."
        },
        {
            name: "o7_7_k1_professional_development_programs_count",
            label: "Број програма стручног усавршавања који укључују унапређивање компетенција запослених у образовању и васпитању о сексуалном и репродуктивном здрављу и правима у основношколском образовању."
        },
        {
            name: "o7_7_k1_bylaws_count",
            label: "Број подзаконских аката за предшколско и основношколско образовање који укључују унапређивање компетенција запослених у образовању и васпитању у области родне равноправности."
        }
    ];

    return (
        <div>
            {inputConfigs.map((config, index) => (
                <div key={index}>
                    <h1 className="step-name mb-[20px] ">{config.label}</h1>
                    <div className=" flex flex-row gap-5 mb-[40px]">
                        <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                            <PositiveNumberInputCell
                                name={config.name}
                                control={control}
                                errors={errors}
                                isRequired={!adminView}
                                height={52}
                                disabled={adminView}
                            />
                        </div>
                    </div>
                </div>
            ))}
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
