import React from 'react'
import { OrganiStranke, TblOblast5 } from '../../../models/Form5/Form5.entity'
import { Control, useWatch } from 'react-hook-form'
import TextInputCell from '../../../components/Common/TextInputCell'
import { ReactComponent as Delete } from '../../../assets/svg/Delete.svg'
import { ReactComponent as Edit } from '../../../assets/svg/Edit.svg'
import { getEnumValues } from '../../../utils/mathUtils'
import SelectBody from './SelectBody'
import PositiveNumberInputCell from '../../Form2/Components/PositiveNumberInputCell'
import { Button } from '@mui/material'

interface UnionPartyBodyTableInterface {
    control: Control<TblOblast5> | undefined
    fields: any[]
    remove: any
    header: string[]
    errors?: any
    disabled?: boolean
    noCategory?: boolean
}

const organiStrankeValues = getEnumValues(OrganiStranke)

function UnionPartyBodyTable({ control, fields, remove, header, errors, disabled, noCategory }: UnionPartyBodyTableInterface) {
    const watchedValues = useWatch<TblOblast5>({ control })

    function manPercentage(manValue: any, womanValue: any) {
        if (manValue == null || manValue == undefined) return ''
        const mValue = manValue || 0
        const wValue = womanValue || 0

        if (mValue + wValue === 0) return ''
        const percentage = (mValue / (mValue + wValue)) * 100
        return percentage % 1 === 0 ? `${percentage.toFixed(0)}` : `${percentage.toFixed(2)}`
    }

    function womanPercentage(manValue: any, womanValue: any) {
        if (womanValue == null || womanValue == undefined) return ''
        const mValue = manValue || 0
        const wValue = womanValue || 0

        if (mValue + wValue === 0) return ''
        const percentage = (wValue / (mValue + wValue)) * 100
        return percentage % 1 === 0 ? `${percentage.toFixed(0)}` : `${percentage.toFixed(2)}`
    }

    const checkRequired = () => {
        return !disabled && !noCategory
    }

    return (
        <table className="simple-table mt-[50px] " style={{ gap: 8 }}>
            <tr className="simple-table-header">
                {header.map((head, index) => (
                    <th className=" w-[186px] h-[54px]" key={index}>
                        {head}
                    </th>
                ))}
                <td className=" pl-3 flex flex-row gap-6 items-center invisible" style={{ border: 0 }}>
                    <div className="w-fit cursor-pointer">
                        <Edit />
                    </div>
                    {fields.length > 1 && (
                        <Button className="w-fit cursor-pointer" disabled={disabled}>
                            <Delete />
                        </Button>
                    )}
                </td>
            </tr>
            {fields.map((field, index) => (
                <tr className="simple-table-row" key={field.id}>
                    <td className=" editable w-[186px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <TextInputCell
                            name={`o5_k6_tela.${index}.text`}
                            control={control}
                            placeholder="Tекст (50 карактера)"
                            maxNumberOfCharacter={50}
                            errors={errors}
                            isRequired={checkRequired()}
                            disabled={disabled}
                        />
                    </td>
                    <td className="editable w-[186px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <SelectBody
                            control={control}
                            name={`o5_k6_tela.${index}.category`}
                            value={!!watchedValues.o5_k6_tela ? watchedValues.o5_k6_tela[index]?.category : ''}
                            items={organiStrankeValues}
                            errors={errors}
                            isRequired={!disabled}
                            disabled={disabled}
                        />
                    </td>
                    <td className=" editable w-[186px] h-[54px]" style={{ border: 0 }}>
                        <PositiveNumberInputCell
                            name={`o5_k6_tela.${index}.man`}
                            control={control}
                            errors={errors}
                            isRequired={checkRequired()}
                            isArray={true}
                            disabled={disabled}
                        />
                    </td>
                    <td className=" editable w-[186px] h-[54px]">
                        {manPercentage(
                            !!watchedValues.o5_k6_tela ? watchedValues.o5_k6_tela[index]?.man : 0,
                            !!watchedValues.o5_k6_tela ? watchedValues.o5_k6_tela[index]?.woman : 0
                        )}
                        %
                    </td>
                    <td className=" editable w-[186px] h-[54px]" style={{ border: 0 }}>
                        <PositiveNumberInputCell
                            name={`o5_k6_tela.${index}.woman`}
                            control={control}
                            errors={errors}
                            isRequired={checkRequired()}
                            isArray={true}
                            disabled={disabled}
                        />
                    </td>
                    <td className=" editable w-[186px] h-[54px]">
                        {womanPercentage(
                            !!watchedValues.o5_k6_tela ? watchedValues.o5_k6_tela[index]?.man : 0,
                            !!watchedValues.o5_k6_tela ? watchedValues.o5_k6_tela[index]?.woman : 0
                        )}
                        %
                    </td>
                    <td className=" pl-3 flex flex-row gap-6 items-center " style={{ border: 0 }}>
                        {/* <div className="w-fit cursor-pointer">
                            <Edit />
                        </div> */}
                        {fields.length > 1 && (
                            <div className="w-fit cursor-pointer" onClick={() => remove(index)}>
                                <Delete />
                            </div>
                        )}
                    </td>
                </tr>
            ))}
        </table>
    )
}

export default UnionPartyBodyTable
