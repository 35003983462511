import React, { useEffect, useState } from 'react'
import { Form7_6Entity } from '../../../../../models/Form7/Entities/Form7_6.entity'
import { Control, useFieldArray, useWatch } from 'react-hook-form'
import ActionButtons from '../../../../../components/ActionButtons'
import UploadFile from '../../../../../components/UploadFile'
import { Button } from '@mui/material'
import FileDescriptionTable from '../../../Components/FileDescriptionTable'

export interface StepInterface {
    control?: Control<Form7_6Entity>
    nextStep: () => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
    clearErrors?: any
}

export const Step1 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_6Entity>({ control })
    const [errorMessage, setErrorMessage] = useState('')
    const [emptyArrayError, setEmptyArrayError] = useState(false)
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o7_6_k1_fileDescription',
    })
    const name = 'o7_6_k1_fileDescription'

    let disabledNext = watchedValues[name]?.length === 0

    let emptyLinkCount = 0
    let filesUploaded = 0

    useEffect(() => {
        if (emptyLinkCount === filesUploaded) {
            clearErrors()
        }
    })

    fields.forEach((item, index) => {
        const { fileName, type, link } = watchedValues[name]?.[index] || {}

        if (fileName === undefined || fileName === null || fileName === '') {
            disabledNext = true
        } else if (type === undefined || type === null || type === '') {
            disabledNext = true
        } else disabledNext = false

        const fileFieldName = `o7_6_k1_file_${index}` as keyof Form7_6Entity

        if (link === '' || link === undefined) {
            emptyLinkCount += 1
            if (watchedValues[fileFieldName]) {
                filesUploaded += 1
            }
        }
    })

    if (emptyLinkCount > filesUploaded) {
        disabledNext = true
    }

    const header = ['Бр.', 'Назив документа', 'Врста', 'Линк ако постоји', 'Да ли доприноси постицању учешћа жена']

    const handleAddArrayField = () => {
        setEmptyArrayError(false)
        append({ fileName: '', type: '', link: '' })
    }

    const validateEmptyArray = () => {
        if (watchedValues[name]?.length === 0 || watchedValues[name] === undefined) {
            setEmptyArrayError(true)
            return true
        }
        return false
    }

    const goToNextStep = () => {
        if (!disabledNext && !validateEmptyArray()) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <div className="flex flex-col justify-between">
                <h1 className="step-name mb-[10px]">
                    Молимо Вас да приложите документе који су релевантни за Успостављање системске подршке за подстицање учешћа жена у друштвеним и технолошким иновацијама и
                    повећању користи од иновационе делатности
                </h1>
                <div className="flex flex-col gap-2">
                    <p>Пример документа:</p>
                    <p className="italic">Законских и стратешки докумената за развој друштвених иновација донети у години извештавања</p>
                    <p className="italic">Анализа уродњавања законских и стратешких докумената за развој друштвених иновација</p>
                    <p className="italic">Извештаји и подаци Завода за интелектуалну својину Републике Србије</p>
                    <p className="italic">Увид у буџетска планирања и извештаје, независне анализе и студије, извештаји Фонда за иновациону делатност за претходну годину</p>
                </div>
            </div>
            <FileDescriptionTable header={header} fields={fields} control={control} errors={errors} name={name} adminView={adminView} remove={remove} form7o6 />

            <div className="flex flex-col mt-[56px]">
                <Button variant="contained" className="max-w-[172px] mt-[56px]" onClick={handleAddArrayField} disabled={adminView}>
                    Додај датотеку
                </Button>
                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError ? 'Додајте барем једну активност!' : ''}</span>
            </div>

            <div className="mt-10 gap-10">
                <h1>Молимo Вас приложите горе наведене документе ако нема јавно доступног линка:</h1>
                <UploadFile control={control} name={'o7_6_k1_file'} disabled={adminView} isRequired={emptyLinkCount > filesUploaded} errors={errors} form7 />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
