import React from 'react'
import TextInputCell from '../../../components/Common/TextInputCell'
import { ReactComponent as Delete } from '../../../assets/svg/Delete.svg'
import { ReactComponent as Edit } from '../../../assets/svg/Edit.svg'
import { Control, FieldArrayWithId, FieldValues, UseFieldArrayRemove } from 'react-hook-form'
import { TblOblast5 } from '../../../models/Form5/Form5.entity'
import { StepInterfaceObl2 } from '../Steps/Step1'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import { Button } from '@mui/material'
import HelpButton from '../../../components/Common/HelpButton'

export interface TextTableInterface<T extends FieldValues> {
    control: Control<T> | undefined
    errors?: any
    fields: any
    remove: UseFieldArrayRemove
    disabled?: boolean
    label?: any
    name?: any
    maxChar?: any
    showHelpMessage?: boolean
    helpName?: string
}

function TextTable<T extends FieldValues>({
    control,
    errors,
    fields,
    remove,
    disabled = false,
    label,
    name,
    maxChar = 100,
    showHelpMessage = false,
    helpName = '',
}: TextTableInterface<T>) {
    return (
        <table className="simple-table mt-[13px]" style={{ gap: 8 }}>
            <tr className="simple-table-header">
                <th className=" w-[114px] h-[54px]">Редни број</th>
                <th className=" w-full max-w-[80%] h-[54px]">{label}</th>
                {showHelpMessage && <HelpButton name={helpName} />}
            </tr>
            {fields.map((field: any, index: any) => (
                <tr className="simple-table-row" key={field.id}>
                    <td className="disabled min-w-[114px] h-[54px]">{index + 1}</td>
                    <td className=" w-full max-w-[80%] editable h-[54px]" style={{ padding: 0, border: 0 }}>
                        <TextInputCell
                            disabled={disabled}
                            name={`${name}.${index}.text_content`}
                            control={control}
                            placeholder={`Tекст (${maxChar} карактера)`}
                            errors={errors}
                            isRequired={disabled ? false : true}
                            maxNumberOfCharacter={maxChar}
                            isTextTable={true}
                        />
                    </td>
                    <td className="simple-table-cell pl-3 flex flex-row gap-6 items-center " style={{ border: 0 }}>
                        {/* <div className="w-fit cursor-pointer">
                            <Edit />
                        </div> */}
                        {fields.length > 1 && (
                            <Button className="w-fit cursor-pointer" onClick={() => remove(index)} disabled={disabled}>
                                <Delete />
                            </Button>
                        )}
                    </td>
                </tr>
            ))}
        </table>
    )
}

export default TextTable
