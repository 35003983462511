import React from 'react'
import { TblOblast4 } from '../../../../models/Form4/Form4.entity'
import { Control } from 'react-hook-form'
import NoAnalytics from '../../Common/NoAnalytics'
import ActionButtons from '../../../../components/ActionButtons'
export interface StepInterface {
    control?: Control<TblOblast4>
    nextStep: (skip?: boolean) => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
    countType?: 'total' | 'average'
    selectedCategories?: any[]
    selectedSubCategories?: any[]
}
const Step1 = ({
    control,
    nextStep,
    previousStep,
    previousButtonDisabled = false,
    errors,
    adminView,
    countType,
    setValue,
    selectedCategories,
    selectedSubCategories,
}: StepInterface) => {
    return (
        <div>
            <h1 className="step-name">Оцена стања родне равноправности у години вођења евиденције о остваривању родне равноправности:</h1>
            <NoAnalytics />

            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step1
