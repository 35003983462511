import React, { useState } from 'react'
import { Form7_13Entity } from '../../../../../models/Form7/Entities/Form7_13.entity'
import { Control, useFieldArray, useWatch } from 'react-hook-form'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import ActionButtons from '../../../../../components/ActionButtons'
import TextTable from '../../../../Form2/Components/TextTable'
import { Button } from '@mui/material'

export interface StepInterface {
    control?: Control<Form7_13Entity>
    nextStep: () => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
    clearErrors?: any
}

export const Step1 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_13Entity>({ control })
    const headers = [
        'Број прописа који регулишу механизме РР на нивоу организације власти за који подносите извештај, у складу са Законом о родној равноправности, њихов статус, надлежности и процедуре, и који дефинишу процедуре учешћа у креирању родно одговорних јавних политика',
        'Број механизама РР на националном  или покрајинском или локалном нивоу власти за који подносите извештај и нивоима унутрашње организације које имају обезбеђене људске, финансијске и материјалне услове за функционисање, разврстано по нивоима организације рада, регионима/територијалној припадности',
        'Број и називи родно одговорних јавних политика  и области на које се односе на ниво власти за који подносите извештај у којима активно учествују механизми РР, укључујући и лица задужена за родну равноправност',
    ]

    const numberFields: ('o7_13_k1_br_propisa' | 'o7_13_k1_br_mehanizama')[] = ['o7_13_k1_br_propisa', 'o7_13_k1_br_mehanizama']

    const [emptyArrayError, setEmptyArrayError] = useState(false)

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o7_13_k1_odgovorni',
    })
    const firstArrayName = 'o7_13_k1_odgovorni'

    const addArrayField = () => {
        setEmptyArrayError(false)
        append({ text_content: '' })
    }

    const validateArray = (): boolean => {
        if (!watchedValues.o7_13_k1_odgovorni || watchedValues.o7_13_k1_odgovorni.length === 0) {
            return true
        }

        const newMap = watchedValues.o7_13_k1_odgovorni?.map((field) => field.text_content !== '')
        return newMap?.includes(false) ? true : false
    }

    const disableArray = validateArray()
    const disabledNext = !numberFields.every((e) => {
        return watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== ''
    })

    const validateEmptyArray = () => {
        if (watchedValues[firstArrayName]?.length === 0 || watchedValues[firstArrayName] === undefined) {
            setEmptyArrayError(true)
            return true
        }
        return false
    }

    const goToNextStep = () => {
        if (!disabledNext && !disableArray && !validateEmptyArray()) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name mb-[10px]">{headers[0]}</h1>
            <div className="max-w-[200px] flex flex-col">
                <PositiveNumberInputCell
                    name={`${numberFields[0]}`}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    height={62}
                    disabled={adminView}
                    form7
                    clearErrors={clearErrors}
                />

                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_13_k1_br_propisa ? 'Попуните обавезно поље!' : ''}</span>
            </div>
            <h1 className="step-name mb-[10px]">{headers[1]}</h1>
            <div className="max-w-[200px] flex flex-col">
                <PositiveNumberInputCell
                    name={`${numberFields[1]}`}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    height={62}
                    disabled={adminView}
                    form7
                    clearErrors={clearErrors}
                />

                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px]  mb-10">{errors.o7_13_k1_br_mehanizama ? 'Попуните обавезно поље!' : ''}</span>
            </div>
            <h1 className="step-name mb-[10px]">{headers[2]}</h1>
            <div className="mt-[50px]">
                <div>
                    <TextTable
                        control={control}
                        name={firstArrayName}
                        errors={errors}
                        fields={fields}
                        remove={remove}
                        label={'Називи родно одговорних јавних политика и области'}
                        maxChar={200}
                        disabled={adminView}
                    />

                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{errors && errors?.[firstArrayName] ? 'Попуните обавезно поље!' : ''}</span>
                </div>
                <div className="flex flex-col">
                    <Button variant="contained" className="max-w-[172px]" onClick={addArrayField} style={{ marginTop: 20 }} disabled={adminView}>
                        Додај назив
                    </Button>
                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError ? 'Додајте барем једну активност!' : ''}</span>
                </div>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
