export const FORM7_11NAMES = [
    '1. Пример докумената',
    '2. Број пријава свих облика родно заснованог насиља према женама и девојчицама, у јавној и приватној сфери, по врсти насиља, односу са насилником, и узрасту жртве, посебно насиља које је засновано на полу, полним карактеристикама, односно роду и родном идентитету',
    '3. Број жртава трговине људима, разврстано према полу, узрасту, и облику експлоатације',
    '4. Проценат/број кривичних пријава/оптужења за кривичнa дела са елементима насиља, посебно за кривична дела сексуалног насиља и трговине људима у години извештавања.',
    '5. Број ОЦД које су пружају услуге жртвама насиља, активне су у заштити права жртава, које се залажу за измене дискриминаторих друштвених норми и родних стереотипа и надгледају примену међународних стандарда у заштити жртава родно заснованог насиља. ',
    '6. Број превентивних програма/интервенција рада са учиниоцима насиља у циљу спречавања насиља',
    '7. Поштовани, кликом на заврши и пошаљи потврђујете да сте унели све потребне информације у дефинисана поља',
]
export const HELP_BUTTON_7_11 = ['o7_11-k1', 'o7_11-k2', 'o7_11-k3', 'o7_11-k4', 'o7_11-k5', 'o7_11-k6', 'o7_11-k7']
