import { useWatch } from 'react-hook-form'
import ActionButtons from '../../../components/ActionButtons'
import { TblOblast6 } from '../../../models/Form6/Form6.entity'
import SimpleInputRowForObject from '../../Form2/Components/SimpleImputRowForObject'
import { StepInterface } from './Step1'

const REGEX_STRING = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

function Step8({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast6>({ control })
    const fields: ('firstName' | 'lastName' | 'phoneNumber' | 'email')[] = ['firstName', 'lastName', 'phoneNumber', 'email']

    const disabledNext =
        !fields.every((e) => !!watchedValues?.submitted_by && !!watchedValues?.submitted_by[e]) ||
        !REGEX_STRING.test(!!watchedValues?.submitted_by && watchedValues?.submitted_by?.email ? watchedValues?.submitted_by?.email : '')

    const goToNexStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <h1 className="text-[18px] leading-6 text-textPrimary ">Лице задужено за сачињавање извештаја</h1>
            <div className="mt-[20px] flex flex-col gap-[15px]">
                <SimpleInputRowForObject
                    title="Име"
                    name="submitted_by.firstName"
                    maxNumberOfCharacter={50}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    disabled={adminView}
                    isName
                />
                <SimpleInputRowForObject
                    title="Презиме"
                    name="submitted_by.lastName"
                    maxNumberOfCharacter={50}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    disabled={adminView}
                    isName
                />
                <SimpleInputRowForObject
                    title="Контакт телефон"
                    name="submitted_by.phoneNumber"
                    maxNumberOfCharacter={50}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    disabled={adminView}
                />
                <SimpleInputRowForObject
                    title="Емаил"
                    name="submitted_by.email"
                    maxNumberOfCharacter={50}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    disabled={adminView}
                    isEmail
                />
            </div>
            <p className="ml-5 min-h-[30px] text-textError text-[0.75rem] text leading-[30px]">
                {errors['submitted_by'] &&
                (errors['submitted_by']['first_name'] ||
                    errors['submitted_by']['last_name'] ||
                    errors['submitted_by']['phone'] ||
                    (errors['submitted_by']['email'] && errors['submitted_by']['email'].type == 'required'))
                    ? 'Попуните обавезно поље.'
                    : errors['submitted_by'] && errors['submitted_by']['email']
                    ? 'Неважећа email адреса.'
                    : ''}
            </p>
            <ActionButtons nextButton={goToNexStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step8
