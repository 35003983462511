import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { createAdminUser, editUser, getUser } from '../../server/server'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Checkbox } from '@mui/material'
import { getValue } from '@testing-library/user-event/dist/utils'
import HelpButton from '../../components/Common/HelpButton'

type AdminUser = {
    id: string
    email: string
    firstName: string
    lastName: string
    admin: boolean
    password?: string
}

const AdminUser = () => {
    const {
        register,
        handleSubmit,
        getValues,
        control,
        formState: { errors },
        setValue,
    } = useForm<AdminUser>()
    let [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    const fields: { label: string; field: keyof AdminUser }[] = [
        { label: 'Емаил', field: 'email' },
        { label: 'Име', field: 'firstName' },
        { label: 'Презиме', field: 'lastName' },
    ]

    const userId = searchParams.get('userId')

    useEffect(() => {
        if (userId) loadUser()
    }, [userId])

    const loadUser = async () => {
        const resp = await getUser(userId)
        const data = resp?.data

        if (resp.status === 200 && data) {
            Object.keys(data).forEach((key) => {
                if (key === 'admin') {
                    setValue(key as keyof AdminUser, data[key] === true)
                } else {
                    setValue(key as keyof AdminUser, data[key])
                }
            })
        }
    }

    const getValidationPattern = (field: any, label: any) => {
        switch (field) {
            case 'firstName':
            case 'lastName':
                return {
                    value: /^[A-Za-zА-Ша-шЉљЊњЏџЂђЈјЋћ\s]+$/,
                    message: `${label} може садржати само слова`,
                }
            case 'email':
                return {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: `${label} није исправна email адреса`,
                }
            default:
                return undefined
        }
    }

    const onSubmit: SubmitHandler<AdminUser> = async () => {
        if (userId) {
            const data = {
                id: getValues('id'),
                firstName: getValues('firstName'),
                lastName: getValues('lastName'),
                email: getValues('email'),
                admin: getValues('admin'),
            }
            const resp = await editUser(data)
            if (resp.status === 201) {
                alert('Успешно измењен админ корисник')
                navigate('/admin/users')
            } else {
                alert(`Грешка приликом чувања корисника, ${resp.status}`)
            }
        } else {
            const data = {
                id: getValues('id'),
                firstName: getValues('firstName'),
                lastName: getValues('lastName'),
                email: getValues('email'),
                password: userId ? undefined : getValues('password'),
            }
            const resp = await createAdminUser(data)
            if (resp.status === 201) {
                alert('Успешно направљен админ корисник')
                navigate('/admin/users')
            } else if (resp.status === 409) {
                alert(`Корисник са унетом емаил адресом већ постоји`)
            } else {
                alert(`Грешка приликом чувања корисника, ${resp.status}`)
            }
        }
    }
    return (
        <div className="flex items-center mt-[20px] w-full h-full flex-col">
            <h2 className="mb-[32px] text-[24px] leading-[28px] font-light max-w-[520px]">
                {userId ? 'Измена' : 'Нови'} админ {userId ? 'корисника' : 'корисник'}
            </h2>
            <form className="flex flex-col items-center w-full max-w-[600px] " onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="flex flex-row w-full mb-[10px] justify-end">
                    <HelpButton name="new_admin_user_help" />
                </div>
                <div className="flex flex-col w-full">
                    {fields.map((item, index) => (
                        <div key={index} className="flex flex-col mb-[16px]">
                            {userId && <label>{item.label}</label>}
                            <input
                                className="border border-[#0000003B] max-w-full min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                                placeholder={item.label}
                                {...register(item.field, {
                                    required: true,
                                    pattern: getValidationPattern(item.field, item.label),
                                })}
                            />
                            <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[3px]">
                                {errors[item.field]?.type === 'required' ? `${item.label} је обавезно поље` : ''}
                                {errors[item.field]?.type === 'pattern' ? `Молимо унесите исправан формат: ${errors[item.field]?.message} ` : ''}
                            </span>
                        </div>
                    ))}
                    {!userId && (
                        <div className="flex flex-col mb-[16px]">
                            {userId && <label>Лозинка</label>}
                            <input
                                className="border border-[#0000003B] max-w-full min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                                placeholder="Лозинка"
                                type="password"
                                {...register('password', {
                                    required: !userId ? 'Лозинка је обавезно поље' : false,
                                    minLength: {
                                        value: 8,
                                        message: 'Лозинка мора имати најмање 8 карактера',
                                    },
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/,
                                        message: 'Лозинка мора садржати најмање једно велико слово, једно мало слово, један број и један специјални знак',
                                    },
                                })}
                            />
                            {errors.password?.type === 'required' && (
                                <span className="text-[#d32f2f] text-[12px] leading-5 h-2 ml-[3px]">{errors.password?.type === 'required' ? 'Лозинка је обавезно поље' : ''}</span>
                            )}
                            {errors.password?.type === 'minLength' && (
                                <span className="text-[#d32f2f] text-[12px] leading-5 h-2 ml-[3px]">{errors.password?.type === 'minLength' ? errors?.password?.message : ''}</span>
                            )}
                            {errors.password?.type === 'pattern' && (
                                <span className="text-[#d32f2f] text-[12px] leading-5 h-2 ml-[3px]">{errors.password?.type === 'pattern' ? errors?.password?.message : ''}</span>
                            )}
                        </div>
                    )}
                    {userId && (
                        <div className="flex flex-row items-center justify-center mt-[16px] max-w-[520px]">
                            <Controller
                                name={'admin'}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => {
                                    return (
                                        <Checkbox
                                            {...field}
                                            size="large"
                                            onChange={(e) => {
                                                field.onChange(e.target.checked)
                                            }}
                                            checked={field.value === true}
                                            sx={{
                                                color: errors.admin ? '#d32f2f' : '#243964',
                                                '&.Mui-checked': {
                                                    color: errors.admin ? '#d32f2f' : '#243964',
                                                },
                                            }}
                                        />
                                    )
                                }}
                            />
                            <span className={`text-[14px] leading-[20px] font-light max-w-[520px] ${errors.admin && 'text-[#d32f2f]'}`}>Да ли је корисник администратор?</span>
                        </div>
                    )}
                </div>
                <div className="flex flex-row items-center justify-between gap-[20px] w-full">
                    <button
                        onClick={() => navigate('/admin/users')}
                        className="w-full h-[42px] flex items-center justify-center rounded-[4px] border border-[#243964] text-[15px] text-[#243964] font-medium leading-[26px] cursor-pointer mt-[43px] max-w-[520px]"
                    >
                        ОДУСТАНИ
                    </button>
                    <button
                        type="submit"
                        className="w-full h-[42px] flex items-center justify-center rounded-[4px] bg-[#243964] text-[15px] text-[#ffffff] font-medium leading-[26px] cursor-pointer mt-[43px] max-w-[520px]"
                    >
                        ДОДАЈ АДМИН КОРИСНИКА
                    </button>
                </div>
            </form>
        </div>
    )
}

export default AdminUser
