import { StepInterfaceObl2 } from './Step1'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import { useWatch } from 'react-hook-form'
import ComplexTable from '../../../components/Tables/ComplexTable'
import ActionButtons from '../../../components/ActionButtons'

interface RowInterface {
    label: string
    fields: (keyof TblOblast2)[]
}

const rows: RowInterface[] = [
    { label: 'Лице без стеченог основног образовања и васпитања', fields: ['o2_k3_z_n1', 'o2_k3_m_n1'] },
    { label: 'Први ниво (ниво 1) НОКС', fields: ['o2_k3_z_n2', 'o2_k3_m_n2'] },
    { label: 'Други ниво (ниво 2) НОКС', fields: ['o2_k3_z_n3', 'o2_k3_m_n3'] },
    { label: 'Трећи ниво (ниво 3) НОКС', fields: ['o2_k3_z_n4', 'o2_k3_m_n4'] },
    { label: 'Четврти ниво (ниво 4) НОКС', fields: ['o2_k3_z_n5', 'o2_k3_m_n5'] },
    { label: 'Пети ниво (ниво 5) НОКС', fields: ['o2_k3_z_n6', 'o2_k3_m_n6'] },
    { label: 'Шести ниво, подниво један (ниво 6.1) и подниво два  (ниво 6.2) НОКС', fields: ['o2_k3_z_n7', 'o2_k3_m_n7'] },
    { label: 'Седми ниво, подниво један  (ниво 7.1) и подниво два (ниво 7.2) НОКС', fields: ['o2_k3_z_n8', 'o2_k3_m_n8'] },
    { label: 'Осми ниво (ниво 8) НОКС', fields: ['o2_k3_z_n9', 'o2_k3_m_n9'] },
]

const allFields = [
    'o2_k3_m_n1',
    'o2_k3_m_n2',
    'o2_k3_m_n3',
    'o2_k3_m_n4',
    'o2_k3_m_n5',
    'o2_k3_m_n6',
    'o2_k3_m_n7',
    'o2_k3_m_n8',
    'o2_k3_m_n9',
    'o2_k3_z_n1',
    'o2_k3_z_n2',
    'o2_k3_z_n3',
    'o2_k3_z_n4',
    'o2_k3_z_n5',
    'o2_k3_z_n6',
    'o2_k3_z_n7',
    'o2_k3_z_n8',
    'o2_k3_z_n9',
] as const

const allMaleFields = [...allFields.filter((e) => e.includes('_m_'))] as const
const allFemaleFields = [...allFields.filter((e) => e.includes('_z_'))] as const

type AllFieldsType = (typeof allFields)[number]

const Step3 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterfaceObl2) => {
    const watchedValues = useWatch<TblOblast2>({ control })
    const fields: AllFieldsType[] = [...allFields]

    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) {
            nextStep()
        }
    }

    return (
        <div>
            <h1 className="step-name">Квалификације које поседују лица именована у састав тела за родну равноправност, разврстана по полу </h1>
            <ComplexTable
                rows={rows}
                control={control}
                allFemaleFields={[...allFemaleFields]}
                allMaleFields={[...allMaleFields]}
                allFields={[...allFields]}
                header={['Ниво квалификације:', 'Жене', 'Жене%', 'Мушкарци', 'Мушкарци%', 'Укупно', 'Укупно%']}
                errors={errors}
                disabled={adminView}
            />
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step3
