import React, { useEffect, useState } from 'react'
import { Button, FormControl, TextField } from '@mui/material'
import { Entity, PibEntity } from './types'

interface PibListProps {
    entities: PibEntity[]
    onSelect: (id: string) => void
    type?: 'category' | 'subcategory' | 'entity' | 'pib'
    onAddNewPib?: (pib: string) => void
    showAddNewPib?: boolean
}

export const PibList: React.FC<PibListProps> = ({ entities, onSelect, type, onAddNewPib, showAddNewPib }) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [newPib, setNewPib] = useState('')
    const [pibError, setPibError] = useState(false)

    useEffect(() => {
        setSearchTerm('')
    }, [type])

    const handleAddNewPib = async () => {
        await onAddNewPib?.(newPib)
        setNewPib('')
    }

    return (
        <div className="flex-1 h-full  max-h-[600px] min-h-[600px] flex flex-col ">
            <TextField
                label="Претрага по ПИБ-у"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: '100%', marginBottom: 10 }}
            />
            <div className="flex flex-row items-center gap-[10px] justify-center mb-[10px]" style={{ opacity: showAddNewPib ? 100 : 0 }}>
                <FormControl className="w-full min-h-[70px]">
                    <TextField
                        label="Унос новог ПИБ-а"
                        variant="outlined"
                        className="max-h-[56px]"
                        value={newPib}
                        onChange={(e) => {
                            const inputValue = e.target.value
                            if (/^\d*$/.test(inputValue) && inputValue.length <= 9) {
                                setNewPib(inputValue)
                            }
                        }}
                        disabled={!showAddNewPib}
                        inputProps={{
                            minLength: 9,
                            maxLength: 9,
                        }}
                        helperText={newPib.length > 0 && newPib.length < 9 ? 'ПИБ мора садржати тачно 9 карактера' : ' '}
                        error={newPib.length > 0 && newPib.length !== 9}
                    />
                </FormControl>
                <Button
                    variant="contained"
                    color="inherit"
                    className="max-h-[56px]"
                    disabled={!showAddNewPib || newPib.length < 9}
                    onClick={handleAddNewPib}
                    style={{ alignSelf: 'flex-start' }}
                >
                    Додај ПИБ
                </Button>
            </div>
            <div className=" flex-1 border overflow-y-auto border-[gray] rounded-md gap-[5px] flex flex-col p-[10px]">
                {entities
                    .filter((e) => e?.pib?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
                    .map((entity) => {
                        return (
                            <Button variant="contained" color={entity.selected ? 'primary' : 'inherit'} key={entity.id} onClick={() => onSelect(entity.id)}>
                                <div>ПИБ: {entity.pib}</div>
                            </Button>
                        )
                    })}
            </div>
        </div>
    )
}
