import { TblOblast1 } from '../../../../models/Form1/Form1.entity'

import ActionButtons from '../../../../components/ActionButtons'

import SimpleTable from '../../../../components/Tables/SimpleTable'
import { StepInterface } from './Step1'
import HelpButton from '../../../../components/Common/HelpButton'
import { useEffect } from 'react'
import { getAnalyticsDataForForm1 } from '../../../../server/server'
export interface RowInterface {
    label: string
    field: keyof TblOblast1
}

const rows: RowInterface[] = [
    { label: 'Мушкарци', field: 'o1_k14_m' },
    { label: 'Жене', field: 'o1_k14_z' },
]
function Step14({
    control,
    nextStep,
    previousStep,
    previousButtonDisabled = false,
    errors,
    adminView,
    countType,
    setValue,
    selectedCategories,
    selectedSubCategories,
}: StepInterface) {
    const fields: ('o1_k14_m' | 'o1_k14_z')[] = ['o1_k14_m', 'o1_k14_z']

    useEffect(() => {
        loadData()
    }, [countType, selectedCategories, selectedSubCategories])

    const loadData = async () => {
        const data = {
            type: countType,
            fields: fields,
            category: selectedCategories?.map((e) => e.name),
            subCategory: selectedSubCategories?.map((e) => e.name),
        }
        const resp = await getAnalyticsDataForForm1(data)
        if (resp.status === 200) {
            const data = resp.data
            fields.forEach((field) => {
                setValue(field, parseFloat(data[field]))
            })
        }
    }

    return (
        <div>
            <h1 className="step-name">
                14. Број запослених и радно ангажованих лица укључених у програме стручног усавршавања и додатног образовања, као и стручног оспособљавања приправника, разврстаних
                по полу, у години вођења евиденције:
            </h1>
            <div className="flex flex-row gap-2 ">
                <SimpleTable control={control} header={['Пол', 'Број', 'Проценат']} rows={rows} fields={fields} errors={errors} disabled={adminView} />{' '}
                <HelpButton name="o1-k14" marginTop={30} />
            </div>
            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={false} />
        </div>
    )
}

export default Step14
