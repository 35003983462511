import React from 'react'
import { useWatch } from 'react-hook-form'
import Textbox from '../../../components/Common/Textbox'
import { TblOblast5, YESNO } from '../../../models/Form5/Form5.entity'
import ActionButtons from '../../../components/ActionButtons'
import { StepInterface } from './Step1'

function Step2({ control, nextStep, previousStep, previousButtonDisabled = false, isParty, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast5>({ control })
    const fields: ('o5_k2_naziv' | 'o5_k2_citat')[] = ['o5_k2_naziv', 'o5_k2_citat']

    const disabledNext = !fields?.every((e) => (e in watchedValues ? !!watchedValues[e] : false))

    const disabledTb = (watchedValues.o5_k1_r1 === YESNO.Ne && watchedValues.o5_k1_r2 === YESNO.Ne && watchedValues.o5_k1_r3 === YESNO.Ne) || adminView

    const goToNexStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView || disabledTb) nextStep()
    }
    return (
        <div>
            <h1 className="text-[18px] leading-6 text-textPrimary ">Дописати одговор ако су регулисана предметна права</h1>
            {disabledTb && !adminView && (
                <p className="min-h-[5px] mt-[10px] text-textError">
                    У првом кораку сте одабрали три опције НЕ, да би сте унели вредности у ова поља, вратите се на Корак 1 и одаберите бар једну опцију ДА
                </p>
            )}
            <div className="mt-[20px]">
                <h4 className="mb-[10px] text-[18px] leading-6 text-textPrimary">Назив акта:</h4>
                <Textbox
                    name="o5_k2_naziv"
                    control={control}
                    placeholder="Tекст (300 карактера)"
                    maxNumberOfCharacters={300}
                    errors={errors}
                    isRequired={!disabledTb}
                    disabled={disabledTb}
                    rules={disabledTb ? {} : { required: 'Обавезно попуните ово поље' }}
                />
            </div>
            <div className="mt-[30px]">
                <h4 className="mb-[10px] text-[18px] leading-6 text-textPrimary">Цитирати део акта:</h4>
                <Textbox
                    name="o5_k2_citat"
                    control={control}
                    placeholder="Tекст (2048 карактера)"
                    maxNumberOfCharacters={2048}
                    errors={errors}
                    isRequired={!disabledTb}
                    disabled={disabledTb}
                    rules={disabledTb ? {} : { required: 'Обавезно попуните ово поље' }}
                />
            </div>
            <ActionButtons nextButton={goToNexStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step2
