import React, { useEffect } from 'react'
import { StepInterface } from './Step1'
import NoAnalytics from '../../Common/NoAnalytics'
import PositiveDecimalInputSeparate from '../../../../components/Common/PositiveDecimalInputSeparate'
import ActionButtons from '../../../../components/ActionButtons'
import { getAnalyticsDataForForm2 } from '../../../../server/server'

const Step7 = ({
    control,
    nextStep,
    previousStep,
    previousButtonDisabled = false,
    errors,
    adminView,
    countType,
    setValue,
    selectedCategories,
    selectedSubCategories,
}: StepInterface) => {
    return (
        <div>
            <h1 className="step-name mb-[20px] mt-[10px]">Проценат реализованог буџета на годишњем нивоу са становишта родне перспективе</h1>
            <NoAnalytics />

            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step7
