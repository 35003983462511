import React from 'react'
import image20 from '../../../../assets/images/FormTutorialImages/20.png'
import image21 from '../../../../assets/images/FormTutorialImages/21.png'
import image22 from '../../../../assets/images/FormTutorialImages/22.png'

import Actions from '../../Components/Actions'
import { TutorialStepInterface } from './Step1'

const Step6 = ({ nextStep, previousStep, previousButtonDisabled = false, skipClick, onClose }: TutorialStepInterface) => {
    return (
        <div className="flex h-full w-full flex-col items-center">
            <div className="relative w-full h-full">
                <img src={image20} alt="Образац слика 20" width={1000} className="w-full" />
            </div>
            <div className="mt-10 gap-10 items-start w-full">
                <div className="flex flex-col ml-[100px] gap-6">
                    <div className="flex flex-row items-center gap-4">
                        <img src={image21} alt="Образац слика 21" width={200} />
                        <div className="flex flex-col">
                            <p className="text-[16px] max-w-[700px]">
                                Уколико сте попунили сва обавезна поља, када кликнете на ово дугме, унос је завршен и извештај је сачуван, извештај више не можете мењати већ само
                                гледати. Поље „НАЗИВ МЕСТА“ се попуњава аутоматски на основу унетог седишта субјекта као и поље „ДАТУМ“. Након успешног попуњавања извештаја
                                појавиће се прозор са поруком који пружа две опције.
                            </p>
                            <p className="text-[16px] max-w-[700px]">
                                1. Попуните анкету – Кликом на ово дугме можете приступити краткој анкети о вашем искуству са попуњавањем форме
                            </p>
                            <p className="text-[16px] max-w-[700px]">2. Почетна страница – Кликом на ово дугме бићете враћени на почетну страницу.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-10">
                <img src={image22} alt="Образац слика 22" width={1000} />
            </div>
            <div className="mt-10 w-full max-w-[70%]">
                <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={false} skipButton={skipClick} lastStep closeModal={onClose} />
            </div>
        </div>
    )
}

export default Step6
