import React, { useEffect } from 'react'
import { StepInterface } from './Step1'
import NoAnalytics from '../../Common/NoAnalytics'
import ActionButtons from '../../../../components/ActionButtons'
import PositiveDecimalInputSeparate from '../../../../components/Common/PositiveDecimalInputSeparate'
import { getAnalyticsDataForForm2 } from '../../../../server/server'

const Step6 = ({
    control,
    nextStep,
    previousStep,
    previousButtonDisabled = false,
    errors,
    adminView,
    countType,
    setValue,
    selectedCategories,
    selectedSubCategories,
}: StepInterface) => {
    const fields: 'o4_k6_ocena_broj'[] = ['o4_k6_ocena_broj']

    useEffect(() => {
        loadData()
    }, [countType, selectedCategories, selectedSubCategories])

    const loadData = async () => {
        const data = {
            type: countType,
            fields: fields,
            category: selectedCategories?.map((e) => e.name),
            subCategory: selectedSubCategories?.map((e) => e.name),
        }
        const resp = await getAnalyticsDataForForm2(data)
        if (resp.status === 200) {
            const data = resp.data
            fields.forEach((field) => {
                setValue(field, parseFloat(data[field]))
            })
        }
    }
    return (
        <div>
            <h1 className="step-name">Оцена ефеката примене посебних мера</h1>
            <div className="mt-[20px] h-[200px] flex items-center justify-center">
                <p className="italic text-inactive">За ово поље нема аналитике пошто је у питању текстуални унос</p>
            </div>

            <h1 className="step-name mb-[20px] mt-[10px]">Оцена степена реализације покрајинског или локалног буџета са становишта родне перспективе (од 1 до 5)</h1>
            <PositiveDecimalInputSeparate name="o4_k6_ocena_broj" placeholder={'1-5'} control={control} errors={errors} isRequired={!adminView} disabled={adminView} />

            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step6
