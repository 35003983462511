import { Button, Modal } from '@mui/material'
import React, { useState } from 'react'
import { ReactComponent as CloseFilled } from '../../assets/svg/CloseFilled.svg'

interface modalInterface {
    isOpen: boolean
    onClose: any
    onConfirm: any
    label?: string
    subText?: string
}
export const YesNoModal = ({ isOpen, onClose, onConfirm, label, subText }: modalInterface) => {
    if (!isOpen) return null

    return (
        <Modal open={isOpen} onClose={onClose}>
            <div className="flex flex-col justify-center items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white max-w-[40%] h-full max-h-[40%] p-5 shadow-lg rounded-lg">
                <h2 className="text-[18px]">{label}</h2>
                <p className="text-[16px] mt-[20px]">{subText}</p>
                <div className="mt-[40px] flex flex-row justify-between w-[80%] gap-[10px]">
                    <Button
                        onClick={onClose}
                        style={{
                            width: '100%',
                            height: '42px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px',
                            border: '1px solid #243964',
                            color: '#243964',
                            fontSize: '15px',
                            fontWeight: '500',
                            lineHeight: '26px',
                        }}
                    >
                        Не
                    </Button>
                    <Button
                        onClick={() => onConfirm('yes')}
                        className="w-full h-[42px] flex items-center justify-center rounded-[4px] border !bg-[#243964] text-[15px] !text-white font-medium leading-[26px]"
                    >
                        Да
                    </Button>
                </div>
                <Button
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        fontSize: '16px',
                        cursor: 'pointer',
                        color: 'black',
                    }}
                >
                    <CloseFilled />
                </Button>
            </div>
        </Modal>
    )
}
