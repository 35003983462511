import React from 'react'
import { StepInterface } from './Step1'
import NoAnalytics from '../../Common/NoAnalytics'
import ActionButtons from '../../../../components/ActionButtons'
import { useNavigate } from 'react-router-dom'

const Step19 = ({
    control,
    nextStep,
    previousStep,
    previousButtonDisabled = false,
    errors,
    adminView,
    countType,
    setValue,
    selectedCategories,
    selectedSubCategories,
}: StepInterface) => {
    const navigate = useNavigate()
    const finishOverview = () => {
        navigate('/admin/analytics')
    }
    return (
        <div>
            <h1 className="step-name">
                Планиране активности сарадње тела за родну равноправност са другим радним телима или органима на републичком, покрајинском и локалном нивоу по питању остваривања и
                унапређивања родне равноправности:
            </h1>

            <NoAnalytics />
            <ActionButtons
                nextButton={finishOverview}
                previousButton={previousStep}
                nextButtonDisabled={false}
                isAdmin={true}
                lastStep
                previousButtonDisabled={previousButtonDisabled}
            />
        </div>
    )
}

export default Step19
