import { useWatch } from 'react-hook-form'
import ActionButtons from '../../../components/ActionButtons'
import { TblOblast6 } from '../../../models/Form6/Form6.entity'
import Textbox from '../../../components/Common/Textbox'
import { StepInterface } from './Step1'

function Step2({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast6>({ control })

    const fields: ('o6_k2_t1' | 'o6_k2_t2')[] = ['o6_k2_t1', 'o6_k2_t2']

    const disabledNext = !fields?.every((e) => (e in watchedValues ? !!watchedValues[e] : false))

    const goToNexStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <h1 className="text-[18px] leading-6 text-textPrimary ">Резултати процене ризика који угрожавају примену принципа родне равноправности</h1>
            <div className="mt-[20px] ">
                <Textbox
                    name="o6_k2_t1"
                    control={control}
                    placeholder="Tекст (100 карактера)"
                    errors={errors}
                    isRequired={adminView ? false : 'Попуните обавезно поље.'}
                    disabled={adminView}
                />
            </div>
            <h4 className="mt-[50px] text-[18px] leading-6 text-textPrimary">
                Навести резултате процене извора угрожавања принципа родне равноправности запослених лица и/или странака у процесу рада
            </h4>
            <div className="mt-[20px]">
                <Textbox
                    name="o6_k2_t2"
                    control={control}
                    placeholder="Tекст (2048 карактера)"
                    errors={errors}
                    isRequired={adminView ? false : 'Попуните обавезно поље.'}
                    disabled={adminView}
                    maxNumberOfCharacters={2048}
                />
            </div>
            <ActionButtons nextButton={goToNexStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step2
