import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { baseUrl } from '../../../server/connections';

const SSOLogin = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Get the code and state from the URL params
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        const state = params.get('state');

        if (code && state) {
            // Redirect to your backend's sso-login endpoint with the params
            window.location.href = `${baseUrl}/sso-login?code=${code}&state=${state}`;
        } else {
            // Handle error case - no code or state
            console.error('Missing code or state parameters');
            navigate('/auth-error');
        }
    }, [location, navigate]);

    return (
        <div className="sso-login-container">
            <p>Processing authentication...</p>
        </div>
    );
};

export default SSOLogin;
