import { GRADE } from '../../CommonTypes'
import { File } from '../../File.entity'
import { Organization } from '../../Organization.entity'
import { SubmittedBy } from '../../SubmittedBy.entity'
import { FileDescription } from './Common/FileDescription.entity'

export const NumberOfSteps = 8

export interface Form7_17Entity {
    report_id: string
    rep_year: number
    submitted_date?: Date
    submitted_place?: Date
    category: string
    organisation: Organization
    status: string
    submitted_by?: SubmittedBy

    o7_17_k1_ocena: GRADE
    o7_17_k1_opis: string //max400

    o7_17_k2_indikatoriPrethodna: number
    o7_17_k2_indikatoriTrekuca: number
    o7_17_k2_opis: string //max500

    o7_17_k3_budzet: number

    o7_17_k4_fileDescription: FileDescription[]
    o7_17_k4_file: File[]

    o7_17_k5_fileDescription: FileDescription[]
    o7_17_k5_file: File[]

    o7_17_k6_procenatNeplacenogRada?: number
    o7_17_k6_ukupanNeplacenRad?: number

    o7_17_k7_rad_tg5: number
    o7_17_k7_rad_tg4: number
    o7_17_k7_rad_tg3: number
    o7_17_k7_rad_tg2: number
    o7_17_k7_rad_tg1: number
    o7_17_k7_rad_tg0: number
    o7_17_k7_rad_ocena: string //max100
    o7_17_k7_novac_tg5: number
    o7_17_k7_novac_tg4: number
    o7_17_k7_novac_tg3: number
    o7_17_k7_novac_tg2: number
    o7_17_k7_novac_tg1: number
    o7_17_k7_novac_tg0: number
    o7_17_k7_novac_ocena: string //max100
    o7_17_k7_znanje_tg5: number
    o7_17_k7_znanje_tg4: number
    o7_17_k7_znanje_tg3: number
    o7_17_k7_znanje_tg2: number
    o7_17_k7_znanje_tg1: number
    o7_17_k7_znanje_tg0: number
    o7_17_k7_znanje_ocena: string //max100
    o7_17_k7_vreme_tg5: number
    o7_17_k7_vreme_tg4: number
    o7_17_k7_vreme_tg3: number
    o7_17_k7_vreme_tg2: number
    o7_17_k7_vreme_tg1: number
    o7_17_k7_vreme_tg0: number
    o7_17_k7_vreme_ocena: string //max100
    o7_17_k7_moc_tg5: number
    o7_17_k7_moc_tg4: number
    o7_17_k7_moc_tg3: number
    o7_17_k7_moc_tg2: number
    o7_17_k7_moc_tg1: number
    o7_17_k7_moc_tg0: number
    o7_17_k7_moc_ocena: string //max100
    o7_17_k7_zdravlje_tg5: number
    o7_17_k7_zdravlje_tg4: number
    o7_17_k7_zdravlje_tg3: number
    o7_17_k7_zdravlje_tg2: number
    o7_17_k7_zdravlje_tg1: number
    o7_17_k7_zdravlje_tg0: number
    o7_17_k7_zdravlje_ocena: string //max100
    o7_17_k7_indeks_tg0: number
    o7_17_k7_indeks_tg1: number
    o7_17_k7_indeksRR: number
    o7_17_k7_fileDescription: FileDescription[]
    o7_17_k7_file: File[]
}
