import React from 'react'
import image18 from '../../../../assets/images/FormTutorialImages/18.png'
import image19 from '../../../../assets/images/FormTutorialImages/19.png'

import Actions from '../../Components/Actions'
import { TutorialStepInterface } from './Step1'

const Step5 = ({ nextStep, previousStep, previousButtonDisabled = false, skipClick, onClose }: TutorialStepInterface) => {
    return (
        <div className="flex h-full w-full flex-col items-center">
            <div className="relative w-full h-full">
                <img src={image18} alt="Образац слика 18" width={1000} className="w-full" />
            </div>
            <div className="mt-10 gap-10 items-start w-full">
                <div className="flex flex-col ml-[100px] gap-6">
                    <div className="flex flex-row items-center gap-4">
                        <img src={image19} alt="Образац слика 19" width={200} />
                        <p className="text-[16px] max-w-[700px]">
                            Поље за додавање датотеке. Да би сте додали датотеку можете превући и отпустити датотеку у облачић на средини екрана или кликнути на дугме „ПРЕТРАЖИТЕ“
                            и одабрати жељену датотеку из свог рачунара. Подржани формат је ПДФ до 2 МБ величине. Други формати нису подржани. Можете додати више датотека. Уколико
                            додате датотеку која није подржана добићете обавештење као на горњој слици. Датотеке које сте додали можете брисати кликом на иконицу канте поред
                            отпремљених датотека као на слици горе.
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-10 w-full max-w-[70%]">
                <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={false} skipButton={skipClick} closeModal={onClose} />
            </div>
        </div>
    )
}

export default Step5
