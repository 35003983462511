import { TblOblast1 } from '../../../../models/Form1/Form1.entity'
import ActionButtons from '../../../../components/ActionButtons'
import { useFieldArray, useWatch } from 'react-hook-form'
import { getPercentage } from '../../../../utils/mathUtils'
import { StepInterface } from './Step1'
import { Button } from '@mui/material'
import TextInputCell from '../../../../components/Common/TextInputCell'
import PositiveNumberInputCell from '../../../Form2/Components/PositiveNumberInputCell'
import HelpButton from '../../../../components/Common/HelpButton'
import { useEffect } from 'react'
import { getAnalyticsDataForForm1 } from '../../../../server/server'

function Step8({
    control,
    nextStep,
    previousStep,
    previousButtonDisabled = false,
    errors,
    adminView,
    countType,
    setValue,
    selectedCategories,
    selectedSubCategories,
}: StepInterface) {
    const watchedValues = useWatch<TblOblast1>({ control })

    let totalMaleValue = 0
    let totalFemaleValue = 0

    const {
        fields: firstArrayFields,
        append: appendFirstArray,
        remove: removeFirstArray,
    } = useFieldArray({
        control,
        name: 'o1_k8_obrazovanje',
    })

    const firstArrayName = 'o1_k8_obrazovanje'

    let disabledFirstArrayNext: any

    firstArrayFields.forEach((item, index) => {
        const { name, femaleNumber, maleNumber } = watchedValues[firstArrayName]?.[index] || {}
        if (
            !name ||
            femaleNumber === undefined ||
            femaleNumber === null ||
            femaleNumber.toString() === '' ||
            maleNumber === undefined ||
            maleNumber === null ||
            maleNumber.toString() === ''
        ) {
            disabledFirstArrayNext = true
            return
        }
    })

    const educationLevels = watchedValues[firstArrayName]

    if (Array.isArray(educationLevels)) {
        educationLevels.forEach((level: any) => {
            const maleNumber = Number(level.maleNumber) || 0
            const femaleNumber = Number(level.femaleNumber) || 0

            totalMaleValue += maleNumber
            totalFemaleValue += femaleNumber
        })
    }
    const totalValue = totalMaleValue + totalFemaleValue

    const header = ['Ниво квалификације:', 'Жене', 'Жене%', 'Мушкарци', 'Мушкарци%', 'Укупно', 'Укупно%']

    useEffect(() => {
        loadData()
    }, [countType, selectedCategories, selectedSubCategories])

    const loadData = async () => {
        const fields = [firstArrayName]
        const data = {
            type: countType,
            fields: fields,
            category: selectedCategories?.map((e) => e.name),
            subCategory: selectedSubCategories?.map((e) => e.name),
        }
        const resp = await getAnalyticsDataForForm1(data)
        if (resp.status === 200) {
            for (let i = 0; i < firstArrayFields.length; i++) {
                removeFirstArray(0)
            }
            for (const field of resp.data) {
                appendFirstArray(field)
            }
        }
    }

    return (
        <div>
            <h1 className="step-name">
                8. Број кандидата пријављених на конкурсе за попуњавање положаја или извршилачких радних места у органима јавне власти, односно за заснивање радног односа или ради
                другог вида радног ангажовања код послодавца разврстаних по полу и по квалификацијама у години вођења евиденције:
            </h1>
            <div>
                <table className="complex-table mt-[30px]">
                    <tr className="complex-table-header">
                        {header.map((header, index) => (
                            <th className={`complex-table-cell ${index === 0 && '!min-w-[200px]'}`} key={index}>
                                {header}
                            </th>
                        ))}
                        <HelpButton name="o1-k8" />
                    </tr>
                    {firstArrayFields.map((field, index) => {
                        const femaleValue = Number(watchedValues[firstArrayName]?.[index]?.femaleNumber) || 0
                        const maleValue = Number(watchedValues[firstArrayName]?.[index]?.maleNumber) || 0
                        const name = watchedValues[firstArrayName]?.[index]?.name
                        const total = maleValue + femaleValue

                        const femalePercentage = getPercentage(femaleValue, totalFemaleValue)
                        const malePercentage = getPercentage(maleValue, totalMaleValue)
                        return (
                            <div className="flex flex-row" key={`${index}`}>
                                <tr className="complex-table-row">
                                    <td className="complex-table-cell !border-none !min-w-[200px] disabled">{name}</td>
                                    <td className="complex-table-cell !border-none disabled">{femaleValue}</td>
                                    <td className="complex-table-cell disabled">{femalePercentage}%</td>
                                    <td className="complex-table-cell !border-none disabled">{maleValue}</td>
                                    <td className="complex-table-cell disabled">{malePercentage}%</td>
                                    <td className="complex-table-cell disabled">{total || 'Цео број'}</td>
                                    <td className="complex-table-cell disabled">{getPercentage(total, totalValue)}%</td>
                                </tr>
                            </div>
                        )
                    })}
                    <tr className="complex-table-footer">
                        <td className="complex-table-cell !min-w-[200px]">Укупно</td>
                        <td className="complex-table-cell">{totalFemaleValue || 'Цео број'}</td>
                        <td className="complex-table-cell">{getPercentage(totalFemaleValue, totalFemaleValue)}%</td>
                        <td className="complex-table-cell">{totalMaleValue || 'Цео број'}</td>
                        <td className="complex-table-cell">{getPercentage(totalMaleValue, totalMaleValue)}%</td>
                        <td className="complex-table-cell">{totalValue || 'Цео број'}</td>
                        <td className="complex-table-cell">{getPercentage(totalValue, totalValue)}%</td>
                    </tr>
                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{errors && errors?.['o1_k8_obrazovanje'] ? 'Попуните обавезно поље!' : ''}</span>
                </table>
            </div>

            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step8
