import { File } from '../../File.entity'
import { Organization } from '../../Organization.entity'
import { Report } from '../../Report'
import { SubmittedBy } from '../../SubmittedBy.entity'
import { Service } from './Common/Service.entity'

export const NumberOfSteps = 10

export interface Form7_5Entity {
    report_id: string
    rep_year: number
    submitted_date?: Date
    submitted_place?: Date
    category: string
    organisation: Organization
    status: string

    o7_5_k1_services: Service[]

    o7_5_k2_16_30_z: number
    o7_5_k2_31_40_z: number
    o7_5_k2_41_50_z: number
    o7_5_k2_51_61_z: number
    o7_5_k2_61_71_z: number
    o7_5_k2_16_30_m: number
    o7_5_k2_31_40_m: number
    o7_5_k2_41_50_m: number
    o7_5_k2_51_61_m: number
    o7_5_k2_61_71_m: number

    o7_5_k3_16_30_z: number
    o7_5_k3_31_40_z: number
    o7_5_k3_41_50_z: number
    o7_5_k3_51_61_z: number
    o7_5_k3_61_71_z: number
    o7_5_k3_16_30_m: number
    o7_5_k3_31_40_m: number
    o7_5_k3_41_50_m: number
    o7_5_k3_51_61_m: number
    o7_5_k3_61_71_m: number

    o7_5_k4_m: number
    o7_5_k4_z: number

    o7_5_k5_budzet: number //.toFixed(2)
    o7_5_k5_donacije: number //.toFixed(2)

    o7_5_k6_obuke: Report[]

    o7_5_k7_svest: boolean
    o7_5_k7_regulative: boolean
    o7_5_k7_softver: boolean
    o7_5_k7_obuka: boolean
    o7_5_k7_prepreke: boolean
    o7_5_k7_vreme: boolean
    o7_5_k7_budzet: boolean

    o7_5_k7_drugo: string //200char

    o7_5_k8_fajl: File

    o7_5_k9_fajl: File //nullable

    submitted_by?: SubmittedBy
}
