import { StepInterfaceObl2 } from './Step1'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import { useWatch } from 'react-hook-form'
import '../../../assets/styles/SuperComplexTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { sumValues } from '../../../utils/mathUtils'
import PositiveNumberInputCell from '../Components/PositiveNumberInputCell'

interface RowInterface {
    label: string
    fields: (keyof TblOblast2)[]
}

const rows: RowInterface[] = [
    { label: 'Од 15-30 година живота', fields: ['o2_k18_kol1_15_30', 'o2_k18_kol2_15_30', 'o2_k18_kol3_15_30', 'o2_k18_kol4_15_30', 'o2_k18_kol5_15_30', 'o2_k18_kol6_15_30'] },
    { label: 'Од 31-40 година живота', fields: ['o2_k18_kol1_31_40', 'o2_k18_kol2_31_40', 'o2_k18_kol3_31_40', 'o2_k18_kol4_31_40', 'o2_k18_kol5_31_40', 'o2_k18_kol6_31_40'] },
    { label: 'Од 41-50 година живота', fields: ['o2_k18_kol1_41_50', 'o2_k18_kol2_41_50', 'o2_k18_kol3_41_50', 'o2_k18_kol4_41_50', 'o2_k18_kol5_41_50', 'o2_k18_kol6_41_50'] },
    { label: 'Од 51-60 година живота', fields: ['o2_k18_kol1_51_60', 'o2_k18_kol2_51_60', 'o2_k18_kol3_51_60', 'o2_k18_kol4_51_60', 'o2_k18_kol5_51_60', 'o2_k18_kol6_51_60'] },
    {
        label: 'Од 61-70 година живота и више',
        fields: ['o2_k18_kol1_61_70', 'o2_k18_kol2_61_70', 'o2_k18_kol3_61_70', 'o2_k18_kol4_61_70', 'o2_k18_kol5_61_70', 'o2_k18_kol6_61_70'],
    },
]
const allFields = [
    'o2_k18_kol1_15_30',
    'o2_k18_kol2_15_30',
    'o2_k18_kol3_15_30',
    'o2_k18_kol4_15_30',
    'o2_k18_kol5_15_30',
    'o2_k18_kol6_15_30',
    'o2_k18_kol1_31_40',
    'o2_k18_kol2_31_40',
    'o2_k18_kol3_31_40',
    'o2_k18_kol4_31_40',
    'o2_k18_kol5_31_40',
    'o2_k18_kol6_31_40',
    'o2_k18_kol1_41_50',
    'o2_k18_kol2_41_50',
    'o2_k18_kol3_41_50',
    'o2_k18_kol4_41_50',
    'o2_k18_kol5_41_50',
    'o2_k18_kol6_41_50',
    'o2_k18_kol1_51_60',
    'o2_k18_kol2_51_60',
    'o2_k18_kol3_51_60',
    'o2_k18_kol4_51_60',
    'o2_k18_kol5_51_60',
    'o2_k18_kol6_51_60',
    'o2_k18_kol1_61_70',
    'o2_k18_kol2_61_70',
    'o2_k18_kol3_61_70',
    'o2_k18_kol4_61_70',
    'o2_k18_kol5_61_70',
    'o2_k18_kol6_61_70',
] as const

const headers = [
    'Старосна структура',
    'Укупна вредност неплаћеног кућног рада особа оба пола, нa годишњем нивоу исказана у динаримa (РСД):',
    'Укупан број сати особа оба пола које проводе у неплаћеном кућном раду, исказаних на годишњем нивоу',
    'Укупна вредност у РСД које жене у јединици територијалне аутономије или локалне самоуправе проводе у неплаћеном кућном раду, исказаних на годишњем нивоу:',
    'Укупан број сати које жене јединици територијалне аутономије или локалне самоуправе проводе у неплаћеном кућном раду, исказаних на годишњем нивоу:',
    'Укупна вредност у РСД које мушкарци у јединици територијалне аутономије или локалне самоуправе проводе у неплаћеном кућном раду, исказаних на годишњем нивоу:',
    'Укупан број сати које мушкарци у јединици територијалне аутономије или локалне самоуправе проводе у неплаћеном кућном раду, исказаних на годишњем нивоу:',
]

type AllFieldsType = (typeof allFields)[number]
const Step18 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterfaceObl2) => {
    const watchedValues = useWatch<TblOblast2>({ control })
    const fields: AllFieldsType[] = [...allFields]

    const columnPatterns = ['_kol1_', '_kol2_', '_kol3_', '_kol4_', '_kol5_', '_kol6_']

    const columnSums: Record<string, number> = {}

    columnPatterns.forEach((pattern) => {
        if (pattern === '_kol2_' || pattern === '_kol4_' || pattern === '_kol6_') {
            const colToSum = fields.filter((field) => field.includes(pattern))
            columnSums[pattern] = sumValues(watchedValues, colToSum, true)
        } else {
            const colToSum = fields.filter((field) => field.includes(pattern))
            columnSums[pattern] = sumValues(watchedValues, colToSum)
        }
    })

    const disabledNext = !fields.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name">
                Подаци о неплаћеном кућном раду на нивоу јединице територијалне аутономије или јединице локалне самоуправе исказани за годину вођења евиденције, разврстани по полу
            </h1>
            <div>
                <table className="super-complex-table mt-[30px] w-[1088px]">
                    <tr className="super-complex-table-header">
                        {headers.map((head, index) => (
                            <th className="super-complex-table-cell !min-w-[172px] !ml-0 py-[15px] px-[25px]" key={index}>
                                {head}
                            </th>
                        ))}
                    </tr>
                    {rows.map((row) => (
                        <tr className="super-complex-table-row">
                            <td className="super-complex-table-cell disabled !min-w-[172px] !ml-0 px-[5px]">{row.label}</td>
                            <td className="super-complex-table-cell editable !min-w-[172px] !ml-0 !border-none">
                                <PositiveNumberInputCell
                                    name={row.fields[0]}
                                    control={control}
                                    decimal={true}
                                    isMultipleTable={false}
                                    errors={errors}
                                    isRequired={!adminView}
                                    disabled={adminView}
                                />
                            </td>
                            <td className="super-complex-table-cell editable !min-w-[172px] !ml-0 !border-none">
                                <PositiveNumberInputCell
                                    name={row.fields[1]}
                                    control={control}
                                    decimal={false}
                                    isMultipleTable={false}
                                    errors={errors}
                                    isRequired={!adminView}
                                    disabled={adminView}
                                />
                            </td>
                            <td className="super-complex-table-cell editable !min-w-[172px] !ml-0 !border-none">
                                <PositiveNumberInputCell
                                    name={row.fields[2]}
                                    control={control}
                                    decimal={true}
                                    isMultipleTable={false}
                                    errors={errors}
                                    isRequired={!adminView}
                                    disabled={adminView}
                                />
                            </td>
                            <td className="super-complex-table-cell editable !min-w-[172px] !ml-0 !border-none">
                                <PositiveNumberInputCell
                                    name={row.fields[3]}
                                    control={control}
                                    decimal={false}
                                    isMultipleTable={false}
                                    errors={errors}
                                    isRequired={!adminView}
                                    disabled={adminView}
                                />
                            </td>
                            <td className="super-complex-table-cell editable !min-w-[172px] !ml-0 !border-none">
                                <PositiveNumberInputCell
                                    name={row.fields[4]}
                                    control={control}
                                    decimal={true}
                                    isMultipleTable={false}
                                    errors={errors}
                                    isRequired={!adminView}
                                    disabled={adminView}
                                />
                            </td>
                            <td className="super-complex-table-cell editable !min-w-[172px] !ml-0 !border-none">
                                <PositiveNumberInputCell
                                    name={row.fields[5]}
                                    control={control}
                                    decimal={false}
                                    isMultipleTable={false}
                                    errors={errors}
                                    isRequired={!adminView}
                                    disabled={adminView}
                                />
                            </td>
                        </tr>
                    ))}
                    <tr className="super-complex-table-footer">
                        <td className="super-complex-table-cell !min-w-[172px] !ml-0 px-[5px]">Укупно</td>
                        {/* 20 */}
                        <td className="super-complex-table-cell !min-w-[172px] !ml-0">{columnSums['_kol1_'].toFixed(2)}</td>
                        {/* 30 */}
                        <td className="super-complex-table-cell !min-w-[172px] !ml-0">{columnSums['_kol2_']}</td>
                        {/* 40 */}
                        <td className="super-complex-table-cell !min-w-[172px] !ml-0">{columnSums['_kol3_'].toFixed(2)}</td>
                        {/* 50 */}
                        <td className="super-complex-table-cell !min-w-[172px] !ml-0">{columnSums['_kol4_']}</td>
                        {/* 60 */}
                        <td className="super-complex-table-cell !min-w-[172px] !ml-0">{columnSums['_kol5_'].toFixed(2)}</td>
                        {/* 70 */}
                        <td className="super-complex-table-cell !min-w-[172px] !ml-0">{columnSums['_kol6_']}</td>
                    </tr>
                </table>
                <div className="h-[20px]">{Object.keys(errors).length > 0 && <p className="text-[0.75rem] ml-[50px] text-[#d32f2f]">Попуните обавезно поље.</p>}</div>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={false} />
        </div>
    )
}

export default Step18
